import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Call = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "23"} height={props.width || "22"} fill={props.fill || "none"} >
      <path d="M21.0014 15.9201V18.9201C21.0025 19.1986 20.9455 19.4743 20.8339 19.7294C20.7223 19.9846 20.5587 20.2137 20.3535 20.402C20.1483 20.5902 19.906 20.7336 19.6421 20.8228C19.3783 20.912 19.0988 20.9452 18.8214 20.9201C15.7442 20.5857 12.7884 19.5342 10.1914 17.8501C7.77523 16.3148 5.72673 14.2663 4.1914 11.8501C2.50138 9.2413 1.44964 6.27109 1.1214 3.1801C1.09641 2.90356 1.12927 2.62486 1.2179 2.36172C1.30652 2.09859 1.44897 1.85679 1.63616 1.65172C1.82336 1.44665 2.0512 1.28281 2.30519 1.17062C2.55917 1.05843 2.83374 1.00036 3.1114 1.0001H6.1114C6.5967 0.995321 7.06719 1.16718 7.43516 1.48363C7.80313 1.80008 8.04348 2.23954 8.1114 2.7201C8.23802 3.68016 8.47285 4.62282 8.8114 5.5301C8.94594 5.88802 8.97506 6.27701 8.8953 6.65098C8.81555 7.02494 8.63026 7.36821 8.3614 7.6401L7.0914 8.9101C8.51495 11.4136 10.5879 13.4865 13.0914 14.9101L14.3614 13.6401C14.6333 13.3712 14.9766 13.1859 15.3505 13.1062C15.7245 13.0264 16.1135 13.0556 16.4714 13.1901C17.3787 13.5286 18.3213 13.7635 19.2814 13.8901C19.7672 13.9586 20.2108 14.2033 20.5279 14.5776C20.8451 14.9519 21.0136 15.4297 21.0014 15.9201Z" stroke={props.stroke ? props.stroke : "black"} stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default Call;
