import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const ContactsV2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512 512"} xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M1198 4940 c-168 -29 -316 -135 -393 -281 -54 -103 -65 -166 -65 -386 l0 -193 180 0 180 0 0 174 c0 186 7 224 49 269 61 67 -53 62 1421 62 1242 0 1337 -1 1366 -17 43 -24 81 -71 94 -117 7 -27 9 -621 8 -1842 -3 -1752 -4 -1805 -22 -1842 -11 -22 -37 -51 -59 -65 l-41 -27 -1338 -3 c-1221 -2 -1341 -1 -1371 14 -84 43 -107 90 -107 224 l0 100 -180 0 -180 0 0 -110 c0 -134 12 -194 57 -288 43 -91 141 -194 228 -239 127 -66 42 -63 1538 -63 945 0 1372 3 1409 11 199 41 370 214 413 416 22 103 22 3674 0 3778 -40 188 -180 345 -364 408 l-66 22 -1355 1 c-745 1 -1376 -2 -1402 -6z"/>
          <path d="M2454 4206 c-195 -45 -364 -173 -452 -345 -54 -102 -72 -168 -78 -281 -16 -266 130 -517 368 -634 202 -100 401 -100 606 0 264 129 414 429 361 720 -44 239 -220 442 -450 518 -103 34 -258 44 -355 22z m284 -387 c233 -119 225 -452 -13 -570 -41 -20 -65 -24 -135 -24 -70 0 -94 4 -135 24 -140 69 -213 229 -172 372 59 203 268 293 455 198z"/>
          <path d="M404 3981 c-136 -62 -137 -257 -3 -327 41 -21 1076 -21 1117 0 36 19 78 65 91 99 24 64 3 155 -46 199 -52 47 -61 48 -605 48 -479 0 -516 -2 -554 -19z"/>
          <path d="M740 3185 l0 -365 180 0 180 0 0 365 0 365 -180 0 -180 0 0 -365z"/>
          <path d="M2350 2769 c-294 -77 -514 -307 -574 -601 -13 -64 -16 -157 -16 -555 0 -445 2 -481 19 -519 22 -49 70 -88 126 -103 56 -15 1314 -15 1370 0 57 16 104 55 127 106 18 42 19 67 16 551 l-3 507 -33 95 c-93 268 -289 451 -556 520 -111 28 -367 28 -476 -1z m442 -376 c119 -56 204 -165 228 -292 5 -30 10 -209 10 -402 l0 -349 -441 0 -440 0 3 383 c3 374 4 383 27 440 31 78 94 154 161 195 88 52 130 62 265 59 112 -2 125 -4 187 -34z"/>
          <path d="M365 2716 c-17 -7 -45 -29 -62 -49 -78 -89 -46 -230 63 -279 41 -18 69 -19 567 -16 l523 3 41 27 c46 31 83 97 83 149 -1 50 -33 112 -76 145 l-37 29 -536 2 c-428 2 -542 0 -566 -11z"/>
          <path d="M740 1920 l0 -360 180 0 180 0 0 360 0 360 -180 0 -180 0 0 -360z"/>
          <path d="M428 1460 c-96 -29 -152 -144 -117 -237 13 -34 55 -80 91 -99 22 -12 120 -14 560 -14 l533 0 40 23 c59 34 87 87 83 163 -3 49 -10 69 -31 98 -57 74 -32 71 -612 73 -286 1 -532 -2 -547 -7z"/>
        </g>
      </svg>
    </span>
  );
};

export default ContactsV2;
