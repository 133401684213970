import { h } from 'preact';
// from flaticon
const CompletedTask = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width || ""} height={props.height || ""} viewBox="0 0 18 22" fill="none">
        <path d="M11.5 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V6.5L11.5 1Z" stroke={props.stroke || "none"} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11 1V7H17" stroke={props.stroke || "none"} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 14L8 16L12 12" stroke={props.stroke || "none"} stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
  );
};

export default CompletedTask;
