/*
  modified : Ashutosh G
  modified : 01/03/2024
  modification : Chatbot comp added
*/
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Send, ChatIcon, Cancel, ChatIcon2, RoboFace } from '../../components/svgs';
import { callAPI, reportException } from '../../lib/transceiver';
import { getItem } from '../../lib/myStore';
import SmallLoader from '../../components/smallLoader';
import { formatDateTime, formatTimeString, formatDateTimeRelative } from '../../lib/utils';
import { route } from 'preact-router';

const Chatbot = (props) => {
  const { setShowMobileFooter } = props;
  let userInfo = getItem('userinfo');
  const fileName = 'chatBot';
  const [showChatbot, setShowChatbot] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const chatboxRef = useRef(null);
  const formRef = useRef(null);
  const chatInputRef = useRef(null);
  const [currentResponse, setCurrentResponse] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputInitHeight, setInputInitHeight] = useState(null);
  const [isBotChatLoading, setIsBotChatLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  let pageLimit = 10;
  const [totalPages, setTotalPages] = useState(null);
  const [prevMessageCount, setPrevMessageCount] = useState(null);
  const [scrollTo, setScrollTo] = useState(null);
  /*
    modified : Ashutosh G
    modified : 02/03/2024
    modification : getting initial height of chatInputBox
  */
  useEffect(() => {
    setInputInitHeight(chatInputRef.current.scrollHeight);
    /*
      modified : Ashutosh G
      modified : 08/03/2024
      modification : called getTotalMessageCount to get total message count
    */
    getTotalMessageCount();
  }, []);

  useEffect(() => {
    /*
      modified : Ashutosh G
      modified : 07/03/2024
      modification : called to get previous message with bot by user on currentPageNo change
    */
    if (userInfo.uuid) {
      getPreviousChatOfUserWithBot();
    }
  }, [currentPageNo]);

  /*
    modified : Ashutosh G
    modified : 04/03/2024
    modification : when chatbot is opened then hiding footer buttons
  */
  useEffect(() => {
    if (showChatbot) {
      setShowMobileFooter(false);
    }
  }, [showChatbot]);

  /*
    modified : Ashutosh G
    modified : 02/03/2024
    modification : setting input event to set height to chatInputBox and keyPress to detect enter to send message
  */
  useEffect(() => {
    let chatInput = chatInputRef.current;

    const handleInput = () => {
        // Adjust the height of the input textarea based on its content
        chatInput.style.height = `${inputInitHeight}px`;
        chatInput.style.height = `${chatInput.scrollHeight}px`;
    };

    const handleKeyDown = (e) => {
        // If Enter key is pressed without Shift key and the window
        // width is greater than 800px, handle the chat
        if (e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
            e.preventDefault();
            handleSubmit();
        }
    };

    chatInput.addEventListener("input", handleInput);
    chatInput.addEventListener("keydown", handleKeyDown);

    // Clean up the event listeners when the component unmounts
    return () => {
        chatInput.removeEventListener("input", handleInput);
        chatInput.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSubmit]);

  /*
    modified : Ashutosh G
    modified : 08/03/2024
    modification : if scrollTo contain id then scrolling to element else scrolling to the bottom of chatbox div
  */
  useEffect(() => {
    if (scrollTo) {
      let element = document.getElementById(scrollTo);
      element.scrollIntoView({ block: 'start' });
      setScrollTo(null);
    } else if (prevMessageCount !== messages.length) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
      setPrevMessageCount(messages.length);
    }
  }, [messages]);

  /*
    modified : Ashutosh G
    modified : 02/03/2024
    modification : clearing userMessage and reseting chatInputBox Height
  */
  useEffect(() => {
    setUserMessage('');
    chatInputRef.current.style.height = `${inputInitHeight}px`;
  }, [messages.length]);

  useEffect(() => {
    if (selectedOption) {
      getDetailsForSelectedOption();
    }
  }, [selectedOption]);

  const toggleChatbot = () => {
    document.body.classList.toggle("show-chatbot");
    setShowChatbot(prevValue => !prevValue);
  };

  const handleInputChange = (e) => {
    setUserMessage(e.target.value);
  };

  /*
    modified : Ashutosh G
    modified : 07/03/2024
    modification : getPreviousChatOfUserWithBot added to get previous chatbot messages made by user
    getting older chat and merging with current chat
  */
  async function getPreviousChatOfUserWithBot() {
    try {
      setIsBotChatLoading(true);
      let params = {
        uuids : userInfo.uuid,
        pageNo: currentPageNo,
        pageSize: pageLimit,
        responseReverse: true
      };

      /*
        modified : Ashutosh G
        modified : 08/03/2024
        modification : if messages contain first message then adding that message formatted id scrollTo variable
      */
      if (messages[0] && !scrollTo) {
        setScrollTo(`message-${messages[0].createdAt}`);
      }
      let previousChat = await callAPI(`get`, `/v1/chatbotMessage`, new URLSearchParams(params).toString());
      let formattedPreviousChat = [];
      previousChat.data.map(message => {
        if (message.question) {
          formattedPreviousChat.push({ content: message.question, sender: "user", createdAt: message.createdAt });
        }
        formattedPreviousChat.push({ content: "Response from the bot", sender: "bot", response: message.result, messageConfig: messageConfigDeterminer(message.result), createdAt: message.createdAt });
      });
      formattedPreviousChat.push({ content: "Hi there 👋 How can I help you today?", sender: "bot", createdAt: new Date() });
      setMessages(prevMessages => [...formattedPreviousChat, ...prevMessages]);
      setIsBotChatLoading(false);
    } catch (HTTPException) {
      setIsBotChatLoading(false);
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getPreviousChatOfUserWithBot'
      });
    }
  }

  /*
    modified : Ashutosh G
    modified : 08/03/2024
    modification : getting total count of message to count total pages
  */
  async function getTotalMessageCount() {
    try {
      let params = {
        uuids : userInfo.uuid,
      };
      let chatPageCount = await callAPI(`get`, `/v1/chatbotMessage/count`, new URLSearchParams(params).toString());
      chatPageCount =  chatPageCount.data.COUNT || 0;
      let pageCount = Math.ceil(chatPageCount / pageLimit)
      setTotalPages(pageCount);
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getTotalMessageCount'
      });
    }
  }

  /*
    modified : Ashutosh G
    modified : 02/03/2024
    modification : adding limit in message config and if case complete then adding url
  */
  function messageConfigDeterminer(response) {
    let obj = {};
    if (response.interactions && response.interactions.length > 1) {
      obj = { type: "selection", displayKey: "interactions", limit: 10, recordsLength: response.interactions.length };
    } else if (response.tasks && response.tasks.length > 1) {
      obj = { type: "selection", displayKey: "tasks", limit: 10, recordsLength: response.tasks.length };
      if (response.interactionID) {
        obj.url = `/processView/${response.interactionID}`;
      }
    } else if (response.hasOwnProperty("messageText") && Object.keys(response).length === 1) {
      obj = { type: "onlyMessage" };
    } else {
      obj = { type: "standard" } ;
      if (response.goToCase) {
        obj.url = `/processView/${response.interactionID}`
      }
    }
    return obj;
  }

  async function handleSubmit () {
    try {
      // e.preventDefault();
      if (userMessage.trim() === '') return;
      setMessages([...messages, { content: userMessage, sender: "user", createdAt: new Date() }]);
      // Call API and handle response
      // Here you should make API calls and handle responses accordingly

      setMessages(prevMessage => [...prevMessage, { content: "Fetching details...", sender: "bot", createdAt: new Date() }]);
      let params = {
        question: userMessage
      };
      let response = await callAPI(`get`, `/v1/personalBotResponse`, new URLSearchParams(params).toString());

      setMessages(prevMessage => [...prevMessage.slice(0, -1), { content: "Response from the bot", sender: "bot", response: response.data, messageConfig: response.data && messageConfigDeterminer(response.data), createdAt: new Date() }]);
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'handleSubmit'
      });
    }
  };

  const getDetailsForSelectedOption = async () => {
    try {
      setMessages(prevMessage => [...prevMessage, { content: "Fetching details...", sender: "bot", createdAt: new Date() }]);
      console.log(messages,"mmmmmmmmmmmmmmmmmm");
      let params = {
        interactionID: selectedOption.uuid,
        question: messages[messages.length-2]['content']
      };
      let response = await callAPI(`get`, `/v1/personalBotResponse`, new URLSearchParams(params).toString());

      setMessages(prevMessage => [...prevMessage.slice(0, -1), { content: "Response from the bot", sender: "bot", response: response.data, messageConfig: response.data && messageConfigDeterminer(response.data), createdAt: new Date() }]);
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getDetailsForSelectedOption'
      });
    }
  };

  /*
    modified : Ashutosh G
    modified : 08/03/2024
    modification : used renderedBotChat with useMemo to render in on change in messages
  */
  const renderedBotChat = useMemo(() => {
    return messages.map((message, index) => {
      let currentMessageDate = formatDateTime(message.createdAt);
      let prevMessageDate = index > 0 ? formatDateTime(messages[index - 1].createdAt) : null;
      return(
        <>
        {
          currentMessageDate !== prevMessageDate &&
            <li>
              <p class='date-divider-chatbot pos-relative' id={`${index}`}>{formatDateTimeRelative(message.createdAt)}</p>
            </li>
        }
        {/*
          modified : Ashutosh G
          modified : 08/03/2024
          modification : adding id which is combination of message text and createdAt for element identification with message
        */}
        <li id={`message-${message.createdAt}`} key={index} className={`chat ${message.sender === 'bot' ? "incoming" : "outgoing"} ${index === 0 && "m-t-0"} ${messages.length - 1 === index && "m-b-0"}`}>
          { message.sender === 'bot' && <RoboFace width="30px" height="30px"  masterClasses={`w-30px h-30px`} fill="#002c5f"/> }
          <MessageTemplate index={index} message={message} setSelectedOption={setSelectedOption} setMessages={setMessages}/>
        </li>
        </>
      )
    });
  }, [messages]);

  // Attach scroll event listener
  useEffect(() => {
    chatboxRef.current.addEventListener('scroll', handleScroll);
    return () => {
      chatboxRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [isBotChatLoading]);

  // Function to handle scroll events
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatboxRef.current;
    if (scrollTop === 0 && !isBotChatLoading && currentPageNo < totalPages) {
      // fetchMessages(page);
      setCurrentPageNo(prevValue => prevValue + 1);
    }
  };

  return (
    <>
      <div className="chatbot-toggler" onClick={(e) => route('/collaboratorAI')}>
        { showChatbot ? <Cancel width="20px" height="20px"  masterClasses={`w-20px h-20px`} fill="#002c5f"/> : <ChatIcon2 width="24px" height="24px" masterClasses={`w-24px h-24px op-unset`} fill="#002c5f"/>}
      </div>
      <div className="chatbot">
        <div class="pos-relative">
          <h2 class="chatbot-header">Chatbot</h2>
          <Cancel width="20px" height="20px"  masterClasses={`w-20px h-20px mobilechatbot-cancel-btn cursor-pointer`} fill="#002c5f" action={toggleChatbot}/>
        </div>
        <ul className="chatbox pos-relative" ref={chatboxRef}>
          {
            isBotChatLoading &&
              <SmallLoader masterClasses='ab-c-t top-21 z-index-101' typeOfLoader='halfSpinningCircle' color='black' style={`width: 30px; height: 30px;`} />
          }
          {/*
            modified : Ashutosh G
            modified : 02/03/2024
            modification : adjust margin of first and last message
          */}
          {renderedBotChat}
        </ul>
        <form ref={formRef} className="chatbot-chat-input">
          <textarea
            ref={chatInputRef}
            placeholder="Enter a message..."
            value={userMessage}
            onChange={handleInputChange}
            spellCheck="false"
            required
          ></textarea>

          <Send width="24px" height="24px" fill="#002c5f" action={handleSubmit} />
        </form>
      </div>
    </>
  );
}

const MessageTemplate = (props) => {
  const { index, message, setSelectedOption, setMessages } = props;
  let { response } = message;
  let startingPoint = 0;

  function selectOption(value) {
    setSelectedOption(value);
  }

  /*
    modified : Ashutosh G
    modified : 02/03/2024
    modification : updateMessageDislayLimit function update limit when click on View All and View Less
  */
  function updateMessageDislayLimit (e, index) {
    setMessages((prevMessages) => {
      let tempMessages = prevMessages;
      if (tempMessages[index] && tempMessages[index].messageConfig && tempMessages[index].messageConfig.limit) {
        tempMessages[index].messageConfig.limit = null;
      } else {
        tempMessages[index].messageConfig.limit = 10;
      }
      return [...tempMessages];
    });
  }

  return (
    <div class={`chatBot-MessageContainer ${message.sender === 'bot' ? "incomingMsg" : "outgoingMsg"}`}>
    {
      message.messageConfig ?
        message.messageConfig.type === "standard"
        ? <>
            {response.interactionName && <p class="m-b-15">{response.interactionName}</p>}
            <p>{response.messageText}</p>
            {message.messageConfig.url &&
              <div class="text-right">
                <button class="primary-button m-t-10"><a href={message.messageConfig.url} target="_blank">Go to case</a></button>
              </div>
            }
          </>
        : message.messageConfig.type === "selection"
        ? <>
            <p class="m-b-15">{response.messageText}</p>
            <div>
              {
                (response[message.messageConfig.displayKey] && message.messageConfig.recordsLength) && response[message.messageConfig.displayKey].slice(startingPoint, startingPoint + message.messageConfig.limit ? message.messageConfig.limit : message.messageConfig.recordsLength).map((item, index) => {
                  return (
                    message.messageConfig.displayKey === "interactions"
                      ? <div class="selectable">
                          <div class="dis-flex gap-5px">
                            <p class="p-t-1">{`${index + 1}.`}</p>
                            <div>
                              <p>{item.customerName}</p>
                              <p>{item.caseID}</p>
                            </div>
                          </div>
                          <div class="additional-element text-right">
                            <button class="primary-button-withouthover" onClick={() => selectOption(item)}>Get Case Details</button>
                          </div>
                        </div>
                      : <div class="selectable cursor-default-imp">
                          <div class="dis-flex gap-5px">
                            <p class="p-t-10">{`${index + 1}.`}</p>
                            <div>
                              <table>
                                <tbody>
                                  <tr><td class="w-32px">Name:</td><td>{item.displayName}</td></tr>
                                  <tr><td class="w-32px">Pending With:</td><td>{item.pendingAt}</td></tr>
                                  <tr><td class="w-32px">Due Date:</td><td>{item.pendingSince}</td></tr>
                                </tbody>
                              </table>
                              <div class="additional-element text-right">
                                {message.messageConfig.url && <button className="primary-button-withouthover m-t-5"><a href={message.messageConfig.url} target="_blank">Go to case</a></button>}
                              </div>
                            </div>
                          </div>
                        </div>
                  )
                })
              }
              {
                message.messageConfig.recordsLength > 10 &&
                <div class="w-full text-right m-t-10">
                  <button id={`viewRecord-${index}`} class="primary-button" onClick={(e) => updateMessageDislayLimit(e, index)}>{message.messageConfig.limit ? "View All" : "View Less"}</button>
                </div>
              }
            </div>
          </>
        :  message.messageConfig.type === "onlyMessage"
        && <>
            <p>{response.messageText}</p>
          </>
      : <p>{message.content}</p>
    }
    {message.createdAt && <p class="chatbox-messageTime">{formatTimeString(message.createdAt)}</p>}
    </div>
  );
}
export default Chatbot;
