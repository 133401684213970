import { h, Fragment } from 'preact';
import { getItem, setItem } from '../../lib/myStore';
import { getInitials, formatDateTime, formatDateTimeRelative, isValidDate, getDateColor, getTextWithHighlightMatching} from '../../lib/utils';

const NewListRow = (props) => {
  const {row, columns, lastColumnClasses, rowAction, entityName, isShowHighlightText, highlightedText} = props;

  let userInfo = getItem('userinfo');

  return (
    <div class={`workspace-list-row w-full ${lastColumnClasses ? lastColumnClasses : ''} ${rowAction ? 'actionable-row' : ''}`} onclick={() => rowAction && rowAction(row, entityName)}>
      {
        columns.map((column, index) => (
          <Fragment>
            <Fragment>
              <div class={`workspace-list-row-col ${column.classes ? `${column.classes}` : ''} ${!index ? 'p-l-40' : 'p-l-8'}`}>
                {
                  column.isDate &&
                  <p class="fs-11 text-theme-color">{formatDateTime(row[column.field])}</p>
                }
                {
                  column.isBoolean &&
                  <p class="fs-11 text-theme-color">{row[column.field] ? 'Yes' : 'No'}</p>
                }
                {
                  column.isPendingSinceDays &&
                  <p class="fs-11 text-theme-color" title={(column.pendingSinceKey && row[column.pendingSinceKey]) ? formatDateTime(row[column.pendingSinceKey]) : ''}>{row[column.field]}</p>
                }
                {
                  isShowHighlightText && highlightedText ?
                    <Fragment>
                      {
                        !column.isDate && !column.isBoolean && !column.isPendingSinceDays &&
                        <p class="fs-11 text-theme-color truncate" title={row[column.field]} dangerouslySetInnerHTML={getTextWithHighlightMatching(row[column.field], highlightedText)} />
                      }
                    </Fragment>
                    :
                    <Fragment>
                      {
                        !column.isDate && !column.isBoolean && !column.isPendingSinceDays &&
                        <p class="fs-11 text-theme-color truncate" title={row[column.field]}>{row[column.field]}</p>
                      }
                    </Fragment>
                }
              </div>
            </Fragment>
          </Fragment>
        ))
      }
      {/*<div style="width:120px" class="workspace-list-row-col ">
        <p class="fs-11 text-theme-color ">{props.customerName}</p>
        <p class=" "> {props.case}</p>
      </div>
      <div style="width:100px" class="workspace-list-row-col  p-l-8">
        <p class="fs-10 workspace-list-row-pill  ">New Lead</p>
      </div>
      <div style="width:100px" class="workspace-list-row-col flex-l-m p-l-8">
        { (props.assignee !== userInfo.displayName) ? (<p class="fs-10 workspace-list-row-pill2 ">Me</p>)
          : (<p class="fs-10 text-theme-color "> {props.assignee} </p>)
        }
      </div>
      <div style="width:200px" class="workspace-list-row-col  p-l-8">
        <span>icon </span>
        <span>Customer not responding</span>
      </div>
      <div style="width:100px" class="workspace-list-row-col  p-l-8">
        {props.dueDate ? (isValidDate(props.dueDate) ? formatDateTimeRelative(props.dueDate) : props.dueDate) : "--" }
      </div>
      <div style="width:100px" class="workspace-list-row-col  p-l-8">
        {(props.caseType === "actionable") ? <span>{props.action ? props.action : "--"} </span>:<span><SirenSm /></span>}
      </div>*/}
    </div>
  );
};


export default NewListRow;
