/*
  Modification: create file Component
  By: Komal
  Date: 24/04/2023
*/
import { h } from 'preact';
// from flaticon
const File = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width={props.width || "256pt"} height={props.height || "256pt"} fill-rule="nonzero"><g fill="gray" fill-rule="nonzero" stroke="#dddddd" stroke-width="3" stroke-linecap="butt" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path transform="scale(9.84615,9.84615)" d="M23,8v14c0,2.20313 -1.79687,4 -4,4h-12c-2.20312,0 -4,-1.79687 -4,-4v-18c0,-2.20312 1.79688,-4 4,-4h8c1.0625,0 2.08203,0.98828 3.79297,2.73438c0.24219,0.24609 0.48438,0.49609 0.73438,0.74219c0.24609,0.24609 0.49609,0.49219 0.73828,0.73047c1.74609,1.71094 2.73438,2.73047 2.73438,3.79297z" id="strokeMainSVG"/></g><g fill-opacity="0.45098" fill="#000000" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(9.84615,9.84615)"><path d="M20.26563,4.20703c-0.24219,-0.23828 -0.49219,-0.48437 -0.73828,-0.73047c-0.25,-0.24609 -0.49219,-0.49609 -0.73437,-0.74219c-1.71094,-1.74609 -2.73047,-2.73437 -3.79297,-2.73437h-8c-2.20312,0 -4,1.79688 -4,4v18c0,2.20313 1.79688,4 4,4h12c2.20313,0 4,-1.79687 4,-4v-14c0,-1.0625 -0.98828,-2.08203 -2.73437,-3.79297zM21,22c0,1.10547 -0.89453,2 -2,2h-12c-1.10547,0 -2,-0.89453 -2,-2v-18c0,-1.10547 0.89453,-2 2,-2l7.28906,-0.00391c0.72266,0.18359 0.71094,1.07031 0.71094,1.95703v3.04688c0,0.55078 0.44922,1 1,1h3c0.99609,0 2,0.00391 2,1z"/></g></g>
      </svg>
    </span>
  );
};

export default File;
