import { h } from 'preact';
// from flaticon
const CommercialVisit = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width || ""} height={props.height || ""} viewBox="3 8 30 30" fill="none">
        <path d="M19.4238 27.7051L21.9375 20.4688H23.1621L20.0039 29H19.1309L19.4238 27.7051ZM17.0742 20.4688L19.5645 27.7051L19.875 29H19.002L15.8496 20.4688H17.0742Z" fill="white" />
        <path d="M17.5 8H22.5" stroke={props.stroke || "none"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.5 9.94446H22.5" stroke={props.stroke || "none"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.5 11.8889L21.0417 15" stroke={props.stroke || "none"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.5 11.8889H18.75" stroke={props.stroke || "none"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.75 11.8889C21.5279 11.8889 21.5279 8 18.75 8" stroke={props.stroke || "none"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.6056 13.4567V13.4567C6.13888 17.5829 5.1405 26.6405 10.5647 32.0647L10.9978 32.4978C15.6841 37.1841 23.2821 37.1841 27.9684 32.4978L28.426 32.0402C33.8456 26.6206 32.8568 17.5771 26.3942 13.4567V13.4567" stroke={props.stroke || "none"} stroke-linecap="round" />
      </svg>
    </span>
  );
};

export default CommercialVisit;
