import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { getInitials } from '../../lib/utils';

const CallingButton = (props) => {


  return (
    <div class="w-full display-flex">
      <div class="callingFloat">
        <div class="trigger">
          <div class='container'>
            {
            /* <img class='avatarImg' src='/assets/images/profile.jpg' /> */
              <span class='textSpan'>{getInitials(props.userDisplayName)}</span>
            }
          </div>
          {/* <i class="fa fa-volume-control-phone" aria-hidden="true"/> */}
        </div>
      </div>
    </div>
  );
};

export default CallingButton;
