import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Table = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg fill={props.fill || '#000'} class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '10px'} height={props.height || '10px'} version="1.1"
	id="Layer_1" x="0px" y="0px"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
      >
        <path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
      </svg>
    </span>
  );
};
export default Table;
