import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Tracker = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg lass={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.width || "512.000000pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M946 5110 c-237 -38 -443 -140 -611 -305 -133 -130 -213 -255 -272 -425 -46 -130 -58 -209 -57 -370 1 -230 48 -385 183 -607 43 -71 229 -377 414 -681 185 -303 337 -555 337 -559 0 -3 -18 -21 -39 -40 -96 -81 -126 -238 -68 -355 104 -211 405 -223 522 -20 l30 52 425 -3 c408 -2 427 -3 471 -23 66 -31 114 -78 146 -142 23 -47 28 -70 28 -132 -1 -124 -56 -214 -163 -267 l-57 -28 -690 -5 c-681 -5 -691 -5 -748 -27 -240 -91 -377 -332 -326 -575 39 -187 200 -348 387 -387 35 -7 236 -11 588 -11 580 0 577 0 602 55 7 14 12 35 12 46 0 28 -26 75 -47 85 -10 5 -268 11 -573 14 l-555 5 -57 28 c-107 53 -163 144 -163 267 0 123 56 214 163 267 l57 28 690 5 c681 5 691 5 748 27 240 91 377 332 326 575 -39 187 -200 348 -387 387 -34 7 -203 11 -465 11 l-412 0 -24 46 c-14 25 -42 60 -63 78 -21 18 -38 36 -38 39 0 4 152 256 337 559 185 304 371 610 414 681 43 70 92 163 109 207 160 417 72 860 -235 1175 -158 162 -341 264 -560 311 -85 19 -298 26 -379 14z m379 -220 c313 -81 560 -328 646 -645 18 -65 22 -107 22 -225 0 -161 -17 -249 -73 -368 -30 -64 -811 -1352 -820 -1352 -9 0 -790 1287 -820 1352 -56 119 -73 207 -73 368 0 118 4 160 22 225 92 341 366 595 709 660 104 20 282 13 387 -15z m-176 -2904 c67 -35 65 -143 -4 -174 -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"/>
          <path d="M983 4506 c-219 -53 -383 -261 -383 -486 0 -270 230 -500 500 -500 270 0 500 230 500 500 0 274 -233 503 -508 499 -31 0 -80 -6 -109 -13z m251 -220 c105 -51 161 -144 161 -266 0 -85 -21 -142 -74 -203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430 80 126 246 170 384 103z"/>
          <path d="M3866 3450 c-237 -38 -443 -140 -611 -305 -133 -130 -213 -255 -272 -425 -47 -132 -58 -208 -57 -375 1 -126 5 -165 27 -246 46 -176 76 -229 470 -848 209 -328 393 -618 410 -644 l29 -48 -39 -34 c-22 -18 -50 -54 -64 -79 l-24 -46 -447 0 c-480 0 -488 -1 -514 -51 -27 -52 -8 -115 41 -137 19 -9 148 -12 473 -12 l447 -1 30 -51 c93 -161 314 -194 458 -69 92 82 121 237 64 353 -16 32 -46 73 -69 92 l-40 35 29 48 c17 26 201 316 410 644 239 375 392 625 413 674 174 419 88 879 -225 1200 -158 162 -341 264 -560 311 -85 19 -298 26 -379 14z m379 -220 c313 -81 560 -328 646 -645 18 -65 22 -107 22 -225 0 -167 -18 -252 -81 -382 -35 -73 -801 -1287 -812 -1287 -11 0 -777 1214 -812 1287 -63 130 -81 215 -81 382 0 118 4 160 22 225 92 341 366 595 709 660 104 20 282 13 387 -15z m-176 -2844 c47 -25 63 -83 37 -135 -35 -66 -137 -66 -172 0 -47 91 44 182 135 135z"/>
          <path d="M3903 2846 c-219 -53 -383 -261 -383 -486 0 -270 230 -500 500 -500 270 0 500 230 500 500 0 274 -233 503 -508 499 -31 0 -80 -6 -109 -13z m251 -220 c105 -51 161 -144 161 -266 0 -85 -21 -142 -74 -203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430 80 126 246 170 384 103z"/>
          <path d="M2361 386 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124 -149 87z"/>
        </g>
      </svg>
    </span>
  );
};

export default Tracker;
