import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";
import axios from "axios";
import CONSTANTS from '../../lib/constants';
import moment from "moment";




const StageDetailModal = (props) => {


  return (
    <div class="profilemodalslide">
      <div class="profile-modal-container pos-relative" style='overflow: hidden; background: white'>
        <div class="profile-modal">
          <div class="profile-header-container">
            <div class="profile-header" style='border-bottom: 1px solid #003468 !important;'>
              <div class="profile-left-header-container">
                <span class="profile-left-header-text p-t-5 font-bold fs-20 text-408b8b" style="height: fit-content;">
                  {props.modalFor} Details
                </span>
              </div>
            </div>
          </div>
          <div class="profile-content-container" style='overflow: hidden'>
            <div>
              <div class="" style="height: calc( 100vh - 100px ); overflow: hidden auto" >
                <div class="col-xs-12 col-lg-12 p-l-0 p-r-0 m-t-5" title="Click to view the notification">
                    Details
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default StageDetailModal;
