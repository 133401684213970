import { route } from 'preact-router';
import { unProtectedRoutes } from './routes';

/*
  Modification: added new condition for customer login route
  By: Masum Raja
  Date: 20/07/2023
*/

export class AppStore {
  static get(key) {
    /*
      Modification: T1654 - Added condition to verify unProtectedRoutes for routing to / page
      By: Devang
      Date: 26/03/2024
    */
    /*
      Modification: Commented below condition as not required as is logged in already doing the job this snippet was created for
      By: Devang
      Date: 30/05/2024
    */
    /*if (!window.localStorage.length && !unProtectedRoutes.includes(window.location.pathname) && !(window.location.pathname.startsWith('/caseDetail') && window.location.pathname.endsWith('/login'))) {
      route(`/`);
    }*/
    if (!window.localStorage.getItem(key) || window.localStorage.getItem(key) !== "undefined") {
      return JSON.parse(window.localStorage.getItem(key) || '{}');
    }

    return JSON.parse('{}');
  }
  static set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  static remove(key) {
    window.localStorage && window.localStorage.removeItem(key);
  }
  static removeAll() {
    window.localStorage && window.localStorage.clear();
  }
}
