import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Laptop = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" fill={props.fill || ''} stroke={props.stroke || 'none'} width={props.width || "24px"} height={props.height || "24px"} viewBox={props.viewBox || "0 0 32 32"}
        preserveAspectRatio="xMidYMid meet"
      >
        <g>
      	<path fill="#828282" d="M1.5,25H19v1.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V25h17.5c0.827,0,1.5-0.673,1.5-1.5v-22C39,0.673,38.327,0,37.5,0h-36C0.673,0,0,0.673,0,1.5v22C0,24.327,0.673,25,1.5,25z M1,1.5C1,1.224,1.224,1,1.5,1h36C37.776,1,38,1.224,38,1.5v22c0,0.276-0.224,0.5-0.5,0.5h-36C1.224,24,1,23.776,1,23.5V1.5z"/>
      	<path fill="#828282" d="M3.5,22h32c0.276,0,0.5-0.224,0.5-0.5v-18C36,3.224,35.776,3,35.5,3h-32C3.224,3,3,3.224,3,3.5v18C3,21.776,3.224,22,3.5,22z M4,4h31v17H4V4z"/>
      	<path fill="#828282" d="M14,28c-1.14,0-2,0.86-2,2v1.5c0,0.276,0.224,0.5,0.5,0.5h14c0.276,0,0.5-0.224,0.5-0.5v-1.425C27,28.912,26.122,28,25,28H14z M26,30.075V31H13v-1c0-0.589,0.411-1,1-1h11C25.57,29,26,29.462,26,30.075z"/>
        </g>
      </svg>
    </span>
  );
};

export default Laptop;
