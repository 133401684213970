import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const FieldInput = (props) => {
  return (
    <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || '16'}
      height={props.height || "18"} viewBox="0 0 9 11"
    >
      <path d="M5.59375 1H1.875C1.64294 1 1.42038 1.09219 1.25628 1.25628C1.09219 1.42038 1 1.64294 1 1.875V8.875C1 9.10706 1.09219 9.32962 1.25628 9.49372C1.42038 9.65781 1.64294 9.75 1.875 9.75H7.125C7.35706 9.75 7.57962 9.65781 7.74372 9.49372C7.90781 9.32962 8 9.10706 8 8.875V3.40625L5.59375 1Z" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.375 1V3.625H8" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.25 5.8125H2.75" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.25 7.5625H2.75" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.625 4.0625H2.75" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default FieldInput;
