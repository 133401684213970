import { h } from 'preact';
// from flaticon

const Refresh = (props) => {
  return (
    <span onclick={() => props.action && props.action()} class={props.masterClasses || ''} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512"} height={props.height || "512"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M3556 4465 c-76 -27 -135 -110 -136 -190 0 -22 7 -78 15 -125 8 -47 15 -91 15 -98 0 -8 -21 -2 -61 18 -454 230 -1007 255 -1484 68 -416 -162 -762 -481 -959 -882 -120 -242 -174 -463 -183 -736 -6 -205 5 -327 47 -500 118 -484 433 -898 869 -1145 643 -364 1460 -296 2032 171 101 83 266 258 341 364 76 107 192 334 233 455 38 114 75 281 75 343 0 113 -65 192 -178 217 -74 16 -182 -37 -217 -106 -9 -16 -24 -73 -35 -128 -69 -344 -252 -635 -529 -844 -235 -177 -481 -263 -781 -274 -258 -8 -440 30 -665 142 -399 198 -667 555 -751 1000 -23 119 -23 348 -1 471 91 508 443 920 921 1078 159 53 267 70 446 70 180 0 300 -19 442 -70 80 -29 242 -103 236 -109 -2 -2 -71 -15 -153 -30 -105 -19 -163 -34 -192 -51 -53 -31 -94 -99 -101 -165 -10 -101 74 -206 177 -224 37 -6 771 112 849 137 76 24 141 113 142 193 0 44 -124 782 -140 831 -6 17 -25 46 -43 64 -63 64 -148 85 -231 55z"/>
        </g>
      </svg>
    </span>
  );
};

export default Refresh;
