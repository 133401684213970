import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const CaseV2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M1254 4836 c-46 -20 -99 -71 -117 -113 -25 -56 -1127 -3880 -1134 -3934 -12 -100 55 -205 158 -242 24 -9 248 -75 498 -147 322 -92 469 -130 506 -130 74 0 151 41 187 100 20 33 166 522 537 1810 l510 1765 3 -1750 3 -1750 21 -40 c29 -54 70 -94 119 -116 37 -17 78 -19 576 -19 l537 0 56 29 c35 19 58 26 62 19 4 -5 25 -19 48 -29 38 -17 75 -19 579 -19 429 0 544 3 570 13 52 22 98 68 123 122 l24 50 0 2050 0 2050 -24 50 c-27 58 -78 107 -133 126 -30 11 -147 14 -568 14 -529 0 -530 0 -572 -22 -23 -13 -44 -27 -48 -33 -3 -6 -21 0 -43 15 -21 14 -62 29 -91 35 -71 13 -971 13 -1042 0 -86 -17 -165 -87 -185 -164 -7 -31 -5 -30 -46 -11 -18 8 -168 53 -333 101 -165 47 -376 108 -470 135 -179 52 -253 60 -311 35z m555 -261 c533 -153 519 -148 537 -190 13 -31 -11 -118 -547 -1976 -408 -1413 -566 -1950 -582 -1967 -13 -14 -32 -22 -52 -22 -17 0 -224 56 -460 124 -237 68 -449 129 -472 135 -44 12 -83 51 -83 82 0 14 960 3358 1096 3819 27 93 49 120 96 120 17 0 227 -56 467 -125z m1820 14 c66 -23 61 135 61 -2088 l0 -2019 -26 -31 -26 -31 -517 0 -518 0 -24 26 -24 26 0 2033 0 2033 24 26 c13 14 33 27 45 29 52 10 974 7 1005 -4z m1286 -3 c18 -8 38 -23 44 -34 8 -15 11 -616 11 -2052 l0 -2031 -25 -24 -24 -25 -516 0 -516 0 -24 25 -25 24 0 2034 c0 2026 0 2034 20 2060 11 14 30 28 43 30 12 2 237 5 500 6 392 1 484 -2 512 -13z"/>
          <path d="M1466 4470 c-35 -11 -84 -56 -97 -88 -13 -33 -438 -1506 -451 -1564 -14 -59 9 -127 53 -158 16 -12 146 -55 289 -97 295 -85 307 -86 371 -25 l39 37 230 800 c252 875 246 845 189 910 -36 41 -52 47 -342 130 -242 69 -237 68 -281 55z m506 -296 c-5 -26 -59 -215 -64 -219 -3 -3 -458 125 -466 131 -3 2 57 223 64 235 2 3 108 -25 236 -62 203 -58 233 -69 230 -85z m-336 -301 l232 -67 -19 -65 c-96 -336 -203 -707 -206 -710 -6 -9 -474 133 -470 143 3 6 53 181 113 389 60 207 111 377 114 377 3 0 109 -30 236 -67z m-231 -934 c99 -28 183 -54 187 -58 9 -8 -57 -236 -68 -235 -26 4 -450 127 -457 133 -4 4 -1 27 7 52 8 24 24 76 36 116 l21 72 47 -14 c26 -8 128 -38 227 -66z"/>
          <path d="M905 2445 c-14 -13 -25 -35 -25 -48 0 -53 23 -65 275 -137 132 -38 252 -70 268 -70 57 0 88 73 50 119 -13 15 -83 40 -261 91 -134 39 -252 70 -263 70 -11 0 -31 -11 -44 -25z"/>
          <path d="M1070 2087 c-13 -7 -31 -25 -40 -40 -13 -24 -13 -30 0 -54 9 -16 36 -38 62 -50 79 -38 119 -115 98 -192 -21 -78 -79 -123 -160 -123 -59 0 -103 22 -135 69 -21 32 -25 49 -25 115 0 86 -17 118 -64 118 -63 0 -86 -38 -86 -143 0 -62 5 -83 30 -135 73 -145 233 -209 391 -157 75 25 159 110 184 185 51 151 -11 318 -144 384 -66 34 -83 37 -111 23z"/>
          <path d="M2802 4400 c-18 -11 -41 -34 -52 -52 -19 -32 -20 -52 -20 -882 0 -836 0 -849 20 -882 12 -18 40 -42 64 -53 40 -20 59 -21 312 -21 260 0 270 1 309 23 26 14 48 37 63 67 l22 44 0 816 c0 520 -4 828 -10 852 -6 21 -27 51 -50 72 l-40 36 -293 0 c-274 0 -294 -1 -325 -20z m566 -252 l-3 -123 -242 -3 -243 -2 0 125 0 125 245 0 246 0 -3 -122z m2 -683 l0 -405 -245 0 -245 0 0 405 0 405 245 0 245 0 0 -405z m0 -680 l0 -125 -245 0 -245 0 0 125 0 125 245 0 245 0 0 -125z"/>
          <path d="M2832 2320 c-32 -30 -30 -82 4 -109 25 -20 39 -21 285 -21 141 0 265 3 274 6 24 10 46 60 39 89 -13 53 -26 55 -314 55 -254 0 -267 -1 -288 -20z"/>
          <path d="M3035 2007 c-68 -23 -101 -44 -148 -97 -59 -66 -80 -129 -75 -223 17 -322 447 -409 589 -121 28 57 33 78 33 138 0 123 -64 225 -177 282 -62 32 -161 41 -222 21z m142 -148 c44 -16 90 -67 103 -113 31 -111 -66 -219 -183 -203 -133 18 -186 190 -85 284 44 41 108 54 165 32z"/>
          <path d="M4082 4400 c-18 -11 -41 -34 -52 -52 -19 -32 -20 -52 -20 -879 l0 -846 23 -33 c12 -18 35 -43 50 -54 27 -20 40 -21 322 -21 281 0 295 1 321 21 15 11 37 33 48 48 20 27 21 41 24 465 3 432 2 438 -18 464 -28 35 -77 37 -108 4 -21 -22 -22 -31 -22 -240 l0 -217 -245 0 -245 0 0 405 0 405 245 0 245 0 0 -53 c0 -60 17 -92 55 -101 29 -7 79 15 89 39 3 9 6 136 6 281 0 244 -2 268 -20 303 -11 22 -35 48 -53 60 -33 20 -47 21 -323 21 -271 0 -291 -1 -322 -20z m568 -255 l0 -125 -245 0 -245 0 0 125 0 125 245 0 245 0 0 -125z m0 -1360 l0 -125 -245 0 -245 0 0 125 0 125 245 0 245 0 0 -125z"/>
          <path d="M4116 2319 c-34 -27 -35 -71 -1 -104 l24 -25 266 0 266 0 24 25 c14 13 25 36 25 50 0 14 -11 37 -25 50 l-24 25 -264 0 c-252 0 -266 -1 -291 -21z"/>
          <path d="M4302 2002 c-66 -24 -118 -65 -159 -126 -43 -62 -56 -116 -51 -196 16 -220 251 -354 452 -256 111 54 170 152 170 281 1 91 -18 141 -79 213 -75 87 -221 124 -333 84z m174 -151 c81 -37 114 -139 72 -221 -32 -62 -85 -92 -155 -87 -96 7 -160 82 -151 178 11 115 126 179 234 130z"/>
        </g>
      </svg>
    </span>
  );
};

export default CaseV2;
