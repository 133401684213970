import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Checklist2 = (props) => {
  return (
    <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || '18'}
      height={props.height || "16"} viewBox="0 0 10 9"
    >
      <path d="M4.78516 1.5H10.2852" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.78516 4.5H10.2852" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.78516 7.5H10.2852" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.28516 1.5L1.78516 2L2.78516 1" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.28516 4.5L1.78516 5L2.78516 4" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.28516 7.5L1.78516 8L2.78516 7" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default Checklist2;
