export const ACTIONS = {
  CALL_API: 'call-api',
  SUCCESS: 'success',
  ERROR: 'error',
  FOLLOW_UP: 'followUp',
  TASK_LIST: 'taskList',
  CURRENT_ROW: 'currentRow',
  PROCESSES: 'processes',
  CURRENT_PROCESS: 'currentProcess',
  FILTERS: 'filter'
};

export const caseDetailsReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CALL_API: {
      console.log(state, ACTIONS.CALL_API );
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.CURRENT_ROW: {
      console.log(state, ACTIONS.CURRENT_ROW );
      return {
        ...state,
        currentRow: action.data
      };
    }
    case ACTIONS.FOLLOW_UP: {
      console.log(state, ACTIONS.FOLLOW_UP );
      return {
        ...state,
        followUp: action.data
      };
    }
    case ACTIONS.TASK_LIST: {
      console.log(state, ACTIONS.TASK_LIST );
      return {
        ...state,
        taskList: action.data
      };
    }
    case ACTIONS.CURRENT_PROCESS: {
      console.log(state, ACTIONS.CURRENT_PROCESS );
      return {
        ...state,
        currentProcess: action.data
      };
    }
    case ACTIONS.FILTERS: {
      console.log(state, ACTIONS.FILTERS );
      return {
        ...state,
        filters: {...state.filters, [action.data.entityName]: action.data.filterData}
      };
    }
    default: return state;
  }
};

export const initialState = {
  followUpTask:[],
  taskList:[],
  currentRow:[],
  filters: {}
};
