import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const BlockSign = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2380 4790 c-438 -39 -825 -189 -1180 -454 -122 -91 -325 -294 -416 -416 -246 -329 -391 -685 -445 -1090 -16 -122 -16 -418 0 -540 67 -510 284 -953 641 -1310 424 -424 970 -651 1570 -653 366 -1 682 71 1000 229 681 340 1130 972 1231 1734 16 122 16 418 0 540 -67 510 -284 953 -641 1310 -355 355 -797 573 -1300 639 -113 15 -353 21 -460 11z m1755 -1775 l25 -24 0 -431 0 -431 -25 -24 -24 -25 -1551 0 -1551 0 -24 25 -25 24 0 431 0 431 25 24 24 25 1551 0 1551 0 24 -25z"/>
        </g>
      </svg>
    </span>
  );
};

export default BlockSign;
