import { h } from 'preact';

const Coupon = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '28'} height={props.height || '28'} viewBox={props.viewBox || "0 0 28 28"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 8.16668V10.5C4.42826 10.5 5.3185 10.8688 5.97487 11.5251C6.63125 12.1815 7 13.0718 7 14C7 14.9283 6.63125 15.8185 5.97487 16.4749C5.3185 17.1313 4.42826 17.5 3.5 17.5V19.8333C3.5 21.1167 4.55 22.1667 5.83333 22.1667H22.1667C22.7855 22.1667 23.379 21.9208 23.8166 21.4833C24.2542 21.0457 24.5 20.4522 24.5 19.8333V17.5C23.5717 17.5 22.6815 17.1313 22.0251 16.4749C21.3687 15.8185 21 14.9283 21 14C21 13.0718 21.3687 12.1815 22.0251 11.5251C22.6815 10.8688 23.5717 10.5 24.5 10.5V8.16668C24.5 7.54784 24.2542 6.95435 23.8166 6.51676C23.379 6.07918 22.7855 5.83334 22.1667 5.83334H5.83333C5.21449 5.83334 4.621 6.07918 4.18342 6.51676C3.74583 6.95435 3.5 7.54784 3.5 8.16668Z" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.1667 5.83334V8.16668" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.1667 19.8333V22.1667" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.1667 12.8333V15.1667" stroke={props.stroke || "white"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};
export default Coupon;
