const ViewSVG = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action()} width={props.width || '32pt'} height={props.height || '32pt'} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 32 32"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}>
        <path stroke={props.stroke || "#000000"} style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M29,16c0,0-5.8,8-13,8S3,16,3,16s5.8-8,13-8S29,16,29,16z"/>
        <circle stroke={props.stroke || "#000000"} style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" cx="16" cy="16" r="4"/>
      </svg>
    </span>
  );
};
export default ViewSVG;
