import { h } from 'preact';

const RoundedCross = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} width={props.width || "15"} height={props.height || "15"} viewBox={props.viewBox || "0 0 15 15"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.68358 13.8672C11.2001 13.8672 14.0507 11.0165 14.0507 7.49998C14.0507 3.98349 11.2001 1.13281 7.68358 1.13281C4.16709 1.13281 1.31641 3.98349 1.31641 7.49998C1.31641 11.0165 4.16709 13.8672 7.68358 13.8672Z" stroke={props.stroke || "#47AF2C"} stroke-width="0.795896" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.59374 5.58984L5.77344 9.41015" stroke={props.stroke || "#47AF2C"} stroke-width="0.795896" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.77344 5.58984L9.59374 9.41015" stroke={props.stroke || "#47AF2C"} stroke-width="0.795896" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default RoundedCross;
