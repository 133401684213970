// modified by Vihang
// modifield on 18/02/2022
// modfication:created newpopup modal

/*
  Modified By: Vihang
  Modified On: 23 May 2022
  Modification: enquiry form modal mobile responsive changes
*/
export const NewPopupModal = ({ onClose, title,modalHeight, modalDisplay,children,modalWidth,modalScroll, classes, backgroundColor}) => {
  return (

    <div class='orgChartModal'>
      <div id="formModal" className={"org-chart-modal " + modalDisplay}>
        { /* Modified By: Vihang
      Modified On: 16 March 2022
      Modification: added new width props
      */}
        <div class={`card org-chart-modal-content org-chart-width ${classes ? classes : ""}`} id='courierModal' style={`height:${modalHeight ? modalHeight : '95%'};width:${modalWidth};padding:0;`}>
          {/*<div style="position:sticky">*/}
          { /* Modified By: Manohar
              Modified On: 14 Jul 2023
              Modification: added onClose condition
        */}
          {
            onClose && (
              <div>
                <span class="isMobile right-drawer-label-back" onClick={onClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#302727" height="24" width="24"><path d="M12 20 4 12 12 4 13.425 5.4 7.825 11H20V13H7.825L13.425 18.6Z"/></svg>
                </span>
                <div class="right-drawer-label" onClick={onClose}>
                  <div class="right-drawer-close-btn" title="Close">
                    <div class="right-drawer-close-btn-inner" />
                  </div>
                </div>
              </div>
            )
          }

          {/*</div>*/}
          <div style={`height:100%;border-radius:8px; position:relative; ${modalScroll ? "overflow:hidden auto" : ""} ${backgroundColor ? `background:${backgroundColor}`: "" }`}>
            {children}
          </div>
        </div>
      </div>
    </div>

  );
};

export const NewPopupModalBody = ({children}) => {
  return (
    <div class="org-chart-modal-body background-transparent">
      {children}
    </div>

  );
};
