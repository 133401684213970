import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Pending = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || '16'}
        height={props.height || "18"} viewBox="0 0 512 512"
      >
        <path d="M102.1 33.1c-14.5 2.8-22.7 7.3-33.6 18.3C42 78 30.1 113.1 33.2 155.5c3.3 45.1 24 93.7 62.1 145.5 21.2 28.7 44.5 53.9 76.7 82.5 70 62.3 122.8 89.9 183 95.6 40.3 3.8 78.7-9.2 106.1-36 6.6-6.5 9.5-10.2 11.8-15.1 6.3-13.6 8.7-31 5.7-41.5-3.6-12.3-10.5-19.9-24.1-26-17-7.8-57.3-24.7-69.3-29.2-12.6-4.7-12.8-4.7-25.2-4.8-16.5 0-19.2 1.2-32.1 14.4-8.4 8.6-10.9 10.5-19.3 14.7l-9.6 4.8-5.4-1.7c-15.3-4.9-61.8-42.3-89-71.6-33-35.6-54-66.1-52-75.5 1.8-8.5 8.7-18.3 21-29.9 10.3-9.7 11.9-13.7 11.8-29.7 0-12.1-.1-12.9-4.1-23.5-4.9-13.3-14.5-36.4-25.4-61.1-8.7-19.7-12.4-25.2-20.3-29.8-8.6-5-21.8-6.8-33.5-4.5zm20.2 16c7.7 2.1 10.8 6.3 19.8 26.7 10.9 24.7 22.4 52.7 25.5 62 2.7 7.9 3.4 19.4 1.6 24.2-.5 1.4-5.6 7.2-11.3 13-11.4 11.5-16.8 19.7-20 30.3-2.8 8.9-2.1 13.2 4.1 25.6 10.8 21.4 32.6 48.8 64 80.1 38.8 38.8 68.5 59.9 89.9 64 10.9 2.1 25.3-5.2 41.1-20.9 5.8-5.7 11.6-10.8 13-11.3 3.5-1.3 14.7-1.2 20.1.2 6 1.6 40.9 15.8 65.1 26.5 21.7 9.6 25.6 12.5 27.9 20.7 1.6 6.2.6 16.4-2.6 25.7-2.5 7.1-3.7 8.9-9.9 15.2C417 465 365.8 472.8 308 452.8c-60.3-20.9-138.8-83.6-195.6-156.4-10-12.7-26.9-38-34.3-51.1-28-49.5-36.9-100.1-24.6-139.3 3.3-10.5 9.2-22.4 15.3-31 6.5-9.2 17.4-19.7 23-22.2 9.8-4.2 22.9-5.8 30.5-3.7z" />
        <path d="M304.8 61.6c-39.8 7.2-66.6 41.8-64.5 83.4 1.4 28.6 18.1 53.6 44.2 66.5 13.2 6.6 19.5 8 35.5 8s22.3-1.4 35.5-8c26.1-12.9 42.8-37.9 44.2-66.5 2.1-41.8-24.9-76.5-65.2-83.5-10.5-1.8-19.4-1.8-29.7.1zm30.2 16c11.6 2.7 20.5 7.9 30 17.4 14.1 14 19.7 28.3 18.7 47.5-1.5 29.4-19.3 51.8-46.9 59.2-8.4 2.3-25.2 2.3-33.6 0-27.6-7.4-45.4-29.8-46.9-59.2-1-19.2 4.6-33.5 18.7-47.5 9.2-9.3 18.5-14.7 29.7-17.4 7.8-1.9 22.3-1.9 30.3 0z" />
        <path d="M314.5 106.5c-2.5 2.4-2.5 2.7-2.5 19.5 0 15.6.2 17.2 2 19 1.1 1.1 3 2.2 4.3 2.5 1.2.4 12.1.5 24.1.3 21.5-.3 21.8-.3 23.7-2.7 2.6-3.2 2.4-7.7-.6-10.6-2.4-2.5-2.7-2.5-20-2.5H328v-11.5c0-11-.1-11.7-2.5-14-1.5-1.6-3.6-2.5-5.5-2.5s-4 .9-5.5 2.5z" />
      </svg>
    </span>
  );
};

export default Pending;
