import { h, Component, Fragment } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";

const ComboBox = (props) => {
  const {
    isMobileVie,
    placeholder,
    options,
    value,
    setValue,
    objectDisplayKey,
    mapping,
    isSetDisplayName,
    displayKey,
    setValueDisplayName,
    clearAll,
    setClearAll
  } = props;

  const [isSuggestion, setIsSuggestion] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [showOptions, setShowOptions] = useState(options);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (options && options.length) setShowOptions(options);
  }, [options]);

  useEffect(() => {
    let pattern = new RegExp(`${selectedValue}`, 'i');

    if (selectedValue) {
      let result = options && options.filter((option) => {
        return option[objectDisplayKey] && option[objectDisplayKey].match(pattern) !== null;
      });
      isSelected ? setShowOptions(options) : setShowOptions(result);
    } else {
      setShowOptions(options);
    }

  }, [selectedValue]);

  useEffect(() => {
    if (clearAll) {
      setValue('');
      setSelectedValue('');
      setValueDisplayName && setValueDisplayName('');
      setIsSelected(false);
      setClearAll(false);
    }

  },[clearAll]);

  const handleSuggestionBox = () => setIsSuggestion(true);

  const handleSetOption = (value, name) => {
    if (!isSelected) setIsSelected(true);
    setSelectedValue(mapping ? mapping[name.toLowerCase()] : displayKey ? value : name);
    setValue(value);

    if (isSetDisplayName) setValueDisplayName(name);

    setIsSuggestion(false);
  };

  const handleCloseSearchSuggestion = () => setIsSuggestion(false);

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };

  const searchRef = useOutsideClick(handleCloseSearchSuggestion);

  const handleSearch = (e) => {
    setSelectedValue(e.target.value);
    setValue('');

    if (isSelected) setIsSelected(false);
  };

  return (
    <div class="comboBox-search-box p-r-0 p-l-0">
      <div ref={searchRef} class={`comboBox-search-container h-30 pos-relative`}>
        <input
          autocomplete="off"
          type="text"
          id="search-basic"
          placeholder={placeholder}
          value={selectedValue}
          class="comboBox-search-input"
          onFocus={handleSuggestionBox}
          onChange={handleSearch}
        />
        {
          isSuggestion &&
            <div class={`${'comboBox-search-suggestion'}`}>
              <ul class='flex-col p-t-8 p-b-8 gap-8px max-h-200 overflow-x-y' >
                {
                  showOptions && showOptions.map((item) => (
                    <li class={`cursor-pointer bg-hov-f8fafc p-4 p-l-8 ${value === item.uuid && 'bg-f5f5f5'}`}
                      onClick={() => handleSetOption(displayKey ? item : item.uuid, item[objectDisplayKey])}
                    >
                      {mapping && mapping[item[objectDisplayKey].toLowerCase()] ?
                        mapping[item[objectDisplayKey].toLowerCase()] : displayKey ?
                          item : item[objectDisplayKey]
                      }
                    </li>
                  ))
                }
                {
                  (showOptions && !showOptions.length || showOptions === undefined)  && (
                    <p class='text-align-center'>No Records</p>
                  )
                }
              </ul>
            </div>
        }
      </div>
    </div>

  );
};

export default ComboBox;
