import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Ribbon from '../../components/ribbon';
import { getInitials, getDay, getMonth, modelMapping, formatDateTime, getFormattedAmount, applyAclForFeedAndChat, getWeeks, typeOfEnquiryMappingClassColor, capitalizeFirstLetter } from '../../lib/utils';
import { getItem, setItem, removeAll } from '../../lib/myStore';
import CONSTANTS from '../../lib/constants';

/*
  Modification: added css for mobile view
  By: Masum Raja
  Date: 16/11/2023
*/

const SemiInfoDetailCardV2 = (props) => {
  let userInfo = getItem('userinfo');
  /*
      Modification: Add Five Star Rating
      By: Komal Wable
      Date: 28/04/2023
    */
  return (
    <Fragment>
      {/*
        Bug: T1687 - 40 - Add New Case - Modal - Hand pointer not required on Info displayed below the green tick after adding the new case
        Modification: Added action function condition for cursor pointer
        By: Devang
        Date: 10/04/2024
      */}
      <div id={props.identifier ? props.identifier: `${props.title}`} class={`${props.isMobileView ? 'p-t-0' : 'mobile-p-t-30'} newWorkSpaceCard pos-relative semi-info-detail-card mobile-p-l-2 overflow-hidden ${props.action ? 'cursor-pointer' : ''} ${props.masterCardClasses || ''}`}
        style={`background:${props.cardBgColor};height:${props.cardHeight}; ${props.masterCardStyle || ''}`}
        onclick={(e)=> props.action && props.action(e)} title={props.title || ''}
      >
        <div class={props.masterClasses || "p-t-0 p-b-0 display-flex justify-between flex-direction-column h-full first-letter-capital"}>
          {
            props.cardHeader && (
              <div className={!props.cardTextTop ? "display-flex": ""} style="">
                <div class='display-flex'>
                  {
                    props.cardTextTop && (
                      <div class={`fs-10 f-w-300 ${props.cardTextTopClasses ? props.cardTextTopClasses : " " } `} style="align-self: baseline;"><span>{props.cardTextTop}</span>
                      </div>
                    )
                  }
                  {
                    props.rating && (
                      <div class='m-l-20'>{props.rating}</div>
                    )
                  }
                </div>
                {/*
                  modified : Ashutosh G
                  modified : 24/07/2023
                  modification : max width set to cardHeader h1
                */}
                {/*
                  Modification: Added truncate class to header
                  by: Masum Raja
                  Date: 23/10/2023
                */}
                <h1 title={props && props.cardHeader} class={`${props.cardHeaderClasses || `fs-15 mobile-fs-11 f-w-400 text-black`} truncate`} style="margin:0; align-self: baseline;letter-spacing: 0.08px; max-width: 285px;">{props.cardHeader}</h1>
                {props.tagName &&
                <div class="display-flex" style="width: 54%;justify-content:end;align-self: self-end;">
                  <span class="fs-10 f-w-500 m-l-10" style="border-radius:20px;color:#fff;padding:5px 10px;background:#154042;align-self: center;">{props.tagName}</span>
                </div>
                }
                {
                  props.cardCaseNo && (
                    <p class="fs-12 f-w-400 p-t-4 text-theme-color" style="align-self: baseline;"><span>{props.cardCaseNo}</span>
                    </p>
                  )
                }
              </div>
            )
          }
          {
            props.ribbons && props.ribbons.length > 0 && props.ribbons.map(ribbon => (
              <Ribbon ribbonText={ribbon.text} ribbonColor={ribbon.color} type={ribbon.type} variant={ribbon.variant} isTextNotVisible={ribbon.isTextNotVisible} ribbonTextColor={ribbon.ribbonTextColor} />
            ))
          }

          {
            props.cardSubHeader && (
              <div  class="display-flex" style={`${props.subHeaderStyle || ""} width: 100%;justify-content: space-between; align-self: self-end;`}>
                <p class={`${props.cardSubHeaderClasses ? props.cardSubHeaderClasses : " "} fs-12 mobile-fs-9 f-w-400 first-letter-capital `} >{props.cardSubHeader}</p>
                {/*<div class="display-flex flex-direction-column fs-10  m-r-32" style="border-radius: 8px; color: #444444; padding: 5px; right: 26px; position: absolute; bottom: -2px; line-height:2.2">
                <span>Approx. DD Of Delivery</span>
                <span class=" fs-11" style="align-self:left;">22nd March,2022</span>
              </div>*/}

              </div>
            )
          }
          <div class="display-flex">
            {
              props.cardText1Header && props.cardText1 && (
                <div class="p-t-8 p-r-8 p-b-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardText1Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardText1}</p>
                </div>
              )
            }
            {
              props.cardText2Header && props.cardText2 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardText2Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardText2}</p>
                </div>
              )
            }
            {
              props.cardText3Header && props.cardText3 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardText3Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardText3}</p>
                </div>
              )
            }
            {
              props.cardText4Header && props.cardText4 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardText4Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardText4}</p>
                </div>
              )
            }
            {
              props.cardText5Header && props.cardText5 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardText5Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardText5}</p>
                </div>
              )
            }
            {
              props.cardText6Header && props.cardText6 && (
                <div class="p-t-8 p-b-8 p-l-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardText6Header}</span>
                  </p>
                  <p style="margin-top:-5px;"><span class={`fs-12 f-w-400 ${props.cardText6 === "Tourist" ? "text-theme-color highlight-fbc12d bor-rad-all-15 p-4-16" : "text-white highlight-ed736d"}`}>{props.cardText6 === "Tourist" ? "Taxi": "Non-Taxi"}</span></p>
                </div>
              )
            }
          </div>
          <div class="display-flex">
            {
              props.cardSecondRowText1Header && props.cardSecondRowText1 && (
                <div class="p-t-8 p-r-8 p-b-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardSecondRowText1Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardSecondRowText1}</p>
                </div>
              )
            }
            {
              props.cardSecondRowText2Header && props.cardSecondRowText2 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardSecondRowText2Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardSecondRowText2}</p>
                </div>
              )
            }
            {
              props.cardSecondRowText3Header && props.cardSecondRowText3 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardSecondRowText3Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardSecondRowText3}</p>
                </div>
              )
            }
            {
              props.cardSecondRowText4Header && props.cardSecondRowText4 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardSecondRowText4Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardSecondRowText4}</p>
                </div>
              )
            }
            {
              props.cardSecondRowText5Header && props.cardSecondRowText5 && (
                <div class="p-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardSecondRowText5Header}</span>
                  </p>
                  <p class={`fs-12 f-w-400 text-theme-color`} >{props.cardSecondRowText5}</p>
                </div>
              )
            }
            {
              props.cardSecondRowText6Header && props.cardSecondRowText6 && (
                <div class="p-t-8 p-b-8 p-l-8">
                  <p class="fs-10 f-w-300 text-theme-color" style="align-self: baseline;"><span>{props.cardSecondRowText6Header}</span>
                  </p>
                  <p style="margin-top:-5px;"><span class={`fs-12 f-w-400 ${props.cardSecondRowText6 === "Tourist" ? "text-theme-color highlight-fbc12d bor-rad-all-15 p-4-16" : "text-white highlight-ed736d"}`}>{props.cardSecondRowText6 === "Tourist" ? "Taxi": "Non-Taxi"}</span></p>
                </div>
              )
            }
          </div>
          {/*
            By: Yashvi
            On: 9th July 2024
            T1687: Icons are overlapped on Expected Date of Booking
            Modification: Added class via props
          */}
          <div class={props.taskView ? "semiInfoDetailCard-icon-container-sm flex-c-m" : ` ${props.cardIconsClass ? props.cardIconsClass : ''} ${props.isMobileView ? 'pos-relative m-t-15' : 'right-33 m-r-2'} semiInfoDetailCard-icon-container flex-c-m`}>
            {props.cardIcons && props.cardIcons.length > 0 && props.cardIcons.map(icon => (
              <span onClick={(e)=> icon.action && icon.action(e)} class={icon.active ? `${props.iconContainerClasses || ""} bg-CAE1E4 semiInfoDetailCard-icon flex-r-m flex-col ${icon.class || "p-b-4"}` : `${props.iconContainerClasses || ""} semiInfoDetailCard-icon flex-r-m flex-col ${icon.class || "p-b-4"}`}>
                {icon.svg}
                {
                  icon.text && (
                    <p class="fs-7 text-3C3C3C">{icon.text}</p>
                  )
                }
              </span>
            ))}
          </div>
        </div>
        {props.taskView && (
          <div class="semiInfo-userDetails-container flex-col bor-rad-all-5 h-full p-l-8">
            <p class="text-theme-color flex-c-m f-w-300 m-t-14 fs-10 m-r-14">Case Owner </p>
            <div class='flex-c-m'>
              <div class={`${props.isMobileView ? 'display-none' : ''} assigned-user round_iconsworkspace m-t-0 bg-3C5C83 fs-10 m-0 h-20px max-w-20px min-w-20px`}>
                <p class="bg-3C5C83 text-white fs-9 f-w-600 m-0" title={userInfo.displayName ? userInfo.displayName : ''}>{getInitials(userInfo.displayName)}</p>
              </div>
              <div class="text-theme-color cursor-pointer m-l-6 m-t-2 ">
                <p class="fs-12 f-w-500 lh-1-3 ">{userInfo.displayName ? userInfo.displayName : "---"} </p>
                <p class="fs-10 f-w-400 lh-1-2 ">{userInfo.userBranchName ? userInfo.userBranchName : "---"} </p>
              </div>
            </div>
          </div>
        )
        }
      </div>
    </Fragment>
  );
};


export default SemiInfoDetailCardV2;
