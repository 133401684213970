import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Verify = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2215 5110 c-141 -21 -302 -86 -437 -176 -91 -61 -242 -206 -314 -300 -68 -91 -136 -218 -169 -315 -32 -94 -43 -193 -49 -477 l-6 -234 -39 -44 c-68 -77 -75 -159 -30 -337 38 -151 76 -239 116 -274 35 -29 37 -36 44 -107 4 -42 17 -106 29 -142 27 -81 115 -213 210 -317 l72 -77 -7 -73 c-16 -176 -68 -320 -140 -392 -41 -41 -55 -47 -501 -195 -252 -84 -488 -168 -524 -186 -139 -72 -238 -175 -306 -319 -33 -70 -164 -565 -164 -621 0 -16 13 -39 34 -60 l34 -34 1160 0 1160 0 31 26 c38 32 49 80 27 123 -34 65 33 61 -1140 61 l-1064 0 48 190 c54 213 88 287 162 360 26 25 70 59 98 74 28 15 257 97 510 181 495 165 531 182 610 274 111 129 180 357 180 594 l0 98 -93 94 c-150 149 -200 237 -216 378 -12 100 -34 157 -81 207 -19 21 -42 63 -56 104 -30 87 -58 220 -47 223 33 12 59 32 74 58 15 25 19 81 29 360 10 277 15 341 32 400 83 287 372 567 667 647 58 16 106 21 201 20 231 0 405 -57 538 -177 60 -54 87 -96 96 -147 8 -45 46 -92 95 -118 51 -26 98 -78 116 -129 26 -75 35 -205 35 -532 0 -302 1 -317 20 -342 11 -14 34 -28 50 -32 17 -4 30 -13 30 -22 0 -8 15 -30 34 -49 26 -26 42 -34 71 -34 52 0 105 53 105 104 0 49 -21 96 -63 143 l-35 38 -5 335 c-5 362 -10 408 -63 513 -34 67 -65 103 -127 150 -40 30 -58 55 -88 116 -56 114 -170 220 -311 289 -188 93 -440 133 -643 102z"/>
          <path d="M3220 2784 c-535 -180 -608 -208 -633 -235 l-28 -31 4 -556 c4 -609 4 -602 68 -796 143 -429 523 -827 1051 -1100 75 -39 140 -66 158 -66 44 0 259 114 425 224 396 265 667 590 784 942 64 194 64 187 68 796 l4 556 -28 31 c-25 28 -96 54 -636 236 -335 113 -614 205 -620 204 -7 0 -284 -92 -617 -205z m1165 -200 l525 -178 0 -412 c0 -226 -5 -457 -10 -513 -42 -403 -314 -787 -776 -1093 -61 -40 -149 -93 -197 -118 l-87 -45 -73 37 c-187 96 -389 242 -551 398 -260 252 -405 524 -436 821 -5 56 -10 286 -10 513 l0 412 518 173 c284 96 524 178 532 182 8 5 21 7 27 4 7 -2 249 -84 538 -181z"/>
          <path d="M4435 2014 c-16 -8 -180 -164 -362 -347 -204 -203 -336 -328 -341 -322 -5 6 -82 102 -172 215 -89 113 -173 215 -187 228 -44 40 -125 25 -157 -30 -35 -59 -25 -77 224 -387 126 -159 237 -292 246 -295 36 -14 82 -5 120 23 21 16 204 195 405 397 396 399 396 399 363 469 -15 31 -64 65 -96 65 -7 0 -26 -7 -43 -16z"/>
        </g>
      </svg>
    </span>
  );
};

export default Verify;
