import { h } from "preact";

const OrgChartModal = (props) => {
  const {title, titles, masterClasses, orgChartClasses, isOpen, onClose, children} = props;

  return (
    <div class='orgChartModal'>
      <div id="floatingModal" class={`org-chart-modal ${masterClasses || ''}`} style={`display: ${ isOpen ? "block" : "none"}`}>
        <div class={`org-chart-modal-content org-chart-width ${orgChartClasses || ''}`} style='padding: 0px !important'>
          <div>
            <div class={`modal-header`}>
              <span class='fs-12'>
                <div class="display-flex">
                  <ul class='breadrcrumb_arrows_inverted m-r-8'>
                    {
                      titles && titles.length &&
                        titles.map(title => (
                          <li>{title}</li>
                        ))
                    }
                    {
                      title &&
                      <li>{title}</li>
                    }
                  </ul>
                </div>
              </span>
              <span class="modal-close" onClick={onClose}>&times;</span>
            </div>
            {/*
              Modification: Commented extra padding to make it similar to COMP
              By: Devang
              Date: 21/06/2024
            */}
            <div class="org-chart-modal-body background-transparent"
              // style='padding: 30px 0px !important'
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgChartModal;
