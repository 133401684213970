import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Exclamation2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} onclick={(e) => props.action && props.action(e)} title={props.title || ''}>
      <svg class={props.classes || ""}  xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width={props.width || "5"} height={props.height || "19"}  fill={props.fill || 'none'} >
        <path d="M4.12109 0.9375L3.73438 12.7852H0.921875L0.523438 0.9375H4.12109ZM0.429688 16.4531C0.429688 15.9531 0.601562 15.5391 0.945312 15.2109C1.29688 14.875 1.75781 14.707 2.32812 14.707C2.90625 14.707 3.36719 14.875 3.71094 15.2109C4.05469 15.5391 4.22656 15.9531 4.22656 16.4531C4.22656 16.9375 4.05469 17.3477 3.71094 17.6836C3.36719 18.0195 2.90625 18.1875 2.32812 18.1875C1.75781 18.1875 1.29688 18.0195 0.945312 17.6836C0.601562 17.3477 0.429688 16.9375 0.429688 16.4531Z" fill="#FF0000"/>
      </svg>
    </span>
  );
};

export default Exclamation2;
