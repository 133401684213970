import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Upload = (props) => {
  /*
      Modification: Documents Tab - Upload icon thickness is more than eye icon
      Developer: Manohar sule
      Date: 21/08/2023
    */

  return (

    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action()} width={props.width || '296.999pt'} height={props.height || '296.999pt'} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 -960 960 960" style="enable-background:new 0 0 296.999 296.999;" viewBox="0 -960 960 960"><path fill={props.fill || ''} stroke={props.stroke || 'none'} d="M260-212q-70 0-119-48.774T92-380q0-69 49.5-118.5T254-548q10-86 74.5-143T480-748q95.271 0 161.636 66.364Q708-615.271 708-520v52h32q54 0 91 37t37 91q0 54-36 91t-90 37H526q-26 0-43-17t-17-43v-240l-90 90-20-19 124-124 124 124-20 19-90-90v240q0 12 10 22t22 10h214q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-22q-55 0-96.5 41T120-380q0 58 41 99t99 41h100v28H260Zm220-254Z"/>
      </svg>
    </span>
  );
};
export default Upload;
