import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Leads = (props) => {
  return (
    <svg class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || ""} height={props.height || ""} viewBox={props.viewBox || "0 0 512 512"}
	style={props.style || ""} preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill ? props.fill : "#000000"} stroke={props.stroke ? props.stroke : "none"}>
        <path d="M2525 5111 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -36 -20 -230 0 -206 0 -209 24 -234 46 -49 127 -42 156 15 19 37 22 394 3 434 -20 44 -76 65 -123 46z" />
        <path d="M1739 4742 c-42 -21 -63 -61 -53 -105 7 -36 178 -218 228 -244 64 -33 141 15 141 88 0 37 -7 46 -115 153 -64 63 -126 116 -141 120 -15 3 -39 -2 -60 -12z" />
        <path d="M3315 4753 c-31 -7 -242 -222 -248 -252 -13 -65 52 -129 116 -115 45 9 234 195 248 245 15 47 -9 95 -56 115 -19 8 -36 13 -37 13 -2 -1 -12 -3 -23 -6z" />
        <path d="M2525 4441 c-61 -25 -80 -108 -36 -155 79 -84 207 4 159 109 -20 44 -76 65 -123 46z" />
        <path d="M2423 4049 c-332 -32 -639 -172 -880 -402 -166 -159 -292 -347 -368 -549 l-17 -47 -55 39 c-78 55 -141 75 -243 75 -102 0 -165 -20 -243 -75 -116 -84 -167 -188 -175 -356 -5 -117 8 -185 49 -265 l18 -37 -47 -12 c-140 -35 -313 -177 -384 -315 -64 -125 -72 -169 -73 -368 l0 -179 27 -23 c39 -33 79 -37 365 -33 236 3 253 4 279 24 52 38 56 103 9 150 -24 24 -24 24 -255 24 l-230 0 0 68 c1 185 75 328 219 417 87 54 114 58 417 62 l281 5 18 -68 c28 -103 71 -209 127 -313 l50 -94 -594 -596 c-449 -451 -599 -607 -616 -643 -106 -220 26 -482 265 -528 81 -16 155 -6 234 31 56 27 105 71 397 362 184 183 340 344 348 359 21 40 17 73 -11 106 -30 36 -70 47 -111 32 -16 -6 -181 -162 -369 -350 -295 -294 -345 -339 -378 -345 -133 -25 -241 88 -207 217 10 38 75 107 587 619 l575 576 87 -86 c110 -111 247 -209 386 -276 212 -104 371 -143 610 -152 191 -6 341 15 514 73 461 154 830 543 956 1008 l18 68 281 -5 c303 -4 330 -8 417 -62 144 -89 218 -232 219 -417 l0 -68 -490 0 -491 0 -24 -24 c-47 -47 -43 -112 9 -150 27 -20 40 -21 539 -24 282 -2 531 0 555 3 23 3 55 17 70 30 l27 23 0 179 c-1 199 -9 243 -73 368 -71 138 -244 280 -384 315 l-47 12 18 37 c41 80 54 148 49 265 -8 168 -59 272 -175 356 -78 55 -141 75 -243 75 -102 0 -165 -20 -243 -75 l-55 -39 -17 47 c-76 202 -202 390 -368 549 -245 234 -545 369 -892 403 -113 11 -145 11 -262 -1z m389 -220 c683 -135 1136 -808 1004 -1489 -99 -513 -488 -906 -1006 -1017 -119 -25 -381 -25 -500 0 -939 201 -1341 1287 -755 2042 294 380 779 558 1257 464z m-1855 -884 c87 -44 123 -113 123 -240 0 -71 -4 -90 -28 -136 -40 -77 -102 -114 -192 -114 -91 0 -152 36 -193 115 -27 51 -28 61 -25 151 3 86 6 101 33 144 60 97 179 131 282 80z m3419 -11 c74 -45 104 -111 104 -224 0 -77 -4 -94 -28 -141 -40 -77 -102 -114 -192 -114 -90 0 -152 37 -192 114 -24 46 -28 65 -28 136 0 46 5 96 11 112 51 138 203 193 325 117z" />
        <path d="M2440 3649 c-410 -43 -771 -335 -904 -733 -175 -524 88 -1109 597 -1328 153 -66 237 -82 427 -82 136 0 180 3 251 21 614 155 971 796 773 1389 -113 337 -387 599 -729 699 -70 20 -268 48 -314 44 -9 -1 -54 -6 -101 -10z m328 -220 c302 -70 543 -302 639 -616 23 -78 26 -104 26 -233 0 -161 -15 -240 -71 -363 -32 -69 -99 -177 -110 -177 -4 1 -23 23 -42 51 -56 79 -182 184 -283 236 l-58 29 19 24 c55 71 82 162 82 280 0 198 -81 339 -240 414 -60 29 -73 31 -170 31 -97 0 -110 -2 -170 -31 -159 -75 -240 -216 -240 -414 0 -118 27 -209 82 -280 l19 -24 -58 -29 c-101 -52 -227 -157 -283 -236 -19 -28 -38 -50 -42 -51 -11 0 -78 108 -110 177 -56 123 -71 202 -71 363 0 129 3 155 26 233 142 465 587 726 1055 616z m-134 -529 c43 -16 104 -79 121 -125 13 -32 16 -66 14 -128 -4 -72 -8 -90 -34 -132 -80 -130 -270 -130 -350 0 -26 42 -30 60 -34 132 -5 126 32 200 124 247 34 18 120 21 159 6z m58 -701 c60 -13 185 -67 226 -99 56 -44 135 -127 160 -168 l25 -44 -29 -24 c-46 -38 -223 -121 -299 -141 -52 -13 -108 -18 -215 -18 -107 0 -163 5 -215 18 -76 20 -253 103 -299 141 l-29 24 25 44 c25 41 104 124 160 168 39 30 154 82 213 96 64 16 209 17 277 3z" />
        <path d="M1503 1173 c-35 -7 -73 -59 -73 -100 0 -77 103 -124 159 -72 77 72 18 191 -86 172z" />
      </g>
    </svg>
  );
};

export default Leads;
