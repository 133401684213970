import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const EndTestdrive = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512.000000pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M3520 5114 c-300 -37 -493 -113 -1030 -404 -594 -322 -773 -384 -1139 -397 -151 -5 -176 -8 -192 -24 -11 -11 -19 -28 -19 -40 0 -32 508 -1898 527 -1938 21 -41 69 -76 127 -92 58 -16 269 -5 394 20 254 52 397 114 887 379 586 318 783 387 1144 399 151 5 176 8 192 24 11 11 19 27 19 37 0 37 -511 1908 -533 1951 -35 69 -75 85 -222 87 -66 1 -136 0 -155 -2z m234 -485 c37 -138 66 -252 64 -254 -1 -2 -48 -8 -102 -14 -130 -15 -218 -33 -343 -72 -57 -18 -105 -30 -107 -28 -4 4 -136 490 -136 501 0 7 126 50 219 73 85 22 224 43 287 44 l51 1 67 -251z m-1105 -405 c37 -137 67 -252 65 -256 -6 -10 -297 -170 -427 -234 -65 -33 -115 -64 -113 -70 2 -6 37 -132 77 -280 39 -148 75 -273 80 -278 8 -9 244 107 419 208 63 36 117 62 121 58 14 -15 140 -505 132 -512 -16 -16 -320 -182 -422 -231 -57 -28 -107 -47 -111 -42 -3 4 -35 115 -70 245 -35 130 -66 242 -70 248 -5 8 -39 1 -112 -24 -126 -42 -271 -72 -390 -82 l-87 -7 -11 44 c-7 24 -41 151 -76 281 -35 130 -64 241 -64 246 0 6 30 13 68 16 113 11 279 45 393 82 60 19 111 38 113 43 4 6 -108 440 -129 498 -2 7 51 38 128 76 73 35 195 101 272 145 77 44 141 79 143 78 1 -2 33 -115 71 -252z m694 -257 c43 -156 77 -286 77 -289 0 -4 -53 -32 -117 -63 -65 -31 -187 -95 -271 -142 -84 -47 -155 -84 -156 -82 -3 4 -156 566 -156 574 0 13 516 285 540 285 4 0 41 -127 83 -283z m709 -436 c38 -139 66 -256 64 -259 -3 -2 -45 -8 -94 -13 -105 -10 -213 -31 -330 -66 -48 -14 -96 -28 -107 -30 -18 -4 -26 18 -89 252 -38 141 -67 257 -65 259 8 7 137 46 214 65 104 25 274 50 324 47 10 -1 35 -80 83 -255z"/>
          <path d="M763 4349 c-34 -21 -73 -90 -73 -128 0 -28 1036 -4114 1050 -4140 5 -11 25 -31 43 -45 101 -77 247 -10 247 114 0 55 -1024 4102 -1049 4145 -42 72 -148 99 -218 54z"/>
        </g>
      </svg>
    </span>
  );
};

export default EndTestdrive;
