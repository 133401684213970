import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const StartTestdrive = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512.000000pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M298 4823 c-14 -16 -16 -122 -18 -913 -2 -492 -2 -912 -1 -932 2 -57 28 -78 100 -78 l59 0 7 -312 c4 -172 6 -752 4 -1288 -3 -781 -1 -979 9 -997 l13 -23 2089 0 2089 0 13 23 c10 18 12 218 9 1007 -2 541 0 1121 4 1287 l7 303 43 0 c54 0 103 17 109 38 3 10 6 429 8 932 3 863 2 917 -15 943 l-17 27 -380 0 -379 0 -15 -22 c-12 -16 -16 -47 -16 -115 l0 -93 -1460 0 -1460 0 0 93 c0 68 -4 99 -16 115 l-15 22 -378 0 c-347 0 -379 -1 -393 -17z m662 -948 l0 -835 -270 0 -270 0 0 835 0 835 270 0 270 0 0 -835z m3740 0 l0 -835 -270 0 -270 0 0 835 0 835 270 0 270 0 0 -835z m-680 0 l0 -595 -1460 0 -1460 0 0 595 0 595 1460 0 1460 0 0 -595z m0 -830 c0 -88 2 -98 25 -120 20 -21 34 -25 80 -25 l55 0 0 -1132 0 -1133 -151 285 c-82 157 -240 461 -351 675 -319 620 -378 732 -396 748 -15 13 -115 15 -712 18 -382 2 -704 1 -715 -3 -19 -7 -124 -205 -862 -1623 l-52 -100 -1 1131 0 1132 60 4 c91 7 100 19 100 140 l0 98 1460 0 1460 0 0 -95z m-3222 -1387 l-3 -1243 -107 -3 -108 -3 0 1246 0 1245 110 0 110 0 -2 -1242z m3742 -3 l0 -1246 -107 3 -108 3 -3 1243 -2 1242 110 0 110 0 0 -1245z m-2500 567 c0 -5 -211 -413 -468 -908 l-469 -899 -56 -3 c-36 -2 -57 1 -57 8 0 9 895 1736 927 1788 11 18 22 22 68 22 30 0 55 -4 55 -8z m450 -47 c0 -92 30 -127 95 -111 32 8 45 41 45 112 l0 54 143 0 142 0 470 -901 c259 -496 472 -905 473 -910 2 -5 -258 -9 -612 -9 l-616 0 0 65 c0 75 -20 105 -70 105 -50 0 -70 -30 -70 -105 l0 -65 -616 0 c-355 0 -614 4 -612 9 2 5 214 414 473 910 l469 901 143 0 143 0 0 -55z m1172 -845 c257 -492 467 -901 467 -908 1 -10 -14 -12 -56 -10 l-56 3 -469 899 c-257 494 -468 903 -468 908 0 5 26 8 58 6 l57 -3 467 -895z"/>
          <path d="M1495 4096 c-60 -17 -124 -80 -126 -123 -4 -103 22 -138 148 -199 40 -19 84 -49 98 -65 47 -56 26 -124 -46 -153 -58 -23 -90 -20 -180 18 -21 9 -45 -47 -26 -62 29 -23 93 -34 164 -30 61 4 82 10 116 34 57 40 79 85 74 151 -5 74 -36 110 -138 160 -98 48 -132 80 -132 124 0 68 97 109 188 79 40 -13 55 -5 55 33 0 35 -116 54 -195 33z"/>
          <path d="M1760 4065 l0 -35 90 0 90 0 0 -270 0 -270 40 0 40 0 0 270 0 270 95 0 95 0 0 35 0 35 -225 0 -225 0 0 -35z"/>
          <path d="M2350 4038 c-70 -203 -180 -533 -180 -540 0 -4 17 -8 38 -8 l38 0 30 93 30 92 110 0 109 0 31 -90 31 -90 42 -3 c37 -3 42 -1 37 15 -3 10 -50 146 -104 303 l-97 285 -46 3 -47 3 -22 -63z m113 -160 l45 -138 -90 0 c-70 0 -89 3 -86 13 6 27 83 266 84 264 1 -1 22 -64 47 -139z"/>
          <path d="M2783 4093 l-23 -4 0 -300 0 -299 40 0 40 0 0 130 0 130 61 0 c53 0 64 -3 89 -29 27 -26 35 -49 70 -183 12 -47 14 -48 51 -48 22 0 39 2 39 4 0 2 -11 47 -25 101 -29 110 -45 147 -77 170 -17 13 -19 18 -10 22 33 12 83 68 93 103 25 83 -27 171 -118 198 -38 11 -181 14 -230 5z m230 -77 c31 -21 51 -75 42 -115 -12 -57 -74 -91 -167 -91 l-48 0 0 108 c0 60 3 112 8 116 13 14 137 0 165 -18z"/>
          <path d="M3190 4065 l0 -35 90 0 90 0 0 -270 0 -270 40 0 40 0 0 270 0 270 96 0 95 0 -3 33 -3 32 -222 3 -223 2 0 -35z"/>
          <path d="M2515 1895 c-23 -23 -25 -31 -25 -130 0 -99 2 -107 25 -130 13 -14 34 -25 45 -25 12 0 32 11 45 25 23 23 25 31 25 130 0 99 -2 107 -25 130 -13 14 -33 25 -45 25 -11 0 -32 -11 -45 -25z"/>
          <path d="M2514 1456 c-16 -13 -19 -29 -22 -129 -3 -106 -2 -117 17 -136 12 -12 34 -21 51 -21 17 0 39 9 51 21 19 19 20 30 17 136 -3 125 -12 143 -68 143 -14 0 -35 -6 -46 -14z"/>
          <path d="M2515 1005 c-23 -23 -25 -31 -25 -130 0 -99 2 -107 25 -130 13 -14 34 -25 45 -25 12 0 32 11 45 25 23 23 25 31 25 130 0 99 -2 107 -25 130 -13 14 -33 25 -45 25 -11 0 -32 -11 -45 -25z"/>
        </g>
      </svg>
    </span>
  );
};

export default StartTestdrive;
