import { h } from 'preact';

const Calendar = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512.000000pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet" class={props.classes || ""} onclick={(e) => props.action && props.action(e)} >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M1182 5109 c-45 -13 -108 -80 -121 -126 -6 -21 -11 -88 -11 -149 l0 -112 -192 -4 c-173 -4 -202 -8 -283 -32 -256 -77 -462 -281 -542 -541 l-28 -90 0 -1695 0 -1695 28 -90 c80 -259 283 -462 542 -542 l90 -28 866 0 865 0 41 27 c62 41 88 90 88 168 0 78 -26 127 -88 168 l-41 27 -840 5 -841 5 -58 23 c-111 45 -195 133 -234 246 -17 48 -18 138 -18 1686 0 1548 1 1638 18 1686 39 113 124 202 234 245 50 19 82 23 224 27 l167 4 3 -134 c4 -119 6 -137 28 -171 13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 22 34 24 52 27 171 l4 132 448 0 448 0 4 -133 c3 -125 5 -136 30 -174 41 -63 90 -88 169 -88 54 0 72 5 106 27 22 15 51 44 64 65 22 34 24 52 27 171 l4 132 453 0 453 0 4 -133 c3 -125 5 -136 30 -174 41 -63 90 -88 169 -88 54 0 72 5 106 27 22 15 51 44 64 65 22 34 24 52 28 171 l3 134 172 -4 c145 -4 179 -7 225 -26 111 -44 189 -121 233 -229 l23 -58 5 -645 5 -645 24 -38 c13 -21 42 -50 64 -65 34 -22 52 -27 106 -27 79 0 128 25 169 88 l27 41 0 670 0 671 -28 90 c-80 259 -286 464 -542 541 -81 24 -110 28 -287 32 l-196 4 -4 132 c-3 124 -5 135 -30 173 -41 62 -90 88 -168 88 -78 0 -127 -26 -168 -88 -25 -38 -27 -49 -30 -174 l-4 -133 -453 0 -453 0 -4 133 c-3 118 -5 136 -27 170 -13 21 -42 50 -64 65 -34 22 -52 27 -106 27 -79 0 -128 -25 -169 -88 -25 -38 -27 -49 -30 -174 l-4 -133 -448 0 -448 0 -4 133 c-3 125 -5 136 -30 174 -15 22 -43 50 -62 62 -41 26 -125 35 -174 20z"/>
          <path d="M1182 3209 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M2052 3209 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M2922 3209 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M3792 3209 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M3735 2405 c-264 -41 -505 -164 -687 -351 -96 -97 -162 -192 -224 -319 -164 -337 -164 -713 0 -1050 123 -251 311 -439 560 -560 339 -165 713 -165 1052 0 249 121 437 309 560 560 164 337 164 713 0 1050 -123 251 -311 439 -560 560 -222 108 -469 147 -701 110z m317 -399 c390 -77 668 -407 668 -796 0 -389 -278 -719 -668 -796 -277 -54 -585 59 -765 281 -248 306 -248 724 0 1030 180 221 488 335 765 281z"/>
          <path d="M3842 1809 c-47 -14 -109 -80 -122 -129 -7 -27 -10 -129 -8 -289 l3 -248 27 -40 c15 -22 44 -51 65 -64 37 -23 44 -24 248 -24 204 0 211 1 248 24 21 13 50 42 65 64 37 56 39 153 3 210 -39 63 -89 90 -181 95 l-78 4 -4 137 c-3 122 -5 140 -27 174 -47 76 -151 113 -239 86z"/>
          <path d="M1182 2339 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M2052 2339 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M1182 1469 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
          <path d="M2052 1469 c-48 -14 -109 -80 -123 -131 -23 -89 12 -182 88 -229 57 -36 154 -34 210 3 62 41 88 90 88 168 0 77 -26 127 -85 166 -43 29 -125 39 -178 23z"/>
        </g>
      </svg>
    </span>
  );
};

export default Calendar;
