import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Vas = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'} >
          <path d="M2160 4713 c-204 -225 -373 -418 -377 -430 -8 -33 20 -69 58 -77 17 -3 119 -6 225 -6 l194 0 0 -414 0 -414 -92 -22 c-118 -29 -244 -73 -330 -116 -37 -19 -70 -34 -73 -34 -3 0 -5 68 -5 150 l0 150 140 0 c127 0 142 2 160 20 45 45 36 60 -215 336 -129 143 -246 263 -259 268 -18 7 -32 6 -48 -3 -21 -12 -323 -339 -440 -477 -55 -64 -60 -93 -22 -123 24 -19 40 -21 150 -21 l124 0 0 -308 0 -309 -26 -24 c-48 -44 -145 -168 -201 -257 -125 -198 -200 -400 -244 -652 -20 -114 -17 -412 5 -535 65 -357 224 -662 476 -915 637 -636 1651 -667 2324 -72 456 404 660 1022 536 1621 -59 282 -210 576 -404 789 l-76 83 0 289 0 290 140 0 c136 0 142 1 165 25 34 33 31 58 -13 108 -235 260 -447 490 -458 494 -8 3 -25 3 -38 -1 -22 -5 -470 -488 -494 -533 -15 -27 -1 -67 27 -82 11 -6 76 -11 145 -11 l126 0 0 -145 c0 -80 -2 -145 -5 -145 -3 0 -23 9 -45 20 -83 42 -201 84 -325 115 l-130 32 -3 412 -2 411 217 1 c120 1 229 4 243 8 31 8 56 55 44 82 -13 26 -732 816 -750 823 -9 3 -24 6 -35 6 -12 0 -149 -144 -389 -407z m680 -65 c148 -164 270 -300 270 -303 0 -3 -81 -5 -180 -5 -179 0 -181 0 -205 -25 l-25 -24 0 -448 0 -448 -155 -1 -155 -1 0 452 c0 374 -2 455 -14 471 -13 17 -31 19 -193 24 l-180 5 276 303 c151 166 278 301 282 300 5 -2 130 -137 279 -300z m-1121 -848 c77 -85 141 -158 141 -162 0 -5 -44 -8 -97 -8 -142 0 -133 17 -133 -271 l0 -239 -67 -49 c-38 -28 -71 -50 -75 -51 -5 0 -8 129 -8 287 0 345 8 323 -122 323 -48 0 -88 3 -88 6 0 11 293 325 300 322 4 -2 71 -73 149 -158z m1986 -2 l148 -163 -106 -5 c-68 -3 -111 -10 -119 -18 -10 -10 -14 -80 -16 -292 -2 -154 -5 -280 -7 -280 -2 0 -34 21 -70 46 l-65 46 0 228 c0 282 6 269 -128 272 l-92 3 147 163 c81 89 150 162 154 162 3 0 73 -73 154 -162z m-820 -567 c132 -31 237 -67 350 -121 507 -243 846 -741 884 -1300 20 -282 -31 -545 -154 -795 -153 -313 -388 -553 -692 -708 -454 -231 -964 -233 -1417 -6 -369 186 -651 511 -780 900 -111 333 -111 665 0 997 154 464 536 841 1004 990 81 26 151 42 280 66 14 2 117 3 230 1 164 -3 223 -8 295 -24z" />
          <path d="M2380 3013 c-292 -44 -549 -172 -751 -373 -194 -193 -313 -412 -370 -680 -29 -138 -31 -361 -4 -500 53 -281 186 -525 394 -722 532 -507 1371 -483 1874 51 176 188 288 408 339 666 24 127 26 341 3 470 -85 480 -427 876 -888 1030 -147 49 -257 65 -427 64 -80 -1 -156 -3 -170 -6z m470 -156 c215 -56 394 -159 551 -316 157 -158 260 -337 316 -551 31 -118 42 -335 24 -460 -37 -248 -144 -469 -316 -649 -206 -217 -463 -341 -764 -371 -456 -46 -912 197 -1145 608 -161 286 -194 648 -87 964 138 409 491 712 923 794 119 22 379 13 498 -19z" />
          <path d="M2034 2502 c-35 -23 -36 -86 -2 -109 7 -4 103 -10 213 -13 193 -5 202 -6 256 -32 89 -44 151 -114 184 -209 l14 -39 -320 0 c-207 0 -327 -4 -340 -11 -41 -21 -44 -86 -5 -111 6 -4 156 -8 334 -8 l322 0 0 -23 c0 -37 -51 -128 -98 -175 -23 -24 -69 -56 -100 -70 -54 -26 -67 -27 -254 -32 -216 -5 -230 -10 -230 -65 0 -32 -7 -25 412 -420 168 -158 315 -292 328 -298 44 -20 97 27 84 75 -2 10 -137 143 -299 296 l-295 277 113 6 c82 5 129 12 168 28 148 60 268 198 303 351 l11 50 122 0 c105 0 124 2 138 18 21 23 22 62 3 88 -13 17 -29 19 -139 22 l-125 4 -11 45 c-15 58 -50 127 -95 186 l-35 46 195 3 c180 3 197 5 210 22 19 27 18 70 -4 89 -17 15 -68 17 -533 17 -283 0 -519 -4 -525 -8z" />
        </g>
      </svg>
    </span>
  );
};

export default Vas;
