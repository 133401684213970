import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

/*
  Modification: added onClick function
  By: Masum Raja
  Date: 20/03/2024
*/

/*
  Modification: Added span
  By: Devang
  Date: 21/08/2024
*/
const Stock = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width || ""} height={props.width || ""} fill={props.fill || "#00000"} onclick={(e) => props.action && props.action()}>
        <path d="M4 19.375V8.85q-.375-.125-.688-.525Q3 7.925 3 7.375v-1.75q0-.7.463-1.162Q3.925 4 4.625 4h14.75q.7 0 1.163.463.462.462.462 1.162v1.75q0 .55-.312.95-.313.4-.688.525v10.525q0 .7-.462 1.163-.463.462-1.163.462H5.625q-.7 0-1.162-.462Q4 20.075 4 19.375ZM5 9v10.375q0 .275.175.45t.45.175h12.75q.275 0 .45-.175t.175-.45V9Zm14.375-1q.275 0 .45-.175t.175-.45v-1.75q0-.275-.175-.45T19.375 5H4.625q-.275 0-.45.175T4 5.625v1.75q0 .275.175.45t.45.175Zm-10 5.375h5.25v-1h-5.25ZM5 20V9v11Z" />
      </svg>
    </span>
  );
};

export default Stock;
