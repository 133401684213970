/*
  modified : Ashutosh G
  modified : 01/03/2024
  modification : ChatIcon2 added
*/
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const ChatIcon2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class={props.classes || ""}
        width={props.width || "512pt"}
        height={props.height || "512pt"}
        viewBox={props.viewBox || "0 -960 960 960"}
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" fill={props.fill || "#000000"} stroke={props.stroke || "none"} />
      </svg>
    </span>
  );
};

export default ChatIcon2;
