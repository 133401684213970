import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const AddCaseV2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} version="1.0" xmlns="http://www.w3.org/2000/svg" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M442 5100 c-106 -28 -187 -75 -267 -155 -81 -81 -127 -161 -155 -271 -20 -75 -20 -117 -20 -1794 0 -1677 0 -1719 20 -1794 11 -43 34 -105 52 -139 47 -88 156 -194 246 -239 138 -69 130 -69 959 -66 731 3 742 3 769 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -39 21 -779 26 -722 5 -754 6 -792 25 -50 25 -105 80 -130 130 -20 39 -20 70 -20 1765 0 1695 0 1726 20 1765 25 50 80 105 130 130 39 20 67 20 1340 20 l1300 0 40 -22 c52 -28 109 -88 132 -138 17 -37 18 -94 23 -750 5 -657 6 -713 23 -738 78 -119 271 -77 292 65 3 24 5 354 3 733 -4 760 -1 722 -68 856 -66 129 -199 244 -338 291 l-67 23 -1320 2 c-1274 2 -1323 1 -1393 -17z"/>
          <path d="M747 4256 c-49 -18 -64 -32 -89 -80 -37 -73 -13 -153 62 -203 l33 -23 527 0 527 0 33 23 c48 32 72 69 77 119 7 58 -23 118 -74 149 l-38 24 -510 2 c-409 2 -517 0 -548 -11z"/>
          <path d="M720 3388 c-48 -33 -72 -70 -77 -120 -7 -58 23 -118 74 -149 l38 -24 1025 -3 c564 -2 1044 0 1068 3 57 8 118 62 132 116 19 69 -17 151 -80 184 -25 13 -173 15 -1088 15 l-1059 0 -33 -22z"/>
          <path d="M3510 2756 c-607 -108 -1065 -580 -1151 -1185 -15 -106 -7 -361 15 -461 58 -265 179 -492 367 -685 201 -207 431 -334 717 -397 145 -32 407 -32 551 0 285 64 502 182 702 381 199 200 317 417 381 702 32 144 32 406 0 551 -43 198 -124 381 -239 539 -71 99 -244 268 -341 332 -148 99 -321 173 -497 212 -97 22 -405 29 -505 11z m413 -321 c373 -65 690 -336 816 -699 117 -333 57 -709 -157 -992 -337 -446 -966 -555 -1434 -247 -241 158 -414 421 -463 700 -101 578 274 1123 851 1237 93 19 283 19 387 1z"/>
          <path d="M3650 2108 c-19 -13 -45 -43 -57 -68 -22 -42 -23 -56 -23 -267 l0 -223 -223 0 c-211 0 -225 -1 -267 -22 -66 -34 -93 -79 -88 -149 5 -65 32 -109 82 -133 28 -13 73 -16 264 -16 l231 0 3 -237 c3 -230 4 -239 27 -276 61 -99 193 -101 265 -3 20 25 21 45 26 269 l5 242 242 5 c224 5 244 6 269 26 98 72 96 204 -3 265 -37 23 -46 24 -275 27 l-238 3 0 231 c0 255 -5 279 -63 320 -47 34 -132 36 -177 6z"/>
          <path d="M735 2546 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8 240 -10 659 -8 613 3 622 3 650 24 98 73 98 195 0 268 -28 21 -37 21 -660 23 -510 2 -638 0 -662 -11z"/>
        </g>
      </svg>
    </span>
  );
};

export default AddCaseV2;
