import { h } from 'preact';

const Loader = () => {

  return (
    <div class="loadingio-spinner-eclipse-55z59xbhxwt"><div class="ldio-huzgndzb6dc">
      <div />
    </div></div>  );
};

export default Loader;
