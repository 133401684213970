import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Tick = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg title={props.title || ""} class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" idth={props.width || "490pt"} height={props.height || "490pt"} viewBox={props.viewBox || "0 0 490 490"} style={`enable-background:new 0 0 490 490; ${props.style || ''}`} fill={props.fill || ''}>
        <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 "/>
        <g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g />
      </svg>
    </span>
  );
};

export default Tick;
