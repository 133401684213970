import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const visit = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''} style={'user-select:none;'}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" width={props.width || "512pt"} height={props.height || "512pt"} viewBox="0 0 26 26" fill="none">
        <circle cx="13" cy="13" r="12.5" stroke={props.stroke || "#FFF"}/>
        <path d="M13.3984 16.2734L16.75 6.625H18.3828L14.1719 18H13.0078L13.3984 16.2734ZM10.2656 6.625L13.5859 16.2734L14 18H12.8359L8.63281 6.625H10.2656Z" fill={props.fill || "#FFF"}/>
      </svg>
    </span>
  );
};





export default visit;
