const unProtectedRoutes = ['/', '/readEmails', '/set-new-password', '/set-password', '/parineetihonda', '/kotharihyundai'];

/*const verifyUnprotectedRoute = (path) => {
  return unProtectedRoutes.includes(path) && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView'));
}*/

export {
  unProtectedRoutes
  // verifyUnprotectedRoute
};
