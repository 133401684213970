import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const NewCall = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2370 5114 c-19 -2 -77 -9 -128 -15 -126 -14 -306 -52 -427 -90 -390 -121 -738 -327 -1029 -609 -225 -218 -386 -438 -522 -715 -127 -260 -203 -509 -246 -810 -17 -118 -17 -512 0 -630 42 -297 119 -551 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92 309 -137 500 -185 196 -49 343 -70 545 -77 694 -24 1354 233 1861 724 293 285 522 648 648 1028 92 280 126 494 126 805 0 312 -34 525 -126 805 -127 382 -354 743 -648 1028 -396 383 -880 622 -1425 703 -96 14 -472 27 -546 18z m401 -244 c680 -66 1284 -416 1686 -978 204 -285 342 -632 400 -1002 24 -160 24 -500 0 -660 -53 -343 -182 -680 -360 -945 -170 -252 -410 -492 -662 -662 -419 -282 -979 -422 -1491 -372 -676 65 -1280 416 -1681 977 -204 285 -342 632 -400 1002 -24 160 -24 500 0 660 82 525 309 967 686 1332 329 320 718 525 1161 612 227 44 449 57 661 36z"/>
          <path d="M1778 4049 c-51 -8 -255 -87 -388 -152 -153 -74 -260 -208 -305 -382 -21 -79 -21 -267 -1 -365 44 -213 159 -469 317 -705 455 -680 1176 -1241 1749 -1361 99 -21 286 -20 365 0 107 29 204 83 276 155 71 72 114 151 205 376 57 143 68 228 38 317 -30 92 -93 142 -389 310 -299 170 -332 182 -440 155 -87 -22 -142 -62 -205 -148 -76 -104 -153 -132 -246 -90 -117 53 -541 477 -595 595 -42 93 -14 170 90 246 86 63 126 118 148 205 27 108 15 141 -155 438 -81 143 -166 281 -190 307 -68 78 -170 114 -274 99z m95 -256 c15 -18 242 -404 279 -477 28 -55 18 -77 -57 -131 -120 -85 -181 -191 -192 -330 -6 -75 11 -154 48 -226 73 -144 534 -605 678 -678 72 -37 151 -54 226 -48 137 10 241 70 327 188 28 39 61 72 73 75 13 3 39 -3 61 -14 73 -37 459 -264 477 -279 27 -25 21 -65 -34 -201 -97 -239 -125 -278 -234 -329 -79 -36 -233 -44 -352 -18 -706 157 -1691 1142 -1848 1848 -26 119 -18 273 18 352 51 109 80 130 312 226 148 62 192 70 218 42z"/>
          <path d="M3210 3792 c-60 -30 -70 -64 -70 -249 l0 -163 -165 0 c-185 0 -213 -7 -241 -62 -20 -38 -14 -101 13 -130 35 -40 74 -48 238 -48 l155 0 0 -155 c0 -164 8 -203 48 -238 29 -27 92 -33 130 -13 55 28 62 56 62 241 l0 165 160 0 c174 0 216 9 244 52 25 39 30 82 12 119 -28 59 -43 63 -236 69 l-175 5 -5 175 c-5 192 -10 208 -66 235 -41 19 -61 19 -104 -3z"/>
        </g>
      </svg>
    </span>
  );
};

export default NewCall;
