import axios from 'axios';
import CONSTANTS from '../../src/lib/constants';

export async function sendNotification(
  title, //1 String /required field
  body, //2 String /required field
  userIDs, //3 Array /required field
  tag, //4 String /optional field /pass null if not required
  iconFileID, //5 String /optional field /pass null if not required
  imageFileID, //6 String /optional field /pass null if not required or don't pass if not required
  taskID
) {
  try {
    let payload = {
      title,
      body,
      userIDs
    };
    if (tag && tag !== null) {
      payload['tag'] = tag;
    }
    if (iconFileID && iconFileID !== null) {
      payload['iconFileID'] = iconFileID;
    }
    if (imageFileID && imageFileID !== null) {
      payload['imageFileID'] = imageFileID;
    }
    if (taskID && taskID !== null) {
      payload['taskID'] = taskID;
    }
    await axios.post(`${CONSTANTS.API_URL}/api/sendPushNotification`, payload);
    return 'Notification Api called';
  } catch (HTTPException){
    return HTTPException.message;
  }
}



/*
call sendNotification function from anywhere in this project just import this function from this file and pass following arguments
1.title which will be shown at the top of the notification in bold
2.body is the description of the notification which will be shown below title
3.userIDs is array of user ids to which notification should be shown provide atleast one userid
4.tag is any string which make sure that user should not be notified more than once at a time
  you can user thise string 'id1, id2 .....' using same tag ensures that old notification with same tag will be discarded and instead of that new notification will be shown without notification tone
5.iconFileID is fileid which will be shown in small size on right side of the notifiation
  if not provided default shape 96x96 image is provided
6.imageFileID is fileid which will be shown in large size on bottom of the notification but
  above the action buttons
*/
