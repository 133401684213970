import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Verified = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M4165 4493 c-647 -448 -1395 -1180 -2024 -1981 -45 -58 -77 -89 -84 -85 -7 4 -223 155 -481 335 l-469 327 -26 -19 c-14 -10 -83 -76 -153 -147 l-127 -128 156 -215 c381 -524 808 -1143 999 -1446 58 -93 109 -173 113 -177 4 -4 56 75 116 175 547 927 1234 1917 1786 2578 227 272 323 377 576 628 l234 232 -253 0 -253 -1 -110 -76z"/>
        </g>
      </svg>
    </span>
  );
};

export default Verified;
