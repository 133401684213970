import { h, Fragment } from 'preact';
import NewListRow from '../../components/newListRow';
import { Search, ContactSearchSuggestion, TaskSearchSuggestion, InteractionSearchSuggestion, CaseSearchSuggestion } from '../../components/svgs';

const NewListV2 = (props) => {
  const { entityTitle, entityName, isFirstColumnEntityNameIcon, dataset, headClasses, lastColumnClasses, rowAction, isShowHighlightText, highlightedText } = props;

  const getEntityNameTextColor = entity => {
    if (['Contact', 'User'].includes(entity)) {
      return 'text-orange';
    } else if (entity === 'Task') {
      return 'text-color-green';
    } else if (entity === 'Interaction') {
      return 'text-color-red';
    } else if (entity === 'Case') {
      return 'text-color-444';
    } else if (entity === 'Courier') {
      return 'text-lightPurple';
    } else if (entity === 'Visit') {
      return 'text-lightBlue';
    }
  };

  const getRowButtonIcon = entity => {
    if (['Contact', 'User'].includes(entity)) {
      return <ContactSearchSuggestion masterClasses="flex-c-m" title={entity} fill="#404040" width="20" height="20" />;
    } else if (entity === 'Task') {
      return <TaskSearchSuggestion masterClasses="flex-c-m" title={entity} fill="#404040" width="20" height="20" />;
    } else if (entity === 'Interaction') {
      return <InteractionSearchSuggestion masterClasses="flex-c-m" title={entity} fill="#404040" width="20" height="20" />;
    } else if (entity === 'Case') {
      return <CaseSearchSuggestion masterClasses="flex-c-m" title={entity} fill="#404040" width="20" height="20" />;
    } else if (entity === 'Courier') {
      return <div class="search-suggestion-letter-icon flex-c-m" title={entity}>C</div>;
    } else if (entity === 'Visit') {
      return <div class="search-suggestion-letter-icon flex-c-m" title={entity}>V</div>;
    }
  };

  return (
    <div class="workspace-list-container-v2">
      <div class={`workspace-list-head ${headClasses ? headClasses : ''}`}>
        {dataset.columns && dataset.columns.map((column, index) => (
          <Fragment>
            {
              (isFirstColumnEntityNameIcon && index === 0) ?
                <div class={`workspace-list-head-item ${column.classes ? `${column.classes}` : ''} ${!index ? 'p-l-40' : 'p-l-8'}`}>
                  <div class="flex-l-m">
                    {getRowButtonIcon(entityName)}
                    <div class={`w-fit-content m-l-4 ${getEntityNameTextColor(entityName)}`}>
                      {entityTitle}
                    </div>
                  </div>
                </div>
                :
                <div class={`workspace-list-head-item ${column.classes ? `${column.classes}` : ''} ${!index ? 'p-l-40' : 'p-l-8'}`}>
                  {column.label}
                </div>
            }
          </Fragment>
        ))}
      </div>
      <div class="workspace-list-body">
        {dataset.rows && dataset.rows.length && dataset.rows.map((row, index) =>
          <NewListRow
            row={row}
            columns={dataset.columns}
            lastColumnClasses={(index+1) === dataset.rows.length ? lastColumnClasses : ''}
            rowAction={rowAction}
            entityName={entityName}
            isShowHighlightText={isShowHighlightText}
            highlightedText={highlightedText}
          />
        )}
      </div>
    </div>
  );
};

export default NewListV2;
