import { h } from 'preact';
// from flaticon
const ViewedRecently = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M2493 4896 c-28 -24 -28 -26 -31 -156 -4 -148 4 -182 49 -205 35 -19 63 -19 98 0 45 23 53 57 49 205 -3 130 -3 132 -31 156 -19 16 -40 24 -67 24 -27 0 -48 -8 -67 -24z"/>
          <path d="M1539 4581 c-22 -22 -29 -39 -29 -67 0 -33 9 -46 97 -136 54 -54 108 -101 120 -104 63 -16 123 32 123 97 0 41 -5 48 -98 141 -94 93 -100 98 -141 98 -34 0 -49 -6 -72 -29z"/>
          <path d="M3368 4512 c-93 -93 -98 -100 -98 -141 0 -65 60 -113 123 -97 12 3 66 50 120 104 88 90 97 103 97 136 0 55 -44 96 -101 96 -41 0 -47 -5 -141 -98z"/>
          <path d="M2290 4224 c-709 -95 -1462 -586 -2085 -1359 -148 -184 -205 -269 -205 -305 0 -83 420 -584 705 -840 527 -475 1032 -740 1565 -822 112 -17 469 -17 580 0 548 85 1070 364 1600 854 283 262 670 729 670 808 0 79 -387 546 -670 808 -543 502 -1083 786 -1633 857 -144 18 -385 18 -527 -1z m572 -213 c579 -125 1023 -570 1150 -1154 32 -148 32 -446 0 -594 -127 -586 -569 -1028 -1155 -1155 -148 -32 -446 -32 -594 0 -586 127 -1028 569 -1155 1155 -32 148 -32 446 0 594 97 447 378 815 780 1022 152 77 304 124 507 155 77 11 373 -3 467 -23z m-1678 -483 c-5 -7 -28 -44 -52 -82 -98 -151 -189 -387 -229 -591 -25 -127 -25 -463 0 -590 40 -204 131 -440 229 -591 24 -38 48 -76 53 -83 10 -17 -83 52 -205 153 -114 95 -371 347 -484 476 -129 146 -266 322 -266 340 0 19 134 190 265 340 122 139 397 406 515 501 190 151 187 149 174 127z m2986 -177 c109 -93 347 -329 454 -451 126 -143 266 -322 266 -340 0 -18 -140 -197 -266 -340 -107 -122 -345 -358 -454 -451 -99 -85 -246 -196 -235 -178 5 8 29 45 53 83 96 149 178 355 224 567 19 90 22 134 22 319 0 185 -3 229 -22 319 -46 212 -128 418 -224 567 -24 38 -48 75 -53 83 -11 18 136 -93 235 -178z"/>
          <path d="M2367 3716 c-245 -44 -456 -154 -632 -331 -461 -460 -461 -1190 0 -1650 460 -461 1190 -461 1650 0 237 236 357 544 342 874 -6 145 -27 247 -79 379 -33 84 -46 107 -72 123 -40 24 -67 24 -118 -2 -56 -29 -137 -23 -174 12 -59 55 -72 137 -31 198 41 63 47 76 47 105 0 81 -260 230 -490 281 -103 22 -343 28 -443 11z m431 -215 c65 -16 262 -96 262 -106 0 -1 -7 -23 -16 -49 -25 -72 -15 -195 20 -264 34 -67 91 -124 158 -159 46 -24 66 -28 153 -30 l100 -2 23 -83 c35 -125 40 -303 13 -433 -48 -233 -167 -431 -344 -570 -554 -439 -1365 -169 -1548 515 -33 123 -33 357 -1 480 100 376 419 662 807 723 71 12 291 -2 373 -22z"/>
          <path d="M2494 3216 l-34 -34 0 -321 c0 -353 2 -367 58 -390 20 -9 83 -11 202 -9 l172 3 24 28 c33 38 33 96 0 134 -24 27 -27 28 -140 31 l-116 4 0 260 0 260 -34 34 c-24 25 -43 34 -66 34 -23 0 -41 -9 -66 -34z"/>
          <path d="M1613 747 c-94 -94 -103 -106 -103 -141 0 -55 43 -96 101 -96 41 0 47 5 141 98 89 89 98 101 98 136 0 48 -13 72 -50 91 -59 31 -76 23 -187 -88z"/>
          <path d="M3318 834 c-35 -18 -48 -43 -48 -90 0 -35 9 -47 98 -136 94 -93 100 -98 141 -98 58 0 101 41 101 96 0 35 -9 47 -103 141 -111 111 -130 120 -189 87z"/>
          <path d="M2509 585 c-43 -23 -51 -59 -47 -205 3 -130 3 -132 31 -156 38 -33 96 -33 134 0 28 24 28 26 31 156 4 148 -4 182 -49 205 -35 19 -66 18 -100 0z"/>
        </g>
      </svg>
    </span>
  );
};

export default ViewedRecently;
