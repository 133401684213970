import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const BusinessImpact = (props) => {
  return (
    <svg class={props.classes || ''} xmlns="http://www.w3.org/2000/svg" width={props.width || "10"} height={props.width || "10"} fill={props.fill || "none"} viewBox="0 0 16 16">
      <path d="M4 2H12" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 5.33337H12" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 8.66663L9.66667 14" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 8.66663H6" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 8.66667C10.4447 8.66667 10.4447 2 6 2" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default BusinessImpact;
