import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Dots = (props) => {
  return (
    <svg class={props.classes || ''} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} height={props.height || "12"} viewBox={props.viewBox || "0 0 2 12"} >
      <circle cx="1" cy="1" r="1" fill={props.fill || "#002C5F"}/>
      <circle cx="1" cy="6" r="1" fill={props.fill || "#002C5F"}/>
      <circle cx="1" cy="11" r="1" fill={props.fill || "#002C5F"}/>
    </svg>
  );
};

export default Dots;
