import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Task = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg fill={props.fill || 'none'} class={props.classes || ''} onclick={(e) => props.action && props.action(e)} width={props.width || '13px'} height={props.height || '16px'} version="1.1" id="Layer_1" x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" viewBox="0 0 13 16">
        <path d="M8.35 1H2.4C2.0287 1 1.6726 1.1475 1.41005 1.41005C1.1475 1.6726 1 2.0287 1 2.4V13.6C1 13.9713 1.1475 14.3274 1.41005 14.5899C1.6726 14.8525 2.0287 15 2.4 15H10.8C11.1713 15 11.5274 14.8525 11.7899 14.5899C12.0525 14.3274 12.2 13.9713 12.2 13.6V4.85L8.35 1Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 1V5.2H12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.5 10.1L5.9 11.5L8.7 8.69995" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};
export default Task;
