import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
const CornerUpRight = (props) => {
  return (
    <span id={props.identifier} class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} fill={props.fill || "none"} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={props.strokeWidth || 2}>
        <path d="m15 14 5-5-5-5" />
        <path d="M4 20v-7a4 4 0 0 1 4-4h12" />
      </svg>
    </span>
  );
};
export default CornerUpRight;
