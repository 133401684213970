import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import axios from 'axios';
import toastr from "toastr";
import imageCompression from 'browser-image-compression';

import CONSTANTS from '../../lib/constants';
import { modelMapping, validateAadharNumber, getFormattedAmount, isAllowedFileType, deepCopy } from '../../lib/utils';

import FileViewer from '../../components/fileViewer';
import SearchableCaseDropdown from '../../components/searchableCaseDropdown';
import OrgChartModal from "../../components/orgChartModal";

const ReceivePaymentModal = (props) => {
  const {
    handleClose,
    handleSubmit,
    preselectedInteractionID
  } = props;

  const videoTypeExtension = ['mov', 'mp4', 'mkv', 'm4v', 'mpeg','webm'];
  const imageTypeExtension = ['png', 'jpg', 'jpeg', 'gif'];

  let [selectedCase, setSelectedCase] = useState('');
  let [selectedCaseObj, setSelectedCaseObj] = useState({});
  const [isPreselectedInteractionLoading, setIsPreselectedInteractionLoading] = useState(Boolean(preselectedInteractionID));
  const [paymentFileDetails, setPaymentFileDetails] = useState({});
  const [isUndertakingSent, setIsUndertakingSent] = useState(false);
  const [isPayeeAdded, setIsPayeeAdded] = useState(false);
  const [isPayeeAdding, setIsPayeeAdding] = useState(false);
  const [isAadhaarError, setIsAadhaarError] = useState(false);
  const [isPANError, setIsPANError] = useState(false);
  const [isPaymentSaving, setIsPaymentSaving] = useState(false);

  let [typeOfPayment, setTypeOfPayment] = useState('');
  let [typeOfPayments, setTypeOfPayments] = useState([]);
  let [modeOfPayment, setModeOfPayment] = useState('');
  let [amountPaid, setAmountPaid] = useState(0);
  let [chequeNumber, setChequeNumber] = useState('');
  let [transactionReferenceNumber, setTransactionReferenceNumber] = useState('');
  let [payeeName, setPayeeName] = useState('');
  let [typeOfPayee, setTypeofPayee] = useState('');
  let [payees, setPayees] = useState([]);
  let [relationWithVehicleOwner, setRelationWithVehicleOwner] = useState('');
  let [payeeBank, setPayeeBank] = useState('');
  let [aadharNumber, setAadharNumber] = useState('');
  let [panNumber, setPanNumber] = useState('');
  let [payeeEmail, setPayeeEmail] = useState('');

  const [isSaveConfirmModalOpen, setIsSaveConfirmModalOpen] = useState(false);

  useEffect(()=>{
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-bottom-left",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "600",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "slideDown",
      "hideMethod": "slideUp"
    };
  }, []);

  useEffect(() => {
    getPreselectedInteraction();
  }, []);

  const getPreselectedInteraction = async () => {
    if (preselectedInteractionID) {
      try {
        setIsPreselectedInteractionLoading(true);
        const { data } = await axios.get(`${CONSTANTS.API_URL}/api/v1/interaction?uuids=${preselectedInteractionID}`);
        if (data && data.length) {
          handleCaseChange(data[0]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsPreselectedInteractionLoading(false);
      }
    }
  }

  /*const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;

    let oldPaymentFileDetailsObj = deepCopy(paymentFileDetails);
    oldPaymentFileDetailsObj[name] = value;

    setPaymentFileDetails(oldPaymentFileDetailsObj);
  }*/

  const deleteUploadedFile = (name) => {
    let oldPaymentFileDetailsObj = deepCopy(paymentFileDetails);
    oldPaymentFileDetailsObj[name] = '';

    setPaymentFileDetails(oldPaymentFileDetailsObj);
  }


  const handleCaseChange = async (caseObj) => {
    setSelectedCase(caseObj.uuid);
    setSelectedCaseObj(caseObj);
    setTypeOfPayments(['Partial Booking Amount', 'Full Booking Amount','Partial Down Payment', 'Full Down Payment', 'Full Payment', 'Accessories Partial Payment', 'Accessories Full Payment'])
  }

  async function getPayeeList(id) {
    let Response = await axios.get(`${CONSTANTS.API_URL}/api/v1/payee?interactionID=${id}`);
    if (Response.data.length) {
      setPayees(Response.data);
    } else {
      setPayees([]);
    }
  }
  async function checkIfValid(e, type) {
   if (type === "aadhar") {
     const isValid = validateAadharNumber(e.target.value);
     if (!isValid) {
       setIsAadhaarError(true);
     } else {
       setIsAadhaarError(false);
     }
   }
   if (type === "panNumber") {
     const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
     if(regpan.test(e.target.value)){
       setIsPANError(false);
     } else {
       setIsPANError(true);
     }
   }
  }

  async function getCompressedImageFile(fileToBeUploaded) {
    let imageFileToBeCompressed = fileToBeUploaded.files[0];
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 900
    };
    let compressedFile = await imageCompression(imageFileToBeCompressed, options);
    let fileData = '';
    let fileObj = {};
    if (fileToBeUploaded.files[0].size > 30000) {
      fileData = compressedFile;
      fileObj.name= compressedFile.name;
      fileObj.size= compressedFile.size;
      fileObj.type= imageFileToBeCompressed.type;
      fileObj.value= fileToBeUploaded.value;
    } else {
      fileData = imageFileToBeCompressed;
      fileObj.name= imageFileToBeCompressed.name;
      fileObj.size= imageFileToBeCompressed.size;
      fileObj.type= imageFileToBeCompressed.type;
      fileObj.value= fileToBeUploaded.value;
    }
    return Promise.resolve(fileData);
  }

  async function handlePayee(e) {
    const { id: fieldName } = e.target;

    const fileTypeError = await isAllowedFileType(e.target.files[0]);
    if (!fileTypeError) {
      toastr.warning("This file cannot be uploaded either because this is not a supported file type. Please exclude such files and try again.");
      return;
    }

    const file = {
      name: e.target.files[0].name,
      size: e.target.files[0].size,
      type: e.target.files[0].type,
      value: e.target.files[0].name,
    };
    let payload = {
      file,
      interactionID: selectedCaseObj.uuid
    }
      let fileDetails
      await axios.post(`${CONSTANTS.API_URL}/api/v1/file/getSignedUrl`, payload).then(async res => {
        if (res && res.data) {
          fileDetails = res.data;
          try {
            //  Save File on S3
            const opts = {
              headers: {
                name: 'Content-Type',
                value: 'multipart/form-data',
              }
            };
            /*
             By: Yashvi
             On: 21th July 2022
             If file type is image only then compress the file.
           */
           let fileData = e.target.files[0];
           let fileExtension = e.target.files[0].name.split('.').pop();
           if (imageTypeExtension.includes(fileExtension)) {
             fileData = await getCompressedImageFile(e.target);
           }
           /*
             By: Yashvi
             On: 21th July 2022
             Process files (PPT, PDF, DOC) into images using lambda function
           */
            const fileUpload = await axios.put(fileDetails.signedURL, fileData, opts);
            // if (fileUpload) {
              let processTimeStarted;
              let optsData = {
                onUploadProgress(progressEvent) {
                  let processPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                  if (processPercentCompleted  >= 0 && !processTimeStarted) {
                    processTimeStarted = new Date();

                  } else if (processPercentCompleted > 0 && processTimeStarted) {
                    let timeElapsed = (new Date()) - processTimeStarted; // timeStarted is a Date Object
                    let uploadSpeed = progressEvent.loaded / (timeElapsed/1000); // Upload speed in second

                    // `callback` is the function that shows the time to user.
                    // The only argument is the number of remaining seconds.
                    let time = (progressEvent.total - progressEvent.loaded) / uploadSpeed;
                    let processTimeToUpload = Math.round(time);
                    if (processTimeToUpload >= 60) {
                      processTimeToUpload = Math.round(processTimeToUpload / 60);
                      // that.state.processTimeValue = processTimeToUpload === 1 ? "minute" : "minutes";
                    } else {
                      // that.state.processTimeValue = "seconds";
                    }
                  }
                }
              };

              if (fileExtension === 'pdf'){
                let process = await axios.put(`${CONSTANTS.API_URL}/api/file/processMultiple/pdf`, {
                  fileName: file.name,
                  fileID: fileDetails.uuid,
                  interactionID: selectedCaseObj.uuid,
                }, optsData);
              }
              if (fileExtension === 'ppt' || fileExtension === 'pptx'){
                let process = await axios.put(`${CONSTANTS.API_URL}/api/file/processMultiple`, {
                  fileName: file.name,
                  fileID: fileDetails.uuid,
                  interactionID: selectedCaseObj.uuid,
                }, optsData);
              }
              if (fileExtension === 'docx'){
                let process = await axios.put(`${CONSTANTS.API_URL}/api/file/processMultiple/doc`, {
                  fileName: file.name,
                  fileID: fileDetails.uuid,
                  interactionID: selectedCaseObj.uuid,
                }, optsData);
              }
              if (videoTypeExtension.includes(fileExtension)){
                await axios.put(`${CONSTANTS.API_URL}/api/v1/courseContentMultiple/generateThumbnail/`, {
                  fileName: file.name,
                  fileID: fileDetails.uuid,
                  interactionID: selectedCaseObj.uuid,
                }, optsData);
              }

              let oldPaymentFileDetailsObj = deepCopy(paymentFileDetails);
              oldPaymentFileDetailsObj[fieldName] = fileDetails.uuid;
              setPaymentFileDetails(oldPaymentFileDetailsObj);
          } catch (e) {
            console.error(e);
          }
        }
      });
  }

  async function addPayee(e) {
    try {
      e.preventDefault();
      setIsPayeeAdding(true);
      const payeePayload = {
        displayName: payeeName,
        email: payeeEmail,
        interactionID: selectedCaseObj.uuid,
        typeOfCustomer: 'Individual',
        relationWithVehicleOwner,
        bankName: payeeBank,
        isUndertakingSent: true,
        isUndertakingSigned: true,
        aadharNumber,
        panNumber
      };
      let newPayee = await axios.post(`${CONSTANTS.API_URL}/api/v1/payee`, payeePayload);
      toastr.info('New payee has been added for case ' + selectedCaseObj.uniqueID);
      setIsPayeeAdded(true);
    } catch (e) {
      console.error(e);
      toastr.error('Error adding payee for the case');
    } finally {
      setIsPayeeAdding(false);
    }
  }

  /*
    Modification: Added states fir Alert popup when submitting receive payment task with amount and customer's info
    By: Devang
    Date: 27/08/2024
  */
  const openSavePaymentConfirmModal = () => {
    setIsSaveConfirmModalOpen(true);
  }
  const closeSavePaymentConfirmModal = () => {
    setIsSaveConfirmModalOpen(false);
  }

  async function savePayment(e) {
    try {
      e.preventDefault();
      closeSavePaymentConfirmModal();
      setIsPaymentSaving(true);
      const payload = {
        typeOfPayment,
        amountPaid,
        chequeNumber,
        transactionReferenceNumber,
        payeeName,
        payeeBank,
        modeOfPayment,
        dynamicProperties_payeeAadharID: paymentFileDetails.dynamicProperties_payeeAadharID || '',
        dynamicProperties_payeePanID: paymentFileDetails.dynamicProperties_payeePanID || '',
        dynamicProperties_undertakingID: paymentFileDetails.dynamicProperties_undertakingID || '',
        dynamicProperties_paymentProof: paymentFileDetails.dynamicProperties_paymentProof || '',
      };
      /*
        Modification: Accepted Response form savePayment api and showed toasters
        By: Devang
        Date: 28/08/2024
      */
      const Response = await axios.post(`${CONSTANTS.API_URL}/api/v1/savePayment/${selectedCaseObj.uuid}`, payload);
      toastr.success("Task Receive Payment completed");
      if (Response.data.messageResponses && Response.data.messageResponses.length) {
        await Promise.all(Response.data.messageResponses.map(async (messageObj) => {
          if (messageObj.sendTo) {
            let messageNotification = await axios.post(`${CONSTANTS.API_URL}/api/v1/notification`, messageObj);
            if (messageNotification.status === 200) {
              triggerNotifications(true)
            }
            toastr.info(messageObj.message);
          } else {
            toastr.info(messageObj.message);
          }
        }))
      }
      handleSubmit && handleSubmit();
    } catch (e) {
      toastr.error('Error saving payment.');
      console.error(e);
    } finally {
      setIsPaymentSaving(false);
    }
  }

  let [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  let [uploadedfile, setUploadedfile] = useState([]);
  let [fileViewerFor, setFileViewerFor] = useState({
    for: 'case',
    interactionID: ''
  });

  async function toggleFileViewer() {
    setIsFileViewerOpen(!isFileViewerOpen);
  }

  async function closeFileViewer() {
    setIsFileViewerOpen(false);
  }

  async function getReferenceFilesForFileViewer(e, type, openViewer, fieldName) {
    let fileViewerObj = [];
    let fileID = [paymentFileDetails[fieldName]];
    if (type === 'Uploaded Files') {
      fileViewerObj.push({name: 'Uploaded Files', fileIDs: fileID});
    }
    let payload = {
      interactionID: selectedCaseObj.uuid,
      s3KeyFor: 'case',
      fileViewerObj
    };
    fileViewerFor['interactionID'] = selectedCaseObj.uuid;
    let response = await axios.put(`${CONSTANTS.API_URL}/api/fileViewer`, payload);
    fileViewerFor.type = type;
    if (response && response.data) {
      setUploadedfile(response.data);
      if (openViewer) setIsFileViewerOpen(true);
    }
  }

  return (
    <>
      <div class='orgChartModal'>
        <div id="modelCaseModal" style="display:block;" class="org-chart-modal overflow-unset">
          <div class="org-chart-modal-content org-chart-width" style='padding: 0px !important'>
            <div>
              <div class={`modal-header`}>
                <span class='fs-12'>
                  <div class="display-flex">
                    <ul class='breadrcrumb_arrows_inverted m-r-8'>
                      <li>Receive Payment</li>
                    </ul>
                  </div>
                </span>
                <span class="modal-close" onClick={handleClose}>&times;</span>
              </div>
              <div class="org-chart-modal-body background-transparent" style="height:80vh; overflow:auto; padding: 30px 0 !important">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    {
                      (selectedCaseObj && selectedCaseObj.uuid) && (
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="m-t-10 form-flex flex-direction-column align-baseline">
                              <span class="fs-14 text-gray">Customer Name </span>
                              <span class={"first-letter-capital list-card-value "}>{selectedCaseObj["dynamicProperties_customerName"]}</span>
                            </div>
                            <div class="m-t-10 form-flex flex-direction-column align-baseline">
                              <span class="fs-14 ">Source </span>
                              <span class={"first-letter-capital list-card-value "}>{selectedCaseObj["dynamicProperties_source"]}</span>
                            </div>
                            <div class="m-t-10 form-flex flex-direction-column align-baseline">
                              <span class="fs-14 text-gray">Model </span>
                              <span class={"first-letter-capital list-card-value "}>{selectedCaseObj["dynamicProperties_selectedModelName"] ? modelMapping[selectedCaseObj["dynamicProperties_selectedModelName"]]: "--"}</span>
                            </div>
                            <div class="m-t-10 form-flex flex-direction-column align-baseline">
                              <span class="fs-14 text-gray">Variant </span>
                              <span class={"first-letter-capital list-card-value "}>{selectedCaseObj["dynamicProperties_selectedVariantName"] ? selectedCaseObj["dynamicProperties_selectedVariantName"]: "--"}</span>
                            </div>
                            <div class="m-t-10 form-flex flex-direction-column align-baseline">
                              <span class="fs-14 text-gray ">Amount Paid </span>
                              <span class={"first-letter-capital list-card-value "}>{selectedCaseObj["dynamicProperties_typeOfPayment"] === "Full Booking Amount" ? getFormattedAmount(selectedCaseObj["dynamicProperties_amountPaid"]) : selectedCaseObj["dynamicProperties_bookingAmountPaid"] ? getFormattedAmount(selectedCaseObj['dynamicProperties_bookingAmountPaid']) : "--"}</span>
                            </div>
                            <div class="m-t-10 form-flex flex-direction-column align-baseline">
                              <span class="fs-14 text-gray">Balance Amount </span>
                              <span class={"first-letter-capital list-card-value "}>{selectedCaseObj["dynamicProperties_typeOfPayment"] === "Partial Booking Amount" ? getFormattedAmount(selectedCaseObj["dynamicProperties_balanceBookingAmount"]): selectedCaseObj["dynamicProperties_balanceAmount"] ? getFormattedAmount(selectedCaseObj['dynamicProperties_balanceAmount']) : "--"}</span>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    {
                      (selectedCaseObj && !selectedCaseObj.uuid) && (
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 display-flex text-gray">
                            <p>Please select a case to receive payment</p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-t-5 display-flex flex-column">
                    <div class="m-b-10">
                      <div class="p-b-10 text-gray" >
                        <p> Select Case <span class="star-mandatory-entry p-l-2">*</span></p>
                        {/*
                          Bug: T1687 - 20 - Receive Payment Dropdown - Dropdowns should have search functionality (i.e. when I type option it should be selected automatically)
                          Modification: Added searchable dropdown and commented html select tag
                          By: Devang
                          Date: 11/04/2024
                        */}
                        {/*<select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text"  name="case" value={selectedCase} onChange={async (e) => {
                          // await setSelectedCase(e.target.value);
                          await setCase(e.target.value);
                          //await getPayeeList(e.target.value);
                        }} style="text-shadow:none">
                          <option value=''>Select Case</option>
                          {
                            cases.map(caseObj => (
                              <option value={caseObj.uuid}>{caseObj.caseID}</option>
                            ))
                          }
                        </select>*/}
                        {
                          preselectedInteractionID ?
                          <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text" name="case" value={selectedCaseObj.uniqueID || ''} disabled style="text-shadow:none">
                            {
                              isPreselectedInteractionLoading ?
                              <option value=''>Loading...</option>
                              :
                              <option value={selectedCaseObj.uniqueID}>{selectedCaseObj.uniqueID}</option>
                            }
                          </select> :
                          <SearchableCaseDropdown selectedValue={selectedCase} onChange={handleCaseChange} />
                        }
                      </div>
                      {
                        selectedCase && (
                          <div class="m-b-10">
                            <div class="p-b-10 text-gray" >
                              <p>Type of Payment <span class="star-mandatory-entry p-l-2">*</span></p>
                              <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text"  name="case" value={typeOfPayment} onChange={async (e) => {
                                setTypeOfPayment(e.target.value);
                              }} style="text-shadow:none">
                                <option value=''>Select Type of Payment</option>
                                {
                                  typeOfPayments.map(typeOfPayment => (
                                    <option value={typeOfPayment}>{typeOfPayment}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                        )
                      }
                      {
                        typeOfPayment && (
                          <div class="m-b-10">
                            <div class="p-b-10 text-gray" >
                              <p>Amount <span class="star-mandatory-entry p-l-2">*</span></p>
                              <input autocomplete="off" type="number" onWheel={e => e.preventDefault()} value={amountPaid} onInput={(e) => setAmountPaid(e.target.value)} min="0" />
                            </div>
                          </div>
                        )
                      }
                      {
                        typeOfPayment && (
                          <div class="m-b-10">
                            <div class="p-b-10 text-gray" >
                              <p>Type of Payee <span class="star-mandatory-entry p-l-2">*</span></p>
                              <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text"  name="typeOfPayee" value={typeOfPayee} disabled={isPayeeAdded} onChange={async (e) => {
                                setTypeofPayee(e.target.value);
                              }} style="text-shadow:none">
                                <option value=''>Select Type of Payee</option>
                                <option selected={typeOfPayee === "Self"} value='Self'>Self</option>
                                <option selected={typeOfPayee === "Other"} value='Other'>Other(Added)</option>
                                <option selected={typeOfPayee === "Add New"} value='Add New'>Add New</option>
                              </select>
                            </div>
                          </div>
                          )
                      }
                      {/*
                        Modification: Commenting this conditional render as it won't be true right now as we are not getting payees
                        By: Devang
                        Date: 15/07/2024
                      */}
                      {/*
                        typeOfPayee && typeOfPayee === "Other" && payees.length > 0 && (
                          <div class="m-b-10">
                            <div class="p-b-10 text-gray" >
                              <p>Select Payee <span class="star-mandatory-entry p-l-2">*</span></p>
                              <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text"  name="case" value={typeOfPayment} onChange={async (e) => {
                                setTypeOfPayment(e.target.value);
                              }} style="text-shadow:none">
                                <option value=''>Select Payee</option>
                                {
                                  payees.map(payee => (
                                    <option value={payee.uuid}>{payee.displayName}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                        )
                      */}
                      {
                        typeOfPayee && (
                          <>
                            {
                              (typeOfPayee === "Other" && payees.length <= 0  || typeOfPayee === "Add New") && (
                                <>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Payee Name <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <input autocomplete="off" type="text" value={payeeName} onInput={(e) => setPayeeName(e.target.value)} />
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Relation with the Vehicle Owner <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text"  name="typeOfPayee" value={relationWithVehicleOwner} onChange={async (e) => {
                                        setRelationWithVehicleOwner(e.target.value);
                                      }} style="text-shadow:none">
                                        <option value=''>Select Relation</option>
                                        <option value='W/O'>W/O</option>
                                        <option value='S/O'>S/O</option>
                                        <option value='D/O'>D/O</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Payee Bank <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <input autocomplete="off" type="text" value={payeeBank} onInput={(e) => setPayeeBank(e.target.value)} />
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Payee Aadhar Number <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <input autocomplete="off" id="payeeAadhar" type="text" onblur={(e) => checkIfValid(e, 'aadhar')} value={aadharNumber} placeholder="XXXX XXXX XXXX" maxlength="12" onInput={(e) => setAadharNumber(e.target.value)} />
                                      {
                                        isAadhaarError &&
                                        <span id={`error-payeeAadhar`} class="text-color-red fs-10">Aadhar Number is Invalid. Please Enter Valid Number</span>
                                      }
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Upload Payee Aadhar Card <span class="star-mandatory-entry p-l-2">*</span></p>
                                      {
                                        !paymentFileDetails.dynamicProperties_payeeAadharID ? (
                                          <div>
                                            <input class="uploadInputFile" id="dynamicProperties_payeeAadharID" type="file" onInput={handlePayee}/>
                                            <label class="p-t-b-5" for="dynamicProperties_payeeAadharID">
                                              <figure>
                                                <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                              </figure>
                                            </label>
                                          </div>
                                        ) : (
                                          <div class="uploaded-image m-0">
                                            <button type="button" id={`uploaded-image-dynamicProperties_payeeAadharID`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, 'dynamicProperties_payeeAadharID')}>View Uploaded File</button>
                                            <p class="delete-file-icon" title="Delete file" onClick={() => deleteUploadedFile('dynamicProperties_payeeAadharID')}>{'x'}</p>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Payee PAN <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <input autocomplete="off" class="text-up" type="text" onblur={(e) => checkIfValid(e, 'panNumber')} placeholder="XXXXXXXXXX" value={panNumber} maxlength="10" onInput={(e) => setPanNumber(e.target.value)} />
                                      {
                                        isPANError &&
                                        <span id={`error-payeePan`} class="text-color-red fs-10">PAN Number is Invalid. Please Enter Valid Number</span>
                                      }
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Upload Payee PAN Card <span class="star-mandatory-entry p-l-2">*</span></p>
                                      {
                                        !paymentFileDetails.dynamicProperties_payeePanID ? (
                                          <div>
                                            <input class="uploadInputFile" id="dynamicProperties_payeePanID" type="file" onInput={handlePayee}/>
                                            <label class="p-t-b-5" for="dynamicProperties_payeePanID">
                                              <figure>
                                                <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                              </figure>
                                            </label>
                                          </div>
                                        ) : (
                                          <div class="uploaded-image m-0">
                                            <button type="button" id={`uploaded-image-dynamicProperties_payeePanID`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, 'dynamicProperties_payeePanID')}>View Uploaded File</button>
                                            <p class="delete-file-icon" title="Delete file" onClick={() => deleteUploadedFile('dynamicProperties_payeePanID')}>{'x'}</p>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Payee Email <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <input autocomplete="off" type="text" value={payeeEmail} onInput={(e) => setPayeeEmail(e.target.value)} />
                                    </div>
                                  </div>
                                  <div class="m-b-10">
                                    <div class="flex-l-m">
                                      <button class="secondary-button" onClick={() => setIsUndertakingSent(true)}>
                                        <a
                                          href={`/assets/static/Undertaking_for_accepting_the_payment_from_other_account.pdf`}
                                          download={`Undertaking for accepting the payment from other account.pdf`}
                                          target="_blank"
                                        >Download Undertaking</a>
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    isUndertakingSent && (
                                      <>
                                        <div class="m-b-10">
                                          <div class="p-b-10 text-gray" >
                                            <p>Upload Signed Undertaking <span class="star-mandatory-entry p-l-2">*</span></p>
                                            {
                                              !paymentFileDetails.dynamicProperties_undertakingID ? (
                                                <div>
                                                  <input class="uploadInputFile" id="dynamicProperties_undertakingID" type="file" onInput={handlePayee} />
                                                  <label class="p-t-b-5" for="dynamicProperties_undertakingID">
                                                    <figure>
                                                      <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                                    </figure>
                                                  </label>
                                                </div>
                                              ) : (
                                                <div class="uploaded-image m-0">
                                                  <button type="button" id={`uploaded-image-dynamicProperties_undertakingID`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, 'dynamicProperties_undertakingID')}>View Uploaded File</button>
                                                  <p class="delete-file-icon" title="Delete file" onClick={() => deleteUploadedFile('dynamicProperties_undertakingID')}>{'x'}</p>
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>
                                        <div class="m-b-10">
                                          <div class="p-b-10">
                                            <button
                                              class="primary-button"
                                              disabled={
                                                !(payeeName && payeeEmail && relationWithVehicleOwner && payeeBank && aadharNumber && panNumber && paymentFileDetails.dynamicProperties_payeeAadharID && paymentFileDetails.dynamicProperties_payeePanID && paymentFileDetails.dynamicProperties_undertakingID) ||
                                                (isAadhaarError || isPANError || isPayeeAdded || isPayeeAdding)
                                              }
                                              onClick={addPayee}>Add Payee</button>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                </>
                              )
                            }
                            {
                              (isPayeeAdded || typeOfPayee === "Self") && (
                                <>
                                  <div class="m-b-10">
                                    <div class="p-b-10 text-gray" >
                                      <p>Mode of Payment <span class="star-mandatory-entry p-l-2">*</span></p>
                                      <select class="p-l-r-0-2rem text-4b4d51 fs-1rem h-2rem text-4b4d51 background-white border-none" type="text"  name="modeOfPayment" value={modeOfPayment} onChange={async (e) => {
                                        setModeOfPayment(e.target.value);
                                      }} style="text-shadow:none">
                                      <option value=''>Select Mode of Payment</option>
                                      <option selected={modeOfPayment === "Cash"} value='Cash'>Cash</option>
                                      <option selected={modeOfPayment === "Cheque"} value='Cheque'>Cheque</option>
                                      <option selected={modeOfPayment === "Digital"} value='Digital'>Digital</option>
                                      </select>
                                    </div>
                                  </div>
                                  {
                                    modeOfPayment && (
                                      <>
                                        {
                                          modeOfPayment === "Cheque" && (
                                            <div class="m-b-10">
                                              <div class="p-b-10 text-gray" >
                                                <p>Cheque Number <span class="star-mandatory-entry p-l-2">*</span></p>
                                                <input autocomplete="off" type="number" onWheel={e => e.preventDefault()} value={chequeNumber} onInput={(e) => setChequeNumber(e.target.value)} min="0" />
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          modeOfPayment === "Digital" && (
                                            <div class="m-b-10">
                                              <div class="p-b-10 text-gray" >
                                                <p>Transaction Reference Number <span class="star-mandatory-entry p-l-2">*</span></p>
                                                <input autocomplete="off" type="number" onWheel={e => e.preventDefault()} value={transactionReferenceNumber} onInput={(e) => setTransactionReferenceNumber(e.target.value)} min="0" />
                                              </div>
                                            </div>
                                          )
                                        }
                                        <div class="m-b-10">
                                          <div class="p-b-10 text-gray" >
                                            <p>Payee Name <span class="star-mandatory-entry p-l-2">*</span></p>
                                            <input autocomplete="off" type="text" value={payeeName} onInput={(e) => setPayeeName(e.target.value)} />
                                          </div>
                                        </div>
                                        {
                                          modeOfPayment !== "Cash" && (
                                            <div class="m-b-10">
                                              <div class="p-b-10 text-gray" >
                                                <p>Payee Bank <span class="star-mandatory-entry p-l-2">*</span></p>
                                                <input autocomplete="off" type="text" value={payeeBank} onInput={(e) => setPayeeBank(e.target.value)} />
                                              </div>
                                            </div>
                                          )
                                        }
                                        <div class="m-b-10">
                                          <div class="p-b-10 text-gray" >
                                            <p>Upload Payment Proof <span class="star-mandatory-entry p-l-2">*</span></p>
                                            {
                                              !paymentFileDetails.dynamicProperties_paymentProof ? (
                                                <div>
                                                  <input class="uploadInputFile" id="dynamicProperties_paymentProof" type="file" onInput={handlePayee}/>
                                                  <label class="p-t-b-5" for="dynamicProperties_paymentProof">
                                                    <figure>
                                                      <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                                    </figure>
                                                  </label>
                                                </div>
                                              ) : (
                                                <div class="uploaded-image m-0">
                                                  <button type="button" id={`uploaded-image-dynamicProperties_paymentProof`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, 'dynamicProperties_paymentProof')}>View Uploaded File</button>
                                                  <p class="delete-file-icon" title="Delete file" onClick={() => deleteUploadedFile('dynamicProperties_paymentProof')}>{'x'}</p>
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                </>
                              )
                            }
                          </>
                        )
                      }
                      <div class="p-b-10">
                        <button disabled={
                          isPaymentSaving ||
                          !(
                            typeOfPayment && amountPaid && typeOfPayee &&
                            (
                              !(typeOfPayee === "Other" && payees.length <= 0  || typeOfPayee === "Add New") ||
                              ((typeOfPayee === "Other" && payees.length <= 0  || typeOfPayee === "Add New") && isPayeeAdded)
                            ) &&
                            (
                              (typeOfPayee === "Self" || isPayeeAdded) &&
                              (
                                (modeOfPayment !== "Cheque" || (modeOfPayment === "Cheque" && chequeNumber)) &&
                                (modeOfPayment !== "Digital" || (modeOfPayment === "Digital" && transactionReferenceNumber)) &&
                                (!(modeOfPayment !== "Cash" || isPayeeAdded) || ((modeOfPayment !== "Cash" || isPayeeAdded) && payeeBank)) &&
                                payeeName
                              )
                            ) &&
                            paymentFileDetails.dynamicProperties_paymentProof
                          )
                        } class="primary-button m-l-10" onClick={openSavePaymentConfirmModal}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            isFileViewerOpen &&
            <FileViewer
              closeFileViewer={closeFileViewer}
              toggleFileViewer={toggleFileViewer}
              isFileViewerOpen={isFileViewerOpen}
              uploadedfile={uploadedfile}
              fileViewerFor={fileViewerFor}
              contentDetails={{}}
              isDeleteHidden
              isUploadHidden
              isViewedFrom
            />
          }
          {/*
            Modification: Added Alert popup when submitting receive payment task with amount and customer's info
            By: Devang
            Date: 27/08/2024
          */}
          {
            isSaveConfirmModalOpen && (
              <OrgChartModal title={"Confirm Payment"} masterClasses={``} orgChartClasses={`w-40vw`} isOpen={isSaveConfirmModalOpen} onClose={closeSavePaymentConfirmModal}>
                {
                  <div class="row">
                    <div class="flex-c-m flex-direction-column m-b-16">
                      <p class="fs-16 m-b-12">Please confirm that you are receiving the payment of <span class="text-red-2">{getFormattedAmount(amountPaid)}</span> against customer <span class="text-green-4">{selectedCaseObj["dynamicProperties_customerName"]}</span>.</p>
                    </div>
                    <div class="flex-r-m w-full">
                      <button class="primary-button flex-c-m m-r-8" onClick={closeSavePaymentConfirmModal}>Cancel</button>
                      <button class="primary-button flex-c-m" onClick={savePayment}>Confirm</button>
                    </div>
                  </div>
                }
              </OrgChartModal>
            )
          }
        </div>
      </div>
    </>
  )
};

export default ReceivePaymentModal;
