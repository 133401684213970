import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";

const Ribbon = (props) => {
  const { ribbonText, ribbonColor, ribbonTextColor, type, variant, isTextNotVisible, title } = props;

  return (
    <div>
      {
        type === 'rotated' && ribbonText &&
        <div title={title || ribbonText} class={`rotated-card-ribbon ${ribbonColor ? `${ribbonColor.toLowerCase()}-ribbon` : 'default-ribbon-color'} ${isTextNotVisible ? `${ribbonColor.toLowerCase()}-text-ribbon no-select` : ''}`}>
          {ribbonText}
        </div>
      }
      {
        type === 'rotated-sm' && ribbonText &&
        <div title={title || ribbonText} class={`rotated-sm-card-ribbon ${ribbonColor ? `${ribbonColor.toLowerCase()}-ribbon` : 'default-ribbon-color'} ${isTextNotVisible ? `${ribbonColor.toLowerCase()}-text-ribbon no-select` : ''}`}>
          {ribbonText}
        </div>
      }
      {
        type === 'folded' && ribbonText &&
        <div title={title || ribbonText} class={`folded-card-ribbon-container ${variant}-ribbon display-flex pos-absolute ${(variant && variant !== 'secondary') ? 'top-0 right-0' : ''}`}>
          <div class={`folded-card-ribbon ${ribbonColor ? `${ribbonColor.toLowerCase()}-ribbon` : 'default-ribbon-color'}`}>
            <span class={`folded-card-ribbon-text ${ribbonTextColor ? ribbonTextColor.toLowerCase() : ''}`}>{ribbonText}</span>
          </div>
        </div>
      }
    </div>
  );
};

export default Ribbon;
