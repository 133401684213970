import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Finance = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'} >
          <path d="M1406 5105 c-125 -44 -197 -193 -154 -316 6 -18 160 -280 341 -582 181 -302 327 -550 325 -551 -1 -2 -50 -27 -108 -56 -471 -235 -806 -687 -891 -1202 -29 -178 -151 -2268 -135 -2318 6 -20 25 -44 45 -57 l34 -23 1697 0 1697 0 34 23 c20 13 39 37 45 57 9 28 -2 264 -51 1116 -35 595 -69 1117 -75 1160 -45 307 -176 594 -384 842 -136 161 -344 323 -534 413 -48 23 -89 43 -90 45 -2 1 144 247 324 545 220 364 332 560 341 594 30 116 -41 250 -155 291 -58 21 -111 17 -277 -21 -339 -77 -561 -101 -864 -92 -220 6 -292 15 -742 93 -174 30 -332 54 -350 54 -19 -1 -51 -7 -73 -15z m457 -295 c457 -77 517 -83 797 -83 307 0 464 18 788 89 90 20 166 34 169 31 3 -3 -142 -249 -321 -546 -180 -298 -329 -547 -332 -554 -8 -22 -96 1 -90 22 3 9 28 108 56 220 55 222 56 243 5 291 -29 27 -99 37 -135 18 -45 -24 -64 -71 -120 -296 l-54 -222 -57 0 -58 0 -54 173 c-31 94 -79 245 -107 334 -43 135 -57 167 -82 188 -74 62 -198 6 -198 -91 0 -15 43 -161 95 -325 52 -164 95 -300 95 -302 0 -2 -18 -7 -39 -11 -22 -3 -46 -8 -54 -12 -9 -3 -16 2 -19 14 -3 11 -153 265 -333 564 -180 298 -329 546 -332 551 -7 12 17 9 380 -53z m842 -1285 c91 -6 250 -42 348 -79 498 -187 854 -639 917 -1161 10 -82 120 -1920 120 -2001 l0 -34 -1530 0 -1530 0 0 23 c0 38 109 1888 115 1962 55 622 513 1139 1126 1270 79 18 262 34 319 29 19 -2 71 -6 115 -9z" />
          <path d="M2044 2749 c-48 -14 -76 -51 -82 -108 -4 -45 -2 -52 31 -87 l35 -39 204 -5 c218 -6 227 -8 305 -68 29 -22 73 -79 73 -96 0 -3 -122 -6 -270 -6 -286 0 -314 -4 -354 -48 -23 -27 -31 -99 -14 -132 7 -13 29 -35 47 -47 33 -23 39 -23 312 -23 164 0 279 -4 279 -9 0 -24 -70 -97 -120 -125 l-55 -31 -200 -5 c-169 -4 -204 -8 -226 -23 -52 -35 -65 -112 -26 -165 40 -56 707 -677 742 -691 96 -38 195 62 156 158 -7 16 -120 130 -251 254 l-238 225 64 12 c158 30 309 146 379 291 19 40 35 81 35 91 0 16 10 18 90 18 138 0 200 38 200 122 0 94 -50 128 -189 128 -78 0 -92 3 -97 18 -4 9 -19 46 -35 81 -16 35 -29 65 -29 67 0 2 57 4 126 4 141 0 184 12 209 61 38 72 2 158 -73 179 -46 12 -984 12 -1028 -1z" />
        </g>
      </svg>
    </span>
  );
};

export default Finance;
