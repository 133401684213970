import { h } from 'preact';

const EditNote = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClasses || ''}`} title={props.title || ''}>
      <svg class={`${props.classes || ''} ${props.identityClasses || ''}`} onclick={(e) => props.action && props.action(e)} width={props.width || "20"} height={props.height || "20"} viewBox={props.viewBox || "0 0 20 20"} fill={props.fill || 'none'} xmlns="http://www.w3.org/2000/svg">
        <path class={props.identityClasses || ''} d="M9.11426 2.88568H2.89204C2.42054 2.88568 1.96835 3.07298 1.63496 3.40638C1.30156 3.73978 1.11426 4.19196 1.11426 4.66346V17.1079C1.11426 17.5794 1.30156 18.0316 1.63496 18.365C1.96835 18.6984 2.42054 18.8857 2.89204 18.8857H15.3365C15.808 18.8857 16.2602 18.6984 16.5936 18.365C16.927 18.0316 17.1143 17.5794 17.1143 17.1079V10.8857" stroke={props.stroke || "white"} stroke-linecap="round" stroke-linejoin="round"/>
        <path class={props.identityClasses || ''} d="M15.7806 1.55228C16.1342 1.19866 16.6138 1 17.1139 1C17.614 1 18.0936 1.19866 18.4473 1.55228C18.8009 1.90591 18.9996 2.38552 18.9996 2.88562C18.9996 3.38572 18.8009 3.86533 18.4473 4.21895L10.0028 12.6634L6.44727 13.5523L7.33615 9.99673L15.7806 1.55228Z" stroke={props.stroke || "white"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default EditNote;
