import { useState, useEffect } from 'preact/hooks';
import {formatDateTime, getInitials, typeOfEnquiryMappingStyleColor, getStageAcronym, isValidDate, formatDateTimeRelative} from '../../lib/utils';
import moment from "moment";
import {setItem, getItem } from '../../lib/myStore';
import Loader from '../loader';
import { Clipboard } from '../../components/svgs';

{/*
        modified by Vihang
        modified at 04/05/2022
        modification : responsive changes for list card
  */}
const ListCard = (props) => {
  let [newDataSet, setDataset] = useState([]);
  let [selectedList, setSelectedList] = useState('');

  useEffect(() => {
    if (props.selectedList && props.selectedList.uuid) {
      setSelectedList(props.selectedList.uuid);
    } else {
      setSelectedList('');
    }
  },[props.selectedList]);
  /*
    modified by Vihang
    modified at 11/05/2022
    modification : when any of the task cards are selected thier first list is selected by default and show the slected task on semi detail
  */
  useEffect(async () => {
    setDataset(props.taskData);
    // let currentTask = getItem("currentRowID");
    let currentTask = props.taskType === "dayPlanTask" ? await getItem("currentTaskID") : await getItem("currentRowID");
    if (Object.keys(currentTask).length) {
      setSelectedList(currentTask.uuid);
    }
  },[props.taskData]);

  useEffect(async ()=> {
    if (newDataSet && props.taskType === "dayPlanTask" && newDataSet.rows) {
      // let currentTask = getItem("currentRowID");
      let currentTask = props.taskType === "dayPlanTask" ? await getItem("currentTaskID") : await getItem("currentRowID");
      if (Object.keys(currentTask).length) {
        setSelectedList(currentTask.uuid);
      }
      props.showData(currentTask);
    }
  },[newDataSet]);

  useEffect(async () => {
    setDataset(props.taskData);
    // let currentTask = getItem("currentRowID");
    let currentTask = props.taskType === "dayPlanTask" ? await getItem("currentTaskID") : await getItem("currentRowID");
    if (Object.keys(currentTask).length) {
      setSelectedList(currentTask.uuid);
    }
  },[props.updateNextTask]);

  async function handleData(row, index) {
    console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk');
    props.taskType === "dayPlanTask" ? await setItem("currentTaskID", row) : await setItem("currentRowID", row);
    await setSelectedList(row.uuid);
    await props.showData(row, newDataSet[index+1]);
    console.log(row, 'rowrowrowrowrowrowrowrowororo', props.setShowSemiDetailView);

    if (props.setShowSemiDetailView) {

      props.setShowSemiDetailView(true);

    }
  }

  function deadlineDays(row) {
    if (row['dueDate']) {
      let z = moment(moment(row['dueDate']).format("DD/MM/YYYY"), 'DD-MM-YYYY');
      let y = moment(moment(new Date()).format("DD/MM/YYYY"), 'DD-MM-YYYY');
      let diffDays = y.diff(z, 'days');
      return diffDays;
    }
  }

  /*
      modified by Vihang
      modified at 11/05/2022
      modification : If the list is empty, display message "No Tasks" added
    */
  /*
      modified by Vihang
      modified at 17/05/2022
      modification : selection color of the list changed to #91c7e7, tag color changed on selection and text color changed to white
    */
  /*
        modified by Vihang
        modified at 18/05/2022
        modification : all the task pages selection issue fixed
      */
  function routeToCaseView(row,index) {
    // window.open(`/processView/${row.uuid}`, '_blank');
    window.open(`/caseView/${row.uuid}`, '_blank');
  }
  return (
    <div class="h-inherit">
      {/*
        Modification: removed index from the function call argument as it was not defined and returning error in inboxTask template
        By: Devang
        Date: 26/05/2022
      */}
      {props.taskType === "inboxTask" && newDataSet && newDataSet.length > 0 && newDataSet.map((row) => (
        <div class={`listCard listCard-border-bottom fadeAnimationText col-xs-12 mobile-p-l-0 m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#91c7e7' : ' '}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  <em className={`icon icon-file-text p-r-5 fs-14`} />
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{row.userName}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-white" : ""}`}>{row.message}</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{formatDateTime(row.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.group === "task" ? "#20639B": row.group === "Approval"? "#2F9395":""}`}>{ row.group === "task" ? "Task" :(row.group === "Approval") ? "Approval":""}</span>
        </div>
      ))}
      {/*
        Modified by : Arun Singh
        Modified on : 27/12/2023
        Modification : Include a 'Due Date' field in the mobile view list.
      */}
      { props.taskType === "dayPlanTask" && newDataSet && newDataSet.columns && newDataSet.rows.length > 0 && newDataSet.rows.map ((row) => (

        <div class={`listCard listCard-border-bottom m-b-5 pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"} mobile-m-r-4 `}
          onClick={(e) => handleData(row)}
        >
          <div class='msgContainer mobile-listcard-border  animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? ''  : ''}`}>
            <div class='display-flex justify-between align-center min-h-inherit'>
              <div class="display-flex w-full align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="flex-sb w-full m-l-10 m-r-0">
                  <div>
                    <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-gray" : "text-gray"}`}>{row.interactionID ? row.caseID: row.uniqueID}</p>
                    <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-black" : "text-black"}`}>{row.displayName}</p>
                  </div>
                  <p class={`fs-12 first-letter-capital ${selectedList === row.uuid ? "text-black" : "text-black"}`}>{isValidDate(row.dueDate) ? formatDateTimeRelative(row.dueDate) : row.dueDate || ''}</p>
                </div>
              </div>
              {/*
                <div class={`m-r-30 tasks-tags ${selectedList === row.uuid ? "bg-tag-bfbfbf" : deadlineDays(row) >= 0 ? 'bg-sunset-orange':'bg-yellow'}`}>
                  <div class="display-flex flex-direction-column fs-10">
                    <p class="f-w-600" >{deadlineDays(row) >= 0 ? 'Overdue':'Due'}</p>
                    <p class="f-w-600"> {deadlineDays(row) + " Days"}</p>
                  </div>
                </div>
              */}
            </div>
          </div>

          {/*  <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.containerType === "Checklist" || row.containerType === "Select Cars" ? "#20639B": row.containerType === "Form" ? "#F16A43" :row.containerType === "Form and Approval" || row.containerType === "Approval"? "#2F9395":""}`}>{ row.containerType === "Checklist" || row.containerType === "Select Cars" ? "CHK": row.containerType === "Form" ? "Form" :(row.containerType === "Form and Approval" || row.containerType === "Approval") ? "Approval":""}</span>*/}
        </div>
      ))}

      {
        props.taskType === "recentlyViewedCases" &&
        <div class="p-l-0 p-r-0  listcard-thead">
          <div class="pos-sticky row flex-nowrap listcard-tr">
            <span title="Task" class="  p-t-8   listcard-th" style="width: 40px;">
              <span class="pos-relative"> Sr.
              </span>
            </span>
            <span title="Task" class="  p-t-8   listcard-th" style="width: 250px;">
              <span class="pos-relative"> CASES @ All
              </span>
            </span>
            <span title="Open Since" class="  p-t-8 listcard-th " style="width: 108px;">
              <span class="pos-relative"> Last Viewed At</span>
            </span>
          </div>
        </div>

      }
      { props.taskType === "recentlyViewedCases" && newDataSet && newDataSet.columns && newDataSet.rows.length > 0 && newDataSet.rows.map ((row, index) => (
        <div class={`listCard listCard-border-bottom pos-relative text-black mobile-m-r-4 `} onClick={(e)=>{
          routeToCaseView(row,index);
        }}>
          <div class='msgContainer  mobile-listcard-border cursor-pointer animatedHover min-h-inherit'>
            <div class='display-flex justify-between align-center min-h-inherit'>
              <div class="display-flex align-center">
                <span style="width: 40px;height: 50px;border-right: 1px solid rgba(0,0,0,0.051);" class=" p-l-10 fs-10 fadeMoveUpAnimationNotForwards flex-c-m  listcard-td  ">
                  <span class="w-fit-content cursor-default" >
                    {index + 1}.
                  </span>
                </span>
                <span style="min-width: 250px; text-align: left;" class={`fadeMoveUpAnimationNotForwards pos-relative p-0 listcard-td `} >

                  <div class="h-full w-full p-l-10 display-flex flex-direction-row flex-l-m  cursor-pointer">
                    <div class="" >
                      <span class=" h-full w-full">
                        <p class="h-full justify-center flex-direction-column p-0">
                          <span title="Info" class="p-0 listcard1-case accordion-category-heading fs-10 first-letter-capital" style="color: tomato; ">{row['dynamicProperties_customerName']}
                          </span>
                          <span class="h-full flex-l-m fs-12" title={row['uniqueID']}>{row['uniqueID']}
                          </span>
                        </p>
                      </span>
                    </div>
                  </div>
                </span>
                <span style="width: 108px;" class=" fadeMoveUpAnimationNotForwards flex-c-m  listcard-td  ">
                  <span class="w-fit-content cursor-default fs-11" style="">
                    {isValidDate(row.recentlyViewedAt) ? formatDateTimeRelative(row.recentlyViewedAt) : row.createdAt}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}

      { props.taskType === "case" && newDataSet && newDataSet.columns && newDataSet.rows.length > 0 && newDataSet.rows.map ((row) => (
        <div class={`listCard m-b-5 listCard-border-bottom pos-relative ${selectedList === row.uuid ? "text-black" : "text-white"} mobile-m-r-4 `} onClick={(e) => handleData(row)}>
          <div class='msgContainer  mobile-listcard-border cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? '' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  <Clipboard masterClasses="flex-c-m" classes="w-20px h-20px" title={row && row.dynamicProperties_typeOfEnquiry} fill={selectedList === row.uuid  ? '#000' : '#fff'} />
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 ${selectedList === row.uuid ? "text-gray" : "text-d0e7f7"}`}>{row.interactionID ? row.caseID: row.uniqueID}</p>
                  {
                    !row.isVisited && (
                      <span class='notify-span' />
                    )
                  }
                  <p class={`fs-12 first-letter-capital listCardDescription text-white ${selectedList === row.uuid ? "text-black" : ""}`}>{row.dynamicProperties_customerName}</p>
                </div>
              </div>
            </div>
          </div>
          {/*<div class={`enquiry-type-dot pos-absolute`} style={`background: ${typeOfEnquiryMappingStyleColor[row && row.dynamicProperties_typeOfEnquiry]}`} />*/}
          {/*
            row.currentStage &&
          */}
          {/*  <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-second-ribbon" style={`background:#6393a1`}>{getStageAcronym("booking")}</span>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:#20639B`}>{moment(row.createdAt).format("MMM - YY")}</span>*/}
        </div>
      ))}

      { props.taskType === "callTask" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard listCard-border-bottom fadeAnimationText m-b-5 col-xs-12 pos-relative ${selectedList === row.uuid ? "text-gray" : ""}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#91c7e7' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{!row.extension ? "Ext: Unknown" : "Ext:" + row.extension}</p>
                  <p class='fs-14 first-letter-capital'>{row.mobileNumber ? row.mobileNumber : "Unknown" }</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{row.starttime ? formatDateTime(row.starttime) : '--'}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.typeOfCaller === "Employee" ? "#20639B": row.typeOfCaller === "Customer" ? "#F16A43" : row.typeOfCaller === "Other"? "#2F9395":""}`}>{ row.typeOfCaller === "Employee" ? "Employee": row.typeOfCaller === "Customer" ? "Customer" :(row.typeOfCaller === "Others") ? "Others":""}</span>
        </div>
      ))}
      {/*
          Modification: Added new template for listCard to be used in CallingDashboard
          By: Devang
          Date: 07/06/2022
      */}
      { props.taskType === "callInfo" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard listCard-border-bottom fadeAnimationText m-b-5 pos-relative ${selectedList === row.uuid ? "text-grey" : ""}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#91c7e7' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{!row.extension ? "Ext: Unknown" : "Ext:" + row.extension}</p>
                  <p class='fs-14 first-letter-capital'>{row.mobileNumber ? row.mobileNumber : "Unknown" }</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  {/*
                    Modification: Kept below starttime to just Date, not to formatDateTime, due to some other kind of date object
                    By: Devang
                    Date: 07/06/2022
                  */}
                  <p class="f-w-600" >{row.starttime ? Date(row.starttime) : '--'}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.typeOfCaller === "Employee" ? "#20639B": row.typeOfCaller === "Customer" ? "#F16A43" : row.typeOfCaller === "Other"? "#2F9395":""}`}>{ row.typeOfCaller === "Employee" ? "Employee": row.typeOfCaller === "Customer" ? "Customer" :(row.typeOfCaller === "Others") ? "Others":""}</span>
        </div>
      ))}
      { props.taskType === "Courier" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard listCard-border-bottom fadeAnimationText m-b-5 pos-relative ${selectedList === row.uuid ? "text-white" : ""}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#91c7e7' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{row.caseID}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-white" : ""}`}>{row.courierDescription}</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{formatDateTime(row.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ row.typeOfCourier === "Legal" ? "#F6BE00": row.typeOfCourier === "Director" ? "#F16A43" :row.typeOfCourier === "HMIL" ? "#2F9395":""}`}>{ row.typeOfCourier === "Legal" ? "Legal": row.typeOfCourier === "Director" ? "Director" :row.typeOfCourier === "HMIL" ? "HMIL":""}</span>
        </div>
      ))}
      { props.taskType === "visitTask" && newDataSet && newDataSet.length > 0 && newDataSet.map ((row) => (
        <div class={`listCard listCard-border-bottom fadeAnimationText m-b-5 pos-relative ${selectedList === row.uuid ? "text-white" : ""}`} onClick={(e) => handleData(row)}>
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'  style={`${selectedList === row.uuid ? 'background:#91c7e7' : ''}`}>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class={`fs-10 f-w-600 ${selectedList === row.uuid ? "text-white" : "text-grey"}`}>{!row.uniqueID ? "Unknown" : row.uniqueID}</p>
                  <p class={`fs-12 first-letter-capital listCardDescription ${selectedList === row.uuid ? "text-white" : ""}`}>{row.displayName ? row.displayName : "Unknown" }</p>
                </div>
              </div>
              <div class={`m-r-30 tasks-tags bg-sunset-orange ${selectedList === row.uuid ? "bg-tag-bfbfbf" : ""}`}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600" >{row.createdAt ? formatDateTime(row.createdAt) : '--'}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:olor-white fs-9 text-center text-uppercase ${ row.type === "Commercial" ? "#20639B": row.type === "Non-Commercial" ? "#F16A43" :""}`}>{ row.type === "Commercial" ? "Commercial": row.type === "Non-Commercial" ? "Non-Commercial" : ""}</span>
        </div>
      ))}
      {newDataSet && (newDataSet.length === 0 || newDataSet.rows && newDataSet.rows.length === 0 ) && (<p class="noTasksList f-w-400 fs-13">You are all caught up'</p>)}
    </div>
  );
};

export default ListCard;
