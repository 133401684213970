import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Courior = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''} style={'user-select:none;'}>
      <svg class={props.classes || ""} fill={props.fill || ''} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "22"} height={props.height || "22"} viewBox="0 0 22 22" fill="none">
        <path d="M19.9249 7.83534L7.57486 1.22534C7.29615 1.07737 6.98541 1 6.66986 1C6.35431 1 6.04357 1.07737 5.76486 1.22534L2.11486 3.12534C1.78575 3.30569 1.51042 3.57016 1.31699 3.89175C1.12355 4.21334 1.01895 4.58051 1.01387 4.95576C1.00878 5.331 1.1034 5.70087 1.28805 6.02759C1.4727 6.3543 1.74076 6.62614 2.06486 6.81534L14.2849 13.7453C14.5817 13.9099 14.9155 13.9963 15.2549 13.9963C15.5942 13.9963 15.9281 13.9099 16.2249 13.7453L20.0149 11.5053C20.3323 11.3114 20.5931 11.0373 20.7711 10.7106C20.949 10.384 21.0379 10.0163 21.0288 9.64437C21.0197 9.27249 20.9129 8.90955 20.7191 8.59201C20.5253 8.27447 20.2514 8.01351 19.9249 7.83534Z" stroke={props.stroke || "white"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.10459 7.83534L14.4546 1.22534C14.7333 1.07737 15.044 1 15.3596 1C15.6751 1 15.9859 1.07737 16.2646 1.22534L19.9146 3.12534C20.2463 3.30113 20.5255 3.56188 20.7234 3.88092C20.9213 4.19996 21.031 4.56584 21.0412 4.94116C21.0514 5.31648 20.9617 5.68777 20.7813 6.01706C20.6009 6.34635 20.3363 6.62183 20.0146 6.81534L7.74459 13.7453C7.44779 13.9099 7.11398 13.9963 6.77459 13.9963C6.4352 13.9963 6.10139 13.9099 5.80459 13.7453L2.01459 11.5053C1.69717 11.3114 1.43637 11.0373 1.25839 10.7106C1.08041 10.384 0.991518 10.0163 1.00064 9.64437C1.00976 9.27249 1.11657 8.90955 1.31034 8.59201C1.50412 8.27447 1.77804 8.01351 2.10459 7.83534Z" stroke={props.stroke || "white"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.0146 20.9951V11.9951" stroke={props.stroke || "white"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.0147 12.4951V15.8651C19.015 16.2426 18.9117 16.6129 18.716 16.9356C18.5202 17.2583 18.2396 17.5211 17.9046 17.6951L11.9046 20.7751C11.6297 20.918 11.3245 20.9926 11.0146 20.9926C10.7048 20.9926 10.3996 20.918 10.1246 20.7751L4.12465 17.6951C3.78973 17.5211 3.50908 17.2583 3.31333 16.9356C3.11758 16.6129 3.01426 16.2426 3.01465 15.8651V12.4951" stroke={props.stroke || "white"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};




export default Courior;
