import { h } from 'preact';
// from flaticon
const FilledRoundedStar = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M2479 4997 c-62 -23 -108 -57 -137 -100 -16 -23 -161 -351 -322 -729 -291 -682 -293 -688 -324 -693 -17 -2 -359 -34 -761 -70 -577 -52 -738 -70 -770 -84 -91 -40 -152 -124 -162 -224 -6 -64 8 -121 44 -174 16 -23 273 -256 573 -518 300 -262 555 -487 567 -499 l23 -21 -161 -705 c-88 -388 -164 -727 -170 -755 -35 -184 135 -353 317 -315 57 12 84 27 839 479 269 161 499 298 510 303 17 8 123 -52 650 -368 347 -207 650 -386 674 -397 29 -13 66 -19 108 -19 150 -1 267 117 265 267 0 40 -56 305 -166 785 l-165 725 22 21 c12 12 267 237 567 499 300 262 557 495 573 518 95 141 32 341 -127 402 -28 11 -283 38 -794 84 -414 38 -755 71 -757 73 -3 2 -136 312 -296 689 -161 376 -306 704 -323 728 -62 87 -202 133 -297 98z m365 -1049 c162 -380 291 -669 308 -690 17 -22 54 -49 91 -67 62 -30 63 -30 762 -92 385 -34 703 -65 707 -68 4 -4 -235 -220 -532 -480 -476 -417 -543 -479 -564 -525 -48 -99 -51 -79 123 -841 88 -385 158 -701 157 -702 -1 -1 -265 156 -586 348 -322 193 -607 362 -635 375 -63 30 -161 33 -220 5 -22 -10 -308 -179 -635 -374 -327 -196 -595 -355 -596 -354 -2 1 67 308 153 682 102 449 155 701 156 741 1 48 -5 74 -27 117 -24 51 -76 99 -566 529 -297 260 -536 475 -532 478 4 4 322 35 706 69 385 35 713 68 730 74 54 18 109 61 137 105 15 24 151 332 301 683 150 351 275 639 278 639 3 0 131 -294 284 -652z"/>
        </g>
      </svg>
    </span>
  );
};

export default FilledRoundedStar;
