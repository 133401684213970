import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const RightArrow = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "24px"} height={props.height || "24px"}
    	preserveAspectRatio="xMidYMid meet" >
        <path xmlns="http://www.w3.org/2000/svg"fill="white" d="m11 18-6-6 6-6 1.4 1.4L7.825 12l4.575 4.6Zm6.6 0-6-6 6-6L19 7.4 14.425 12 19 16.6Z" />
      </svg>
    </span>
  );
};

export default RightArrow;
