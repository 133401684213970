import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const ChatIcon = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}
	     viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M3140 4306 c-388 -56 -720 -232 -971 -514 -33 -37 -72 -86 -87 -110 -24 -38 -30 -43 -57 -38 -177 29 -281 38 -405 33 -456 -19 -867 -226 -1123 -566 -156 -207 -239 -430 -253 -677 -21 -361 121 -710 397 -975 54 -52 69 -73 69 -95 0 -16 -14 -126 -30 -244 -16 -118 -30 -228 -30 -243 0 -36 40 -77 75 -77 18 0 103 52 256 155 133 90 238 155 252 155 12 0 64 -9 113 -20 353 -77 727 -28 1042 135 169 88 327 213 438 348 l40 48 104 -25 c58 -14 143 -30 190 -36 177 -21 430 -7 589 35 32 8 70 15 83 15 15 0 119 -64 268 -165 245 -165 275 -180 311 -152 41 31 41 44 4 314 -19 142 -35 266 -35 275 0 9 40 57 88 105 48 48 108 115 134 148 310 406 363 924 140 1370 -203 408 -617 707 -1092 790 -130 22 -390 28 -510 11z m488 -171 c332 -65 605 -218 807 -453 121 -140 204 -297 253 -481 22 -85 25 -116 25 -266 0 -152 -2 -180 -26 -268 -58 -217 -152 -378 -331 -566 -148 -154 -148 -155 -118 -380 12 -91 22 -172 22 -178 0 -7 -76 39 -169 101 -104 71 -183 117 -205 122 -47 8 -104 1 -231 -27 -86 -20 -128 -23 -295 -23 -217 0 -317 15 -487 75 -205 72 -366 173 -519 324 -617 614 -395 1586 442 1935 87 36 273 85 375 99 102 14 357 6 457 -14z m-1788 -625 c41 -5 87 -12 102 -16 l28 -6 -19 -36 c-56 -112 -100 -299 -108 -462 -26 -511 277 -1010 762 -1257 52 -26 95 -52 95 -56 0 -17 -118 -132 -200 -193 -314 -237 -746 -326 -1138 -234 -104 25 -165 25 -217 1 -23 -10 -101 -59 -175 -110 -73 -50 -134 -90 -136 -89 -1 2 6 62 17 133 34 239 31 252 -107 396 -166 174 -259 332 -311 527 -23 85 -26 117 -26 257 -1 142 2 171 26 260 54 200 158 375 312 524 170 165 359 268 604 331 148 38 339 49 491 30z"/>
          <path d="M2640 3107 c-49 -16 -133 -102 -148 -153 -28 -94 -8 -169 63 -239 102 -103 243 -101 338 5 93 103 90 228 -8 325 -70 71 -152 91 -245 62z m135 -172 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z"/>
          <path d="M3280 3107 c-49 -16 -133 -102 -148 -153 -28 -94 -8 -169 63 -239 102 -103 243 -101 338 5 93 103 90 228 -8 325 -70 71 -152 91 -245 62z m135 -172 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z"/>
          <path d="M3920 3107 c-49 -16 -133 -102 -148 -153 -28 -94 -8 -169 63 -239 102 -103 243 -101 338 5 93 103 90 228 -8 325 -70 71 -152 91 -245 62z m135 -172 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z"/>
          <path d="M1065 2855 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 271 0 271 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -271 0 -271 0 -24 -25z"/>
          <path d="M1065 2375 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 311 0 311 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -311 0 -311 0 -24 -25z"/>
          <path d="M1065 1895 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 391 0 391 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -391 0 -391 0 -24 -25z"/>
        </g>
      </svg>
    </span>
  );
};

export default ChatIcon;
