import { h } from 'preact';

const SmallLoader = (props) => {
  const {masterClasses, classes, top, left, bottom, imgClasses, textClasses, typeOfLoader, color, style, isTextRequired} = props;
  return (
    <>
      <div class={`small-loader ${masterClasses || ''}`}>
        {/*
          Modification: Added multiple kinds of loaders
          By: Masum Raja
          Date: 19/07/2023
        */}
        {
          typeOfLoader === 'spinningDots' &&
          <span class="spinning-dots-loader" />
        }
        {
          typeOfLoader === 'halfSpinningCircle' &&
          <span class="half-spinning-loader" style={`${color ? `border-top: 3px solid ${color};` : ''} ${style}`} />
        }
        {
          typeOfLoader === 'dotsWave' &&
          <div class='dots-wave' />
        }
        {/*
      		modified by Arun Singh
      		modified at 03/04/2024
      		modification : Add new modern spinner code.
        */}
        {
          typeOfLoader === 'ldsSpinner' &&
          <>
            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            {isTextRequired && <div>Loading</div>}
          </>
        }
        {
          !typeOfLoader &&
          <div class="circle-4-loader small-loader-center"><span /></div>
        }
      </div>
    </>
  );
};

export default SmallLoader;
