import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const AddCase = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "25px"} height={props.height || "25px"}
	viewBox="0 0 512.000000 512.000000" fill={props.fill || "#000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2475 4623 c-418 -25 -835 -188 -1165 -455 -132 -106 -207 -178 -294 -282 -203 -239 -353 -540 -421 -841 l-22 -100 -251 -5 -252 -5 -32 -33 c-28 -27 -33 -40 -33 -80 0 -45 10 -62 290 -465 188 -273 299 -424 317 -433 31 -16 66 -18 102 -5 30 12 41 26 359 490 276 402 279 406 240 472 -32 53 -60 59 -288 59 l-208 0 7 38 c30 188 149 454 293 654 352 489 1011 803 1573 749 496 -47 908 -270 1286 -695 l62 -69 53 5 c70 7 109 47 109 111 0 50 -10 67 -117 188 -292 335 -721 582 -1158 668 -99 20 -313 42 -370 39 -16 -1 -52 -3 -80 -5z" />
          <path d="M2039 3297 c-48 -32 -67 -89 -46 -139 28 -67 47 -72 262 -78 180 -5 193 -6 240 -31 28 -14 64 -44 84 -70 l34 -44 -275 -5 c-307 -6 -315 -7 -345 -78 -21 -50 -2 -107 46 -139 33 -23 39 -23 302 -23 148 0 269 -3 269 -6 0 -18 -68 -88 -108 -112 -46 -26 -52 -27 -242 -32 -207 -5 -221 -9 -257 -59 -20 -29 -19 -93 2 -127 31 -49 681 -658 719 -673 85 -33 171 47 151 139 -5 23 -65 86 -241 253 l-233 221 67 14 c166 34 324 170 377 327 l19 53 104 4 c103 3 104 3 133 35 37 42 45 75 28 120 -24 62 -56 78 -169 81 l-99 4 -12 36 c-6 21 -20 54 -31 75 l-18 37 122 0 c105 0 128 3 156 20 69 42 79 130 21 186 l-30 29 -498 3 -497 3 -35 -24z" />
          <path d="M4393 3195 c-12 -8 -151 -202 -308 -431 -314 -454 -316 -460 -273 -524 39 -57 51 -60 284 -60 l211 0 -14 -60 c-121 -526 -484 -967 -995 -1209 -479 -227 -948 -236 -1428 -26 -268 117 -488 283 -720 543 -59 65 -68 72 -103 72 -80 0 -134 -57 -124 -132 13 -96 319 -394 567 -553 231 -148 526 -259 798 -301 140 -21 392 -21 532 0 817 125 1504 739 1691 1512 17 71 33 135 35 142 3 9 60 12 245 12 209 0 245 2 270 17 37 22 63 76 56 118 -6 35 -538 821 -588 868 -34 31 -100 37 -136 12z" />
        </g>
      </svg>
    </span>
  );
};

export default AddCase;
