import { useState, useEffect } from 'preact/hooks';
import {formatDateTime,getInitials,getFormattedAmount} from '../../lib/utils';
import axios from "axios";
import { reportException } from '../../lib/transceiver';
import CONSTANTS from '../../lib/constants';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import FileViewer from '../../components/fileViewer';
import { getItem } from '../../lib/myStore';

const ListSingleCardWizard = (props) => {
  const fileName = 'listSingleCardWizard';
  let userInfo = getItem('userinfo');
  let [fileViewerFor, setFileViewerFor] = useState({
    for: 'case'
  });
  let [currentRow, setCurrentRow] = useState(props.currentRow);
  let [followups, setFollowUps] = useState(props.followups);
  let [quotations, setQuotationss] = useState(props.quotations);
  let [currentIndex, setCurrentIndex] = useState(0);
  let [currentIndexQuotation, setCurrentIndexQuotation] = useState(0);
  let [insuranceCompanies, setInsuranceCompanies] = useState(props.insuranceCompanies);
  let [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  let [coupons, setCoupons] = useState([]);
  let [couponTotalAmount, setCouponTotalAmount] = useState(0);
  const [couponQuantityWiseTotalAmount, setCouponQuantityWiseTotalAmount] = useState(0);

  let [uploadedfile, setUploadedfile] = useState([]);

  useEffect(async () => {
    try {
      setFollowUps(props.followups);
      setQuotationss(props.quotations);
      setInsuranceCompanies(props.insuranceCompanies);
      let coupons = await axios.get(`${CONSTANTS.API_URL}/api/v1/coupons?isMasterCoupon=true`);
      await setCoupons(coupons.data);
      if (coupons.data.length > 0 && currentRow && currentRow['dynamicProperties_selectedCouponIDsWithQuantity']) {
        /*
          modified : Ashutosh G
          modified : 08/09/2023
          modification : setting total quantity wise amount to couponQuantityWiseTotalAmount state
        */
        let tempCouponQuantityWiseTotalAmount = getFormattedAmount(Object.keys(currentRow["dynamicProperties_selectedCouponIDsWithQuantity"]).reduce((acc, obj) => {return acc + ((coupons.data.find(coupon => coupon.uuid === obj) && coupons.data.find(coupon => coupon.uuid === obj).amount) ? Number(coupons.data.find(coupon => coupon.uuid === obj).amount) : 0) * currentRow["dynamicProperties_selectedCouponIDsWithQuantity"][obj]; }, 0));
        setCouponQuantityWiseTotalAmount(tempCouponQuantityWiseTotalAmount);
        console.log(tempCouponQuantityWiseTotalAmount, 'tempCouponQuantityWiseTotalAmounttempCouponQuantityWiseTotalAmounttempCouponQuantityWiseTotalAmount');
        couponTotalAmount = getFormattedAmount(currentRow['dynamicProperties_selectedCouponID'].split(',').reduce((acc, obj) => {return acc + ((coupons.data.find(coupon => coupon.uuid === obj) && coupons.data.find(coupon => coupon.uuid === obj).amount) ? Number(coupons.data.find(coupon => coupon.uuid === obj).amount) : 0);}, 0));
        setCouponTotalAmount(couponTotalAmount);
      } else {
        setCouponTotalAmount(0);
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'useEffect'
      });
      return HTTPException;
    }
  }, [props.followups, props.quotations, props.insuranceCompanies]);

  useEffect(() => {
    if (quotations && quotations[currentIndexQuotation] && quotations[currentIndexQuotation].interactionID && quotations[currentIndexQuotation].pdfID) {
      getImageSignedUrl(quotations[currentIndexQuotation].interactionID, quotations[currentIndexQuotation].pdfID);
    }
  }, [currentIndexQuotation]);

  async function getImageSignedUrl(interactionID, fileID) {
    try {
      let file = await axios.get(`${CONSTANTS.API_URL}/api/v1/file/getFileFromS3?courierID=${interactionID}&fileID=${fileID}&s3KeyFor=case`);
      if (file.data[0]) {
        document.getElementById(`image-${currentIndexQuotation}`).src = file.data[0].signedUrl;
        return file.data[0].signedUrl;
      }
      return null;
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getImageSignedUrl'
      });
      return HTTPException;
    }
  }

  function viewAllImages(e, index) {
    let options = {
      rotatable:false,
      scalable:false
    };
    const gallery = new Viewer(document.getElementById(`image-${index}`),options);
    gallery.show();
  }
  async function getReferenceFilesForFileViewer(e, type, openViewer, fileId, interactionID) {
    try {
      let fileViewerObj = [];
      // let fileID = [interactionObj['dynamicProperties_'+fieldName]];
      let fileID = [fileId];
      if (type === 'Uploaded Files') {
        fileViewerObj.push({name: 'Uploaded Files', fileIDs: fileID});
      }
      let payload = {
        interactionID,
        s3KeyFor: 'case',
        fileViewerObj
      };
      fileViewerFor['interactionID'] = interactionID;
      let response = await axios.put(`${CONSTANTS.API_URL}/api/fileViewer`, payload);
      fileViewerFor.type = type;
      setFileViewerFor(fileViewerFor);
      if (response && response.data) {
        await setUploadedfile(response.data);
        if (openViewer) await setIsFileViewerOpen(true);
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getReferenceFilesForFileViewer'
      });
      return HTTPException;
    }
  }
  async function toggleFileViewer() {
    setIsFileViewerOpen(!isFileViewerOpen);
  }

  async function closeFileViewer() {
    setIsFileViewerOpen(false);
  }
  return (
    <div class="row">
      {/*
        quotations && quotations.length > 0 && (
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-5">
            <div class="workspace-cards">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.stageIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">QUOTATION'S</span>
                </div>
                <div class="display-flex">
                  <button type="button" class=" custombutton-nextprev" disabled={currentIndexQuotation === 0 ?  true : false} onClick={(e)=> setCurrentIndexQuotation(currentIndexQuotation - 1)} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" ><path d="M10 22 0 12 10 2 11.775 3.775 3.55 12 11.775 20.225Z" /></svg>
                  </button>
                  <span class="align-self-center">{ (currentIndexQuotation + 1) + "/" + quotations.length }</span>
                  <button type="button" class=" custombutton-nextprev" disabled={quotations.length === currentIndexQuotation + 1 ?  true : false} onClick={(e)=> setCurrentIndexQuotation(currentIndexQuotation + 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M8.025 22 6.25 20.225 14.475 12 6.25 3.775 8.025 2 18.025 12Z" /></svg>
                  </button>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0" style="height:auto;overflow:auto;">
                  <div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">
                          Quotation Number
                          {quotations[currentIndexQuotation].isActiveQuotation &&
                            <div class="align-self-center">
                              <div class={`m-r-30 tasks-tags bg-green`}>
                                <div class="display-flex flex-direction-column">
                                  <p>Active</p>
                                </div>
                              </div>
                            </div>
                          }
                        </span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{(currentIndexQuotation + 1)}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Quotation Date</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{formatDateTime(quotations[currentIndexQuotation].createdAt)}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Amount</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital" title={quotations[currentIndexQuotation].amount}>{getFormattedAmount(quotations[currentIndexQuotation].amount)}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">NCB Status</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital" title={quotations[currentIndexQuotation].ncbStatus}>{quotations[currentIndexQuotation].ncbStatus}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">NCB Percentage</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital" title={quotations[currentIndexQuotation].ncbPercentage}>{quotations[currentIndexQuotation].ncbPercentage + "%"}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Policy IDV</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital" title={quotations[currentIndexQuotation].policyIDV}>{getFormattedAmount(quotations[currentIndexQuotation].policyIDV)}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Insurance Company</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{insuranceCompanies.find(insuranceCompany => insuranceCompany.uuid === quotations[currentIndexQuotation].insuranceCompany) && insuranceCompanies.find(insuranceCompany => insuranceCompany.uuid === quotations[currentIndexQuotation].insuranceCompany).displayName}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Quotation</span>
                      </div>
                      <div class="w-full" style="margin: 0 calc(1rem + 5px)">
                        <img id={`image-${currentIndexQuotation}`} class="cursor-pointer object-fit-contain w-full" onClick={(e)=> viewAllImages(e, currentIndexQuotation)} />}
                        <button type="button" id={`image-0-${currentIndexQuotation}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, quotations[currentIndexQuotation]['pdfID'], quotations[currentIndexQuotation]['interactionID'])}>View File</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        Modification: remove animation
        By: Masum Raja
        Date: 09/09/2023
      */}
      {
        followups && followups.length > 0 && (
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-l-0 p-r-5">
            <div class="workspace-cards">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.stageIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">FOLLOW UP'S</span>
                </div>
                <div class="display-flex">
                  <button class=" custombutton-nextprev" disabled={currentIndex === 0 ?  true : false} onClick={(e)=> setCurrentIndex(currentIndex - 1)} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" ><path d="M10 22 0 12 10 2 11.775 3.775 3.55 12 11.775 20.225Z" /></svg>
                  </button>
                  <span class="align-self-center">{ (currentIndex + 1) + "/" + followups.length }</span>
                  <button class=" custombutton-nextprev" disabled={followups.length === currentIndex + 1 ?  true : false} onClick={(e)=> setCurrentIndex(currentIndex + 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M8.025 22 6.25 20.225 14.475 12 6.25 3.775 8.025 2 18.025 12Z" /></svg>
                  </button>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0" style="height:auto;overflow:auto;">
                  <div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Follow up Number</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{(currentIndex + 1)}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Follow up Date</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{formatDateTime(followups[currentIndex].createdAt)}</span>
                    </div>
                    <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Remark Category</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital" title={followups[currentIndex].remark}>{followups[currentIndex].remarkCategory}</span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Next Follow-up Date</span>
                      </div>
                      <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].nextFollowupDate ? formatDateTime(followups[currentIndex].nextFollowupDate): '--'}</span>
                    </div>
                    {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Cash Discount") &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Cash Discount Requested Amount</span>
                        </div>
                        {(currentRow && currentRow.interaction && currentRow.interaction['dynamicProperties_cashDiscountAmount']) && <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{currentRow && currentRow.interaction && currentRow.interaction['dynamicProperties_cashDiscountAmount'] ? getFormattedAmount(currentRow.interaction['dynamicProperties_cashDiscountAmount']) : '--'}</span>}
                        {(currentRow && currentRow['dynamicProperties_cashDiscountAmount']) && <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{currentRow['dynamicProperties_cashDiscountAmount'] ? getFormattedAmount(currentRow['dynamicProperties_cashDiscountAmount']) : '--'}</span>}
                      </div>
                    }
                    {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].isCashDiscountApproved &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Cash Discount Approved Amount</span>
                        </div>
                        {(currentRow && currentRow.interaction && currentRow.interaction['dynamicProperties_cashDiscountAmountApproved']) && <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{currentRow && currentRow.interaction && currentRow.interaction['dynamicProperties_cashDiscountAmountApproved'] ? getFormattedAmount(currentRow.interaction['dynamicProperties_cashDiscountAmountApproved']) : '--'}</span>}
                        {(currentRow && currentRow['dynamicProperties_cashDiscountAmountApproved']) && <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{currentRow['dynamicProperties_cashDiscountAmountApproved'] ? getFormattedAmount(currentRow['dynamicProperties_cashDiscountAmountApproved']) : '--'}</span>}
                      </div>
                    }
                    {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Cash Discount") &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Cash Discount Approved?</span>
                        </div>
                        <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].isCashDiscountApproved ? 'Yes' : 'No'}</span>
                      </div>
                    }
                    {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Change Insurance Company") &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Rollover Approved?</span>
                        </div>
                        <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].isChangeInsuranceCompanyApproved ? 'Yes' : 'No'}</span>
                      </div>
                    }
                    {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Coupon") &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Coupon(s) Approved?</span>
                        </div>
                        <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].isCouponApproved ? 'Yes' : 'No'}</span>
                      </div>
                    }
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Coupon") ?
                          <span class="fs-14">Coupon(s) Approval Remark</span>
                          : <span class="fs-14">Team Leader Remark</span>
                        }
                      </div>
                      {(followups && followups[currentIndex] && followups[currentIndex]['remarkTL']) && <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups && followups[currentIndex] && followups[currentIndex]['remarkTL'] ? followups[currentIndex]['remarkTL'] : '--'}</span>}
                    </div>
                    {/*
                      modified : Ashutosh G
                      modified : 08/09/2023
                      modification : currentRow['dynamicProperties_selectedCouponID'] && currentRow['dynamicProperties_selectedCouponID'].length > 0 condtion added below
                    */}
                    {(followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Coupon") && currentRow['dynamicProperties_selectedCouponID'] && currentRow['dynamicProperties_selectedCouponID'].length > 0) &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span>Total Coupon Amount: <strong>{couponTotalAmount}</strong></span>
                        </div>
                        <div class="flex-col-r">
                          {
                            (currentRow && currentRow['dynamicProperties_selectedCouponID']) ?
                              coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                <div>
                                  {(currentRow["dynamicProperties_selectedCouponID"] && currentRow["dynamicProperties_selectedCouponID"].split(",").includes(coupon.uuid)) && `${coupon.displayName} - ${getFormattedAmount(coupon.amount)}`}
                                </div>
                              ))
                              :
                              '--'
                          }
                        </div>
                      </div>
                    }
                    {/*
                        modified : Ashutosh G
                        modified : 08/09/2023
                        modification : dynamicProperties_selectedCouponIDsWithQuantity table block added below for followups section
                    */}
                    {
                      (followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && followups[currentIndex].negotiationMethod.includes("Coupon") && currentRow['dynamicProperties_selectedCouponIDsWithQuantity'] && Object.keys(currentRow['dynamicProperties_selectedCouponIDsWithQuantity']).length > 0) &&
                        <div class="flex-sb borderteambottomdark p-b-8 p-t-8 gap-30px">
                          <div class="flex-sb ws-nowrap">
                            <span>Total Coupon Amount: <strong>{couponQuantityWiseTotalAmount}</strong></span>
                          </div>
                          <div class="flex-col-r">
                            {
                              (currentRow && currentRow['dynamicProperties_selectedCouponIDsWithQuantity']) ?
                                <table>
                                  <tr>
                                    <th class={"w-80p f-w-400"}>Description</th>
                                    <th class={"w-20p f-w-400"}>Price</th>
                                  </tr>
                                  {coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                    (currentRow["dynamicProperties_selectedCouponIDsWithQuantity"] && Object.keys(currentRow["dynamicProperties_selectedCouponIDsWithQuantity"]).includes(coupon.uuid)) &&
                                      <tr>
                                        <td class={"w-80p"}>{`${coupon.displayName} - ${getFormattedAmount(coupon.amount)} X ${currentRow["dynamicProperties_selectedCouponIDsWithQuantity"][coupon.uuid]}`}</td>
                                        <td class={"w-20p"}>{`${getFormattedAmount(coupon.amount * currentRow["dynamicProperties_selectedCouponIDsWithQuantity"][coupon.uuid])}`}</td>
                                      </tr>
                                  ))}

                                </table>
                                :
                                '--'
                            }
                            {/*
                              (currentRow && currentRow['dynamicProperties_selectedCouponID']) ?
                                coupons && coupons.length > 0 && coupons.map((coupon, index) => (
                                  <div>
                                    {(currentRow["dynamicProperties_selectedCouponID"] && currentRow["dynamicProperties_selectedCouponID"].split(",").includes(coupon.uuid)) && `${coupon.displayName} - ${getFormattedAmount(coupon.amount)}`}
                                  </div>
                                ))
                                :
                                '--'
                            */}
                          </div>
                        </div>
                    }
                    {followups[currentIndex].remarkCategory === 'Wants to Negotiate' && followups[currentIndex].negotiationMethod && ["Cash Discount", 'Coupon'].includes(followups[currentIndex].negotiationMethod) &&
                      <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Quotation</span>
                        </div>
                        <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].quotationID ? `Quotation ${(quotations.findIndex(d => d.uuid === followups[currentIndex].quotationID)) + 1}` : ''}</span>
                      </div>
                    }
                    {
                      followups[currentIndex].callID && followups[currentIndex].callRecordingLink && (
                        <div class="flex-sb flex-direction-column borderteambottomdark  p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14">Call Recording</span>
                          </div>
                          <div class="flex-sb">
                            <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital"><audio src={"http://114.143.17.132:6751" + followups[currentIndex].callRecordingLink.split("192.168.55.2:6751")[1]} controls /></span>
                          </div>
                        </div>
                      )
                    }
                    {
                      followups[currentIndex].callID && followups[currentIndex].callStatus && (
                        <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14">Call Status</span>
                          </div>
                          <span class="text-primary-tenant first-letter-capital p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].callStatus}</span>
                        </div>
                      )
                    }
                    {
                      followups[currentIndex].callID && !followups[currentIndex].callRecordingLink && (
                        <div class="flex-sb flex-direction-column borderteambottomdark  p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14">Call Recording</span>
                          </div>
                          <div class="flex-sb">
                            <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">Fetching Call Recording</span>
                          </div>
                        </div>
                      )
                    }
                    {
                      !followups[currentIndex].callID && (
                        <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14">Call Recording</span>
                          </div>
                          <div class="flex-sb">
                            <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">Not Applicable</span>
                          </div>
                        </div>
                      )
                    }
                    {
                      followups[currentIndex].callID && !followups[currentIndex].callStatus && (
                        <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14">Call Status</span>
                          </div>
                          <span class="text-primary-tenant first-letter-capital p-l-12 p-r-24 f-w-600 first-letter-capital">{!followups[currentIndex].callRecordingLink ? "Not Found": "Finished"}</span>
                        </div>
                      )
                    }
                    {
                      !followups[currentIndex].callID && (
                        <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14">Call Status</span>
                          </div>
                          <span class="text-primary-tenant first-letter-capital p-l-12 p-r-24 f-w-600 first-letter-capital">Not Applicable</span>
                        </div>
                      )
                    }
                    {
                      /*
                        By: Yashvi
                        On: 02nd July 2024
                        T1693: Point no. 7 - As there is a pre-decided remark dropdown as well as there is no field to enter remark, caller remark should be removed.
                        Only show caller reamrk if remark if it exists
                      */
                      (followups[currentIndex] && followups[currentIndex].remark) && <div class="flex-sb borderteambottomtenant p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">Caller Remark</span>
                          <span class="text-primary-tenant p-l-12 p-r-24 f-w-600 first-letter-capital">{followups[currentIndex].remark ? followups[currentIndex].remark : '--'}</span>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {isFileViewerOpen &&
        <FileViewer
          uploadBrowsedReferenceFile={toggleFileViewer}
          closeFileViewer={closeFileViewer}
          toggleFileViewer={toggleFileViewer}
          isFileViewerOpen={isFileViewerOpen}
          uploadedfile={uploadedfile}
          fileViewerFor={fileViewerFor}
          contentDetails={{}}
          isDeleteHidden={true}
          isUploadHidden={true}
          isViewedFrom={true}
        />
      }
    </div>
  );
};

export default ListSingleCardWizard;
