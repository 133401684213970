import { useState, useEffect, useRef } from 'preact/hooks';
import moment from 'moment';
import axios from 'axios';

import { setItem, getItem } from '../../lib/myStore';
import { formatDateTime, getFormattedAmount, inWords, isObject } from '../../lib/utils';
import CONSTANTS from '../../lib/constants';

import LinearProgress from 'preact-material-components/LinearProgress';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import ListSingleCard from '../../components/listSingleCard';
import FileViewer from '../../components/fileViewer';
import MultiSelectBox from '../../components/multiSelectBox';
import { DownArrow, Search, Filter } from '../../components/svgs';

const MultiSelect = (props) => {
  const { dynamicProp, entityObject, saveFormInput} = props;

  const [multiSelectCheckBoxDropDown, setMultiSelectCheckBoxDropDown] = useState(false);
  const [selectedFilterColumns, setSelectedFilterColumns] = useState([]);
  const selectBoxID = `${[dynamicProp.name]}-select-box`;

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };

  const multiCheckBoxRef = useOutsideClick(closeMultiCheckBoxDropDown);
  function closeMultiCheckBoxDropDown () {
    setMultiSelectCheckBoxDropDown(false);
  }

  function toggleMultiSelectdropDown() {
    setMultiSelectCheckBoxDropDown(e => !e);

    /*
      Modification: Added functionality to handle the positioning of select option tray based on editForm scroll
      By: Devang
      Date: 14/12/2023
    */
    setTimeout(() => {
      let scrollableContainer = document.getElementById("formModalContainer");
      let tray = document.getElementById(selectBoxID);

      if (scrollableContainer && tray) {
        let trayBounds = tray.getBoundingClientRect();
        let height = scrollableContainer.offsetHeight - trayBounds.top + 85;
        if (height < trayBounds.height) {
          tray.style.top = `-${trayBounds.height+6}px`;
        } else {
          tray.style.top = "unset";
        }
      }
    }, 0);
  }

  return (
    <div class="pos-relative" ref={multiCheckBoxRef}>
      <div onClick={toggleMultiSelectdropDown} class={`${entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length ? 'flex-sb-m' : 'flex-r-m'} w-full min-h-38px bor-rad-all-5 bor-all-gray cursor-pointer`}>
        <div class='w-fit-content repeat-multiple-select-value p-t-4 p-b-4'>
          {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].map((value, index) => (
            <div class={`multiple-checked-options flex-c-m ${index % 3 === 0 ? 'm-l-5' : ''}`} key={`${value}-${index}`}>
              <p class="truncate text-up">
                {dynamicProp.enum && !!dynamicProp.enum.length && dynamicProp.enum.find((object) => object && object.uuid === value)[entityObject.entityName && entityObject.enumDisplayKey ? entityObject.enumDisplayKey : 'displayName']}
              </p>
            </div>
          ))}
        </div>
        <DownArrow
          title={"Show/Hide"} masterClasses={"hover-icon-2 m-r-8 cursor-pointer"}
          classes="w-12px h-12px m-b-neg-4 m-l-4"
        />
      </div>
      <MultiSelectBox
        boxID={selectBoxID}
        masterClasses={'f-w-500 fs-12 text-color-002c5f-imp'}
        inputClasses={'m-r-7'}
        lableClasses={'flex-c-m'}
        multiSelectCheckBoxDropDown={multiSelectCheckBoxDropDown}
        optionsArray={dynamicProp.enum}
        defaultSelectedOptionsArray={entityObject[dynamicProp.name]}
        selectedFilterColumns={selectedFilterColumns}
        selectedColumn={dynamicProp}
        action={(data, options, updatedOptionsArray, selectedFilterColumn, e) => {
          saveFormInput(e, dynamicProp.name, 'multipleSelect');
        }}
        objectValueKey={'uuid'}
        objectDisplayKey={entityObject.entityName && entityObject.enumDisplayKey ? entityObject.enumDisplayKey : 'displayName'}
      />

      {/*
        dynamicProp.formType === 'multipleSelect' && dynamicProp.entityName === 'ticket' && (
          <Box>
            <Box class="display-flex">
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="menu3">Select {dynamicProp.displayName}</InputLabel>
                <Select
                  fullWidth
                  labelId="menu3"
                  multiple
                  disabled={dynamicProp.isDisabled || !isFormEditable ? true : false}
                  value={entityObject[dynamicProp.name] || []}
                  input={<OutlinedInput label={`Select ${dynamicProp.displayName}`} />}
                  id={`field-${dynamicProp.name}`}
                  onChange={(e) => {
                    props.saveFormInput(e, dynamicProp.name, 'multipleSelect', 0);
                  }}
                  renderValue={(selected) => findTicket(selected, dynamicProp)}
                >
                  {dynamicProp.enum.map((item, index) => (
                    <MenuItem
                      key={item.uuid + index}
                      selected={entityObject[dynamicProp.name] === item.uuid}
                      value={item.uuid}
                    >
                      {item.dynamicProperties_title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )
      */}
    </div>
  );
};

const CreateEditForm = (props) => {
  let [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  let [uploadedfile, setUploadedfile] = useState([]);
  let [fileViewerFor, setFileViewerFor] = useState({
    for: 'case'
  });

  let [isFormEditable, setIsFormEditable] = useState(false);
  let [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // Main form props data to get from processMapping
  /*
    - formOperation
    - formEntityName
    - formEntityObject
    - setFormEntityObject
    - formDynamicProps
  */
  let {entityName, operation, toggleFormPopover, backgroundColor, dynamicProps, entityObject, submitForm,
    setFormValueInput, saveFormInputRadioDefault, saveFormInputRadio, saveFormInputCheckbox, saveAreaFromForm, saveFormInput,
    uploadFile, handleRemoveConfigRule } = props;

  async function getReferenceFilesForFileViewer(e, type, openViewer, fieldName, entityID) {
    let fileViewerObj = [];
    /*
      Modification: Added below OR condition for screenshot key-value and condition in payload for interactionID
      By: Devang
      Date: 28/11/2023
    */
    console.log(type, openViewer, fieldName, entityID, 'entityObjectentityObject');
    let fileID = [entityObject[`dynamicProperties_${fieldName}`] || entityObject[`${fieldName}`]];
    if (type === 'Uploaded Files') {
      fileViewerObj.push({name: 'Uploaded Files', fileIDs: fileID});
    }
    let payload = {
      // interactionID: entityObject.uuid,
      interactionID: entityObject.type === 'Vendor' || entityObject.type === 'Catalogue' || fieldName === 'capturedScreenshot' ? 'entity' : entityObject.uuid ? entityObject.uuid : 'entity',
      s3KeyFor: 'case',
      fileViewerObj
    };
    fileViewerFor['interactionID'] = entityID;
    let response = await axios.put(`${CONSTANTS.API_URL}/api/fileViewer`, payload);
    fileViewerFor.type = type;
    setFileViewerFor(fileViewerFor);
    if (response && response.data) {
      await setUploadedfile(response.data);
      if (openViewer) await setIsFileViewerOpen(true);
    }
  }
  async function toggleFileViewer() {
    setIsFileViewerOpen(!isFileViewerOpen);
  }

  async function closeFileViewer() {
    setIsFileViewerOpen(false);
  }

  async function toggleEditForm() {
    setIsFormEditable(!isFormEditable);
    /*
      Bug: T1672 - BG_FN - Not able to update the dynamicProp(NCB Percentage) as it is getting 600 error
      Modification: Added condition to check if function exists
      By: Devang
      Date: 03/06/2024
    */
    props && props.toggleEditForm && props.toggleEditForm();
  }

  return (

    <NewPopupModal classes="formModal" modalWidth={"65%"} modalDisplay={('show-formPopover overflow-hidden')} onClose={(e) => toggleFormPopover(e)} backgroundColor={backgroundColor ? backgroundColor: ""}>
      <div class="enquiryForm background-transparent h-full">
        <div class="row p-t-10 p-l-30 p-r-30 p-b-10" style="background:#f7f7f7;border-bottom:1px solid lightgrey">
          <div class="col-xs-9 col-sm-10">
            <span class="formHeader display-flex">
              <h4 class="m-t-5 m-b-0">{operation} {((operation !== 'CNG' && operation !== 'Cancellation' && operation !== 'Correction' && operation !== 'Accessories') ? ((operation.split(' ').length === 1) && entityName) : '')}</h4>
            </span>
          </div>
          <div class="col-xs-3 col-sm-2 display-flex justify-flex-end align-center p-r-2">
            {
              !isFormEditable && !isFormSubmitted && (
                <button onClick={(e) => toggleEditForm(e)} class="primary-button m-l-10 min-h-45">Edit Form</button>
              )
            }
            {/*
              Modification: Create / Update btn manipulating on entityObject.errorFields.length
              By: Ashutosh G
              Date: 18/09/2023
            */}
            {
              isFormEditable && (
                <button onClick={(e) => submitForm(e)} class="primary-button m-l-10 min-h-45" disabled={((entityObject.errorFields && entityObject.errorFields.length > 0) || isFormSubmitted)} title={(entityObject.errorFields && entityObject.errorFields.length > 0) ? 'Oops! Seems like you have missed one or more items.' : ''}>{operation === 'Create' ? 'Create' :  'Update'}</button>
              )
            }
            <button onClick={(e) => toggleFormPopover(e)} class="secondary-button m-l-10 min-h-45">Cancel</button>
          </div>
        </div>

        <div class="h-full">
          <form class="h-full">

            <div class="step-content formModalContainer" id="formModalContainer" style="display: block; box-shadow: none;">
              <div class="row">
                {/*
                  By: Yashvi
                  On: 17th Aug 2022
                  Added all the required conditions to hide empty divs
              */}
                {/*
                By: Komal
                On: 27th Feb 2023
                Added condition for multipleDependentProps
            */}
                {
                  dynamicProps.length > 0 && dynamicProps.map((dynamicProp, propIndex) => (
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-15" style={`${((dynamicProp.formType !== "select" && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                      ((dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp) ||
                      (((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField !== "Array" && !dynamicProp.dependentProp))) ||
                      (((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp))) ||
                      ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")))) ||
                      (dynamicProp.formType === "file" && !dynamicProp.dependentProp && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA")) ||
                      ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.name === "negotiationMethod" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                      (((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0))) ||
                      ((dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                      ((dynamicProp.formType === "select" && !dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp) ||
                      ((dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.isSelected) > -1) && !dynamicProp.dependentProp) ||
                      (dynamicProp.formType !== "select" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                      (dynamicProp.formType !== "select" && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                      (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                      (dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue)))) ||
                      (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA") || !entityObject[dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                      (dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item))))) ||
                      ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                      ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))) ||
                      ((dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) || (dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp]))))) ||
                      ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) ||
                      ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue)) ||
                      ((dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) ? '' : 'display: none;'}`}>
                      <div class="input-box" id={`${dynamicProp.name}-input-box`}>
                        {(dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum') && (
                          <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}
                            <span style={entityObject[dynamicProp.name] ? "display: none": "display:inline"}  id={`required-`+dynamicProp.name} class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span>
                            <span style={entityObject[dynamicProp.name] ? "display: inline": "display:none"} id={`success-`+dynamicProp.name}> <svg fill="green" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="15" height="15"><path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z"/></svg></span>
                          </p>
                        )}
                        {/*
                          dynamicProp.formType !== "select" && dynamicProp.formType !== "radio" && dynamicProp.formType !== "checkbox" && (dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum') && !dynamicProp.dependentProp && (
                            <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          )
                        */}
                        {/*
                          (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) &&
                            <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          )
                        */}
                        {/*
                          Bug: astrick not appearing for required select so update button is disabled
                          Modification: added select in this condition for astrick for required filed
                          By: Ashutosh G
                          Date: 13/07/2023
                        */}
                        {/*
                          (dynamicProp.formType === "select" || dynamicProp.formType === "radio" || dynamicProp.formType === "checkbox") && !dynamicProp.dependentProp && (
                            <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          )
                        */}
                        {
                          (dynamicProp.name === 'multipleDependentProps'|| dynamicProp.name ==='multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty'|| !dynamicProp.dependentProp) && (
                            <div class='display-flex'>
                              <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                              <button class='m-l-auto m-r-1' onClick={(e) => props.addObject(e, dynamicProp.name)}>ADD</button>
                            </div>
                          )
                        }
                        {
                          ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.typeofField !== "Array" && !dynamicProp.dependentProp)) && (
                            <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)} onBlur={(e) => props.saveFormInput(e, dynamicProp.name)} />
                          )
                        }
                        {
                          (dynamicProp.name === 'multipleDependentProps'|| dynamicProp.name ==='multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty'|| !dynamicProp.dependentProp) && (
                            <div class='display-flex'>
                              <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                              <button class='m-l-auto' onClick={(e) => props.addObject(e, dynamicProp.name)}>ADD</button>
                            </div>
                          )
                        }
                        {/*
                          Modification: Added Separate condition template for Array typeofField
                          By: Devang
                          Date: 11/08/2022
                        */}
                        {
                          // for dynamicProp.name === enum for now
                        }
                        {/*
                          By: Komal
                          On: 27th Feb 2023
                          Added condition for multipleDependentProps
                      */}
                        {
                          ((dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && (dynamicProp.name !== 'multipleDependentProps') && (dynamicProp.name !== 'multipleDependentEnum') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                            <div class="display-flex flex-direction-column">
                              <div class="display-flex">
                                <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} name={dynamicProp.name} id={`${dynamicProp.name}`} value={dynamicProp.stageKey} onInput={(e) => props.setFormStageValueInput(e, 'stageKey', propIndex)} type={dynamicProp.formType} />
                                <button class="m-l-16" onClick={(e) => props.setFormArrayValueInput(e, dynamicProp.name, dynamicProp.stageKey, 'stageKey', propIndex)}>ADD</button>
                              </div>
                              <div class="display-flex flex-direction-column m-t-6">
                                {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, index) => (
                                  <div class="display-flex flex-sb">
                                    <div>{value}</div>
                                    <div class="text-color-red cursor-pointer" onClick={(e) => props.removeFormArrayValueInput(e, dynamicProp.name, index)}>X</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        }
                        {
                          ((dynamicProp.formType === "text") && (dynamicProp.name === 'multipleDependentProps') && (dynamicProp.formName === 'DynamicProperty') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                            <div class=" m-t-10 display-flex flex-direction-column m-t-6" id={`${dynamicProp.name}-input-box`}>
                              {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, multipleDependentIndexndex) => (
                                <div class="input-box display-flex flex-direction-column m-t-6">
                                  <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => props.removeFormArrayValueInput(e, dynamicProp.name, multipleDependentIndexndex)}>X</div>
                                  <div class="">
                                    <p class="formLabel fs-15 p-b-3">Dependent Prop <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                    <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`dependentProp-${multipleDependentIndexndex}`} id={`dependentProp-${multipleDependentIndexndex}`} value={value.dependentProp ? value.dependentProp:''} onInput={(e) => props.setFormArrayValueInputForProp(e, `dependentProp-${multipleDependentIndexndex}`, e.target.value,dynamicProp.name,multipleDependentIndexndex,propIndex)} />
                                  </div>
                                  <div class="">
                                    <p class="formLabel fs-15 p-b-3">Dependent Prop Values<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                    <div class="display-flex">
                                      <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} name={`dependentPropValues-${multipleDependentIndexndex}`} id={`dependentPropValues-${multipleDependentIndexndex}`} value={dynamicProp[`multipleDependentKey-${multipleDependentIndexndex}`]} onInput={(e) => props.setFormStageValueInputForDependantProps(e, `multipleDependentKey-${multipleDependentIndexndex}`, propIndex,value.dependentPropValues)} type='text'/>
                                      <button class="m-l-16" onClick={(e) => props.setArrayValue(e, `dependentPropValues-${multipleDependentIndexndex}`, dynamicProp.name, dynamicProp[`multipleDependentKey-${multipleDependentIndexndex}`], multipleDependentIndexndex,propIndex,`multipleDependentKey-${multipleDependentIndexndex}`)}>ADD</button>
                                    </div>
                                    <div class="display-flex flex-direction-column m-t-6">
                                      {value.dependentPropValues && value.dependentPropValues.length && value.dependentPropValues.map((valueDependentProp, multipleDependentPropValuesIndex) => (
                                        <div class="display-flex flex-sb">
                                          <div>{valueDependentProp}</div>
                                          <div class="text-color-red cursor-pointer" onClick={(e) => props.removeFormArrayValueInputFromProp(e, dynamicProp.name, multipleDependentPropValuesIndex,multipleDependentIndexndex)}>X</div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div class="">
                                    <p class="formLabel fs-15 p-b-3">Dependent Prop Value<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                    <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`dependentPropValue-${multipleDependentIndexndex}`} id={`dependentPropValue-${multipleDependentIndexndex}`} value={value.dependentPropValue ? value.dependentPropValue : ''} onInput={(e) => props.setFormArrayValueInputForProp(e, `dependentPropValue-${multipleDependentIndexndex}`, e.target.value,dynamicProp.name,multipleDependentIndexndex,propIndex)} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        }
                        {
                          ((dynamicProp.formType === "text") && (dynamicProp.name === 'multipleDependentEnum') && (dynamicProp.formName === 'DynamicProperty') && (dynamicProp.typeofField === "Array" && !dynamicProp.dependentProp)) && (
                            <div class="input-box m-t-10 display-flex flex-direction-column m-t-6" id={`${'query'}-input-box`}>
                              {entityObject[dynamicProp.name] && entityObject[dynamicProp.name].length && entityObject[dynamicProp.name].map((value, multipleDependentIndexndex) => (
                                <div class="input-box display-flex flex-direction-column m-t-6">
                                  <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => props.removeFormArrayValueInput(e, dynamicProp.name, multipleDependentIndexndex)}>X</div>
                                  <div class=" m-t-10 display-flex flex-direction-column m-t-6" >
                                    <div class='display-flex'>
                                      <p class="formLabel fs-15 p-b-3">Query <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                      <button class='m-l-auto m-r-1' onClick={(e) => props.addObjectForEnum(e, dynamicProp.name,multipleDependentIndexndex)}>ADD</button>
                                    </div>
                                    {value.query && value.query.length && value.query.map((queryValue, queryIndex) => (
                                      <div class="input-box display-flex flex-direction-column m-t-6">
                                        <div class="text-color-red cursor-pointer m-l-auto" onClick={(e) => props.removeFormQueryValueInput(e, dynamicProp.name, multipleDependentIndexndex,queryIndex)}>X</div>
                                        <div class="">
                                          <p class="formLabel fs-15 p-b-3">Name Of Prop<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                          <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`nameOfProp-${queryIndex}`} id={`nameOfProp-${multipleDependentIndexndex}-${queryIndex}`} value={queryValue.nameOfProp ? queryValue.nameOfProp:''} onInput={(e) => props.setFormArrayValueInputForEnum(e, `nameOfProp-${queryIndex}`, e.target.value,dynamicProp.name,queryIndex,multipleDependentIndexndex)} />
                                        </div>
                                        <div class="">
                                          <p class="formLabel fs-15 p-b-3">Value Of Prop<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                          <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} type="text" onClick={(e) => props.inputClicked(e)} name={`valueOfProp-${queryIndex}`} id={`valueOfProp-${multipleDependentIndexndex}-${queryIndex}`} value={queryValue.valueOfProp ? queryValue.valueOfProp : ''} onInput={(e) => props.setFormArrayValueInputForEnum(e, `valueOfProp-${queryIndex}`, e.target.value,dynamicProp.name,queryIndex,multipleDependentIndexndex)} />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div class="m-t-15">
                                    <p class="formLabel fs-15 p-b-3">Dependent Enums<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                    <div class="display-flex">
                                      <input autocomplete="off" class="" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} name={`dependentEnums-${multipleDependentIndexndex}`} id={`dependentEnums-${multipleDependentIndexndex}`} value={dynamicProp[`dependentEnums-${multipleDependentIndexndex}`]} onInput={(e) => props.setFormStageValueInputForDependantProps(e, `dependentEnums-${multipleDependentIndexndex}`, propIndex,value.dependentEnums)} type='text'/>
                                      <button class="m-l-16" onClick={(e) => props.setArrayValueForEnum(e, `dependentEnums-${multipleDependentIndexndex}`, dynamicProp.name, dynamicProp[`dependentEnums-${multipleDependentIndexndex}`], multipleDependentIndexndex,propIndex,`dependentEnums-${multipleDependentIndexndex}`)}>ADD</button>
                                    </div>
                                    <div class="display-flex flex-direction-column m-t-6">
                                      {value.dependentEnums && value.dependentEnums.length && value.dependentEnums.map((valueDependentEnums, multipleDependentPropValuesIndex) => (
                                        <div class="display-flex flex-sb">
                                          <div>{valueDependentEnums}</div>
                                          <div class="text-color-red cursor-pointer" onClick={(e) => props.removeFormArrayValueInputFromEnum(e, dynamicProp.name, multipleDependentPropValuesIndex,multipleDependentIndexndex)}>X</div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>

                              ))}
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "date" || dynamicProp.formType === "time") && !dynamicProp.dependentProp && (
                            <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={entityObject[dynamicProp.name] || ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)} onBlur={(e) => props.saveFormInput(e, dynamicProp.name)} />
                          )
                        }
                        {
                          (dynamicProp.formType === "file") && (!dynamicProp.dependentProp && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA"))) && (
                            <div>
                              <input class="uploadInputFile" autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={entityObject[dynamicProp.name]} onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                              <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                                {/*
                                By: Yashvi
                                On: 18th April 2024
                                BG_UI: File upload icon is incorrect
                                Updated the svg
                              */}
                                <figure>
                                  <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                </figure>
                                <span>Choose a file</span>
                              </label>
                            </div>
                          )
                        }
                        {
                          dynamicProp.formType === "file" && !dynamicProp.dependentProp && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && (
                            <div class="uploaded-image">
                              <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                              <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && !dynamicProp.dependentProp && (
                            <div class="display-flex p-r-10 align-center">
                              <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-yes`} id={`${dynamicProp.name}-yes`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-yes`}>Yes</label>
                              <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-no`} id={`${dynamicProp.name}-no`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-no`}>No</label>
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "radio" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                            <div class="display-flex align-center">
                              {
                                dynamicProp.enum.map((enumVal) => (
                                  <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] === enumVal} value={entityObject[dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                ))
                              }
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                            <div>
                              {
                                dynamicProp.enum.map((enumVal) => (
                                  <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                ))
                              }
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "checkbox" && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && dynamicProp.name === "negotiationMethod" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                            <div>
                              <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}</p>
                              {
                                dynamicProp.enum.map((enumVal) => (
                                  <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} defaultChecked={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name].split(",").includes(enumVal) : ""} onChange={(e) =>  props.saveFormInputCheckbox(e, dynamicProp.name, enumVal)}  /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                ))
                              }
                            </div>
                          )
                        }
                        {/*
                          bug: double displayName issue
                          Modification: removed displayName paragraph as it get added by common paragraph for select astrick condition
                          By: Ashutosh G
                          Date: 13/07/2023
                        */}
                        {
                          ((dynamicProp.name === "area" || dynamicProp.name === "companyArea") && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (
                            <div>
                              <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) =>  props.saveAreaFromForm(e, dynamicProp.name)} >
                                {
                                  areaList.map((enumVal) => (
                                    <option selected={entityObject[dynamicProp.name] === enumVal.officeName} value={enumVal.officeName}>{enumVal.officeName}</option>
                                  ))
                                }
                              </select>
                            </div>
                          )
                        }
                        {/*
                          bug: double displayName issue
                          Modification: removed displayName paragraph as it get added by common paragraph for select astrick condition
                          By: Ashutosh G
                          Date: 13/07/2023
                        */}
                        {
                          (dynamicProp.formType === "select" && (dynamicProp.dependentProp && dynamicProp.dependentPropValue) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                            <div>
                              {/*<p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}</p>*/}
                              <div class="display-flex">
                                <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                  <option value=''>Select {dynamicProp.displayName}</option>
                                  {
                                    dynamicProp.enum.map((enumVal) => (
                                      <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          )
                        }
                        {/*
                          bug: double displayName issue
                          Modification: removed displayName paragraph as it get added by common paragraph for select astrick condition
                          By: Ashutosh G
                          Date: 13/07/2023
                        */}
                        {
                          (dynamicProp.formType === "select" && !dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                            <div>
                              <div class="display-flex">
                                <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                  <option value=''>Select {dynamicProp.displayName}</option>
                                  {
                                    dynamicProp.enum.map((enumVal) => (
                                      <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          )
                        }
                        {/*
                          bug: double displayName issue
                          Modification: removed displayName paragraph as it get added by common paragraph for select astrick condition
                          By: Ashutosh G
                          Date: 13/07/2023
                        */}
                        {
                          (dynamicProp.formType === "select" && dynamicProp.entityName && (!dynamicProp.dependentEnum || dynamicProp.dependentEnum.length === 0) && (dynamicProp.enum && dynamicProp.enum.length !== 0)) && !dynamicProp.dependentProp && (
                            <div>
                              <div class="display-flex">
                                <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name, "select", 0)} >
                                  <option value=''>Select {dynamicProp.displayName}</option>
                                  {
                                    dynamicProp.enum.map((item) => (
                                      <option selected={entityObject[dynamicProp.name] ===(dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)} value={(dynamicProp.enumIDKey ? item[dynamicProp.enumIDKey] : item.uuid)}>{item[dynamicProp.enumDisplayKey]}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          )
                        }
                        {/*
                          bug: double displayName issue
                          Modification: removed displayName paragraph as it get added by common paragraph for select astrick condition
                          By: Ashutosh G
                          Date: 13/07/2023
                        */}
                        {
                          (dynamicProp.formType === "select" && (dynamicProp.dependentEnum && dynamicProp.dependentEnum.length !== 0) && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && (dynamicProp.dependentEnum.findIndex((d) => d.isSelected) > -1) && !dynamicProp.dependentProp && (
                            <div>
                              <div class="display-flex">
                                <select disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} class='select' type="text" id={`field-${dynamicProp.name}`} onChange={(e) => props.saveFormInput(e, dynamicProp.name)} >
                                  <option value=''>Select {dynamicProp.displayName}</option>
                                  {
                                    dynamicProp.dependentEnum[dynamicProp.dependentEnum.findIndex((d) => d.isSelected)].dependentEnums.map((enumVal) => (
                                      <option selected={entityObject[dynamicProp.name] === enumVal} value={enumVal}>{enumVal}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          )
                        }
                        {/*
                          dynamicProp.formType !== "select" && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                            <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          )
                        }
                        {
                          dynamicProp.formType !== "select" && dynamicProp.dependentProp && !dynamicProp.dependentPropValue && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                            <p class="formLabel fs-15 p-b-3">{dynamicProp.displayName}<span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                          )
                        */}
                        {
                          dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (!entityObject[dynamicProp.name] || (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA")) && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                            <div>
                              <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}`} id={`${dynamicProp.name}`} type={dynamicProp.formType} onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                              <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                                {/*
                                By: Yashvi
                                On: 18th April 2024
                                BG_UI: File upload icon is incorrect
                                Updated the svg
                              */}
                                <figure>
                                  <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                </figure>
                                <span>Choose a file</span>
                              </label>
                            </div>
                          )
                        }
                        {
                          dynamicProp.formType === "file" && dynamicProp.dependentProp  && dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))) && (
                            <div class="uploaded-image">
                              <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                              <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                            </div>
                          )
                        }
                        {
                          dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && ((entityObject[dynamicProp.name] && entityObject[dynamicProp.name] === "NA") || !entityObject[dynamicProp.name]) && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                            <div>
                              <input class="uploadInputFile" autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} onChange={(e) => props.uploadFile(e, dynamicProp.name)}/>
                              <label class="p-t-b-5" for={`${dynamicProp.name}`}>
                                {/*
                                By: Yashvi
                                On: 18th April 2024
                                BG_UI: File upload icon is incorrect
                                Updated the svg
                              */}
                                <figure>
                                  <svg fill="#408b8b" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
                                </figure>
                                <span>Choose a file</span>
                              </label>
                            </div>
                          )
                        }

                        {
                          dynamicProp.formType === "file" && dynamicProp.dependentProp  && !dynamicProp.dependentPropValue && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] !== "NA") && ((dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(',').some(item => dynamicProp.dependentPropValues.includes(item)))) && (
                            <div class="uploaded-image">
                              <button type="button" id={`image-0-${propIndex}`} class="primary-button cursor-pointer object-fit-contain w-full" onClick={(e)=> getReferenceFilesForFileViewer(e, 'Uploaded Files', true, dynamicProp.name, entityObject.uuid)}>View Uploaded File</button>
                              <p class="delete-file-icon" title="Delete file" onClick={(e) => props.deleteUploadedFile(e, dynamicProp.name)}>{'x'}</p>
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValue && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue))) && (
                            <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)}  onBlur={(e) => props.saveFormInput(e, dynamicProp.name)} />
                          )
                        }
                        {
                          (dynamicProp.formType === "text" || dynamicProp.formType === "number" || dynamicProp.formType === "month") && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) && (
                            <input autocomplete="off" class=""  disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] ? true : false} value={entityObject[dynamicProp.name] ? entityObject[dynamicProp.name] : ''} onInput={(e) => props.setFormValueInput(e, dynamicProp.name)}  onBlur={(e) => props.saveFormInput(e, dynamicProp.name)} />
                          )
                        }
                        {
                          (dynamicProp.formType === "date" || dynamicProp.formType === "time") && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)) || (dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])))) && (
                            <input autocomplete="off" required={dynamicProp.isRequired ? "true" : "false"} name={dynamicProp.name} id={`${dynamicProp.name}`} type={dynamicProp.formType} value={entityObject[dynamicProp.name] || ''} onChange={(e) => props.saveFormInput(e, dynamicProp.name)} disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} />
                          )
                        }
                        {/*
                          Modification: Added for conditions in configuration rule
                          By: komal wable
                          Date: 9/12/2022
                        */}
                        {/*
                          Modification: Added remove button for noOfConditions
                          By: Masum Raja
                          Date: 13/07/2023
                        */}
                        {
                          (dynamicProp.name === 'noOfConditions') && (dynamicProp.formName === 'ConfigurationRule') && (entityObject[dynamicProp.name] && entityObject[dynamicProp.name] > 0) && Array(Number(entityObject[dynamicProp.name])).fill(1).map((item, index) => ((
                            <div class="input-box m-t-10" id={`${dynamicProp.name}-input-box`}>
                              <div>
                                <div class='display-flex flex-sb'>
                                  <p class="formLabel fs-15 p-b-3">Triggered By Field{index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                  <div class="text-color-red cursor-pointer flex-r" onClick={(e) => props.handleRemoveConfigRule(e, dynamicProp.name, index)}>X</div>
                                </div>
                                <div class="display-flex">
                                  <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`triggeredByField${index + 1}`} id={`triggeredByField${index + 1}`} type="text" checked={entityObject[`triggeredByField${index + 1}`] ? true : false} value={entityObject[`triggeredByField${index + 1}`] ? entityObject[`triggeredByField${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `triggeredByField${index + 1}`)}  onBlur={(e) => props.saveFormInput(e, `triggeredByField${index + 1}`)} />
                                </div>
                              </div>
                              <div>
                                <p class="formLabel fs-15 p-b-3">Triggered By Field Value {index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                <div class="display-flex">
                                  <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`triggeredByFieldValue${index + 1}`} id={`triggeredByFieldValue${index + 1}`} type="text" checked={entityObject[`triggeredByFieldValue${index + 1}`] ? true : false} value={entityObject[`triggeredByFieldValue${index + 1}`] ? entityObject[`triggeredByFieldValue${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `triggeredByFieldValue${index + 1}`)}  onBlur={(e) => props.saveFormInput(e, `triggeredByFieldValue${index + 1}`)} />
                                </div>
                              </div>
                              <div>
                                <p class="formLabel fs-15 p-b-3">Operator{index + 1} <span class="star-mandatory-entry p-l-2">{dynamicProp.isRequired ? "*" : ""}</span></p>
                                <div class="display-flex">
                                  <input autocomplete="off" class="" onClick={(e) => props.inputClicked(e)} required={dynamicProp.isRequired ? "true" : "false"} name={`operator${index + 1}`} id={`operator${index + 1}`} type="text" checked={entityObject[`operator${index + 1}`] ? true : false} value={entityObject[`operator${index + 1}`] ? entityObject[`operator${index + 1}`] : ''} onInput={(e) => props.setFormValueInput(e, `operator${index + 1}`)}  onBlur={(e) => props.saveFormInput(e, `operator${index + 1}`)} />
                                </div>
                              </div>
                            </div>
                          )))
                        }
                        {
                          ((dynamicProp.formType === "radio" && (!dynamicProp.enum || dynamicProp.enum.length === 0)) && dynamicProp.dependentProp && ((entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[dynamicProp.dependentProp] && entityObject[dynamicProp.dependentProp].split(",").includes(dynamicProp.dependentPropValue)))) && (
                            <div class="display-flex p-r-10 align-center">
                              <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-yes`} id={`${dynamicProp.name}-yes`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "yes" || entityObject[dynamicProp.name] === true) ? true : false} value="yes" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-yes`}>Yes </label>
                              <input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} required={dynamicProp.isRequired ? "true" : "false"} name={`${dynamicProp.name}-no`} id={`${dynamicProp.name}-no`} type={dynamicProp.formType} checked={(entityObject[dynamicProp.name] === "no" || entityObject[dynamicProp.name] === false) ? true : false} value="no" onChange={(e) => props.saveFormInputRadioDefault(e, dynamicProp.name, dynamicProp.typeofField)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-no`}>No </label>
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValue && (entityObject[dynamicProp.dependentProp] === dynamicProp.dependentPropValue) && (
                            <div class="display-flex formRadioButtons">
                              {
                                dynamicProp.enum.map((enumVal) => (
                                  <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] === enumVal} value={entityObject[dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                ))
                              }
                            </div>
                          )
                        }
                        {
                          (dynamicProp.formType === "radio" && (dynamicProp.enum || dynamicProp.enum.length !== 0)) && dynamicProp.dependentProp && dynamicProp.dependentPropValues && (dynamicProp.dependentPropValues.includes(entityObject[dynamicProp.dependentProp])) && (
                            <div class="display-flex formRadioButtons">
                              {
                                dynamicProp.enum.map((enumVal) => (
                                  <div class="display-flex p-r-10 align-center"><input autocomplete="off" disabled={(dynamicProp.isDisabled || !isFormEditable) ? true: false} name={`${dynamicProp.name}-${enumVal}`} id={`${dynamicProp.name}-${enumVal}`} type={dynamicProp.formType} checked={entityObject[dynamicProp.name] === enumVal} value={entityObject[dynamicProp.name]} onChange={(e) => props.saveFormInputRadio(e, dynamicProp.name, enumVal)} /> <label class='p-l-10 p-r-10' style='font-weight: 300; ' for={`${dynamicProp.name}-${enumVal}`}>{enumVal}</label></div>
                                ))
                              }
                            </div>
                          )
                        }
                        {
                          dynamicProp.formType === 'multipleSelect' &&
                          <MultiSelect
                            dynamicProp={dynamicProp}
                            entityObject={entityObject}
                            saveFormInput={props.saveFormInput}
                          />
                        }

                        {dynamicProp.formType === 'tag' && (
                          <div class="keywords-tags">
                            <div class="bootstrap-tagsinput">
                              {entityObject['tag'] &&
                                  entityObject['tag'].map((tag, index) => (
                                    <span class="tag label label-info" key={index}>
                                      {tag}
                                      <span
                                        role="button"
                                        tabIndex={0}
                                        data-role="remove"
                                        onClick={() => props.removeTag(index, dynamicProp)}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter' || e.key === ' ') {
                                            props.removeTag(index, dynamicProp);
                                          }
                                        }}
                                        title="Remove"
                                      />
                                    </span>
                                  ))}
                              <input
                                id="tags-input-field"
                                type="text"
                                class="bor-none"
                                disabled={dynamicProp.isDisabled || !isFormEditable}
                                data-role="tagsinput"
                                minLength="1"
                                maxLength="30"
                                placeholder="Add tags"
                                value={dynamicProp.tempValue}
                                onKeyUp={(e) => props.addTag(e, dynamicProp)}
                              />
                            </div>
                          </div>
                        )}

                        <span id={`error-`+dynamicProp.name} class="text-color-red fs-10" style="display:none;">Please enter {dynamicProp.displayName}</span>
                        {
                          (!dynamicProp.dependentProp || (dynamicProp.dependentProp && ((entityObject['dynamicProperties_'+dynamicProp.dependentProp] === dynamicProp.dependentPropValue) || (entityObject[''+dynamicProp.dependentProp] && entityObject[''+dynamicProp.dependentProp].split(',').includes(dynamicProp.dependentPropValue))))) && (
                            <span id={`error-`+dynamicProp.name} class="fs-10" >{dynamicProp.description}</span>
                          )
                        }
                        {
                          (entityObject.errorFields && entityObject.errorFields.length > 0 && (entityObject.errorFields.findIndex(item => item.fieldName === dynamicProp.name) > -1)) ? (
                            <p class="fs-10 text-color-red" >{entityObject.errorFields.find(item => item.fieldName === dynamicProp.name).errorMessage}</p>
                          ) : (
                            <p class="fs-10 text-color-red" style="visibility: hidden;">Hidden Error</p>
                          )
                        }
                        {
                          dynamicProp.isAmount && entityObject[dynamicProp.name] &&
                          <span id={`amount-`+dynamicProp.name} class="fs-10 display-block" style="color:#4949ed;">{`INR ${inWords(entityObject[dynamicProp.name])}`}</span>
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

          </form>
        </div>
      </div>

      {isFileViewerOpen &&
        <FileViewer
          uploadBrowsedReferenceFile={props.uploadFile}
          closeFileViewer={closeFileViewer}
          toggleFileViewer={toggleFileViewer}
          isFileViewerOpen={isFileViewerOpen}
          uploadedfile={uploadedfile}
          fileViewerFor={fileViewerFor}
          contentDetails={{}}
          isDeleteHidden={true}
          isUploadHidden={true}
        />
      }
    </NewPopupModal>

  );
};

export default CreateEditForm;
