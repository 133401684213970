/*
  Modification: T1646 - Redefined UI of the fileViewer and integrated
  By: Devang
  Date: 22/03/2024 & 02/04/2024
*/

import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import axios from 'axios';
import CONSTANTS from '../../lib/constants';
// import Plyr from 'plyr';
import Wave from "../../lib/bundle.cjs.js";
import JSZip from 'jszip';
// import Toaster from '../../components/toastercomponent';
import { getInitials, isString, formatDateTime, isValidDate, getDateColor, typeOfEnquiryMappingClassColor, formatDateTimeRelative, registrationTypeMappingClassColor, registrationTypeMapping, registrationTypeTextMappingClassColor} from '../../lib/utils';
import {saveAs} from 'file-saver';
import SemiInfoDetailCard from '../../components/semiInfoDetailCard';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

let zip = new JSZip;
let wave;
let videoTypeExtension = ['mov', 'mp4', 'mkv', 'm4v', 'mpeg','webm'];
let audioTypeExtension = ['mp3', 'aac', 'm4a', 'f4a', 'ogg', 'oga'];
let documentTypeExtension = ['ppt', 'pdf', 'docx'];
let imageTypeExtension = ['png', 'jpg', 'jpeg', 'gif'];
let vttFileExtension = ['vtt'];
let allowedFileType = videoTypeExtension.concat(audioTypeExtension,documentTypeExtension,imageTypeExtension,vttFileExtension);
let classNameList = ["active-yellow", "active-green", "active-blue", "active-pink"];

const FileViewer = (props) => {
  let [fileList, setFileList] = useState([]);
  let [isFileViewerOpen, setIsFileViewerOpen] = useState(props.isFileViewerOpen);
  let [fileViewerFor, setFileViewerFor] = useState(props.fileViewerFor ? props.fileViewerFor : {});
  let [uploadedfile, setUploadedfile] = useState(props.uploadedfile.length ? props.uploadedfile : []);
  let [contentDetails, setContentDetails] = useState(props.contentDetails ? props.contentDetails : {});
  let [activeFileObj, setActiveFileObj] = useState({});
  /*
  Added by: Rutuja
  Added on : 26 Jan 2024
  Zoom in - out feature added. Index added for selected file
  */
  let [activeFileObjIndex, setActiveFileObjIndex] = useState(0);
  let [selectedFileIDs, setSelectedFileIDs] = useState([]);
  let [pdfSignedURL, setPdfSignedURL] = useState([]);
  let [pdfFiles, setPdfFiles] = useState([]);
  let [selectedPPTSlideNumber, setSelectedPPTSlideNumber] = useState(0);
  // let [selectedPreviewImage, setSelectedPreviewImage] = useState('');
  let [selectedPreviewImageIndex, setSelectedPreviewImageIndex] = useState(0);
  let [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);
  let [isMobileFileViewerOpen, setIsMobileFileViewerOpen] = useState(false);
  let [isNoFileForDeletion, setIsNoFileForDeletion] = useState(false);
  let [isNoFileLeftToDelete, setIsNoFileLeftToDelete] = useState(false);
  let [isConfirmDeleteSinglePopupOpen, setIsConfirmDeleteSinglePopupOpen] = useState(false);
  let [isToasterOpenDownloading, setIsToasterOpenDownloading] = useState(false);
  let [toasterMessage, setToasterMessage] = useState('');
  let [isDeleteHidden, setIsDeleteHidden] = useState(props.isDeleteHidden);
  let [isUploadHidden, setIsUploadHidden] = useState(props.isUploadHidden);
  let [isDeleteSound, setIsDeleteSound] = useState(false);
  /*
  Added by: Rutuja
  Added on : 26 Jan 2024
  Zoom in - out feature added
  */
  let [zoomPercentage, setZoomPercentage] = useState(5);

  useEffect(() => {
    console.log(uploadedfile, 'uploadedfileuploadedfileuploadedfile');
    if (uploadedfile && uploadedfile.length && uploadedfile[0].contentArray) {
      setSelectedFileID(uploadedfile[0].contentArray[0], 0, uploadedfile[0].name);
      setActiveFileObjIndex(0);
    }
  }, []);

  useEffect(() => {
    setIsFileViewerOpen(props.isFileViewerOpen);
    setFileViewerFor(props.fileViewerFor);
    setUploadedfile(props.uploadedfile.length ? props.uploadedfile : []);
  }, [props]);

  /*
    By: Yashvi
    On: 17th Feb 2024
    Zoom button for document view
  */
  /*
    By: Prasannadatta Kawadkar
    On: 27th March 2024
    Modification: backdrop shadow was recurring if used outside click on the viewer modal. Bug Solved
  */
  function viewAllImages(e) {
    let options = {
      rotatable:false,
      scalable:false,
      hidden() {
        gallery.destroy();
      }
    };
    const gallery = new Viewer(document.getElementById(e.target.id), options);
    gallery.show();
  }
  async function removeFileFromSelectedFileIDs(id) {
    let index = selectedFileIDs.findIndex((element) => element.uuid === id);
    if (index > -1) {
      selectedFileIDs.splice(index, 1);
      let tempSelectedFileIDs = [...selectedFileIDs];
      setSelectedFileIDs(selectedFileIDs);
      if (!selectedFileIDs.length) {
        setIsConfirmDeletePopupOpen(false);
      }
    }
  }

  async function addFileToSelectedFileIDs(data, index, folderName) {
    selectedFileIDs.push(data);
    let tempSelectedFileIDs = [...selectedFileIDs];
    await setSelectedFileIDs(selectedFileIDs);
    await setIsNoFileForDeletion(false);
    console.log(document.getElementById(`${index}-${folderName}-tick`), "document.getElementById(`${index}-${folderName}-tick`)", data, index, folderName);
    document.getElementById(`${index}-${folderName}-tick`) ? document.getElementById(`${index}-${folderName}-tick`).style.display = 'block' : '';
  }

  async function setSelectedFileID(data, index, folderName, isOpenMobileFileViewer) {
    if (!activeFileObj.file || ((activeFileObj) && activeFileObj.uuid !== data.uuid)) {
      // document.getElementById(`${index}-${folderName}-checkbox`) ? document.getElementById(`${index}-${folderName}-checkbox`).style.display = 'none' : '';
      // document.getElementById(`${index}-${folderName}-icon`) ? document.getElementById(`${index}-${folderName}-icon`).style.display = 'block' : '';
      /*
        Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
        Modification: Open mobile file viewer only when explicitly clicked on file name by user
        By: Devang
        Date: 14/04/2024
      */
      if (isOpenMobileFileViewer) {
        setIsMobileFileViewerOpen(true);
      }
      let extension = data.name.split('.').pop();
      if (documentTypeExtension.includes(extension) && data.uuid) {
        if (activeFileObj && (activeFileObj.uuid !== data.uuid )) {
          setPdfSignedURL([]);
          setPdfFiles([]);
          setSelectedPPTSlideNumber(0);
          // setSelectedPreviewImage('');
          setSelectedPreviewImageIndex(0);
        }
        getPDFSignedUrl(data);
      }
      let videoContainer = (document.getElementById("playerdiv") && getComputedStyle(document.getElementById('file-viewer-container')).display !== 'none') ? document.getElementById("playerdiv") : document.getElementById("playerdivmobile") ? document.getElementById("playerdivmobile") : '';
      if (videoContainer) {
        videoContainer.textContent = '';
      }
      setActiveFileObj(data);
      setActiveFileObjIndex(index);
      if ((videoTypeExtension.includes(extension) && data.uuid)) {
        // initializeVideoPlayer(data);
      } else if (audioTypeExtension.includes(extension) && data.uuid) {
        getRecordAudioSignedURL(data);
      }
    }
  }

  function backToFileViewer() {
    console.log('nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn');
    setIsMobileFileViewerOpen(!isMobileFileViewerOpen);
  }

  async function fileOnHover(id, index, folderName) {
    document.getElementById(`${index}-${folderName}-checkbox`) ? document.getElementById(`${index}-${folderName}-checkbox`).style.display = 'block' : '';
    document.getElementById(`${index}-${folderName}-icon`) ? document.getElementById(`${index}-${folderName}-icon`).style.display = 'none' : '';
    // document.getElementById(`${index}-${folderName}-arrow`) ? document.getElementById(`${index}-${folderName}-arrow`).style.visibility = 'visible' : '';
    // (activeFileObj && activeFileObj.file && activeFileObj.uuid === id) ? (document.getElementById(`${index}-${folderName}-arrow`) ? document.getElementById(`${index}-${folderName}-arrow`).style.display = 'none' : '') : '';
  }

  async function fileOnLeave(id, index, folderName) {
    document.getElementById(`${index}-${folderName}-checkbox`) ? document.getElementById(`${index}-${folderName}-checkbox`).style.display = 'none' : '';
    document.getElementById(`${index}-${folderName}-icon`) ? document.getElementById(`${index}-${folderName}-icon`).style.display = 'block' : '';
    // (activeFileObj && activeFileObj.uuid === id) ? (document.getElementById(`${index}-${folderName}-arrow`) ? document.getElementById(`${index}-${folderName}-arrow`).style.visibility = 'visible' : document.getElementById(`${index}-${folderName}-arrow`).style.visibility = 'hidden') : (document.getElementById(`${index}-${folderName}-arrow`) ? document.getElementById(`${index}-${folderName}-arrow`).style.visibility = 'hidden' : '');
    // (activeFileObj && activeFileObj.uuid === id) ? (document.getElementById(`${index}-${folderName}-arrow`) ? document.getElementById(`${index}-${folderName}-arrow`).style.display = 'block' : '') : '';
  }

  async function initializeVideoPlayer(file) {
    let videoElement = document.createElement('video');
    videoElement.id = "player";
    videoElement.setAttribute("controls","");
    videoElement.setAttribute("crossorigin","");
    videoElement.setAttribute("playsinline","");
    videoElement.setAttribute("pip","");
    videoElement.src = file.signedURL ? file.signedURL : '';
    videoElement.setAttribute("key",file.signedURL ? file.signedURL : '');

    let source = document.createElement('source');
    source.src = file.signedURL ? file.signedURL : '';
    source.setAttribute("size","1080");
    videoElement.append(source);
    let trackElement = document.getElementsByTagName("track");
    if (trackElement) {
      for (let i = trackElement.length-1; i >= 0; --i) {
        trackElement[i].remove();
      }
    }
    let sourceElement = document.getElementsByTagName("source");
    if (sourceElement.length > 1) {
      for (let i = sourceElement.length-1; i >= 0; --i) {
        if (sourceElement.type) {
          sourceElement[i].remove();
        }
      }
    }
    // document.getElementById('playerdiv').append(videoElement);
    let videoContainer = (document.getElementById("playerdiv") && getComputedStyle(document.getElementById('file-viewer-container')).display !== 'none') ? document.getElementById("playerdiv") : document.getElementById("playerdivmobile") ? document.getElementById("playerdivmobile") : '';
    videoContainer.append(videoElement);

    // Setup the player
    const selector = '#player';
    const player = new Plyr(selector, {
      // debug: true,
      keyboard: {
        global: true
      },
      tooltips: {
        controls: true
      },
      settings: ['captions', 'quality', 'speed']
    });
    window.player = player;
    player.pip= false;
  }

  async function getRecordAudioSignedURL(file) {
    try {
      if (file.uuid) {
        if (document.getElementById('audio_player') && getComputedStyle(document.getElementById('file-viewer-container')).display !== 'none') {
          wave = new Wave();
          wave.fromElement('audio_player', 'canvas_player', { type: 'dualbars', stroke: 1, colors: ["white", "blue"]});
        } else if (document.getElementById('audio_player_mobile')) {
          wave = new Wave();
          wave.fromElement('audio_player_mobile', 'canvas_player_mobile', { type: 'dualbars', stroke: 1, colors: ["white", "blue"]});
        }
      }

    } catch (HTTPException) {
      console.error(HTTPException);
    }
  }

  async function getPDFSignedUrl(file) {
    try {
      // if (!isProcessingFile) {
      await axios.get(`${CONSTANTS.API_URL}/api/v1/case/file/${file.uuid}/images`).then(async(files) => {
        let imageArray = [],array =[];
        files.data.map(async(fileObj)=>{
          array.push(fileObj.uuid);
        });
        // if (array.length) {
        //   // fileViewerFor['isProcessSuccess'] = true;
        //   let payload = {
        //     interactionID: fileViewerFor.interactionID,
        //     isProcessSuccess: true
        //   };
        //   await axios.put(`${CONSTANTS.API_URL}/api/v1/${fileViewerFor.for}/${courseContentDetails.uuid}`, payload);
        // }
        if (array.length) {
          let response = await axios.get(`${CONSTANTS.API_URL}/api/content/files?fileIDs=${[array]}&interactionID=${[fileViewerFor.interactionID]}&s3KeyFor=${fileViewerFor.for}&fileType=pdf`);

          let signedURLs = response.data;
          let urlArray = [];
          signedURLs.map((url) => {
            urlArray.push(url.signedURL);
          });
          setPdfSignedURL(response ? urlArray : []);
          setPdfFiles(response.data);
          if (pdfFiles[0] && pdfFiles[0].signedURL) {
            await setSelectedPreviewImage(pdfSignedURL[0], 0);
            await handleScroll();
          }
        }
      });
      // }
    } catch (HTTPException) {
      setPdfSignedURL([]);
      setPdfFiles([]);
      console.error(HTTPException);
      await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`,{action:'Get PDF Content',typeOfVisit:'error',errorCode:HTTPException.response.data.statusCode,errorMessage:HTTPException.response.data.message});
    }
  }

  async function setSelectedPreviewImage(image, index) {
    // if (pdfFiles[index].hyperlink) {
    //   this.setState({hyperLinkOnPreviewSlide: pdfFiles[index].hyperlinkDisplayText ? pdfFiles[index].hyperlinkDisplayText : 'LINK'});
    // } else {
    //   this.setState({hyperLinkOnPreviewSlide: ''});
    // }
    // if (pdfFiles[index].hyperlink) {
    //   this.setState({linkOnPreviewSlide: pdfFiles[index].hyperlink});
    // } else {
    //   this.setState({linkOnPreviewSlide: ''});
    // }
    // this.setState({selectedPreviewImage: image, zoomPercentage:5, selectedPreviewImageIndex: index});
    // setSelectedPreviewImage(image);
    setSelectedPreviewImageIndex(index);
    let el = document.getElementById("content-container-preview"); // Or whatever method to get the element
    // To set the scroll
    // el.scrollTop = 0;
    let myImg = document.getElementById(`processedPreviewImage-${index}`);
    if (myImg) {
      myImg.style.width = 100 + "%";
      myImg.style.maxWidth = 100 + "%";
    }
  }

  /*
    BY: Prasannadatta Kawadkar
    ON: 27 March 2024
    Modification: changed function to solve the bug that buttons were not working proprly on pdf viewer
  */
  async function goToNextSlide() {
    if ((pdfSignedURL.length - 1) > selectedPreviewImageIndex) {
      await setSelectedPreviewImage(pdfSignedURL[selectedPreviewImageIndex+1], selectedPreviewImageIndex+1);
      let scrollableContainer = document.getElementById('preview-image-container');
      let computedStyle = getComputedStyle(document.getElementById(`processedPreviewImage-${selectedPreviewImageIndex + 1}`));
      let computedStyleLineHeight = computedStyle.height.substring(0, computedStyle.height.length-2);
      scrollableContainer.scrollTop = ((selectedPreviewImageIndex + 1) * computedStyleLineHeight) + 10;
    }
  }

  async function goToPreviousSlide() {
    if (selectedPreviewImageIndex > 0) {
      await setSelectedPreviewImage(pdfSignedURL[selectedPreviewImageIndex-1], selectedPreviewImageIndex-1);
      let scrollableContainer = document.getElementById('preview-image-container');
      let computedStyle = getComputedStyle(document.getElementById(`processedPreviewImage-${selectedPreviewImageIndex-1}`));
      let computedStyleLineHeight = computedStyle.height.substring(0, computedStyle.height.length-2);
      scrollableContainer.scrollTop = ((selectedPreviewImageIndex-1) * computedStyleLineHeight);
    }
  }

  function handleScroll() {
    let scrollableContainer = document.getElementById('preview-image-container');
    scrollableContainer.addEventListener('scroll', () => {
      pdfSignedURL.map((data, index) => {
        let elem = document.getElementById(`processedPreviewImage-${index}`);
        isInViewport(elem, scrollableContainer) ? setSelectedPreviewImageIndex(index) : '';
      });
    });
  }

  function isInViewport(elm, container) {
    let rect = elm.getBoundingClientRect();
    let containerStyle = getComputedStyle(container);
    let viewHeight = Number(containerStyle.height.substring(0, containerStyle.height.length - 2));
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  async function toggleNoFileSelectedView() {
    setIsNoFileForDeletion(true);
    setActiveFileObj({});
  }

  async function toggleConfirmDeleteFilePopup() {
    setIsConfirmDeletePopupOpen(!isConfirmDeletePopupOpen);
  }

  async function toggleDeleteSingleFile() {
    setIsConfirmDeleteSinglePopupOpen(!isConfirmDeleteSinglePopupOpen);
  }

  async function removeDeleteDisabledIDs() {
    let selectedFileIDs = selectedFileIDs;
    await Promise.all(selectedFileIDs.map(async (file, index) => {
      if (file.isDeleteDisabled) selectedFileIDs.splice(index, 1);
    }));
    await setSelectedFileIDs(selectedFileIDs);
    if (selectedFileIDs.length) {
      await setIsConfirmDeletePopupOpen(true);
    } else {
      await setIsNoFileLeftToDelete(true);
    }
  }

  async function toggleNoFileLeftToDelete() {
    await setIsNoFileLeftToDelete(!isNoFileLeftToDelete);
  }

  async function closeNoDeleteFilePopup(e) {
    if (e.target.className === 'modal-outer-container') {
      await setIsNoFileLeftToDelete(false);
    }
  }

  async function closeConfirmDeleteFilePopup(e) {
    if (e.target.className === 'modal-outer-container') {
      await setIsConfirmDeletePopupOpen(false);
    }
  }

  async function closeConfirmDeleteSingleFilePopup(e) {
    if (e.target.className === 'modal-outer-container') {
      setIsConfirmDeleteSinglePopupOpen(false);
    }
  }

  async function toggleFileViewer() {
    props.toggleFileViewer();
    if (!isFileViewerOpen) {
      await setSelectedFileIDs([]);
      await setActiveFileObj({});
    }
  }
  /*
    By: Yashvi
    On: 18th April 2024
    BG_FN: Check error occurred on Console
    Added validation to avoid console error
  */
  async function closeFileViewer(e) {
    /*
      Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
      Modification: Restricted user to click on close icon only, commented onclick on outer-container and check for outer-container for closeFileViewer
      By: Devang
      Date: 14/04/2024
    */
    // if (e && e.target && e.target.className && e.target.className.split(" ") && e.target.className.split(" ").includes('modal-outer-container') && !props.noCloseOutDiv) {
    props.closeFileViewer();
    await setIsFileViewerOpen(false);
    // }
  }

  function closeToasterDownloading() {
    setIsToasterOpenDownloading(!isToasterOpenDownloading);
  }

  async function downloadFiles() {
    if (selectedFileIDs.length) {
      await Promise.all(selectedFileIDs.map(async data => {
        await downloadContent(data);
      }));
      await setSelectedFileIDs([]);
    } else if (getComputedStyle(document.getElementById('file-viewer-container')).display === 'none' && isMobileFileViewerOpen && activeFileObj && activeFileObj.uuid) {
      await downloadContent(activeFileObj);
    } else {
      await toggleNoFileSelectedView();
    }
  }

  async function deleteUploadedFile(type, e) {
    if (type === 'selectedFileIDs') {
      await Promise.all(selectedFileIDs.map(file => {
        let fileIndex;
        let responseFileIndex;
        let recordAudioIndex;
        let recordVideoIndex;

        if (contentDetails.fileIDs && contentDetails.fileIDs.length) fileIndex = contentDetails.fileIDs.findIndex((element) => element === file.uuid);
        if (contentDetails.responseFileIDs && contentDetails.responseFileIDs.length) responseFileIndex = contentDetails.responseFileIDs.findIndex((element) => element === file.uuid);
        if (contentDetails.recordAudioIDs && contentDetails.recordAudioIDs.length) recordAudioIndex = contentDetails.recordAudioIDs.findIndex((element) => element === file.uuid);
        if (contentDetails.recordVideoIDs && contentDetails.recordVideoIDs.length) recordVideoIndex = contentDetails.recordVideoIDs.findIndex((element) => element === file.uuid);

        if (fileIndex > -1) {
          contentDetails.fileIDs.splice(fileIndex, 1);
          if (activeFileObj && activeFileObj.uuid && (activeFileObj.uuid === file.uuid)) {
            setActiveFileObj({});
          }
        } else if (responseFileIndex > -1) {
          contentDetails.responseFileIDs.splice(responseFileIndex, 1);
          if (activeFileObj && activeFileObj.uuid && (activeFileObj.uuid === file.uuid)) {
            setActiveFileObj({});
          }
        } else if (recordAudioIndex > -1) {
          contentDetails.recordAudioIDs.splice(recordAudioIndex, 1);
          if (activeFileObj && activeFileObj.uuid && (activeFileObj.uuid === file.uuid)) {
            setActiveFileObj({});
          }
        } else if (recordVideoIndex > -1) {
          contentDetails.recordVideoIDs.splice(recordVideoIndex, 1);
          if (activeFileObj && activeFileObj.uuid && (activeFileObj.uuid === file.uuid)) {
            setActiveFileObj({});
          }
        }
      }));
      let payload = {
        fileIDs: contentDetails.fileIDs,
        responseFileIDs: contentDetails.responseFileIDs,
        recordAudioIDs: contentDetails.recordAudioIDs,
        recordVideoIDs: contentDetails.recordVideoIDs
      };
      if (payload.fileIDs === undefined) {
        delete payload.fileID;
      }
      if (payload.responseFileIDs === undefined) {
        delete payload.responseFileIDs;
      }
      if (payload.recordAudioIDs === undefined) {
        delete payload.recordAudioIDs;
      }
      if (payload.recordVideoIDs === undefined) {
        delete payload.recordVideoIDs;
      }
      setIsConfirmDeletePopupOpen(false);
      setIsConfirmDeleteSinglePopupOpen(false);
      setSelectedFileIDs([]);
      uploadedfile = await props.deleteUploadedFile(payload);
      await setUploadedfile(uploadedfile);
      (uploadedfile.length && uploadedfile[0].contentArray.length) ? await setSelectedFileID(uploadedfile[0].contentArray[0], 0, uploadedfile[0].name) : await setActiveFileObj({}); await setUploadedfile([]);
    } else if (type === 'active') {
      let fileIndex;
      let responseFileIndex;
      let recordAudioIndex;
      let recordVideoIndex;
      if (contentDetails.fileIDs && contentDetails.fileIDs.length) fileIndex = contentDetails.fileIDs.findIndex((element) => element === activeFileObj.uuid);
      if (contentDetails.responseFileIDs && contentDetails.responseFileIDs.length) responseFileIndex = contentDetails.responseFileIDs.findIndex((element) => element === activeFileObj.uuid);
      if (contentDetails.recordAudioIDs && contentDetails.recordAudioIDs.length) recordAudioIndex = contentDetails.recordAudioIDs.findIndex((element) => element === activeFileObj.uuid);
      if (contentDetails.recordVideoIDs && contentDetails.recordVideoIDs.length) recordVideoIndex = contentDetails.recordVideoIDs.findIndex((element) => element === activeFileObj.uuid);

      if (fileIndex > -1) {
        contentDetails.fileIDs.splice(responseFileIndex, 1);
      } else if (responseFileIndex > -1) {
        contentDetails.responseFileIDs.splice(responseFileIndex, 1);
      } else if (recordAudioIndex > -1) {
        contentDetails.recordAudioIDs.splice(recordAudioIndex, 1);
      } else if (recordVideoIndex > -1) {
        contentDetails.recordVideoIDs.splice(recordVideoIndex, 1);
      }
      let payload = {
        fileIDs: contentDetails.fileIDs,
        responseFileIDs: contentDetails.responseFileIDs,
        recordAudioIDs: contentDetails.recordAudioIDs,
        recordVideoIDs: contentDetails.recordVideoIDs
      };
      if (payload.fileIDs === undefined) {
        delete payload.fileID;
      }
      if (payload.responseFileIDs === undefined) {
        delete payload.responseFileIDs;
      }
      if (payload.recordAudioIDs === undefined) {
        delete payload.recordAudioIDs;
      }
      if (payload.recordVideoIDs === undefined) {
        delete payload.recordVideoIDs;
      }
      setIsConfirmDeletePopupOpen(false);
      setIsConfirmDeleteSinglePopupOpen(false);
      setSelectedFileIDs([]);
      uploadedfile = await props.deleteUploadedFile(payload);
      await setUploadedfile(uploadedfile);
      (uploadedfile.length && uploadedfile[0].contentArray.length) ? setSelectedFileID(uploadedfile[0].contentArray[0], 0, uploadedfile[0].name) : await setActiveFileObj({}); await setUploadedfile([]);
    } else {
      console.log('No files for delete');
    }
    e.stopPropagation();
    return;
  }

  async function downloadContent(data) {
    setToasterMessage('File(s) are downloading');
    setIsToasterOpenDownloading(true);
    const FileExt = data.name.split('.').pop();
    let type = videoTypeExtension.includes(FileExt) ? 'video' : audioTypeExtension.includes(FileExt) ? 'audio' :  imageTypeExtension.includes(FileExt) ? 'image' : FileExt;
    zip = new JSZip;
    if (type === 'image') {
      if (data.uuid) {
        let response = await axios.get(`${CONSTANTS.API_URL}/api/files/data?fileID=${[data.uuid]}&s3KeyFor=${fileViewerFor.for}&fileType=userCourseimage`);
        let img = zip.folder('images');
        response.data.map((res) => {
          img.file(res.name, res.signedURL.data, {base64: true});
        });
        zip.generateAsync({type:"blob"}).then((content) => {
          // see FileSaver.js
          saveAs(content, "image.zip");
        });
      }
    } else if (type === 'video' || type === 'audio') {
      window.location.href = data.signedURL;
    } else if ((type === 'doc' || type === 'docx') && data.uuid) {
      let responseDocFile = await axios.get(`${CONSTANTS.API_URL}/api/files/data?fileID=${[data.uuid]}&interactionID=${[fileViewerFor.interactionID]}&s3KeyFor=${fileViewerFor.for}&fileType=doc`);
      let doc = zip.folder('document');
      responseDocFile.data.map((res) => {
        if (res.name.split('.').pop() === 'doc' || res.name.split('.').pop() === 'docx') doc.file(res.name, res.signedURL.data, {base64: true});
      });
      zip.generateAsync({type:"blob"}).then((content) => {
        // see FileSaver.js
        saveAs(content, "document.zip");
      });
    } else if (type === 'ppt' && data.uuid) {
      let responsePptFile = await axios.get(`${CONSTANTS.API_URL}/api/files/data?fileID=${[data.uuid]}&interactionID=${[fileViewerFor.interactionID]}&s3KeyFor=${fileViewerFor.for}&fileType=ppt`);

      let ppt = zip.folder('ppt');
      responsePptFile.data.map((res) => {
        if (res.name.split('.').pop() === 'ppt') ppt.file(res.name, res.signedURL.data, {base64: true});
      });
      zip.generateAsync({type:"blob"}).then((content) => {
        // see FileSaver.js
        saveAs(content, "ppt.zip");
      });
    } else if (type === 'pdf' && data.uuid) {
      let responsePdfFile = await axios.get(`${CONSTANTS.API_URL}/api/files/data?fileID=${[data.uuid]}&interactionID=${[fileViewerFor.interactionID]}&s3KeyFor=${fileViewerFor.for}&fileType=pdf`);

      let pdf = zip.folder('pdf');
      responsePdfFile.data.map((res) => {
        if (res.name.split('.').pop() === 'pdf') pdf.file(res.name, res.signedURL.data, {base64: true});
      });
      zip.generateAsync({type:"blob"}).then((content) => {
        // see FileSaver.js
        saveAs(content, "pdf.zip");
      });
    }
  }

  async function uploadBrowsedReferenceFile(e) {
    // uploadedfile = await props.uploadBrowsedReferenceFile(this);
    await props.uploadBrowsedReferenceFile(e);
    await setUploadedfile(uploadedfile);
  }

  /*
    Bug: if props.positionFor is true then we can not see the FileViewer modal
    Modification: change left 100% to left 0%
    By: Masum
    Date: 12/07/2023
  */
  /*
  Added by: Rutuja
  Added on : 26 Jan 2024
  Zoom in - out feature added
  */
  const handleZoomIn = () => {
    setZoomPercentage(zoomPercentage + 1);
    console.log("zooom innnnnnnnnnnnnn");
    let myImg = document.getElementById(`cropped-image-${activeFileObjIndex}`) || document.getElementById(`processedPreviewImage-${activeFileObjIndex}`);
    console.log(myImg,"myImgmyImgmyImgmyImg");
    // let currWidth = myImg.clientWidth;
    let widthPercentage = 100;
    if (zoomPercentage > 5) {
      widthPercentage = 100 + ((zoomPercentage - 5)*10);

    } else if (zoomPercentage < 5){
      widthPercentage = 100 - ((5 - zoomPercentage)*10);
    }
    // let widthPercentage = 100 + ((zoomPercentage + 1)*10);
    if (zoomPercentage > 10){
      alert("Maximum zoom-in level reached.");
    } else if (myImg){
      myImg.style.width = widthPercentage + "%";
      myImg.style.maxWidth = widthPercentage + "%";
      console.log(myImg.style.width,"myImg.style.widthmyImg.style.widthmyImg.style.width");
      console.log(myImg.style.maxWidth,"myImg.style.maxWidthmyImg.style.maxWidthmyImg.style.maxWidth");
    }
  };

  /*
  Added by: Rutuja
  Added on : 26 Jan 2024
  Zoom in - out feature added
  */
  const handleZoomOut = () => {
    setZoomPercentage(zoomPercentage - 1);
    let myImg = document.getElementById(`cropped-image-${activeFileObjIndex}`) || document.getElementById(`processedPreviewImage-${activeFileObjIndex}`);
    console.log(myImg,"myImg================================");
    let widthPercentage = 100;
    if (zoomPercentage > 5) {
      widthPercentage = 100 + ((zoomPercentage - 5)*10);

    } else if (zoomPercentage < 5) {
      widthPercentage = 100 - ((5 - zoomPercentage)*10);
    }
    // let widthPercentage = 100 + ((zoomPercentage + 1)*10);
    if (zoomPercentage < 0){
      alert("Minimum zoom-out level reached.");
    } else if (myImg){
      myImg.style.width = widthPercentage + "%";
      myImg.style.maxWidth = widthPercentage + "%";
    }
  };
  {/*
    Bug: T1798 - BG_UI - Insurance Renewal - Add New Case - Lead Followup - Customer Details tab - File Viewer - Alignment Issue.
    Modification: Add flex-c-m class for file viewer alignment and calc height h-100vh-55.
    By: Arun Singh
    Date: 28/05/2024
  */}
  return (
    <div class='flex-c-m'>
      {/*
        Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
        Modification: Restricted user to click on close icon only, commented onclick on outer-container for closeFileViewer
        By: Devang
        Date: 14/04/2024
      */}
      <div
        class={`modal-outer-container ${props.positionFor === 'sideModal' && 'left-0'}`}
        // onClick={(e) => closeFileViewer(e)}
        /*
          Modification: T2102 - Added position for InsideNewPopup to handle alignment in coreEntityDynamicFormNoModal
          By: Devang
          Date: 27/11/2024
        */
        style={`${props.isViewedFrom ? 'left: 0; top: 0;' : ''} ${props.positionFor === 'Drawer' ? 'top: 0; left: unset; right: 0;' : props.positionFor === 'InsideNewPopup' ? 'top: -2.9%' : ''}`}
      >
        <div class={`${props.class || "modal-inner-container"} `}>
          {/*<div class="modal-top-styler" />*/}
          <div class="modal-inner-body">
            {/*<div class="modal-top-bar display-flex justify-between">
              <div class="modal-file-txt flex-item fs-14 flex-c-m">Your Files</div>
              <button class="secondary-button flex-c-m min-w-24px-imp w-24px h-25px p-0" onClick={(e) => toggleFileViewer(e)}>
                <svg class="flex-item cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.31021 7.75162C7.4582 7.60363 7.69813 7.60363 7.84612 7.75162L16.6882 16.5937C16.8362 16.7417 16.8362 16.9816 16.6882 17.1296C16.5402 17.2776 16.3003 17.2776 16.1523 17.1296L7.31021 8.28753C7.16222 8.13954 7.16222 7.8996 7.31021 7.75162Z" fill="#5F6368" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6878 7.75162C16.5398 7.60363 16.2999 7.60363 16.1519 7.75162L7.30982 16.5937C7.16183 16.7417 7.16183 16.9816 7.30982 17.1296C7.45781 17.2776 7.69774 17.2776 7.84573 17.1296L16.6878 8.28753C16.8358 8.13954 16.8358 7.8996 16.6878 7.75162Z" fill="#5F6368" />
                </svg>
              </button>
            </div>
            <div class="modal-file-subtext fs-11">You can view all your uploaded files here</div>*/}
            <div class="modal-body-file-viewer display-flex">
              <div class="file-list-container">
                {/*
                28 MARCH 2022  Part-3 changes
                By: Manohar
                On: 1st April 2022
                Modification: new bg color class added
                */}
                {/*<div class="file-top-styler bg-5F6368" />*/}
                {
                  activeFileObj && activeFileObj.metaInfo &&
                  <SemiInfoDetailCard
                    masterCardClasses={'no-shadow m-b-8 p-t-0 p-r-8 p-l-8 p-b-4-imp h-auto cursor-auto-imp'}
                    masterClasses={'m-0'}
                    cardBgColor='#fff'
                    cardHeight='inherit'
                    cardHeader={"Case Details"}
                    masterCardMobileClasses={' '}
                    cardSubHeaderMobileClasses={' '}
                    cardSubHeaderClasses="truncate"
                    cardSubHeader={activeFileObj.metaInfo.customerName ? activeFileObj.metaInfo.customerName : ''}
                    cardText1={activeFileObj.metaInfo.typeOfEnquiry ? `Enquiry type: ${activeFileObj.metaInfo.typeOfEnquiry}` : ''}
                    cardText2={activeFileObj.metaInfo.uniqueID ? activeFileObj.metaInfo.uniqueID : ''}
                  />
                }
                {/*
                  By: Yashvi
                  On: 9th July 2024
                  T1914: Clicking on the + button next to the chat input field and going to the case documents modal and clicking the view document button. When we close the view document window the case document modal is closed. It should remain open.
                  Modification: Add a height of h-100vh-145-imp to the fileViewer modal to ensure stability, as the modal was previously unstable without a defined height. Also set p-b-4-imp in above SemiInfoDetailCard masterCardClasses prop
                */}
                <div class="file-list-scroll-view pos-relative h-100vh-145-imp">
                  <h5 class="text-align-left m-0 p-4 bg-darkblue text-white pos-sticky top-0">Documents</h5>
                  {uploadedfile && uploadedfile.length > 0 && uploadedfile.map((folder) => (
                    <div>
                      {/*
                        By: Anik
                        Date: Jan 13 2022
                        Modification: Class added by removing inline style
                      */}
                      <div class="display-flex file-list-title-container">
                        {/* <div class="display-flex" style="direction: ltr; margin: 8px 6px 4px;">*/}
                        <div class="file-list-title">{folder.name}</div>
                        <div class="title-under-score" style={folder.name.length > 9 ? "width: 48%" : "width: 100%"} />
                      </div>
                      {
                        folder.contentArray.map((data, index) => {
                          const FileExt = data.name.split('.').pop();
                          return (
                            <div>
                              <div
                                id={index + '-' + folder.name +'-container'}
                                /*
                                  Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
                                  Modification: Added a prop to instruct to open mobile file viewer only when explicitly clicked on file name by user
                                  By: Devang
                                  Date: 14/04/2024
                                */
                                onClick={setSelectedFileID.bind(this, data, index, folder.name, true)}
                                // onMouseOver={(e) => fileOnHover(data.uuid, index, folder.name)}
                                // onMouseLeave={(e) => fileOnLeave(data.uuid, index, folder.name)}
                                style={`${activeFileObj && activeFileObj.uuid === data.uuid && 'background: #f2f2f2;'}`}
                              >
                                <div  class={` ${activeFileObj && activeFileObj.uuid === data.uuid ? 'display-flex file-title-container justify-between active' : 'display-flex file-title-container justify-between'}`}>
                                  <div class="display-flex flex-item w-full">
                                    {(selectedFileIDs.findIndex((element) => element.uuid === data.uuid) > -1) ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none" class="m-l-6 m-r-1 svg-file-viewer-tick"
                                        id={index+'-' + folder.name +'-tick'} onClick={(e) => removeFileFromSelectedFileIDs(data.uuid)}
                                      >
                                        <g filter="url(#filter0_d_2034_51198)">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1526 1.88958C11.5449 2.19474 11.6156 2.76019 11.3104 3.15254L7.11043 8.55254C6.83098 8.91183 6.32727 9.00592 5.93697 8.77174L2.93697 6.97174C2.51075 6.716 2.37254 6.16317 2.62827 5.73695C2.884 5.31072 3.43684 5.17252 3.86306 5.42825L6.17921 6.81794L9.8896 2.04745C10.1948 1.65509 10.7602 1.58441 11.1526 1.88958Z" fill="#5F6368" />
                                        </g>
                                        <defs>
                                          <filter id="filter0_d_2034_51198" x="0.499878" y="0.699951" width="13.0001" height="11.2001" filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                          >
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2034_51198" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2034_51198" result="shape" />
                                          </filter>
                                        </defs>
                                      </svg> : <div id={index + '-' + folder.name +'-checkbox'} class="file-checkbox m-l-4 m-r-3 flex-item cursor-pointer" style="display: none;" onClick={(e) => addFileToSelectedFileIDs(data, index, folder.name)} />
                                    }
                                    {((selectedFileIDs.findIndex((element) => element.uuid === data.uuid) <= -1)) &&
                                      <svg id={index + '-' + folder.name +'-icon'} class="flex-item m-l-5 svg-file-viewer-icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="7.99998" cy="8" r="5.9" stroke="#757575" />
                                        <path d="M4 9.6V6.4H5.33123C5.48061 6.4 5.61681 6.43155 5.73983 6.49465C5.86578 6.55774 5.97269 6.64037 6.06056 6.74253C6.15136 6.84469 6.22165 6.96037 6.27145 7.08958C6.32417 7.21878 6.35053 7.34948 6.35053 7.48169C6.35053 7.62291 6.32563 7.75812 6.27584 7.88732C6.22897 8.01652 6.16161 8.1322 6.07374 8.23436C5.9888 8.33652 5.88482 8.41765 5.7618 8.47774C5.63878 8.53784 5.50258 8.56789 5.3532 8.56789H4.72054V9.6H4ZM4.72054 7.92338H5.30927C5.39421 7.92338 5.46743 7.88582 5.52894 7.8107C5.59045 7.73558 5.62121 7.62591 5.62121 7.48169C5.62121 7.40657 5.61095 7.34197 5.59045 7.28789C5.57288 7.2338 5.54798 7.18873 5.51576 7.15267C5.48354 7.11662 5.44693 7.09108 5.40592 7.07605C5.36492 7.05803 5.32391 7.04901 5.28291 7.04901H4.72054V7.92338Z" fill="#757575" />
                                        <path d="M6.73736 9.6V6.4H7.90164C8.1594 6.4 8.38493 6.44206 8.57824 6.52619C8.77156 6.61033 8.93265 6.7245 9.06153 6.86873C9.19334 7.01296 9.29146 7.18272 9.3559 7.37803C9.42326 7.57033 9.45695 7.77615 9.45695 7.99549C9.45695 8.23887 9.42033 8.45972 9.34711 8.65803C9.27388 8.85333 9.16844 9.02159 9.03078 9.16281C8.89604 9.30103 8.73202 9.4092 8.5387 9.48732C8.34832 9.56244 8.13596 9.6 7.90164 9.6H6.73736ZM8.72323 7.99549C8.72323 7.85427 8.70419 7.72657 8.66611 7.61239C8.63097 7.49521 8.57824 7.39455 8.50795 7.31042C8.43765 7.22629 8.35125 7.16169 8.24873 7.11662C8.14622 7.07155 8.03052 7.04901 7.90164 7.04901H7.4579V8.95098H7.90164C8.03345 8.95098 8.15061 8.92695 8.25312 8.87887C8.35564 8.8308 8.44058 8.76469 8.50795 8.68056C8.57824 8.59343 8.63097 8.49277 8.66611 8.37859C8.70419 8.26141 8.72323 8.13371 8.72323 7.99549Z" fill="#757575" />
                                        <path d="M9.88233 9.6V6.4H12V7.04901H10.6029V7.73859H11.754V8.33803H10.6029V9.6H9.88233Z" fill="#757575" />
                                      </svg>
                                    }
                                    <div class="upload-file-name flex-c-m" title={data.name}>{data.name.split('.')[0].slice(0,15) + `${data.name.length > 15 ? "..." : ""}`}.{FileExt}</div>
                                  </div>
                                  {/*((selectedFileIDs.findIndex((element) => element.uuid === data.uuid) <= -1) && activeFileObj && activeFileObj.file && activeFileObj.uuid === data.uuid) &&
                                  */}
                                  {/*
                                    Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
                                    Modification: Added a prop to instruct to open mobile file viewer only when explicitly clicked on file name by user
                                    By: Devang
                                    Date: 14/04/2024
                                  */}
                                  <svg id={index+'-' + folder.name +'-arrow'} onClick={setSelectedFileID.bind(this, data, index, folder.name, true)} class="m-r-8 selected-arrow" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none" xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g filter="url(#filter0_d_1929_16874)">
                                      <path d="M6.39998 4.8L9.59998 8L6.39998 11.2" stroke="#757575" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <filter id="filter0_d_1929_16874" x="5" y="3.9" width="5" height="8.2" filterUnits="userSpaceOnUse"
                                        color-interpolation-filters="sRGB"
                                      >
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dx="-0.5" />
                                        <feGaussianBlur stdDeviation="0.25" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1929_16874" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1929_16874" result="shape" />
                                      </filter>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  ))}
                  {((uploadedfile && uploadedfile.length === 0) && !isUploadHidden) &&
                    <div class="h-full display-flex justify-center align-center p-r-20 p-l-20">
                      <input type="file" id="file-upload" required style="display: none;" onChange={(e) => uploadBrowsedReferenceFile(e)} multiple />
                      <label for="file-upload" class="h-full display-flex justify-center align-center cursor-pointer font-300" title="Upload file(s)">
                        <p class="file-viewer-empty-message">Add your files here</p>
                      </label>
                    </div>
                  }
                </div>
                {/*
                  Bug: T1692 - 14 - Mobile - Task View - Case Documents - Cross icon - There is no cross icon to close the view document modal
                  Modification: Added close button
                  By: Devang
                  Date: 14/04/2024
                */}
                <div class="flex-r-m w-full file-viewer-close-button">
                  <button title="Close file viewer" onClick={(e) => closeFileViewer(e)} class="primary-button-2 m-r-8 m-t-4">
                    Close
                  </button>
                </div>
                {/*<div class="file-list-footer display-flex justify-between">
                  <div class="flex-item display-flex">
                    {!isUploadHidden &&
                      <div class="button-icon-container flex-item display-flex m-l-6">
                        <input type="file" id="file-upload" required style="display: none;" onChange={(e) => uploadBrowsedReferenceFile(e)} multiple />
                        <label for="file-upload" class="display-flex cursor-pointer font-300" title="Upload file(s)">
                          <svg class="button-icon flex-item" title="Upload File" width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8.28223 2.68234V13.8823" stroke="#757575" stroke-miterlimit="10" stroke-linecap="round" />
                            <path d="M2.68237 8.2825H13.8824" stroke="#757575" stroke-miterlimit="10" stroke-linecap="round" />
                          </svg>
                        </label>
                      </div>
                    }
                    {/*{(uploadedfile && uploadedfile.length > 0) &&
                          <Toaster classes="info-toaster-body" message="File(s) are downloading"/>
                      }* /}
                    {(uploadedfile && uploadedfile.length > 0) &&
                      <div title="Download file(s)" onClick={(e) => downloadFiles(e)} class="button-icon-container flex-item display-flex m-l-8 m-r-8">
                        <svg class="button-icon flex-item" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13268 2.51843C8.05627 2.44758 8.98466 2.58159 9.84762 2.91072C10.7106 3.23987 11.4862 3.7558 12.115 4.42047C12.6573 4.99376 13.0779 5.66469 13.3536 6.39481H14.4463C15.3135 6.40614 16.1562 6.6843 16.8527 7.19127C17.5493 7.69839 18.0643 8.40901 18.3201 9.22234C18.576 10.0359 18.5585 10.9076 18.2703 11.7106C17.9821 12.5135 17.4393 13.2038 16.7232 13.6841C16.4939 13.838 16.1833 13.7767 16.0294 13.5474C15.8756 13.3181 15.9368 13.0075 16.1662 12.8536C16.7083 12.49 17.1145 11.9707 17.329 11.3728C17.5436 10.7751 17.5565 10.1275 17.3662 9.52239C17.1758 8.91711 16.791 8.38326 16.2641 7.99974C15.7379 7.61667 15.098 7.4041 14.4364 7.39481H12.9962C12.7764 7.39481 12.5823 7.25125 12.518 7.04104C12.2985 6.32307 11.9126 5.66171 11.3885 5.10767C10.8644 4.55357 10.2157 4.12138 9.49126 3.84507C8.76675 3.56875 7.98621 3.45589 7.20917 3.5155C6.43214 3.57511 5.68011 3.80552 5.01023 4.18844C4.3404 4.57132 3.77081 5.0963 3.34377 5.72247C2.91678 6.34856 2.64329 7.05965 2.54303 7.80165C2.44278 8.54362 2.51822 9.29813 2.76395 10.0082C3.00971 10.7183 3.41965 11.3662 3.96386 11.9021C4.16062 12.0958 4.16306 12.4124 3.96931 12.6092C3.77556 12.8059 3.45899 12.8084 3.26223 12.6146C2.60971 11.9721 2.11571 11.1928 1.81894 10.3352C1.52216 9.47765 1.43076 8.56528 1.55204 7.66775C1.67331 6.77024 2.00388 5.91232 2.51761 5.15903C3.0313 4.40582 3.71436 3.77733 4.51397 3.32026C5.31352 2.86322 6.20909 2.58928 7.13268 2.51843Z" fill="#454545" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0293 9.50391C10.3054 9.50391 10.5293 9.72776 10.5293 10.0039V16.9998C10.5293 17.276 10.3054 17.4998 10.0293 17.4998C9.75315 17.4998 9.5293 17.276 9.5293 16.9998V10.0039C9.5293 9.72776 9.75315 9.50391 10.0293 9.50391Z" fill="#454545" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.46226 13.5426C6.65445 13.3443 6.971 13.3394 7.16928 13.5316L10.0292 16.3036L12.8891 13.5316C13.0874 13.3394 13.404 13.3443 13.5961 13.5426C13.7883 13.7409 13.7834 14.0575 13.5851 14.2497L10.3772 17.359C10.1833 17.5469 9.87513 17.5469 9.68121 17.359L6.4733 14.2497C6.27501 14.0575 6.27007 13.7409 6.46226 13.5426Z" fill="#454545" />
                        </svg>
                      </div>
                    }
                    {((uploadedfile && uploadedfile.length > 0) && (!isDeleteHidden)) &&
                      <div title="Delete file(s)" class="button-icon-container flex-item display-flex m-r-8">
                        <svg class="button-icon flex-item" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                          onClick={selectedFileIDs.length ? (e) => removeDeleteDisabledIDs(e) : (e) => toggleNoFileSelectedView(e)}
                        >
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2667 4.29469H3.73333V13.0526C3.73333 13.4246 4.0517 13.7263 4.44444 13.7263H11.5555C11.9483 13.7263 12.2667 13.4246 12.2667 13.0526V4.29469ZM3.02222 3.621V13.0526C3.02222 13.7967 3.65897 14.4 4.44444 14.4H11.5555C12.341 14.4 12.9778 13.7967 12.9778 13.0526V3.621H3.02222Z" fill="#757575" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59998 3.95784C1.59998 3.77181 1.75078 3.621 1.93682 3.621H14.0631C14.2492 3.621 14.4 3.77181 14.4 3.95784C14.4 4.14388 14.2492 4.29469 14.0631 4.29469H1.93682C1.75078 4.29469 1.59998 4.14388 1.59998 3.95784Z" fill="#757575" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42218 2.27369H6.57774C6.185 2.27369 5.86663 2.57531 5.86663 2.94737V3.62106H10.1333V2.94737C10.1333 2.57531 9.81492 2.27369 9.42218 2.27369ZM6.57774 1.60001C5.79227 1.60001 5.15552 2.20324 5.15552 2.94737V4.29474H10.8444V2.94737C10.8444 2.20324 10.2077 1.60001 9.42218 1.60001H6.57774Z" fill="#757575" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57772 5.97903C6.77409 5.97903 6.93328 6.12984 6.93328 6.31588V11.7053C6.93328 11.8914 6.77409 12.0422 6.57772 12.0422C6.38136 12.0422 6.22217 11.8914 6.22217 11.7053V6.31588C6.22217 6.12984 6.38136 5.97903 6.57772 5.97903Z" fill="#757575" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42208 5.97916C9.61845 5.97916 9.77764 6.12997 9.77764 6.316V11.7055C9.77764 11.8915 9.61845 12.0423 9.42208 12.0423C9.22572 12.0423 9.06653 11.8915 9.06653 11.7055V6.316C9.06653 6.12997 9.22572 5.97916 9.42208 5.97916Z" fill="#757575" />
                        </svg>
                      </div>
                    }
                  </div>
                  {/*
                    By:Yash
                    Date:12th April 2022
                    Modification:Hid 3 Dots
                    * /}
                  {/*<div class="button-icon-container flex-item display-flex m-r-8">
                    <svg class="flex-item button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="7.19995" y="4" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                      <rect x="7.19995" y="7.20001" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                      <rect x="7.19995" y="10.4" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                    </svg>
                  </div>* /}
                </div>*/}
              </div>
              <div class="file-viewer-container" id="file-viewer-container">
                <div class="file-viewer-topbar display-flex justify-between m-b-10">
                  <div class="display-flex flex-item" style="align-items: center;">
                    {/*<div class="file-viewer-button-icon-container display-flex m-l-6 m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>
                    </div>*/}
                    <div class="file-name-viewer m-l-6 m-r-16 flex-item">{activeFileObj && activeFileObj.keyDisplayName ? `${activeFileObj.keyDisplayName} | ` : ''}{(activeFileObj && activeFileObj.name) ? activeFileObj.name : ''}</div>
                    {/*(activeFileObj && activeFileObj.name) &&
                      <div title="Download file" onClick={downloadContent.bind(this, activeFileObj)} class="file-viewer-button-icon-container display-flex m-r-8">
                        <svg class="flex-item button-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13268 2.51843C8.05627 2.44758 8.98466 2.58159 9.84762 2.91072C10.7106 3.23987 11.4862 3.7558 12.115 4.42047C12.6573 4.99376 13.0779 5.66469 13.3536 6.39481H14.4463C15.3135 6.40614 16.1562 6.6843 16.8527 7.19127C17.5493 7.69839 18.0643 8.40901 18.3201 9.22234C18.576 10.0359 18.5585 10.9076 18.2703 11.7106C17.9821 12.5135 17.4393 13.2038 16.7232 13.6841C16.4939 13.838 16.1833 13.7767 16.0294 13.5474C15.8756 13.3181 15.9368 13.0075 16.1662 12.8536C16.7083 12.49 17.1145 11.9707 17.329 11.3728C17.5436 10.7751 17.5565 10.1275 17.3662 9.52239C17.1758 8.91711 16.791 8.38326 16.2641 7.99974C15.7379 7.61667 15.098 7.4041 14.4364 7.39481H12.9962C12.7764 7.39481 12.5823 7.25125 12.518 7.04104C12.2985 6.32307 11.9126 5.66171 11.3885 5.10767C10.8644 4.55357 10.2157 4.12138 9.49126 3.84507C8.76675 3.56875 7.98621 3.45589 7.20917 3.5155C6.43214 3.57511 5.68011 3.80552 5.01023 4.18844C4.3404 4.57132 3.77081 5.0963 3.34377 5.72247C2.91678 6.34856 2.64329 7.05965 2.54303 7.80165C2.44278 8.54362 2.51822 9.29813 2.76395 10.0082C3.00971 10.7183 3.41965 11.3662 3.96386 11.9021C4.16062 12.0958 4.16306 12.4124 3.96931 12.6092C3.77556 12.8059 3.45899 12.8084 3.26223 12.6146C2.60971 11.9721 2.11571 11.1928 1.81894 10.3352C1.52216 9.47765 1.43076 8.56528 1.55204 7.66775C1.67331 6.77024 2.00388 5.91232 2.51761 5.15903C3.0313 4.40582 3.71436 3.77733 4.51397 3.32026C5.31352 2.86322 6.20909 2.58928 7.13268 2.51843Z" fill="#454545" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0293 9.50391C10.3054 9.50391 10.5293 9.72776 10.5293 10.0039V16.9998C10.5293 17.276 10.3054 17.4998 10.0293 17.4998C9.75315 17.4998 9.5293 17.276 9.5293 16.9998V10.0039C9.5293 9.72776 9.75315 9.50391 10.0293 9.50391Z" fill="#454545" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.46226 13.5426C6.65445 13.3443 6.971 13.3394 7.16928 13.5316L10.0292 16.3036L12.8891 13.5316C13.0874 13.3394 13.404 13.3443 13.5961 13.5426C13.7883 13.7409 13.7834 14.0575 13.5851 14.2497L10.3772 17.359C10.1833 17.5469 9.87513 17.5469 9.68121 17.359L6.4733 14.2497C6.27501 14.0575 6.27007 13.7409 6.46226 13.5426Z" fill="#454545" />
                        </svg>
                      </div>
                    */}
                    {/*<div class="file-viewer-button-icon-container display-flex m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>
                    </div>*/}
                  </div>
                  <div class="display-flex flex-item">
                    <button class="secondary-button flex-c-m min-w-24px-imp w-24px h-25px p-0 m-l-6" onClick={(e) => toggleFileViewer(e)}>
                      <svg class="flex-item cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.31021 7.75162C7.4582 7.60363 7.69813 7.60363 7.84612 7.75162L16.6882 16.5937C16.8362 16.7417 16.8362 16.9816 16.6882 17.1296C16.5402 17.2776 16.3003 17.2776 16.1523 17.1296L7.31021 8.28753C7.16222 8.13954 7.16222 7.8996 7.31021 7.75162Z" fill="#5F6368" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6878 7.75162C16.5398 7.60363 16.2999 7.60363 16.1519 7.75162L7.30982 16.5937C7.16183 16.7417 7.16183 16.9816 7.30982 17.1296C7.45781 17.2776 7.69774 17.2776 7.84573 17.1296L16.6878 8.28753C16.8358 8.13954 16.8358 7.8996 16.6878 7.75162Z" fill="#5F6368" />
                      </svg>
                    </button>
                    {/*  <div class="file-viewer-button-icon-container display-flex m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="7.19995" y="4" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                        <rect x="7.19995" y="7.20001" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                        <rect x="7.19995" y="10.4" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                      </svg>
                    </div>*/}
                  </div>
                </div>
                <div id="file-type-viewer-body" class="file-viewer-body" style={{backgroundColor : "#FFFFFF"}}>
                  {imageTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop()) &&
                    <div class="file-type-docs file-viewer-docs h-full">
                      {/*
                        Modification: T1683 - Brought image to center if small than maximum dimensions
                        By: Devang
                        Date: 08/04/2024
                      */}
                      <div class="modal-preview-img-container flex-c-m">
                        {/*
                          By: Yashvi
                          On: 17th Feb 2024
                          Zoom button for document view
                        */}
                        {/*
                          Modification: Fixed aspect-ratio issue
                          By: Devang
                          Date: 06/04/2024
                        */}
                        <img id={"cropped-image-"+activeFileObjIndex} src={activeFileObj.signedURL} class="modal-preview-image" crossOrigin="Anonymous" onClick={(e)=> viewAllImages(e)}/>
                      </div>
                    </div>
                  }
                  {documentTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop()) &&
                    <div class="file-type-docs h-full" style="overflow:auto;" id="preview-image-container">
                      {
                        (selectedPreviewImageIndex > 0) && (
                          <img class="file-viewer-docs-arrows left-arrow" src="/assets/images/Navigation-Arrow-left-hover.svg" onClick={(e) => goToPreviousSlide(e)} title="Go to previous slide" />
                        )
                      }
                      <div class="pdf-slides h-full" id="pdf-slides">
                        <div class="file-viewer-docs" id="content-container-preview">
                          <div>
                            {/*
                            By: Yashvi
                            On: 17th Feb 2024
                            Zoom button for document view
                          */}
                            {pdfSignedURL.map((data, index) => (<img src={data} style="max-width:100%" id={`processedPreviewImage-${index}`}  onClick={(e)=> viewAllImages(e)}/>))}
                          </div>
                        </div>
                      </div>
                      {
                        ((pdfSignedURL.length - 1 > selectedPreviewImageIndex)) && (
                          <img class="file-viewer-docs-arrows right-arrow" src="/assets/images/Navigation-Arrow-right-hover.svg" onClick={(e) => goToNextSlide(e)} title="Go to next slide" />
                        )
                      }
                    </div>
                  }
                  {videoTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop())&&
                    <div class="file-type-docs file-viewer-docs h-full">
                      <div id="playerdiv" class="h-full w-full" />
                    </div>
                  }
                  {audioTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop()) &&
                    <div class=".plyr__video-wrapper position-relative h-full w-full" style='background: #000;'>
                      <canvas id='canvas_player' class="audio-canvas-player" height='150' width='550' />
                      <audio id="audio_player" style='width: 50%;position: absolute; bottom: 5px; left: 25%;' controls src={activeFileObj.signedURL} />
                    </div>
                  }
                  {isNoFileForDeletion && (!activeFileObj || !activeFileObj.name || activeFileObj.name === '') &&
                    <div class="file-type-docs file-viewer-docs h-full display-flex justify-center align-center">
                      {/*
                        Modification: Reworded s to S in selected
                        By: Devang
                        Date: 24/07/2023
                      */}
                      <p>No Files Selected</p>
                    </div>
                  }
                  {(uploadedfile && uploadedfile.length === 0) &&
                    <div class="file-type-docs file-viewer-empty-message h-full display-flex justify-center align-center">
                      <p>No Files to Display</p>
                    </div>
                  }
                </div>
                <div class="file-viewer-bottombar display-flex justify-between m-t-10" style="align-items: center;">
                  {/*<div class="display-flex flex-item" style="align-items: center;">
                    <div class="file-viewer-button-icon-container display-flex m-l-6 m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>
                    </div>

                    <div class="file-viewer-button-icon-container display-flex m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>
                    </div>
                    <div class="file-viewer-button-icon-container display-flex m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>;
                    </div>
                  </div>*/}
                  {/*
                  Added by: Rutuja
                  Added on : 26 Jan 2024
                  Zoom in - out feature added
                  */}
                  <div class="display-flex flex-item" style="align-items: center;">
                    {(activeFileObj && activeFileObj.name) &&
                      <button title="Download file" onClick={downloadContent.bind(this, activeFileObj)} class="primary-button-1 flex-c-m min-w-24px-imp h-24px m-l-8">
                        Download
                      </button>
                    }
                    {/*((activeFileObj && activeFileObj.name && !activeFileObj.isDeleteDisabled)) && (!isDeleteHidden) &&
                      <button title="Delete file" class="secondary-button flex-c-m min-w-24px-imp w-24px h-24px p-0 m-l-8" onClick={(e) => toggleDeleteSingleFile(e)}>
                        <svg class="file-viewer-button-icon flex-item" width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2667 4.29469H3.73333V13.0526C3.73333 13.4246 4.0517 13.7263 4.44444 13.7263H11.5555C11.9483 13.7263 12.2667 13.4246 12.2667 13.0526V4.29469ZM3.02222 3.621V13.0526C3.02222 13.7967 3.65897 14.4 4.44444 14.4H11.5555C12.341 14.4 12.9778 13.7967 12.9778 13.0526V3.621H3.02222Z" fill="#000" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59998 3.95784C1.59998 3.77181 1.75078 3.621 1.93682 3.621H14.0631C14.2492 3.621 14.4 3.77181 14.4 3.95784C14.4 4.14388 14.2492 4.29469 14.0631 4.29469H1.93682C1.75078 4.29469 1.59998 4.14388 1.59998 3.95784Z" fill="#000" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42218 2.27369H6.57774C6.185 2.27369 5.86663 2.57531 5.86663 2.94737V3.62106H10.1333V2.94737C10.1333 2.57531 9.81492 2.27369 9.42218 2.27369ZM6.57774 1.60001C5.79227 1.60001 5.15552 2.20324 5.15552 2.94737V4.29474H10.8444V2.94737C10.8444 2.20324 10.2077 1.60001 9.42218 1.60001H6.57774Z" fill="#000" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57772 5.97903C6.77409 5.97903 6.93328 6.12984 6.93328 6.31588V11.7053C6.93328 11.8914 6.77409 12.0422 6.57772 12.0422C6.38136 12.0422 6.22217 11.8914 6.22217 11.7053V6.31588C6.22217 6.12984 6.38136 5.97903 6.57772 5.97903Z" fill="#000" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42208 5.97916C9.61845 5.97916 9.77764 6.12997 9.77764 6.316V11.7055C9.77764 11.8915 9.61845 12.0423 9.42208 12.0423C9.22572 12.0423 9.06653 11.8915 9.06653 11.7055V6.316C9.06653 6.12997 9.22572 5.97916 9.42208 5.97916Z" fill="#000" />
                        </svg>
                      </button>
                    */}
                    <button title="Zoom In" class="secondary-button flex-c-m min-w-24px-imp w-24px h-24px p-0 m-l-8" onClick={(e) => handleZoomIn(e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24.000000pt" height="24.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                          <path d="M2200 4225 c-579 -96 -1025 -522 -1147 -1095 -14 -66 -18 -129 -18 -280 1 -177 3 -205 27 -300 46 -183 105 -317 207 -470 65 -98 224 -263 326 -338 159 -117 390 -213 594 -247 149 -25 396 -17 537 19 127 31 210 63 325 122 l91 47 391 -390 c216 -215 402 -396 414 -402 68 -34 143 13 143 90 l0 43 -391 392 -391 392 47 43 c113 104 238 278 305 424 371 802 -85 1731 -950 1932 -134 31 -381 40 -510 18z m428 -211 c382 -68 734 -357 873 -719 145 -375 100 -776 -123 -1102 -67 -98 -212 -244 -305 -305 -714 -476 -1658 -81 -1824 762 -20 99 -17 338 5 440 50 234 163 440 332 606 201 198 436 305 744 338 57 6 209 -4 298 -20z"/>
                          <path d="M2358 3534 c-15 -8 -32 -23 -38 -34 -6 -11 -10 -120 -10 -279 l0 -261 -255 0 c-271 0 -301 -4 -325 -49 -18 -35 -12 -88 14 -118 l24 -28 269 -5 268 -5 5 -268 5 -269 28 -24 c38 -33 96 -33 134 0 l28 24 3 271 3 271 260 0 c159 0 268 4 280 10 22 13 49 61 49 90 0 33 -26 75 -55 88 -18 8 -104 12 -280 12 l-255 0 0 255 c0 274 -3 296 -51 321 -35 18 -66 17 -101 -2z"/>
                        </g>
                      </svg>
                    </button>
                    <button title="Zoom Out" class="secondary-button flex-c-m min-w-24px-imp w-24px h-24px p-0" onClick={(e) => handleZoomOut(e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24.000000pt" height="24.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                          <path d="M2210 4284 c-483 -57 -868 -292 -1124 -687 -100 -153 -178 -351 -212 -537 -25 -135 -25 -392 0 -525 90 -482 382 -866 821 -1081 219 -107 397 -147 650 -148 241 0 426 42 641 143 93 45 239 137 309 195 l30 25 420 -420 c456 -454 447 -448 500 -394 53 53 60 45 -390 495 -228 228 -415 419 -415 424 0 4 18 29 41 54 58 66 154 212 201 306 53 105 111 285 134 411 23 134 23 365 0 500 -108 622 -593 1109 -1216 1221 -84 15 -322 26 -390 18z m431 -183 c422 -100 762 -383 932 -774 115 -266 139 -581 66 -872 -61 -240 -222 -502 -408 -662 -260 -224 -550 -333 -885 -333 -334 0 -623 107 -877 323 -368 314 -536 816 -434 1295 109 515 538 935 1055 1033 162 30 401 26 551 -10z"/>
                          <path d="M1652 2847 c-29 -31 -29 -77 1 -105 l23 -22 668 0 667 0 24 25 c30 30 32 64 4 99 l-20 26 -673 0 -673 0 -21 -23z"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div class="display-flex flex-item" style="align-items: center;">
                    <div class="upload-file-name p-r-8">{`Uploaded at: ${formatDateTime(activeFileObj.createdAt)}`}</div>
                    <div class="upload-file-name p-r-8">{`Uploaded by: ${activeFileObj.createdBy}`}</div>
                    <div class="upload-file-name p-r-8">{`Size: ${activeFileObj.size / 1000} KB`}</div>

                    {/*
                    By:Yash
                    Date:12th April 2022
                    Modification:Hide Text
                    */}
                    {/*<p class="fs-12 flex-item m-r-8 f-300 m-b-0">System related message about file...</p>*/}
                    {/*<div class="file-viewer-button-icon-container display-flex m-l-6 m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>
                    </div>
                    <div class="file-viewer-button-icon-container display-flex m-r-8">
                      <svg class="file-viewer-button-icon flex-item" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8194 9.08017L10.9199 3.18062C10.7296 2.92687 10.3489 2.8 10.0318 2.8C9.65115 2.8 9.33397 2.92687 9.08022 3.18062L3.18067 9.08017C2.67318 9.58766 2.67318 10.4123 3.18067 10.9833C3.43441 11.1736 3.75159 11.3004 4.06877 11.3639C4.06877 11.3639 4.06877 11.3639 4.13221 11.3639H4.38595V15.6775C4.38595 16.5656 5.08375 17.2 5.90842 17.2H8.19212C8.44586 17.2 8.63617 17.0097 8.63617 16.7559V13.3304C8.63617 12.9498 8.95335 12.6326 9.33397 12.6326H10.6661C11.0467 12.6326 11.3639 12.9498 11.3639 13.3304V16.7559C11.3639 17.0097 11.5542 17.2 11.808 17.2H14.0917C14.9798 17.2 15.6141 16.5022 15.6141 15.6775V11.3639H15.8045C16.1851 11.3639 16.5022 11.237 16.756 10.9833C17.3269 10.4123 17.3269 9.58766 16.8194 9.08017ZM16.2485 10.3489C16.1851 10.4123 16.0582 10.4758 15.9313 10.4758H15.297C15.0432 10.4758 14.8529 10.6661 14.8529 10.9198V15.6775C14.8529 16.0581 14.5357 16.3753 14.1551 16.3753H12.252V13.3938C12.252 12.5057 11.5542 11.8714 10.7296 11.8714H9.3974C8.5093 11.8714 7.8115 12.5692 7.8115 13.3938V16.3753H5.90842C5.5278 16.3753 5.21062 16.0581 5.21062 15.6775V10.9198C5.21062 10.6661 5.02031 10.4758 4.76657 10.4758H4.13221C4.00533 10.4758 3.87846 10.4123 3.81503 10.3489C3.62472 10.1586 3.62472 9.84141 3.81503 9.6511L9.71459 3.75154C9.77802 3.6881 9.90489 3.62467 10.0318 3.62467C10.1586 3.62467 10.2855 3.6881 10.3489 3.75154L16.2485 9.6511C16.4388 9.84141 16.4388 10.1586 16.2485 10.3489Z" fill="#757575"/>
                      </svg>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          /*
            Modification: Added Toasters, and state + functions accordingly
            By: Devang
            Date: 21/04/2022
        {
          isToasterOpenDownloading &&
            <Toaster parentClasses="" classes="info-toaster-body" message={toasterMessage} onClose={(e) => closeToasterDownloading(e)}/>
        }
        */
        }
      </div>
      {/*
        isConfirmDeletePopupOpen && (
          <div class="modal-outer-container" onClick={(e) => closeConfirmDeleteFilePopup(e)} style="z-index: 8;">
            <div class="main-index-modal file-index-modal responsive-index-modal">
              <div class="index-container file-index-container">
                <div class="invite-div">
                  <div class="title">Are you sure you want to delete <span class="number-font users-count">{selectedFileIDs.length ? selectedFileIDs.length : 1}</span> file(s)?</div>
                </div>
                <div class="courses-div files">
                  {
                    (selectedFileIDs && selectedFileIDs.length > 0) &&
                      selectedFileIDs.map((item, index) => {
                        if (!item.isDeleteDisabled)
                          return (
                            <p class="course files-p">
                              <span class={` course-name files-span ${classNameList[index % 4]}`}>{item.name}
                                <span title="Remove File"><svg class="flex-item cursor-pointer delete-span" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
                                  onClick={(e) => removeFileFromSelectedFileIDs(item.uuid)}
                                >
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.31021 7.75162C7.4582 7.60363 7.69813 7.60363 7.84612 7.75162L16.6882 16.5937C16.8362 16.7417 16.8362 16.9816 16.6882 17.1296C16.5402 17.2776 16.3003 17.2776 16.1523 17.1296L7.31021 8.28753C7.16222 8.13954 7.16222 7.8996 7.31021 7.75162Z" fill="#757575" />
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6878 7.75162C16.5398 7.60363 16.2999 7.60363 16.1519 7.75162L7.30982 16.5937C7.16183 16.7417 7.16183 16.9816 7.30982 17.1296C7.45781 17.2776 7.69774 17.2776 7.84573 17.1296L16.6878 8.28753C16.8358 8.13954 16.8358 7.8996 16.6878 7.75162Z" fill="#757575" />
                                </svg></span>
                              </span>
                            </p>
                          );
                      })
                  }
                </div>
                {/* By:Yash
                    Date:5th April
                    Modification:Removed Background color * /}
                <div class="buttons-div justify-flex-end">
                  <button onClick={(e) => deleteUploadedFile(e, 'selectedFileIDs')} title="Delete file(s)" style="margin-right: 10px;">Yes</button>
                  <button onClick={(e) => toggleConfirmDeleteFilePopup(e)} title="Cancel">No</button>
                </div>
              </div>
            </div>
          </div>
        )
      */}
      {/*
        isConfirmDeleteSinglePopupOpen && (
          <div class="modal-outer-container" onClick={(e) => closeConfirmDeleteSingleFilePopup(e)} style="z-index: 8;">
            <div class="main-index-modal file-index-modal responsive-index-modal">
              <div class="index-container file-index-container">
                <div class="invite-div">
                  <div class="title">Are you sure you want to delete <span class="number-font users-count">1</span> file?</div>
                </div>
                <div class="courses-div files">
                  <p class="course files-p">
                    <span class={` course-name files-span ${classNameList[0]}`}>{activeFileObj.name}</span>
                  </p>
                </div>
                {/* By:Yash
                    Date:5th April
                    Modification:Removed Background color * /}
                <div class="buttons-div justify-flex-end">
                  <button onClick={(e) => deleteUploadedFile(e, 'active')} title="Delete file" style="margin-right: 10px;">Yes</button>
                  <button onClick={(e) => toggleDeleteSingleFile(e)} title="Cancel">No</button>
                </div>
              </div>
            </div>
          </div>
        )
      */}
      {
        isNoFileLeftToDelete && (
          <div class="modal-outer-container" onClick={(e) => closeNoDeleteFilePopup(e)}>
            <div class="main-index-modal file-index-modal responsive-index-modal">
              <div class="index-container file-index-container">
                <div class="invite-div">
                  <div class="title">The files you've chosen are uploaded by the Author and hence cannot be deleted.</div>
                </div>
                {/* By:Yash
                    Date:5th April
                    Modification:Removed Background color */}
                <div class="buttons-div justify-flex-end">
                  <button onClick={(e) => toggleNoFileLeftToDelete(e)} title="Close">Close</button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {/*
        By: Anik
        Date: Jan 13 2022
        Modification: Mobile Landscape & Portrait File Viewer design
      */}
      {
        isMobileFileViewerOpen && (
          <div class="file-viewer-mob">
            <div onClick={(e) => backToFileViewer(e)} class="display-flex">
              <svg class="flex-item m-r-8" width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.8 1.19922L1 7.29922L5.8 12.0992" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="flex-item file-name-mob">{activeFileObj && activeFileObj.keyDisplayName ? `${activeFileObj.keyDisplayName} | ` : ''}{(activeFileObj && activeFileObj.name) ? activeFileObj.name : ''}</p>
            </div>
            <div class=' file-view-container-mob'>
              {imageTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop()) &&
                <div class="file-type-docs file-viewer-docs h-full">
                  <div class="modal-preview-img-container flex-c-m">
                    {/*
                    By: Yashvi
                    On: 17th Feb 2024
                    Zoom button for document view
                  */}
                    {/*
                      Modification: Fixed aspect-ratio issue
                      By: Devang
                      Date: 06/04/2024
                    */}
                    <img id={"cropped-image-"+activeFileObjIndex} src={activeFileObj.signedURL} class="modal-preview-image" crossOrigin="Anonymous" onClick={(e)=> viewAllImages(e)}/>
                  </div>
                </div>
              }
              {documentTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop()) &&
                <div class="file-type-docs h-full" style="overflow:auto;" id="preview-image-container">
                  {
                    (!isMobileFileViewerOpen && selectedPreviewImageIndex > 0) && (
                      <img class="file-viewer-docs-arrows left-arrow" src="/assets/images/Navigation-Arrow-left-hover.svg" onClick={() => goToPreviousSlide()} title="Go to previous slide" />
                    )
                  }
                  <div class="pdf-slides h-full" id="pdf-slides">
                    <div class="file-viewer-docs" id="content-container-preview">
                      <div>
                        {pdfSignedURL.map((data, index) => (<img src={data} style="max-width:100%" id={`processedPreviewImage-${index}`} />))}
                      </div>
                    </div>
                  </div>
                  {
                    (!isMobileFileViewerOpen && ((pdfSignedURL.length - 1) > selectedPreviewImageIndex)) && (
                      <img class="file-viewer-docs-arrows right-arrow" src="/assets/images/Navigation-Arrow-right-hover.svg" onClick={() => goToNextSlide()} title="Go to next slide" />
                    )
                  }
                </div>
              }
              {videoTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop())&&
                <div class="file-type-docs file-viewer-docs h-full">
                  <div id="playerdivmobile" class="h-full w-full" />
                </div>
              }
              {audioTypeExtension.includes(activeFileObj && activeFileObj.name && activeFileObj.name.split('.').pop()) &&
                <div class=".plyr__video-wrapper position-relative w-full" style='background: #000; height: 35vh;'>
                  <canvas id='canvas_player_mobile' class="audio-canvas-player" height='150' width='300' />
                  <audio id="audio_player_mobile" style='width: 50%;position: absolute; bottom: 5px; left: 25%;' controls src={activeFileObj.signedURL} />
                </div>
              }
              {isNoFileForDeletion && (!activeFileObj || !activeFileObj.name || activeFileObj.name === '') &&
                <div class="file-type-docs file-viewer-docs h-full display-flex justify-center align-center" style="height: 100% !important;">
                  {/*
                    Modification: Reworded s to S in selected
                    By: Devang
                    Date: 24/07/2023
                  */}
                  <p>No Files Selected</p>
                </div>
              }
              {(uploadedfile && uploadedfile.length === 0) &&
                <div class="file-type-docs file-viewer-empty-message h-full display-flex justify-center align-center" style="height: 100% !important;">
                  <p>No Files to Display</p>
                </div>
              }
            </div>
            <div class="file-list-footer display-flex justify-between">
              <div class="flex-item display-flex">
                {/*!isUploadHidden &&
                  <div class="button-icon-container flex-item display-flex m-l-6 m-r-8">
                    <input type="file" id="file-upload" required style="display: none;" onChange={(e) => uploadBrowsedReferenceFile(e)} multiple />
                    <label for="file-upload" class="display-flex cursor-pointer font-300" title="Upload file(s)">
                      <svg class="button-icon flex-item" title="Upload File" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.28223 2.68234V13.8823" stroke="#757575" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M2.68237 8.2825H13.8824" stroke="#757575" stroke-miterlimit="10" stroke-linecap="round" />
                      </svg>
                    </label>
                  </div>
                }
                {(uploadedfile && uploadedfile.length > 0) &&
                  <div title="Download file(s)" onClick={(e) => downloadFiles(e)} class="button-icon-container flex-item display-flex m-r-8">
                    <svg class="button-icon flex-item" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13268 2.51843C8.05627 2.44758 8.98466 2.58159 9.84762 2.91072C10.7106 3.23987 11.4862 3.7558 12.115 4.42047C12.6573 4.99376 13.0779 5.66469 13.3536 6.39481H14.4463C15.3135 6.40614 16.1562 6.6843 16.8527 7.19127C17.5493 7.69839 18.0643 8.40901 18.3201 9.22234C18.576 10.0359 18.5585 10.9076 18.2703 11.7106C17.9821 12.5135 17.4393 13.2038 16.7232 13.6841C16.4939 13.838 16.1833 13.7767 16.0294 13.5474C15.8756 13.3181 15.9368 13.0075 16.1662 12.8536C16.7083 12.49 17.1145 11.9707 17.329 11.3728C17.5436 10.7751 17.5565 10.1275 17.3662 9.52239C17.1758 8.91711 16.791 8.38326 16.2641 7.99974C15.7379 7.61667 15.098 7.4041 14.4364 7.39481H12.9962C12.7764 7.39481 12.5823 7.25125 12.518 7.04104C12.2985 6.32307 11.9126 5.66171 11.3885 5.10767C10.8644 4.55357 10.2157 4.12138 9.49126 3.84507C8.76675 3.56875 7.98621 3.45589 7.20917 3.5155C6.43214 3.57511 5.68011 3.80552 5.01023 4.18844C4.3404 4.57132 3.77081 5.0963 3.34377 5.72247C2.91678 6.34856 2.64329 7.05965 2.54303 7.80165C2.44278 8.54362 2.51822 9.29813 2.76395 10.0082C3.00971 10.7183 3.41965 11.3662 3.96386 11.9021C4.16062 12.0958 4.16306 12.4124 3.96931 12.6092C3.77556 12.8059 3.45899 12.8084 3.26223 12.6146C2.60971 11.9721 2.11571 11.1928 1.81894 10.3352C1.52216 9.47765 1.43076 8.56528 1.55204 7.66775C1.67331 6.77024 2.00388 5.91232 2.51761 5.15903C3.0313 4.40582 3.71436 3.77733 4.51397 3.32026C5.31352 2.86322 6.20909 2.58928 7.13268 2.51843Z" fill="#454545" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0293 9.50391C10.3054 9.50391 10.5293 9.72776 10.5293 10.0039V16.9998C10.5293 17.276 10.3054 17.4998 10.0293 17.4998C9.75315 17.4998 9.5293 17.276 9.5293 16.9998V10.0039C9.5293 9.72776 9.75315 9.50391 10.0293 9.50391Z" fill="#454545" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.46226 13.5426C6.65445 13.3443 6.971 13.3394 7.16928 13.5316L10.0292 16.3036L12.8891 13.5316C13.0874 13.3394 13.404 13.3443 13.5961 13.5426C13.7883 13.7409 13.7834 14.0575 13.5851 14.2497L10.3772 17.359C10.1833 17.5469 9.87513 17.5469 9.68121 17.359L6.4733 14.2497C6.27501 14.0575 6.27007 13.7409 6.46226 13.5426Z" fill="#454545" />
                    </svg>
                  </div>
                }
                {((activeFileObj && activeFileObj.uuid && !activeFileObj.isDeleteDisabled)&& (!isDeleteHidden)) &&
                  <div title="Delete file" class="button-icon-container flex-item display-flex m-r-8">
                    <svg class="button-icon flex-item" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                      onClick={(e) => toggleDeleteSingleFile(e)}
                    >
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2667 4.29469H3.73333V13.0526C3.73333 13.4246 4.0517 13.7263 4.44444 13.7263H11.5555C11.9483 13.7263 12.2667 13.4246 12.2667 13.0526V4.29469ZM3.02222 3.621V13.0526C3.02222 13.7967 3.65897 14.4 4.44444 14.4H11.5555C12.341 14.4 12.9778 13.7967 12.9778 13.0526V3.621H3.02222Z" fill="#757575" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59998 3.95784C1.59998 3.77181 1.75078 3.621 1.93682 3.621H14.0631C14.2492 3.621 14.4 3.77181 14.4 3.95784C14.4 4.14388 14.2492 4.29469 14.0631 4.29469H1.93682C1.75078 4.29469 1.59998 4.14388 1.59998 3.95784Z" fill="#757575" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42218 2.27369H6.57774C6.185 2.27369 5.86663 2.57531 5.86663 2.94737V3.62106H10.1333V2.94737C10.1333 2.57531 9.81492 2.27369 9.42218 2.27369ZM6.57774 1.60001C5.79227 1.60001 5.15552 2.20324 5.15552 2.94737V4.29474H10.8444V2.94737C10.8444 2.20324 10.2077 1.60001 9.42218 1.60001H6.57774Z" fill="#757575" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57772 5.97903C6.77409 5.97903 6.93328 6.12984 6.93328 6.31588V11.7053C6.93328 11.8914 6.77409 12.0422 6.57772 12.0422C6.38136 12.0422 6.22217 11.8914 6.22217 11.7053V6.31588C6.22217 6.12984 6.38136 5.97903 6.57772 5.97903Z" fill="#757575" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42208 5.97916C9.61845 5.97916 9.77764 6.12997 9.77764 6.316V11.7055C9.77764 11.8915 9.61845 12.0423 9.42208 12.0423C9.22572 12.0423 9.06653 11.8915 9.06653 11.7055V6.316C9.06653 6.12997 9.22572 5.97916 9.42208 5.97916Z" fill="#757575" />
                    </svg>
                  </div>
                */}
                {(activeFileObj && activeFileObj.name) &&
                  <button title="Download file" onClick={downloadContent.bind(this, activeFileObj)} class="primary-button-1 flex-c-m min-w-24px-imp h-24px m-l-8">
                    Download
                  </button>
                }
                {/*((activeFileObj && activeFileObj.name && !activeFileObj.isDeleteDisabled)) && (!isDeleteHidden) &&
                  <button title="Delete file" class="secondary-button flex-c-m min-w-24px-imp w-24px h-24px p-0 m-l-8" onClick={(e) => toggleDeleteSingleFile(e)}>
                    <svg class="file-viewer-button-icon flex-item" width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2667 4.29469H3.73333V13.0526C3.73333 13.4246 4.0517 13.7263 4.44444 13.7263H11.5555C11.9483 13.7263 12.2667 13.4246 12.2667 13.0526V4.29469ZM3.02222 3.621V13.0526C3.02222 13.7967 3.65897 14.4 4.44444 14.4H11.5555C12.341 14.4 12.9778 13.7967 12.9778 13.0526V3.621H3.02222Z" fill="#000" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59998 3.95784C1.59998 3.77181 1.75078 3.621 1.93682 3.621H14.0631C14.2492 3.621 14.4 3.77181 14.4 3.95784C14.4 4.14388 14.2492 4.29469 14.0631 4.29469H1.93682C1.75078 4.29469 1.59998 4.14388 1.59998 3.95784Z" fill="#000" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42218 2.27369H6.57774C6.185 2.27369 5.86663 2.57531 5.86663 2.94737V3.62106H10.1333V2.94737C10.1333 2.57531 9.81492 2.27369 9.42218 2.27369ZM6.57774 1.60001C5.79227 1.60001 5.15552 2.20324 5.15552 2.94737V4.29474H10.8444V2.94737C10.8444 2.20324 10.2077 1.60001 9.42218 1.60001H6.57774Z" fill="#000" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57772 5.97903C6.77409 5.97903 6.93328 6.12984 6.93328 6.31588V11.7053C6.93328 11.8914 6.77409 12.0422 6.57772 12.0422C6.38136 12.0422 6.22217 11.8914 6.22217 11.7053V6.31588C6.22217 6.12984 6.38136 5.97903 6.57772 5.97903Z" fill="#000" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42208 5.97916C9.61845 5.97916 9.77764 6.12997 9.77764 6.316V11.7055C9.77764 11.8915 9.61845 12.0423 9.42208 12.0423C9.22572 12.0423 9.06653 11.8915 9.06653 11.7055V6.316C9.06653 6.12997 9.22572 5.97916 9.42208 5.97916Z" fill="#000" />
                    </svg>
                  </button>
                */}
              </div>
              {/*
                By:Yash
                Date:12th April 2022
                Modification:Hid 3 Dots
                */}
              {/*<div class="button-icon-container flex-item display-flex m-r-8">
                <svg class="flex-item button-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="7.19995" y="4" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                  <rect x="7.19995" y="7.20001" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                  <rect x="7.19995" y="10.4" width="1.6" height="1.6" rx="0.8" fill="#757575"/>
                </svg>
              </div>*/}
            </div>
          </div>
        )
      }
      {isDeleteSound && (
        <audio autoplay>
          <source src="/assets/static/soundFiles/Delete_Sound.mp3" />
        </audio>
      )
      }
    </div>
  );
};
export default FileViewer;
