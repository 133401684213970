import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Vas2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" class={props.classes || ''} width={props.width || "20"} height={props.width || "20"} fill={props.fill || "none"}  viewBox="0 0 20 20"  >
        <path d="M3.39203 11C2.60374 10.4972 2.26642 10.4997 1.95681 11L1 12.5L4.82724 16.5L6.26246 15.5C6.60118 15.2561 6.7865 14.6279 6.26246 14M3.39203 11C4.66494 8.91129 5.68119 8.39499 8.17608 8.99997C9.67152 9.18277 11.0465 8.99994 12.0033 8.99997C12.9601 8.99999 13.4385 9.26837 13.4385 9.99997C13.4385 10.7316 12.7258 11.0656 12.0033 11H9.6113M3.39203 11L6.26246 14M6.26246 14C6.91321 13.5144 7.34465 13.0057 8.65449 13H11.5249C12.7735 13.0421 13.4167 12.8732 14.3953 12L16.7874 8.49999C17.2658 8 16.7874 7.49998 16.7874 7.49998C16.309 7.00001 15.8306 7.00001 15.3522 7.49997C15.3522 7.49997 14.1859 8.71892 13.4385 9.49997" stroke={props.stroke || "#3C3C3C"} />
        <circle cx="9" cy="3.5" r="2.75" stroke={props.stroke || "#3C3C3C"} stroke-width="0.5" />
        <path d="M8 2H10" stroke={props.stroke || "#3C3C3C"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 2.83325H10" stroke={props.stroke || "#3C3C3C"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 3.66675L9.41667 5.00008" stroke={props.stroke || "#3C3C3C"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 3.66675H8.5" stroke={props.stroke || "#3C3C3C"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.5 3.66667C9.61117 3.66667 9.61117 2 8.5 2" stroke={props.stroke || "#3C3C3C"} stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
  );
};

export default Vas2;
