import { h } from 'preact';

// from flaticon
const ReplyPending = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512.000000pt"} height={props.height || "512.000000pt"}
	viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform={props.transform || "translate(0.000000,512.000000) scale(0.100000,-0.100000)"} fill={props.fill || "#000000"} stroke="none">
          <path d="M1810 5097 c-25 -13 -58 -43 -75 -66 l-30 -43 -3 -183 -3 -184 -382 -3 c-405 -4 -407 -5 -549 -58 -77 -29 -191 -104 -261 -175 -104 -101 -171 -221 -215 -380 -16 -55 -17 -199 -17 -1695 0 -1785 -4 -1656 55 -1812 66 -177 261 -372 438 -438 150 -56 119 -54 797 -58 460 -2 641 0 672 9 84 23 152 124 140 210 -8 58 -54 128 -102 153 -39 20 -55 21 -670 26 l-630 5 -67 32 c-93 44 -151 100 -196 191 l-37 76 0 1605 0 1606 32 67 c44 93 100 151 191 196 l76 37 363 3 363 3 0 -88 c0 -173 58 -313 179 -433 87 -87 164 -131 277 -160 205 -53 411 6 564 159 121 122 179 265 180 444 l0 78 363 -3 362 -3 67 -32 c76 -36 155 -108 187 -171 49 -96 51 -124 51 -641 0 -474 0 -486 21 -529 41 -84 143 -129 232 -102 59 17 94 47 122 104 l25 50 0 490 c0 516 -4 568 -46 696 -70 211 -259 407 -465 484 -135 51 -191 56 -569 56 l-349 0 -3 178 -3 179 -30 48 c-19 30 -45 56 -70 69 -39 20 -56 21 -490 24 l-451 3 -44 -24z m690 -695 l0 -318 -25 -50 c-28 -57 -63 -87 -122 -104 -89 -27 -191 18 -232 102 -20 42 -21 58 -21 366 l0 322 200 0 200 0 0 -318z" />
          <path d="M3460 2405 c-331 -54 -630 -247 -815 -524 -274 -412 -274 -930 1 -1342 340 -512 1010 -685 1558 -401 581 300 818 1011 532 1598 -66 135 -146 243 -257 350 -162 155 -349 255 -569 305 -114 26 -336 33 -450 14z m332 -399 c310 -61 544 -271 634 -571 37 -124 40 -310 6 -430 -77 -268 -268 -474 -520 -559 -444 -151 -913 96 -1044 551 -32 112 -32 314 0 426 68 235 226 421 445 522 152 70 321 92 479 61z" />
          <path d="M3560 1798 c-25 -14 -58 -44 -75 -67 l-30 -43 -3 -261 c-3 -259 -3 -263 21 -307 13 -25 43 -58 66 -75 l43 -30 186 -3 c211 -4 254 3 306 54 72 70 83 165 29 252 -37 60 -88 86 -180 90 l-71 4 -4 132 c-3 127 -4 135 -33 181 -54 87 -167 119 -255 73z" />
        </g>
      </svg>
    </span>
  );
};

export default ReplyPending;
