/*
  By: Prasannadatta Kawadkar
  On: 16 Jan 2024
  Modification: created file for navbarV2 Component
*/
import { h, Component, Fragment } from 'preact';
import { useState, useEffect } from "preact/hooks";
import axios from "axios";
import { route } from "preact-router";
import { getItem, removeAll } from '../../lib/myStore';
import CONSTANTS from '../../lib/constants';
import { Home, Dashboard, RetailV2, Hyundai, BookingV2, EnquiryV2, LeadV2, CaseV2, ContactsV2, AllV2, RenewalLeadV2, QuotationV2, PolicyPurchaseV2, PaymentV2, CompletedV2, DeliveryV2, Lost, Endorsement, Clipboard, ChatIcon, Stock , Cart, Tracker, Generate, Administration, Orgchart, InteractionSearchSuggestion, AccessControl, Documents, CaseStatusDashboard, Report } from '../../components/svgs';
import io from 'socket.io-client';

const NavbarV2 = (props) => {
  let userInfo = getItem('userinfo');
  let designationForRenewalMapper = ["accounts executive", "pick-up executive", "insurance agent", "insurance team lead", "insurance head", "back-office executive"]

  let designationForCRMMapper = ["audit manager", "h promise manager", "claims executive", "accessories incharge", "general manager", "sr.team leader", "sales consultant", "store keeper", "sales manager", "sales head", "billing executive", "showroom hostess", "finance consultant", "training & development manager", "sr.accountant", "data analyst", "delivery host", "cashier", "customer care executive", "finance manager", "corporate manager", "asst.sales manager", "team leader- corporate", "supervisor", "sr.sales consultant", "stockyard incharge", "marketing manager", "testing", "sr.finance consultant", "rto executive", "logistics executive", "team leader", "accessories manager", "accessories fitter", "audit executive", "asst.general manager-accessories", "sourcing executive", "accessories billing executive", "accounts", "accounts manager", "telecaller", "service advisor", "audit assistant", "internal auditor", "customer care executive", "accessories supervisor", "accountant", "accounts assistant", "branch account manager"]

  let designationForBothCRMAndRenewalMapper = ["gm - hyundai advantage", "branch manager"]
  let designationForReceptionistMapper = ["receptionist", "customer care manager"]
  let designationForLogisticsMapper = ["sales co-ordinator"]

  // const socket = io(`${CONSTANTS.SOCKETAPI_URL}`);
  const { panelActive, showLeftPanel, setShowLeftPanel, setShowMobileFooter } = props;
  // const pathname = window.location.pathname;
  let [pathName, setPathName] = useState(window.location.pathname);
  let [showPanel, setShowPanel] = useState(false);
  let [showNotification, setShowNotification] = useState(false);
  /*
    modified : Ashutosh G
    modified : 22/03/2024
    modification : set default valut CRM to navHeader
  */
  let [userDesignation, setUserDesignation] = useState(userInfo.userDesignation ? userInfo.userDesignation : '');
  {/*
    Bug: T1839 - BG_UI - Insurance: Contact/Approval/My Approval: Sales panel is loading for insurance agent.
    Modification: Add new condition for Renewal in navHeader.
    By: Arun Singh
    Date: 03/06/2024
  */}
  let [navHeader, setNavHeader] = useState(((userInfo && userInfo.isSiloAdmin) || (designationForLogisticsMapper.includes(userDesignation.toLowerCase()))) ? 'Home': designationForRenewalMapper.includes(userDesignation.toLowerCase()) ? 'Renewal' : 'CRM');
  const reportRegexPattern = /^\/reports(\/[^\/]+)?$/;

  useEffect(() => {
    setPathName(window.location.pathname);
  },[window.location.pathname]);

  useEffect(() => {
    if (pathName === '/homeV2') {
      setNavHeader('Home');
    }
    else if (pathName === '/collaborator') {
      setNavHeader('CRM');
    }
    else if (pathName === '/salesCRMV2') {
      setNavHeader('CRM');
    }
    else if (getBasePath(pathName) === '/salesCRM') {
      setNavHeader('CRM');
    }
    else if (pathName === '/insuranceRenewalV2') {
      setNavHeader('Renewal');
    }
    else if (getBasePath(pathName) === '/insuranceRenewal') {
      setNavHeader('Renewal');
    }
    else if (pathName === '/home') {
      setNavHeader('Renewal');
    }
    /*
      modified : Ashutosh G
      modified : 11/06/2024
      modification : reports if added for if refresh then navHeader should stays as renewal
    */
    else if (reportRegexPattern.test(pathName)) {
      setNavHeader('Renewal');
    }
  },[pathName]);

  function getBasePath(url) {
    const parts = url.split('/');
    if (parts.length >= 2) {
      return `/${parts[2]}`;
    } else {
      return '/';
    }
  }

  const logout = async (e) => {
    e.preventDefault();
    let res = await axios.post(`${CONSTANTS.API_URL}/api/v1/logout`);
    let email = getItem('userinfo').email;
    await axios.put(`${CONSTANTS.API_URL}/api/v1/user/pageVisitUserInformation`, { action: 'logout', typeOfVisit: 'success', email });
    if (res) {
      await removeAll();
      await route(`/`);
    }
  };

  const reRoute = async () => {
    if (navHeader === 'CRM') {
      route('/salesCRMV2');
    } else if (navHeader === 'Renewal') {
      route('/insuranceRenewalV2');
    } else if (navHeader === 'Home') {
      route('/organization');
    }
  };

  return (
    <Fragment>
      <div class={`${showLeftPanel ? 'leftPanel-overlay' : 'display-none'}`}
        onClick={()=> setShowLeftPanel(false)}
      />
      {/*
        BY: Prasannadatta Kawadkar
        ON: 13 March 2024
        Modification: color changed for the leftInfo Panel and Right Panel
      */}
      <div style="border-right: 2px solid #04364A;" class="leftInfoPanel-tab p-t-4 isDesktop">
        {
          designationForRenewalMapper.includes(userDesignation.toLowerCase()) ?
            <div title='Kothari Hyundai' class={'leftInfoPanel-menu-icon p-t-10 h-50px-imp'} onClick={() => {
              setPathName(`/home`);
              route(`/home`);}}
            >
              <div>
                <Hyundai fill="white" height="32px" width="32px" fill={pathName === "/home" ? 'white' : 'white'} />
              </div>
            </div>
          :
            /*
              modified : Ashutosh G
              modified : 22/03/2024
              modification : path change from /homeV2 to salesHomeV2 when clicked on logo
            */
            <div title='Kothari Hyundai' class={'leftInfoPanel-menu-icon p-t-10'} onClick={() => {
              setPathName(`/salesHomeV2`);
              route(`/salesHomeV2`);}}
            >
              <div>
                <Hyundai fill="white" height="32px" width="32px" fill={pathName === "/salesHomeV2" ? 'white' : 'white'} />
              </div>
            </div>
        }
        {/*
          <div title={navHeader} class={'leftInfoPanel-menu-icon h-54'} style='border: 1px lightgrey; border-style: solid none' onClick={() => reRoute()}>
            <div class='menu-name flex-c-m fs-13'>{navHeader}</div>
          </div>
        */}
        {
          navHeader === 'Home' &&
          <>
            {
              (designationForCRMMapper.includes(userDesignation.toLowerCase()) || designationForBothCRMAndRenewalMapper.includes(userDesignation.toLowerCase())) &&
              <>
                <div title='Sales CRM' class={pathName === "/salesCRMV2" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                  setPathName(`/salesCRMV2`);
                  route(`/salesCRMV2`);}}
                >
                  <div>
                    <Home fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRMV2" ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>CRM</div>
                  </div>
                </div>
              </>
            }
            {
              (designationForRenewalMapper.includes(userDesignation.toLowerCase()) || designationForBothCRMAndRenewalMapper.includes(userDesignation.toLowerCase())) &&
              <>
                <div title='Ins Renewal' class={pathName === "/insuranceRenewalV2" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                  setPathName(`/insuranceRenewalV2`);
                  route(`/insuranceRenewalV2`);}}
                >
                  <div>
                    <Home fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewalV2" ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Renewal</div>
                  </div>
                </div>
              </>
            }
            {
              designationForBothCRMAndRenewalMapper.includes(userDesignation.toLowerCase()) &&
              <>
                <div title='Logistics' class={pathName === "/logisticsV2" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  /*onClick={() => {
                    setPathName(`/logisticsV2`);
                    route(`/logisticsV2`);}}*/
                >
                  <div>
                    <Home fill="white" height="22px" width="22px" fill={pathName === "/logisticsV2" ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Logistics</div>
                  </div>
                </div>

                <div title='Admin' class={pathName === "/adminV2" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  /*onClick={() => {
                    setPathName(`/adminV2`);
                    route(`/adminV2`);}}*/
                >
                  <div>
                    <Home fill="white" height="22px" width="22px" fill={pathName === "/adminV2" ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Admin</div>
                  </div>
                </div>

                <div title='HR' class={pathName === "/hrV2" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  /*onClick={() => {
                    setPathName(`/hrV2`);
                    route(`/hrV2`);}}*/
                >
                  <div>
                    <Home fill="white" height="22px" width="22px" fill={pathName === "/hrV2" ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>HR</div>
                  </div>
                </div>
              </>
            }
            {
              designationForReceptionistMapper.includes(userDesignation.toLowerCase()) &&
              <>
                <div title='Workspace' class={((pathName === "/workspace") || (pathName === "/workspacedetails/dayPlanWorkspace")) ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/workspace`);
                  route(`/workspace`);}}
                  >
                  <div>
                    <Home fill="white" height="22px" width="22px" fill={((pathName === "/workspace") || (pathName === "/workspacedetails/dayPlanWorkspace")) ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Workspace</div>
                  </div>
                </div>
              </>
            }
            {
              designationForLogisticsMapper.includes(userDesignation.toLowerCase()) &&
              <>
                <div title='Stock' class={(pathName === "/stockDashboard") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/stockDashboard`);
                  route(`/stockDashboard`);}}
                  >
                  <div>
                    <Stock fill="white" height="22px" width="22px" fill={((pathName === "/workspace") || (pathName === "/workspacedetails/dayPlanWorkspace")) ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Stock</div>
                  </div>
                </div>
                <div title='Orders' class={(pathName === "/orderDashboard") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/orderDashboard`);
                  route(`/orderDashboard`);}}
                  >
                  <div>
                    <Cart fill="white" height="22px" width="22px" fill={((pathName === "/workspace") || (pathName === "/workspacedetails/dayPlanWorkspace")) ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Orders</div>
                  </div>
                </div>
              </>
            }
            {
              userInfo && userInfo.isSiloAdmin &&
              <>
                <div title='Organization' class={(pathName === "/organization") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/organization`);
                  route(`/organization`);}}
                  >
                  <div>
                    <Orgchart fill="white" height="22px" width="22px" fill={(pathName === "/organization") ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Org</div>
                  </div>
                </div>
                <div title='Scheduler Dashboard' class={(pathName === "/schedulerDashboard") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/schedulerDashboard`);
                  route(`/schedulerDashboard`);}}
                  >
                  <div>
                    <Generate fill="white" height="22px" width="22px" fill={(pathName === "/schedulerDashboard") ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Scheduler</div>
                  </div>
                </div>
                <div title='Config Manager' class={(pathName === "/processMapping") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/processMapping`);
                  route(`/processMapping`);}}
                  >
                  <div>
                    <InteractionSearchSuggestion height="22px" width="22px" viewBox="0 0 18 18" stroke={pathName === "/processMapping" ? '#06141f' : '#fff'} />
                    <div class='menu-name flex-c-m'>Config</div>
                  </div>
                </div>
                <div title='Administration' class={(pathName === "/siloAdministration") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/siloAdministration`);
                  route(`/siloAdministration`);}}
                  >
                  <div>
                    <Administration fill="white" height="22px" width="22px" fill={(pathName === "/siloAdministration") ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Admin</div>
                  </div>
                </div>
                <div title='Access Control' class={(pathName === "/aclPermissions") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/aclPermissions`);
                  route(`/aclPermissions`);}}
                  >
                  <div>
                    <AccessControl fill="white" height="22px" width="22px" fill={(pathName === "/aclPermissions") ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>ACL</div>
                  </div>
                </div>
                <div title='Project Tracker' class={(pathName === "/projectTracker") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                  setPathName(`/projectTracker`);
                  route(`/projectTracker`);}}
                  >
                  <div>
                    <Tracker fill="white" height="22px" width="22px" fill={(pathName === "/projectTracker") ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Project Tracker</div>
                  </div>
                </div>
              </>
            }
          </>
        }
        {/*
          By: Yashvi
          On: 9th July 2024
          T1929: BG_FN - Home Tab Redirects to Sales Workspace Instead of Receptionist Workspace
          Modification: If logged in user is receptionist, redirect to /workspace
        */}
        {
          navHeader === 'CRM' &&
          <>
            <div title='Home' class={["/salesHomeV2", "/workspace"].includes(pathName) ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              if (userInfo && userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === 'receptionist') {
                setPathName(`/workspace`);
                route(`/workspace`);
              } else {
                setPathName(`/salesHomeV2`);
                route(`/salesHomeV2`);
              }
            }}
            >
              <div>
                <Home fill="white" height="22px" width="22px" fill={["/salesHomeV2", "/workspace"].includes(pathName) ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Home</div>
              </div>
            </div>
            {/*
              Bug: T1692 - 45 - Desktop - Chat tab from the left navigation panel - The active icon gets disappeared when we apply the collaborator filter
              Modification: Changed condition for setting collaborator icon active
              By: Devang
              Date: 13/04/2024
            */}
            <div title='Collaborator' class={pathName.includes('/collaborator') ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/collaborator`);
              route(`/collaborator`);}}
            >
              <div>
                {/*
                  modified : Ashutosh G
                  modified : 22/03/2024
                  modification : path icon size changed to 25px
                */}
                <ChatIcon fill="white" height="25px" width="25px" stroke='1.5' fill={pathName.includes('/collaborator') ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Chat</div>
              </div>
            </div>
            <div title='Digital' class={pathName.includes('/digitalAllocation') ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/digitalAllocation`);
              route(`/digitalAllocation`);}}
            >
              <div>
                <InteractionSearchSuggestion height="22px" width="22px" viewBox="0 0 18 18" stroke={pathName === "/digitalAllocation" ? '#06141f' : '#fff'} />
                <div class='menu-name flex-c-m'>Digital</div>
              </div>
            </div>
            {/*
              modified : Ashutosh G
              modified : 19/03/2024
              modification : title change to CRM Cases and pathName.includes("/salesCRM/cases") condition added beacause of dynamic parameter
              By: Yashvi
              On: 26th March 2024
              Point 17: history.back() does not route back to CRM page
              Route it to the exact link rather than salesCRM/cases
            */}
            <div title='CRM Cases' class={pathName.includes("/salesCRM/cases") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/salesCRM/cases`);
              route(`/salesCRM/cases/Lead/New`);}}
            >
              <div>
                <CaseV2 fill="white" height="22px" width="22px" fill={pathName.includes("/salesCRM/cases") ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>CRM</div>
              </div>
            </div>
            {/*
              modified : Ashutosh G
              modified : 19/03/2024
              modification : title change to Back Office Cases and icon changed to Documents and truncate class added on menu name and pathName.includes("/salesBackOffice/cases") condition added beacause of dynamic parameter
            */}
            {/*
              Bug: T1687 - 39 - CRM Dashboard - Back Arrow should redirect to Home
              Modification: Updated route to value
              By: Devang
              Date: 11/04/2024
            */}
            <div title='Back Office Cases' class={pathName.includes("/salesBackOffice/cases") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/salesBackOffice/cases`);
              route(`/salesBackOffice/cases/Booking/Booked`);}}
            >
              <div>
                <Documents fill="white" height="22px" width="22px" fill={pathName.includes("/salesBackOffice/cases") ? 'black' : 'white'} />
                <div class='truncate menu-name flex-c-m'>Back Office</div>
              </div>
            </div>

            <div title='My Tasks' class={pathName === "/myTasks" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/myTasks`);
              route(`/myTasks`);}}
            >
              <div>
                <AllV2 fill="white" height="22px" width="22px" fill={pathName === "/myTasks" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>My Tasks</div>
              </div>
            </div>

            <div title='Sales Followups' class={pathName.split('/')[1] === "salesFollowups" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/salesFollowups`);
              route(`/salesFollowups`);}}
            >
              <div>
                <AllV2 fill="white" height="22px" width="22px" fill={pathName.split('/')[1] === "salesFollowups" ? 'black' : 'white'} />
                {/*
                  modified : Ashutosh G
                  modified : 21/03/2024
                  modification : truncate class added
                */}
                <div class='truncate menu-name flex-c-m'>Sales Followups</div>
              </div>
            </div>

            <div title='Contacts' class={pathName === "/contact" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/contact`);
              route(`/contact`);}}
            >
              <div>
                <ContactsV2 fill="white" height="22px" width="22px" fill={pathName === "/contact" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Contacts</div>
              </div>
            </div>

            {/*<div title='All Tasks' class={pathName === "/CRM/salesCRM/all/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/all/noFilter`);
              route(`/CRM/salesCRM/all/noFilter`);}}
            >
              <div>
                <AllV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/all/noFilter" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Tasks</div>
              </div>
            </div>*/}
{/*
            <div title='Lead' class={pathName === "/CRM/salesCRM/leads/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/leads/noFilter`);
              route(`/CRM/salesCRM/leads/noFilter`);}}
            >
              <div>
                <LeadV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/leads/noFilter" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Lead</div>
              </div>
            </div>

            <div title='Enquiry' class={pathName === "/CRM/salesCRM/enquiry/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/enquiry/noFilter`);
              route(`/CRM/salesCRM/enquiry/noFilter`);}}
            >
              <div>
                <EnquiryV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/enquiry/noFilter" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Enquiry</div>
              </div>
            </div>

            <div title='Booking' class={pathName === "/CRM/salesCRM/booking/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/booking/noFilter`);
              route(`/CRM/salesCRM/booking/noFilter`);}}
            >
              <div>
                <BookingV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/booking/noFilter" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Booking</div>
              </div>
            </div>

            <div title='Retail' class={pathName === "/CRM/salesCRM/retail/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/retail/noFilter`);
              route(`/CRM/salesCRM/retail/noFilter`);}}
            >
              <div>
                <RetailV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/retail/noFilter" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Retail</div>
              </div>
            </div>

            <div title='Delivery' class={pathName === "/CRM/salesCRM/delivery/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/delivery/noFilter`);
              route(`/CRM/salesCRM/delivery/noFilter`);}}
            >
              <div>
                <DeliveryV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/delivery/noFilter" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Delivery</div>
              </div>
            </div> */}

            {/*<div title='Completed' class={pathName === "/CRM/salesCRM/completed" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/salesCRM/completed`);
              route(`/CRM/salesCRM/completed`);}}
            >
              <div>
                <Home fill="white" height="22px" width="22px" fill={pathName === "/CRM/salesCRM/completed" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Completed</div>
              </div>
            </div>*/}
          </>
        }
        {
          navHeader === 'Renewal' &&
          <>
            <div title='Home' class={pathName === "/home" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/home`);
              route(`/home`);}}
            >
              <div>
                <Home fill="white" height="22px" width="22px" fill={pathName === "/home" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Home</div>
              </div>
            </div>

            <div title='Dashboard' class={pathName === "/insuranceRenewalV2" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/insuranceRenewalV2`);
              route(`/insuranceRenewalV2`);}}
            >
              <div>
                <Dashboard fill="white" height="22px" width="22px" fill={pathName === "/insuranceRenewalV2" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Dashboard</div>
              </div>
            </div>

            {/*
              Bug: T1814 - BG_FN: Insurance - Insurance Renewal Workspace page - Tasks and Cases - Missing Integration for New List View
              Modification: Commented cases and tasks paths of insurance module
              By: Devang
              Date: 31/05/2024
            */}
            {/*<div title='All Tasks' class={pathName.split('/').slice(0, 4).join('/') === "/CRM/insuranceRenewal/all" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/insuranceRenewal/all/todayAppointment`);
              route(`/CRM/insuranceRenewal/all/todayAppointment`);}}
            >
              <div>
                <AllV2 fill="white" height="22px" width="22px" fill={pathName.split('/').slice(0, 4).join('/') === "/CRM/insuranceRenewal/all" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Tasks</div>
              </div>
            </div>

            <div title='Cases' class={pathName === "/CRM/insuranceRenewal/cases" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/CRM/insuranceRenewal/cases`);
              route(`/CRM/insuranceRenewal/cases`);}}
            >
              <div>
                <CaseV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/cases" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Cases</div>
              </div>
            </div>*/}

            <div title='Contacts' class={pathName === "/contact" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/contact`);
              route(`/contact`);}}
            >
              <div>
                <ContactsV2 fill="white" height="22px" width="22px" fill={pathName === "/contact" ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Contacts</div>
              </div>
            </div>
            {/*
              By: Yashvi
              On: 5th March 2024
              T1559: Remove allocation button from accounts log in (Kdms Renewal)
              Only show action if userDesignation is insurance head
            */}
            {userInfo && userInfo.userDesignation && userInfo.userDesignation.toLowerCase() === 'insurance head' &&
              <>
                <div
                  title='Case Allocation'
                  class={pathName === "/caseManagement" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
                  onClick={() => {
                    setPathName('/caseManagement');
                    route(`/caseManagement`);
                  }}
                >
                  <div style="margin-bottom: -5px;">
                    <InteractionSearchSuggestion height="22px" width="22px" viewBox="0 0 18 18" stroke={pathName === "/caseManagement" ? '#06141f' : '#fff'} />
                  </div>
                  <div class='menu-name flex-c-m'>Allocation</div>
                </div>
                {/*
                  modified : Ashutosh G
                  modified : 11/06/2024
                  modification : reports route added and only available for insurance head
                */}
                <div title='Reports' class={reportRegexPattern.test(pathName) ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                  setPathName(`/reports`);
                  route(`/reports`);}}
                >
                  <div>
                    <Report fill="white" height="22px" width="22px" fill={reportRegexPattern.test(pathName) ? 'black' : 'white'} />
                    <div class='menu-name flex-c-m'>Reports</div>
                  </div>
                </div>
              </>
            }
            {/*
              Bug: T1823 - BG_FN: Insurance List View: My Approval and Aprroval Search is not working.
              Modification: Added restriction for insurance TL designation
              By: Devang
              Date: 05/05/2025
            */}
            {
              userInfo && !userInfo.isSiloAdmin && userInfo.userDesignation === "Insurance Team Lead" &&
              <div title='Approval' class={pathName === "/workspaceApprovalDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'} onClick={() => {
                setPathName('/workspaceApprovalDetails');
                route(`/workspaceApprovalDetails`);}}
              >
                <div style="margin-bottom: -5px;">
                  <Clipboard fill="black" height="22px" width="22px" fill={pathName === "/workspaceApprovalDetails" ? 'black' : 'white'} />
                </div>
                <div class='menu-name flex-c-m'>Approv.</div>
              </div>
            }
            {/*
              Bug: T1823 - BG_FN: Insurance List View: My Approval and Aprroval Search is not working
              Modification: Commented my approval details page as it is same as workspaceApprovalDetails as discussed by rutuja
              By: Devang
              Date: 04/05/2024
            */}
            {/*<div
              title='My Approvals'
              class={pathName === "/workspaceMyApprovalDetails" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
              onClick={() => {
                setPathName('/workspaceMyApprovalDetails');
                route(`/workspaceMyApprovalDetails`);
              }}
            >
              <div style="margin-bottom: -5px;">

                <Clipboard height="22px" width="22px" fill={pathName === "/workspaceMyApprovalDetails" ? '#06141f' : '#fff'} />
              </div>

              <div class='menu-name flex-c-m'>My Approv.</div>
            </div>*/}

            {/*
              Modification: added new route for case completion status
              By: Masum Raja
              Date: 09/05/2024
            */}

            <div title='Status' class={(pathName === "/dataStatusDashboard" || pathName === "/caseStatus/incompleted" || pathName === "/caseStatus/completed") ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
              setPathName(`/dataStatusDashboard`);
              route(`/dataStatusDashboard`);}}
            >
              <div>
                <CaseStatusDashboard fill="white" height="22px" width="22px" fill={(pathName === "/dataStatusDashboard" || pathName === "/caseStatus/incompleted" || pathName === "/caseStatus/completed") ? 'black' : 'white'} />
                <div class='menu-name flex-c-m'>Status</div>
              </div>
            </div>

            {/*

                <>
                  <div title='Renewal Lead' class={pathName === "/CRM/insuranceRenewal/renewalLead/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/renewalLead/noFilter`);
                    route(`/CRM/insuranceRenewal/renewalLead/noFilter`);}}
                  >
                    <div>
                      <RenewalLeadV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/renewalLead/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Lead</div>
                    </div>
                  </div>

                  <div title='Quotation' class={pathName === "/CRM/insuranceRenewal/quotation/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/quotation/noFilter`);
                    route(`/CRM/insuranceRenewal/quotation/noFilter`);}}
                  >
                    <div>
                      <QuotationV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/quotation/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Quotation</div>
                    </div>
                  </div>

                  <div title='Payment' class={pathName === "/CRM/insuranceRenewal/payment/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/payment/noFilter`);
                    route(`/CRM/insuranceRenewal/payment/noFilter`);}}
                  >
                    <div>
                      <PaymentV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/payment/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Payment</div>
                    </div>
                  </div>

                  <div title='Policy Purchase' class={pathName === "/CRM/insuranceRenewal/policyPurchase/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/policyPurchase/noFilter`);
                    route(`/CRM/insuranceRenewal/policyPurchase/noFilter`);}}
                  >
                    <div>
                      <PolicyPurchaseV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/policyPurchase/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Purchase</div>
                    </div>
                  </div>

                  <div title='Lost' class={pathName === "/CRM/insuranceRenewal/lost/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/lost/noFilter`);
                    route(`/CRM/insuranceRenewal/lost/noFilter`);}}
                  >
                    <div>
                      <Lost fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/lost/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Lost</div>
                    </div>
                  </div>

                  <div title='Endorsements' class={pathName === "/CRM/insuranceRenewal/endorsements/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/endorsements/noFilter`);
                    route(`/CRM/insuranceRenewal/endorsements/noFilter`);}}
                  >
                    <div>
                      <Endorsement fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/endorsements/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Endorse</div>
                    </div>
                  </div>

                  <div title='Completed' class={pathName === "/CRM/insuranceRenewal/completed/noFilter" ? 'leftInfoPanel-menu-icon-active' : 'leftInfoPanel-menu-icon'} onClick={() => {
                    setPathName(`/CRM/insuranceRenewal/completed/noFilter`);
                    route(`/CRM/insuranceRenewal/completed/noFilter`);}}
                  >
                    <div>
                      <CompletedV2 fill="white" height="22px" width="22px" fill={pathName === "/CRM/insuranceRenewal/completed/noFilter" ? 'black' : 'white'} />
                      <div class='menu-name flex-c-m'>Complete</div>
                    </div>
                  </div>
                </>

*/}
          </>
        }
        {/*
          By: Prasannadatta Kawadkar
          On: 6th April 2024
          Modification: added routes for ticketing
        */}
        {userInfo && userInfo.roleName && ['customer', 'support', 'developer', 'tester', 'manager'].includes(userInfo.roleName.toLowerCase()) &&
          <div
            title='Ticketing'
            class={pathName.split('/')[1] === "ticketing" ? 'leftInfoPanel-menu-icon-active ' : 'leftInfoPanel-menu-icon'}
            onClick={() => {
              setPathName(`/ticketing/${userInfo.roleName.toLowerCase() === 'manager' ? 'Unallocated' : 'Open'}`);
              route(`/ticketing/${userInfo.roleName.toLowerCase() === 'manager' ? 'Unallocated' : 'Open'}`);
            }}
          >
            <div>
              <Home fill="white" height="22px" width="22px" fill={pathName.split('/')[1] === "ticketing" ? 'black' : 'white'} />
            </div>
            <div class='menu-name flex-c-m'>Ticketing</div>
          </div>
        }
        <div title="Click to log out" class=" leftInfoPanel-logout cursor-pointer m-l-5 w-24px h-24px" onClick={(e) => logout(e)}>
          <em fill="white" class="cursor-pointer icon icon-sign-out w-24px h-24px text-white fs-24" />
        </div>
      </div>
    </Fragment>
  );
};

export default NavbarV2;
