import { h } from 'preact';
// from flaticon
const RoundStar = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M2330 4788 c-307 -29 -661 -146 -930 -308 -505 -303 -875 -788 -1020 -1334 -84 -316 -98 -568 -51 -892 134 -909 713 -1582 1573 -1830 551 -158 1161 -109 1673 135 743 354 1197 1068 1241 1951 21 412 -114 891 -356 1260 -467 713 -1275 1099 -2130 1018z m431 -328 c750 -76 1350 -526 1604 -1205 102 -273 153 -626 126 -880 -49 -460 -251 -873 -582 -1190 -157 -151 -298 -250 -494 -346 -905 -443 -2002 -119 -2525 746 -180 299 -283 689 -267 1015 27 532 227 999 571 1333 398 386 988 585 1567 527z"/>
          <path d="M2482 3813 c-18 -9 -41 -28 -51 -42 -10 -14 -82 -158 -161 -319 -109 -222 -149 -294 -164 -297 -12 -3 -163 -25 -336 -50 -173 -25 -327 -50 -343 -56 -66 -27 -107 -109 -92 -186 7 -42 15 -50 339 -361 l179 -172 -57 -335 c-38 -220 -56 -348 -52 -370 8 -40 47 -90 87 -110 66 -35 92 -26 423 147 307 160 310 161 337 145 16 -8 157 -82 314 -162 271 -139 289 -147 332 -142 78 9 143 77 143 150 0 22 -25 184 -56 359 l-56 320 247 241 c145 142 254 255 261 274 19 45 17 80 -7 129 -36 75 -49 79 -429 133 -188 27 -343 51 -345 53 -1 1 -69 136 -150 298 -81 162 -157 306 -169 318 -47 53 -129 67 -194 35z m169 -685 c50 -101 101 -196 112 -212 32 -45 88 -62 302 -91 110 -15 202 -30 205 -32 2 -2 -66 -74 -152 -161 -86 -86 -161 -167 -167 -179 -6 -12 -11 -41 -11 -65 0 -35 44 -308 66 -410 5 -27 -2 -24 -199 77 -164 85 -212 105 -246 105 -35 0 -81 -20 -245 -105 l-203 -105 33 192 c37 215 41 276 19 317 -8 16 -85 96 -171 180 -86 83 -155 151 -153 152 428 63 450 67 482 92 24 18 58 77 132 226 55 110 101 201 102 201 2 0 44 -82 94 -182z"/>
        </g>
      </svg>
    </span>
  );
};

export default RoundStar;
