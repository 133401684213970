import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Expand = (props) => {
  return (
    <span onclick={(e) => props.action && props.action(e)} class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} version="1.1" id="Layer_1" x="0px" y="0px" width={props.width || "11px"}
	height={props.height || "11px"} style={`enable-background:new 0 0 330 330; ${props.style || ''}`} fill={props.fill || '#000 '}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
      >
        <path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM184 496H40c-13.3 0-24-10.7-24-24V328c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" />
      </svg>
    </span>
  );
};

export default Expand;
