import { h } from 'preact';

const ForwardArrow = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg width={props.width || "11"} height={props.height || "11"} viewBox={props.viewBox || "0 0 11 11"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.21875 9.88892L9.66319 5.44447L5.21875 1.00003" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 5.44458L1 5.44458" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default ForwardArrow;
