import { useContext } from "preact/hooks";
import { createContext } from 'preact';
import io from "socket.io-client";

// const SOCKET_URL = window.location.origin.includes("localhost")
// 	? "http://localhost:5000"
// 	: "https://whatsapp-web-clone-backend.herokuapp.com/";
//
// const socket = io.connect(SOCKET_URL);

const SocketContext = createContext();

const useSocketContext = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={""}>{children}</SocketContext.Provider>
  );
};

export { useSocketContext, SocketProvider };
