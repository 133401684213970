import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const AddCase = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || ""} height={props.width || ""} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet" onclick={(e) => props.action && props.action(e)}>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M413 5106 c-191 -47 -353 -209 -398 -397 -13 -56 -15 -290 -15 -1829 0 -1945 -3 -1829 62 -1954 48 -90 147 -185 238 -229 41 -19 102 -41 135 -47 42 -8 281 -10 798 -8 l739 3 29 33 c18 21 29 43 29 63 0 37 -23 84 -47 95 -10 5 -356 11 -768 14 l-750 5 -67 33 c-76 37 -120 82 -157 162 l-26 55 0 1775 0 1775 26 55 c37 80 81 125 157 162 l67 33 1350 0 1350 0 56 -26 c69 -33 127 -89 160 -155 l24 -49 5 -705 c4 -540 8 -710 18 -727 29 -52 104 -70 151 -37 51 36 51 34 51 736 0 379 -5 682 -10 723 -13 86 -71 211 -128 276 -54 61 -134 114 -218 147 l-69 27 -1370 2 c-1082 1 -1381 -1 -1422 -11z"/>
          <path d="M703 4255 c-33 -14 -63 -59 -63 -95 0 -37 30 -81 65 -96 51 -21 1099 -21 1150 0 35 15 65 59 65 96 0 37 -30 81 -65 96 -50 21 -1104 20 -1152 -1z"/>
          <path d="M693 3398 c-57 -28 -67 -122 -17 -169 l26 -24 1099 -3 c1223 -3 1145 -7 1175 65 19 43 5 93 -33 123 -25 20 -37 20 -1127 19 -741 0 -1108 -4 -1123 -11z"/>
          <path d="M3510 2756 c-607 -108 -1065 -580 -1151 -1185 -15 -106 -7 -361 15 -461 58 -265 179 -492 367 -685 201 -207 431 -334 717 -397 145 -32 407 -32 551 0 285 64 502 182 702 381 199 200 317 417 381 702 32 144 32 406 0 551 -30 137 -66 237 -127 356 -74 143 -151 245 -270 361 -191 186 -415 307 -680 366 -97 22 -405 29 -505 11z m480 -225 c429 -98 771 -431 880 -857 102 -396 -17 -822 -309 -1115 -403 -403 -1018 -458 -1496 -135 -141 95 -302 281 -384 443 -96 192 -139 442 -111 659 65 520 463 935 978 1019 115 19 328 12 442 -14z"/>
          <path d="M3663 2106 l-28 -24 -5 -294 -5 -293 -293 -5 -294 -5 -24 -28 c-41 -48 -29 -128 26 -160 24 -15 65 -17 309 -17 l281 0 0 -280 c0 -184 4 -287 11 -304 28 -60 122 -71 170 -20 l24 26 3 288 3 289 289 3 288 3 26 24 c51 48 40 142 -20 170 -17 7 -120 11 -304 11 l-280 0 0 281 c0 244 -2 285 -17 309 -32 55 -112 67 -160 26z"/>
          <path d="M710 2553 c-81 -30 -90 -157 -14 -192 18 -8 224 -11 700 -11 l676 0 29 29 c48 49 34 142 -26 169 -19 9 -195 12 -688 11 -364 0 -669 -3 -677 -6z"/>
        </g>
      </svg>
    </span>
  );
};

export default AddCase;
