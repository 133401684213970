/*
  Bug: T1687 - 20 - Receive Payment Dropdown - Dropdowns should have search functionality (i.e. when I type option it should be selected automatically)
  Modification: Added searchable dropdown
  By: Devang
  Date: 11/04/2024
*/
import { h } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";
import { callAPI, reportException, generateCancelTokenSource, isExceptionFromCancel } from '../../lib/transceiver';
import { setItem, getItem } from '../../lib/myStore';
import { Information, BlockSign } from '../../components/svgs';

let cancelTokenSource;
const SearchableCaseDropdown = (props) => {
  const { selectedValue, onChange } = props;
  let userInfo = getItem('userinfo');
  const fileName = "searchableCaseDropdown";

  const [searchedCases, setSearchedCases] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      cancelTokenSource = generateCancelTokenSource(cancelTokenSource);
      searchCases();
    }, 500);

    return () => clearTimeout(searchTimeout);
  }, [searchKey]);

  async function searchCases() {
    if (searchKey) {
      try {
        setIsLoading(true);
        let params = {
          q: searchKey
        };
        let response = await callAPI("get", "/v1/userCases", `userID=${userInfo.uuid}&q=${searchKey}`, { cancelTokenSource });

        if (response.data) setSearchedCases(response.data);
        setIsLoading(false);
      } catch (HTTPException) {
        if (!isExceptionFromCancel(HTTPException)) {
          setIsLoading(false);
        }
        setSearchedCases([]);
        reportException({
          HTTPException,
          fileName,
          functionName: 'searchCases'
        });
      }
    } else {
      setSearchedCases([]);
    }
  }

  function handleSearchKeyChange(e) {
    setIsLoading(true);
    setSearchKey(e.target.value ? e.target.value.trimStart() : '');
  }

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };
  const empSearchRef = useOutsideClick(closeInputDropDown);
  function closeInputDropDown() {
    setIsDropdownVisible(false);
  }

  const handleOptionClick = (caseObj) => {
    onChange(caseObj);
    closeInputDropDown();
  };

  return (
    <div class="row m-b-5">
      <div class="flex-r-m w-full min-h-38px bor-rad-all-5 bor-all-gray pos-relative" ref={empSearchRef}>
        <input
          value={searchKey}
          onChange={handleSearchKeyChange}
          style="color:grey !important; margin-bottom:0px !important; border: none;"
          class={`${'w-full h-full'}`}
          type="text"
          /*
           Bug: T2020 - BG_UI: Sales - Reword - Spelling Mistakes/ Grammatical mistakes
              15) Reword - Receive Payment - Select Case - Search Box - Placeholder
           Modification: Updated "Search Cases..." to "Search cases by customer name..."
                         Updated "Search cases by customer name..." to "Search cases by customer name or mobile..."
           By: Devang
           Date: 20/08/2024
          */
          placeholder='Search cases by customer name or mobile...'
          onFocus={() => setIsDropdownVisible(true)}
        />
        {
          isDropdownVisible &&
          <div style={{ maxHeight: '150px', top: '36px'}} class={`multi-select-container w-full bor-rad-all-5 bor-all-gray p-7 overflow-auto`}>
            {
              isLoading && searchKey ?
                <div class="w-full h-full flex-c-m flex-direction-column">
                  <p class="fs-16 lh-16-px">Loading Data...</p>
                </div>
                :
                searchedCases.length > 0 ? searchedCases.map((caseObj, index) => (
                  <div key={index} class={`${selectedValue === caseObj.uuid ? 'bggray' : ''} p-l-4 p-r-4 flex-l-m cursor-pointer`} onclick={() => handleOptionClick(caseObj)}>
                    <label class={`text-color-002c5f-imp flex-c-m w-full cursor-pointer`}>
                      <div class="flex-c-m justify-between w-full p-l-8">
                        <p>{caseObj.dynamicProperties_customerName}</p>
                        {/*
                          Modification: Added search by mobile parameter
                          By: Devang
                          Date: 27/08/2024
                        */}
                        <p>{caseObj.dynamicProperties_mobile}</p>
                        {/*<span>{caseObj.caseID}</span>*/}
                      </div>
                    </label>
                  </div>
                )): (
                  <div class="w-full h-full flex-c-m flex-direction-column">
                    <p class="fs-16 lh-16-px">{searchKey ? 'No data found for given input' : 'Please input search to show values' }</p>
                  </div>
                )
            }
          </div>
        }
      </div>
    </div>
  );
};

export default SearchableCaseDropdown;
