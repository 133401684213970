import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Cart = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || ""} height={props.width || ""} fill={props.fill || "#00000"} fill={props.fill || "#000000"}>
      <path d="M6.85 21.625q-.75 0-1.275-.525-.525-.525-.525-1.275 0-.775.525-1.287.525-.513 1.275-.513.775 0 1.287.513.513.512.513 1.287 0 .75-.513 1.275-.512.525-1.287.525Zm10 0q-.775 0-1.288-.525-.512-.525-.512-1.275 0-.775.512-1.287.513-.513 1.288-.513.75 0 1.275.513.525.512.525 1.287 0 .75-.525 1.275-.525.525-1.275.525ZM5.775 5.45l2.575 5.375h6.775q.175 0 .313-.088.137-.087.237-.237L18.15 6q.1-.225 0-.388-.1-.162-.325-.162ZM5 3.8h14.35q.65 0 .975.537.325.538 0 1.088l-3.35 6.05q-.25.45-.687.725-.438.275-.938.275H7.9L6.975 14.1q-.15.225 0 .5t.425.275h11.3v1.65H6.85q-1.075 0-1.562-.913Q4.8 14.7 5.275 13.8l1.4-2.575L3 3.45H1.05V1.8h3Zm3.35 7.025h7.15Z" />
    </svg>
  );
};

export default Cart;
