const PriceList = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action()} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
	id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M2445 5114 c-120 -25 -200 -69 -273 -149 -56 -61 -96 -138 -113 -214 l-11 -51 -51 0 c-149 0 -273 -46 -366 -135 -57 -54 -112 -141 -125 -197 l-6 -27 -318 -3 c-301 -3 -319 -4 -364 -25 -71 -32 -141 -103 -174 -176 l-29 -62 -3 -1869 c-1 -1238 1 -1888 8 -1925 24 -131 117 -233 243 -267 78 -21 3316 -21 3394 0 126 34 219 136 243 267 7 37 9 687 8 1925 l-3 1869 -29 62 c-33 73 -103 144 -174 176 -44 21 -65 22 -360 27 l-313 5 -23 58 c-75 183 -217 279 -436 293 l-96 7 -12 53 c-36 172 -188 322 -361 354 -52 10 -215 12 -256 4z m270 -242 c51 -26 100 -77 119 -124 9 -21 16 -40 16 -43 0 -3 -129 -5 -286 -5 l-286 0 7 28 c18 72 84 136 163 159 70 19 216 11 267 -15z m560 -421 c49 -22 90 -62 118 -116 21 -37 22 -54 22 -250 l0 -210 -850 0 -850 0 -3 155 c-4 213 2 266 41 331 25 41 45 60 86 81 l53 28 672 0 c629 0 673 -2 711 -19z m-1785 -385 l0 -55 -144 -3 c-165 -3 -190 -13 -214 -83 -10 -30 -12 -379 -10 -1689 3 -1792 -1 -1677 57 -1708 19 -11 282 -13 1388 -13 l1365 0 29 33 29 32 0 1678 c0 1535 -1 1680 -16 1701 -29 40 -73 51 -209 51 l-125 0 0 55 0 55 270 0 c296 0 320 -4 355 -56 20 -29 20 -60 20 -1894 l0 -1865 -23 -32 c-14 -20 -37 -36 -60 -43 -51 -14 -3233 -14 -3284 0 -23 7 -46 23 -60 43 l-23 32 0 1865 c0 1833 0 1865 20 1894 35 52 55 55 358 56 l277 0 0 -54z m8 -331 c9 -33 20 -50 45 -65 31 -20 53 -20 1017 -20 1123 0 1042 -6 1070 82 l15 48 63 0 62 0 0 -1525 0 -1525 -1210 0 -1210 0 0 1525 0 1525 68 0 68 0 12 -45z" />
          <path d="M1941 3264 c-38 -32 -49 -80 -27 -126 29 -60 39 -62 281 -68 218 -5 221 -5 279 -34 73 -36 136 -97 170 -165 l26 -50 -349 -3 -350 -3 -30 -29 c-39 -37 -48 -83 -27 -128 30 -63 26 -63 408 -66 l348 -3 -21 -40 c-32 -63 -97 -128 -164 -162 l-60 -31 -225 -6 c-148 -3 -232 -9 -246 -17 -52 -30 -70 -104 -36 -154 9 -15 199 -198 421 -407 431 -408 434 -410 502 -381 36 14 69 62 69 99 0 14 -8 39 -19 55 -10 17 -147 151 -305 300 -157 148 -286 273 -286 276 0 4 35 9 78 11 135 9 252 64 360 169 59 57 123 163 146 242 l12 42 124 5 c95 4 131 10 151 23 64 43 64 141 0 184 -20 13 -55 19 -150 23 l-123 5 -18 57 c-10 31 -34 86 -54 122 l-36 66 166 0 c116 0 174 4 197 14 78 32 91 134 23 188 -19 16 -67 18 -613 18 l-591 0 -31 -26z" />
          <path d="M1745 1166 c-40 -17 -65 -56 -65 -102 0 -28 8 -44 34 -70 l34 -34 810 0 810 0 31 26 c61 51 48 150 -24 180 -49 20 -1583 20 -1630 0z" />
        </g>
      </svg>
    </span>
  );
};
export default PriceList;
