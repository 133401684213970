import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const RoleBadge = (props) => {
  return (
    <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} height={props.height || "18"}
      width={props.width || '12'} viewBox="0 0 12 18"
    >
      <path d="M4.54545 8.09091C5.48577 8.09091 6.38757 7.71737 7.05247 7.05247C7.71737 6.38757 8.09091 5.48577 8.09091 4.54545C8.09091 3.60514 7.71737 2.70334 7.05247 2.03844C6.38757 1.37354 5.48577 1 4.54545 1C3.60514 1 2.70334 1.37354 2.03844 2.03844C1.37354 2.70334 1 3.60514 1 4.54545C1 5.48577 1.37354 6.38757 2.03844 7.05247C2.70334 7.71737 3.60514 8.09091 4.54545 8.09091Z" stroke={props.stroke || "#002c5f"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.90909 7.5L8.09091 14L4.54545 12.8182L1 14L2.18182 7.5" stroke={props.stroke || "#002c5f"} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default RoleBadge;
