import { h, Component, Fragment } from 'preact';
import { useState, useEffect } from "preact/hooks";
import toastr from 'toastr';
import { getItem } from '../../lib/myStore';
import html2canvas from 'html2canvas';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { callAPI, reportException } from '../../lib/transceiver';
import { deepCopy, isAllowedFileType } from '../../lib/utils';
import { Screenshot, Loop } from '../../components/svgs';
import CoreEntityDynamicForm from '../../components/coreEntityDynamicForm';
import CONSTANTS from '../../lib/constants';
// import { route } from "preact-router";

const userInfo = getItem('userinfo');
const TicketGenerationV2 = (props) => {
  const fileName = 'ticketGenerationV2';

  // let [formOperation, setFormOperation] = useState();
  const [formEntityObject, setFormEntityObject] = useState();
  const [errorField, setErrorField] = useState([]);
  const [isScreenshotInProgress, setIsScreenshotInProgress] = useState(false);
  const [isTicketInCreation, setIsTicketInCreation] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  let [isCoreEntityFormPopover, setIsCoreEntityFormPopover] = useState(false); // state to check if any process is selected to edit or to create new process and show CreateEditForm
  let [requestDynamicProps, setRequestDynamicProps] = useState();
  let [coreEntityFormOperation, setCoreEntityFormOperation] = useState('Create Request');
  // let [capturedScreenshot, setCapturedScreenshot] = useState('');

  useEffect(() => {
    if (!isCoreEntityFormPopover) {
      setFormEntityObject({});
    }
  }, [isCoreEntityFormPopover]);

  function triggerScreenFlash(){
    const body = document.body;
    const flashDiv = document.createElement('div');
    flashDiv.classList.add('flash');
    body.appendChild(flashDiv);
    // Force a reflow to apply the CSS change immediately
    void flashDiv.offsetWidth;
    flashDiv.style.opacity = '0.5';
    // Listen for the end of the CSS transition
    flashDiv.addEventListener('transitionend', () => {
      body.removeChild(flashDiv);
    });
  }

  const takeScreenshot = async () => {
    let body = document.body;
    await html2canvas(body).then(async (canvas) => {
      await triggerScreenFlash();
      toastr.success('Screenshot Captured'); // screen captured toaster
      // setScreenshotCaptured(true);

      let dataURL = canvas.toDataURL('image/jpeg');
      const base64Data = dataURL.replace(/^data:image\/\w+;base64,/, "");
      let arrayBuffer = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
      await uploadScreenshotFile(arrayBuffer);
    });
  };

  async function uploadScreenshotFile(arrayBuffer) {
    const file = {
      name: 'screenshot.jpeg',
      size: arrayBuffer.byteLength,
      type: 'image/jpeg',
      value: 'screenshot.jpeg'
    };

    try {
      let payload = {
        file,
        // vendorKey: 'screenshot',
        interactionID: 'entity'
      };
      let fileDetails;
      await callAPI(`post`, `/v1/file/getSignedUrl`, payload).then(async (res) => {
        if (res && res.data) {
          fileDetails = res.data;
          try {
            //  Save File on S3
            const opts = {
              headers: {
                name: 'Content-Type',
                value: 'multipart/form-data'
              }
            };
            let fileData = arrayBuffer;
            await axios.put(fileDetails.signedURL, fileData, opts);
            await addRequest(fileDetails.uuid);
          } catch (HTTPException) {
            await reportException({
              HTTPException,
              fileName,
              functionName: 'uploadScreenshotFile'
            });
          }
        }
      });
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'uploadScreenshotFile'
      });
    }
  }

  const takeScreenshotAndCreateTicket = async () => {
    try {
      await takeScreenshot();
      setIsScreenshotInProgress(false);
    } catch (HTTPException) {
      setIsScreenshotInProgress(false);
      await reportException({
        HTTPException,
        fileName,
        functionName: 'takeScreenshotAndCreateTicket'
      });
    }
  };

  const addRequest = async (capturedScreenshot) => { // adding new request
    try {
      let apiBody = { coreEntity: "request", coreEntityProcessName: "ticketing"};
      let response = await callAPI(`put`, `/v1/instantiateCoreEntityProcess`, apiBody);
      await getFormNameRequest(response.data.allCreatedTasks[0]);
      let copyOfEntity = deepCopy(response.data);
      copyOfEntity.coreEntity = "request";
      copyOfEntity.coreEntityProcessName = "ticketing";
      copyOfEntity.capturedScreenshot = capturedScreenshot;
      copyOfEntity.page = window.location.href;
      copyOfEntity.requestStage = 'Open';
      copyOfEntity.requestType = 'Incident Report';
      copyOfEntity.requestStatus = 'New';
      setFormEntityObject(copyOfEntity);
      // setFormOperation("Create");
      setIsCoreEntityFormPopover(true);
    } catch (HTTPException) {
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"addRequest",
        fileName:"rightPanel",
        pageName: "rightPanel"
        // userID:userInfo.uuid,
        // userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };

  const getFormNameRequest = async (obj) => { //getting dynamic properties
    try {
      let dynamicPropsPayload = {
        coreEntity: obj.coreEntity,
        masterTaskID: obj.masterTaskID
      };
      let response = await axios.put(`${CONSTANTS.API_URL}/api/v1/dynamicPropsOrActionDynamicPropsByTask`, dynamicPropsPayload);
      if (response.data.Properties) {
        setRequestDynamicProps(response.data);
      }
    } catch (HTTPException) {
      console.error(HTTPException, 'HTTPException');
      let payload = {
        apiURL:(HTTPException && HTTPException.config && HTTPException.config.url) ? HTTPException.config.url : "",
        errorObject:HTTPException,
        statusCode:(HTTPException && HTTPException.response && HTTPException.response.status) ? HTTPException.response.status : 0,
        errorMessage:HTTPException.message,
        functionName:"getFormNameRequest",
        fileName:"rightPanel",
        pageName: "rightPanel",
        userID:userInfo.uuid,
        userName:userInfo.displayName
      };
      await axios.post(`${CONSTANTS.API_URL}/api/v1/exception`, payload);
      return HTTPException;
    }
  };

  const toggleFormPopoverRequest = (e) => { // form functions
    setIsCoreEntityFormPopover(prevValue => !prevValue);
    setErrorField([]);
    if (isCoreEntityFormPopover) {
      // route('/ticketing/Open');
      resetFormValues();
    }
  };

  const resetFormValues = () => {
    setCoreEntityFormOperation('');
    setFormEntityObject();
  };

  return (
    <Fragment>
      <div class="flex-c flip-card-inner m-r-10 h-fit-content">
        <div class={`leftInfoPanel-cards p-8-imp h-fit-content cursor-pointer flex-c-m`}>
          <span>
            {
              isScreenshotInProgress ?
                <Loop height="24px" width="24px" stroke="#fff" classes="rotating" title='Screenshot in progress'/>
                :
                <Screenshot height="24px" width="24px" fill="#fff" action={() => {
                  setIsScreenshotInProgress(true);
                  takeScreenshotAndCreateTicket();
                }} title='Take screenshot & create ticket'/>
            }
          </span>
        </div>
      </div>
      {
        isCoreEntityFormPopover &&
        <CoreEntityDynamicForm
          isMobileView={props.isMobileView}
          operation={coreEntityFormOperation}
          toggleFormPopover={toggleFormPopoverRequest}
          dynamicProps={requestDynamicProps}
          entityObject={formEntityObject}
        />
      }
    </Fragment>
  );
};

export default TicketGenerationV2;
