const Quotation = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action()} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
	id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M800 4947 c-49 -16 -133 -102 -148 -153 -18 -60 -18 -4408 0 -4468 16 -55 99 -138 154 -154 32 -9 439 -12 1756 -12 1936 0 1773 -7 1851 80 49 55 67 103 67 182 l0 55 91 5 c78 5 100 11 163 42 89 43 146 102 189 194 29 62 32 77 35 195 l4 127 -241 0 -241 0 0 1856 c0 1406 -3 1866 -12 1896 -6 22 -31 62 -55 88 -78 87 86 80 -1857 79 -1224 -1 -1731 -4 -1756 -12z m3495 -172 l25 -24 0 -1856 0 -1855 -1520 0 -1520 0 0 -96 c0 -128 -13 -168 -75 -229 -70 -71 -145 -91 -239 -63 -55 16 -138 99 -154 154 -9 32 -12 490 -12 1993 l0 1952 25 24 24 25 1711 0 1711 0 24 -25z m505 -3911 c0 -77 -88 -189 -166 -212 -32 -9 -417 -12 -1656 -12 l-1615 0 33 65 c18 35 36 89 39 120 l7 55 1679 0 c1547 0 1679 -1 1679 -16z m-2168 -382 l1688 -2 0 -56 c0 -45 -4 -59 -25 -79 l-24 -25 -1711 0 -1711 0 -24 25 c-23 22 -25 32 -25 118 l0 93 72 -35 72 -36 1688 -3z" />
          <path d="M1040 4480 l0 -80 320 0 320 0 0 80 0 80 -320 0 -320 0 0 -80z" />
          <path d="M3280 4480 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
          <path d="M3600 4480 l0 -80 240 0 240 0 0 80 0 80 -240 0 -240 0 0 -80z" />
          <path d="M1040 4160 l0 -80 400 0 400 0 0 80 0 80 -400 0 -400 0 0 -80z" />
          <path d="M3280 4160 l0 -80 400 0 400 0 0 80 0 80 -400 0 -400 0 0 -80z" />
          <path d="M1040 2880 l0 -960 1520 0 1520 0 0 960 0 960 -1520 0 -1520 0 0 -960z m1360 680 l0 -120 -600 0 -600 0 0 120 0 120 600 0 600 0 0 -120z m800 0 l0 -120 -320 0 -320 0 0 120 0 120 320 0 320 0 0 -120z m720 0 l0 -120 -280 0 -280 0 0 120 0 120 280 0 280 0 0 -120z m-1520 -880 l0 -600 -600 0 -600 0 0 600 0 600 600 0 600 0 0 -600z m800 0 l0 -600 -320 0 -320 0 0 600 0 600 320 0 320 0 0 -600z m720 0 l0 -600 -280 0 -280 0 0 600 0 600 280 0 280 0 0 -600z" />
          <path d="M1360 3040 l0 -80 360 0 360 0 0 80 0 80 -360 0 -360 0 0 -80z" />
          <path d="M1360 2720 l0 -80 240 0 240 0 0 80 0 80 -240 0 -240 0 0 -80z" />
          <path d="M1360 2400 l0 -80 240 0 240 0 0 80 0 80 -240 0 -240 0 0 -80z" />
          <path d="M2720 3040 l0 -80 160 0 160 0 0 80 0 80 -160 0 -160 0 0 -80z" />
          <path d="M2720 2720 l0 -80 160 0 160 0 0 80 0 80 -160 0 -160 0 0 -80z" />
          <path d="M2720 2400 l0 -80 160 0 160 0 0 80 0 80 -160 0 -160 0 0 -80z" />
          <path d="M3520 3040 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
          <path d="M3520 2720 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
          <path d="M3520 2400 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
          <path d="M1040 1680 l0 -80 400 0 400 0 0 80 0 80 -400 0 -400 0 0 -80z" />
          <path d="M2800 1680 l0 -80 640 0 640 0 0 80 0 80 -640 0 -640 0 0 -80z" />
          <path d="M3200 1360 l0 -80 440 0 440 0 0 80 0 80 -440 0 -440 0 0 -80z" />
        </g>
      </svg>
    </span>
  );
};
export default Quotation;
