import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const PdfIcon = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || ""} height={props.width || ""} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet" onclick={(e) => props.action && props.action(e)}>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M1166 5104 c-123 -30 -250 -118 -322 -224 -17 -25 -44 -76 -60 -115 l-29 -70 -3 -1067 -3 -1068 -47 0 c-68 0 -148 -42 -187 -98 l-30 -44 -3 -726 c-3 -817 -6 -781 71 -847 45 -39 90 -55 153 -55 l42 0 4 -183 c4 -167 7 -188 31 -250 50 -128 139 -229 256 -291 133 -69 38 -66 1657 -66 1425 0 1463 0 1538 20 43 11 103 34 135 52 74 42 168 139 208 214 67 126 63 5 63 1864 l0 1685 -642 642 -643 643 -1065 -1 c-883 -1 -1075 -3 -1124 -15z m2056 -706 c4 -381 5 -395 27 -451 43 -108 121 -187 226 -226 53 -20 75 -21 439 -21 l384 0 7 -1012 c3 -557 5 -1281 3 -1609 l-3 -596 -30 -48 c-21 -35 -45 -56 -80 -74 l-49 -26 -1421 -3 c-1015 -2 -1435 1 -1474 9 -40 8 -65 21 -97 51 -60 55 -74 106 -74 270 l0 128 1330 0 c1478 0 1368 -5 1443 68 68 66 67 54 67 814 0 483 -3 692 -12 725 -15 60 -87 137 -143 152 -28 8 -436 11 -1362 11 l-1323 0 0 1013 c0 862 2 1021 15 1063 19 65 77 122 142 140 38 10 257 13 1015 13 l966 1 4 -392z m-1766 -2289 c178 -33 262 -136 252 -308 -5 -88 -29 -142 -83 -189 -73 -64 -157 -92 -282 -92 l-83 0 0 -165 0 -165 -105 0 -105 0 0 454 0 455 43 5 c125 17 287 19 363 5z m802 1 c266 -34 402 -182 402 -439 0 -254 -118 -409 -352 -466 -81 -20 -259 -30 -371 -20 l-87 7 0 453 0 454 43 5 c124 17 261 19 365 6z m1112 -85 l0 -85 -180 0 -180 0 0 -105 0 -105 165 0 165 0 0 -85 0 -85 -165 0 -165 0 0 -185 0 -185 -105 0 -105 0 0 460 0 460 285 0 285 0 0 -85z"/>
          <path d="M1267 1953 c-4 -3 -7 -66 -7 -140 l0 -133 68 0 c106 0 168 44 179 127 8 59 -20 111 -71 134 -40 18 -155 27 -169 12z"/>
          <path d="M2078 1954 c-17 -5 -18 -28 -18 -305 l0 -299 27 -6 c41 -11 172 14 217 40 54 32 80 61 108 121 20 43 23 66 23 160 0 94 -3 116 -22 150 -30 56 -80 101 -133 120 -46 17 -171 28 -202 19z"/>
        </g>
      </svg>
    </span>
  );
};

export default PdfIcon;
