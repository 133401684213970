import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const DelegateContact = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} title={props.title || ""} class={`${props.classes || ""} ${props.identityClass || ''}`} width={props.width || "19"} height={props.height || "20"} viewBox={props.viewBox || "0 0 19 20"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class={props.identityClass || ''} d="M18 19V17C18 15.9391 17.5786 14.9217 16.8284 14.1716C16.0783 13.4214 15.0609 13 14 13H9.5M4 19V17C4 16.8636 4.0204 16.676 4.04485 16.5" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path class={props.identityClass || ''} d="M11 9C13.2091 9 15 7.20914 15 5C15 2.79086 13.2091 1 11 1C8.79086 1 7 2.79086 7 5C7 7.20914 8.79086 9 11 9Z" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path class={props.identityClass || ''} d="M4.5 14L7 11.5L4.5 9" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path class={props.identityClass || ''} d="M7 11.5L1 11.5" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default DelegateContact;
