import { h } from 'preact';

// from flaticon
const MicOutlined = (props) => {
  return (
    <span onclick={(e) => props.action && props.action(e)} class={props.masterClasses || ''} title={props.title || ''}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M2439 5110 c-419 -53 -763 -353 -876 -765 l-28 -100 0 -840 0 -840 27 -97 c81 -292 264 -525 518 -657 310 -161 651 -161 957 -2 258 135 440 366 521 659 l27 97 0 835 c0 833 0 835 -22 923 -100 389 -390 676 -776 767 -97 23 -256 32 -348 20z m296 -335 c136 -35 280 -126 372 -236 51 -63 125 -210 145 -289 10 -44 13 -217 13 -845 0 -871 2 -836 -65 -980 -71 -153 -178 -263 -325 -336 -112 -55 -190 -73 -315 -73 -126 0 -203 18 -315 74 -151 75 -251 177 -326 335 -70 148 -70 145 -66 1015 3 868 0 819 78 970 104 201 280 332 504 376 82 16 213 11 300 -11z"/>
          <path d="M975 2886 c-37 -16 -83 -68 -91 -103 -11 -42 4 -261 25 -363 45 -224 150 -468 279 -650 86 -121 280 -316 402 -403 116 -82 321 -185 455 -227 105 -34 275 -70 325 -70 l30 0 0 -375 0 -374 -364 -3 c-352 -3 -366 -4 -393 -24 -98 -73 -98 -195 0 -268 28 -21 31 -21 917 -21 886 0 889 0 917 21 98 73 98 195 0 268 -27 20 -41 21 -393 24 l-364 3 0 374 0 375 30 0 c50 0 220 36 325 70 134 42 338 144 455 227 121 86 316 281 402 403 180 254 292 576 304 880 6 130 5 136 -17 169 -54 78 -140 102 -213 59 -64 -37 -79 -73 -88 -212 -22 -351 -152 -641 -398 -886 -195 -195 -418 -317 -690 -376 -118 -25 -356 -30 -481 -9 -202 33 -399 112 -569 228 -107 73 -274 244 -348 355 -142 214 -214 428 -231 689 -9 140 -24 175 -87 210 -44 26 -94 29 -139 9z"/>
        </g>
      </svg>
    </span>
  );
};

export default MicOutlined;
