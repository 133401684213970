import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Orgchart = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"}
	viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M1965 4481 c-48 -22 -79 -54 -100 -103 -13 -32 -15 -114 -15 -591 l0 -554 23 -44 c13 -24 43 -57 66 -74 l43 -30 239 -3 239 -3 0 -210 0 -209 -891 0 c-619 0 -896 -3 -911 -11 -42 -23 -48 -62 -48 -343 l0 -265 -238 -3 -237 -3 -42 -29 c-27 -18 -51 -46 -68 -79 l-25 -51 0 -545 0 -546 24 -52 c17 -36 38 -60 67 -80 l43 -28 555 -3 c377 -2 568 0 593 8 50 14 97 56 119 105 17 37 19 78 19 593 0 596 0 593 -53 651 -50 55 -83 61 -329 61 l-228 0 0 215 0 215 825 0 825 0 0 -214 0 -215 -239 -3 -239 -3 -43 -30 c-23 -16 -53 -50 -66 -74 l-23 -44 0 -554 c0 -477 2 -559 15 -591 21 -49 52 -81 100 -103 37 -17 77 -19 595 -19 518 0 558 2 595 19 48 22 79 54 100 103 13 32 15 114 15 591 l0 554 -23 44 c-13 24 -43 58 -66 74 l-43 30 -239 3 -239 3 0 215 0 214 825 0 825 0 0 -215 0 -215 -225 0 c-181 0 -233 -3 -263 -15 -49 -21 -81 -52 -103 -100 -17 -37 -19 -77 -19 -595 0 -517 2 -558 19 -595 22 -49 69 -91 119 -105 25 -8 216 -10 593 -8 l555 3 43 28 c29 20 50 44 67 80 l24 52 0 545 c0 509 -2 548 -19 585 -10 22 -31 52 -47 67 -57 53 -84 58 -324 58 l-220 0 0 265 c0 282 -6 321 -48 344 -15 8 -292 11 -911 11 l-891 0 0 214 0 215 239 3 239 3 43 30 c23 17 53 50 66 74 l23 44 0 549 c0 473 -2 554 -15 586 -21 49 -52 81 -100 103 -37 17 -77 19 -595 19 -518 0 -558 -2 -595 -19z m1105 -692 l0 -511 -452 6 c-249 4 -479 9 -510 12 l-58 6 0 499 0 499 510 0 510 0 0 -511z m-1962 -1956 l122 -6 0 -504 0 -503 -515 0 -515 0 0 510 0 510 393 0 c217 0 449 -3 515 -7z m1840 0 l122 -6 0 -504 0 -503 -510 0 -510 0 0 510 0 510 388 0 c214 0 444 -3 510 -7z m1972 -492 l0 -499 -22 -6 c-13 -3 -245 -6 -515 -6 l-493 0 0 505 0 505 515 0 515 0 0 -499z" />
        </g>
      </svg>
    </span>
  );
};

export default Orgchart;
