import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
const FastForward = (props) => {
  const { masterClasses, title, action, classes, width, height, viewBox, fill } = props;

  return (
    <span class={`${masterClasses || ''}`} title={title || ''}>
      <svg onclick={(e) => action && action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${classes || ""}`} width={width || "512pt"} height={height || "512pt"} viewBox={viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <path d="M237.5 32.6c-10.9 1.2-26.2 3.8-36.7 6.5C125.2 57.8 62.8 118 41.2 193c-6.8 23.5-8.7 37.3-8.6 63.5 0 24.9 1.6 37.2 7.8 59.8C61 390.9 121.1 451 195.7 471.6c22.4 6.1 34.9 7.7 59.8 7.8 24.2.1 34.3-1.1 55.9-6.5 62.1-15.5 116.1-59.2 145.3-117.4 9-17.8 17.1-43.9 20.9-67 2.7-16.4 2.5-50.5-.4-67.5-8.1-47.2-27.5-86.3-59.9-120-34-35.5-76.6-57.8-125.8-66.1-11.6-1.9-44.2-3.3-54-2.3zm28 105.3c2.7 1.3 19.7 17.6 52.2 50.2 54.6 54.9 53.8 53.8 53.8 67.9 0 14.1.8 13.1-53.8 67.9-32.4 32.5-49.5 48.9-52.2 50.2-11.3 5.3-27-1.4-32.1-13.7-2-4.7-1.7-13.1.5-18.1 1.4-2.9 14.3-16.6 42.7-45.2l40.7-41.1-40.7-41.1c-28.4-28.6-41.3-42.3-42.7-45.2-2.2-5-2.5-13.4-.5-18.1 5.1-12.3 20.8-19 32.1-13.7zm-75.9 40c2.8 1.3 14.5 11.9 34.4 31.2 34.5 33.5 35.5 34.8 35.4 46.9 0 12.3-.6 13.1-35.1 46.6-31.9 31-35 33.3-43.2 33.4-9.7 0-18.8-5.7-23-14.3-2.7-5.7-2.8-14.4-.2-19.6 1.1-2.1 11.7-13.2 23.7-24.9l21.7-21.2-21.8-21.3c-23.5-22.8-25.4-25.5-25.5-34.5 0-9.1 6.6-18.9 15.4-22.8 4.5-1.9 13.3-1.7 18.2.5z" fill={fill || "#000000"} />
      </svg>
    </span>
  );
};

export default FastForward;
