import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const AllV2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M132 5109 c-45 -13 -109 -80 -122 -127 -7 -28 -9 -773 -8 -2443 l3 -2404 24 -38 c13 -21 42 -50 64 -65 l41 -27 2426 0 2426 0 41 27 c22 15 51 44 64 65 l24 38 0 2426 0 2425 -27 41 c-15 22 -44 51 -65 64 l-38 24 -2410 2 c-1502 1 -2422 -2 -2443 -8z m4588 -2549 l0 -2160 -2160 0 -2160 0 0 2160 0 2160 2160 0 2160 0 0 -2160z"/>
          <path d="M1749 4381 c-53 -17 -80 -40 -296 -254 l-223 -221 -74 72 c-117 114 -187 135 -284 86 -87 -43 -128 -138 -102 -234 10 -38 37 -69 173 -207 97 -98 177 -171 202 -183 51 -25 118 -25 170 -1 26 12 147 126 352 332 326 329 333 338 333 424 -1 118 -138 220 -251 186z"/>
          <path d="M2475 4081 c-99 -46 -141 -158 -96 -256 21 -46 79 -101 119 -112 35 -10 1689 -10 1724 0 40 11 98 66 119 112 45 98 3 210 -96 256 -38 18 -85 19 -885 19 -800 0 -847 -1 -885 -19z"/>
          <path d="M1715 3024 c-16 -9 -132 -118 -257 -242 l-228 -227 -84 84 c-71 70 -92 85 -135 97 -158 41 -297 -115 -236 -264 19 -45 314 -345 367 -373 41 -21 120 -25 165 -8 42 15 651 625 674 674 61 130 -36 276 -183 275 -29 -1 -66 -8 -83 -16z"/>
          <path d="M2492 2749 c-47 -14 -109 -79 -123 -131 -23 -89 12 -182 88 -229 l38 -24 866 0 865 0 41 27 c62 41 88 90 88 168 0 78 -26 127 -88 168 l-41 27 -850 2 c-519 1 -864 -2 -884 -8z"/>
          <path d="M1720 1684 c-20 -9 -130 -109 -263 -242 l-227 -226 -78 76 c-84 84 -128 108 -194 108 -143 -1 -238 -147 -179 -275 11 -25 84 -105 187 -207 143 -141 176 -168 213 -178 54 -14 103 -6 156 25 22 13 173 158 336 322 326 328 338 344 325 441 -17 128 -158 207 -276 156z"/>
          <path d="M2535 1415 c-5 -2 -22 -6 -37 -9 -39 -9 -98 -64 -119 -111 -46 -101 0 -216 103 -260 33 -13 142 -15 880 -15 798 0 845 1 883 19 124 57 153 214 57 314 -62 66 -2 62 -922 64 -459 1 -839 0 -845 -2z"/>
        </g>
      </svg>
    </span>
  );
};

export default AllV2;
