import { useState, useEffect } from 'preact/hooks';
import {formatDateTime, getInitials, getFormattedAmount, getWeeks, modelMapping} from '../../lib/utils';
import axios from "axios";
import CONSTANTS from '../../lib/constants';
import ListSingleCardWizard from '../listSingleCardWizard';
import TaskListSingleCardWizard from '../taskListSingleCardWizard';
import StageDetailModal from "../stageDetailModal";
import { Modal, ModalBody } from '../rightDrawer';

import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { route } from 'preact-router';

const ListSingleCard = (props) => {
  console.log(props,'$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
  let [dataSet, setDataset] = useState(props.taskData);
  let [selectedList, setSelectedList] = useState('');
  let [lastQuotation, setLastQuotation] = useState({});
  let [followups, setFollowUps] = useState(props.followups);
  let [isStageDetailModalOpen, setStageDetailModalVisibility] = useState(false);
  let [stageDetailModalFor, setStageDetailModalFor] = useState('');
  let [interactionStageData, setInteractionStageData] = useState([]);



  function StageDetailModalVisibility(e, modalFor) {
    e.preventDefault();
    setStageDetailModalVisibility(true);
    setStageDetailModalFor(modalFor);
  }
  function closeStageDetailModal(e) {
    e.preventDefault();
    setStageDetailModalVisibility(false);
    setStageDetailModalFor('');
  }

  useEffect(async () => {
    await setDataset(props.taskData);
    if (props.taskData && props.taskData.interactionID) {
      let quotations = await axios.get(`${CONSTANTS.API_URL}/api/v1/quotation?interactionID=${props.taskData.interactionID}`);
      await setLastQuotation(quotations.data);
      let Stages = await axios.get(`${CONSTANTS.API_URL}/api/v1/stage?interactionID=${props.taskData.interactionID}&get=masterStage&sortBy=serialNumber`);
      setInteractionStageData(Stages.data);
      console.log(Stages.data,'@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
    }
  },[props.taskData]);

  function handleData(row) {
    setSelectedList(row.uuid);
    props.showData(row);
  }

  function viewAllImages(e,id, index) {
    // modified by Vihang
    // modified at 16/05/2022
    // modification : remove rotate and flip options from image viewer
    let options = {
      rotatable:false,
      scalable:false
    };
    const gallery = new Viewer(document.getElementById(`image-${index}-${id}`),options);
    gallery.show();
  }

  /*
          modified by Vihang
          modified at 04/05/2022
          modification : card padding adjustments
    */
  function copyText(value, name) {
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = value;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    let tooltip = document.getElementById(name + "-tooltip");
    console.log("tooltip",tooltip);
    if (tooltip) {
      tooltip.style.opacity = "1";
      setTimeout(() => {
        tooltip.style.opacity = "0";
      },2000);
    }
  }
  function redirectToCaseDetail(caseID) {
    console.log('wwww', caseID);
    route(`/caseDetails/${caseID}`);
  }
  return (
    <div>
      {props.typeOfCard === 'caseSingleDetails' && dataSet &&
        <div class="listCard fadeAnimationText m-t-5 m-b-5 pos-relative">
          <div class='msgContainer cursor-pointer animatedHover min-h-inherit'>
            <div class='display-flex justify-between align-center m-l-10 min-h-inherit'>
              <div class="display-flex align-center">
                <div class="h-24px w-24px">
                  {props.taskIcon}
                </div>
                <div class="display-flex flex-direction-column m-l-10 m-r-10">
                  <p class='fs-10 f-w-600 text-grey'>{dataSet.caseID}</p>
                  <p class='fs-14 first-letter-capital'>{dataSet.lastCompletedAction}</p>
                </div>
              </div>
              <div class="display-flex flex-direction-column m-l-10 m-r-10">
                <p class='fs-10 f-w-600 text-grey'>Last Quotation</p>
                <p class='fs-14 first-letter-capital'>{lastQuotation ? lastQuotation: "-"}</p>
              </div>
              <div class={'m-r-30 tasks-tags border-blue'}>
                <div class="display-flex flex-direction-column fs-10">
                  <p class="f-w-600">{dataSet.currentStage}</p>
                </div>
              </div>
            </div>
          </div>
          <span class="h-full pos-absolute color-white fs-9 text-center text-uppercase list-card-ribbon" style={`background:${ dataSet.group === "task" ? "#20639B": dataSet.group === "Approval"? "#2F9395":""}`}>{ dataSet.group === "task" ? "Task" :(dataSet.group === "Approval") ? "Approval":""}</span>
        </div>
      }
      {props.typeOfCard === 'caseDetails' && dataSet &&
        <div>
          <div class="row">
            {/*
              Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
              By: Devang
              Date: 26/05/2022
            */}
            {/*
              Modification: Chnaged border class
              By: Yash
              Date: 21/06/2022
            */}
            <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-5' >
              <div class="workspace-cards ">
                <div class="row justify-between align-center">
                  <div class="display-flex">
                    <span class="workspace-cardsnumber fs-16 f-w-500">
                      <div class="h-24px w-24px">
                        {props.caseIcon}
                      </div>
                    </span>
                    <span class="m-l-10 fs-14 align-self-center">{dataSet.caseID}</span>
                  </div>
                </div>
                <div class="row m-t-10">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Customer Name</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_customerName"]) ? dataSet.interaction["dynamicProperties_customerName"]: "--"}</span></span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Source</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_source"]) ? dataSet.interaction["dynamicProperties_source"]: "--"}</span></span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Sub Source</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_subSource"]) ? dataSet.interaction["dynamicProperties_subSource"]: "--"}</span></span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Area</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_area"]) ? dataSet.interaction["dynamicProperties_area"]: "--"}</span></span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Interested Model</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{dataSet.model ? dataSet.model : (dataSet.interaction && dataSet.interaction['dynamicProperties_selectedModelName']) ? dataSet.interaction['dynamicProperties_selectedModelName']: '--'}</span></span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">Interested Variant</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{dataSet.variant ? dataSet.variant : (dataSet.interaction && dataSet.interaction['dynamicProperties_selectedVariantName']) ? dataSet.interaction['dynamicProperties_selectedVariantName']: '--'}</span></span>
                    </div>
                    <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">No. of Visitors</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{dataSet.visitDetails && dataSet.visitDetails.noOfVisitors ? dataSet.visitDetails.noOfVisitors : '01'}</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-5 p-r-0'>
              {
                // (!followups || followups.length === 0 ) && (
                //   <div class="workspace-cards ">
                //     <div class="row justify-between align-center">
                //       <div class="display-flex">
                //         <span class="workspace-cardsnumber fs-16 f-w-500">
                //           <div class="h-24px w-24px">
                //             {props.stageIcon}
                //           </div>
                //         </span>
                //         <span class="m-l-5 fs-14">STAGE</span>
                //       </div>
                //     </div>
                //     <div class="row m-t-10">
                //       <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                //         <div class="flex-sb borderteambottom p-b-8 p-t-8">
                //           <div class="flex-sb">
                //             <span class="fs-14">{dataSet.currentStage ? dataSet.currentStage : '--'}</span>
                //           </div>
                //           <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.currentStageActiveSince ? dataSet.currentStageActiveSince + ' Days' : '--'}</span></span>
                //         </div>
                //         <div class="flex-sb borderteambottom p-b-8 p-t-8">
                //           <div class="flex-sb">
                //             <span class="fs-14">{dataSet.currentStage ? dataSet.currentStage : '--'}</span>
                //           </div>
                //           <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.currentStageActiveSince ? dataSet.currentStageActiveSince + ' Days' : '--'}</span></span>
                //         </div>
                //         <div class="flex-sb borderteambottom p-b-8 p-t-8">
                //           <div class="flex-sb">
                //             <span class="fs-14">Next Stage</span>
                //           </div>
                //           <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.nextStage ? dataSet.nextStage : '--'}</span></span>
                //         </div>
                //         {(dataSet && dataSet.completedTasks > 0 && dataSet.pendingTasks > 0) &&
                //           <div class="flex-sb borderteambottom p-b-8 p-t-8">
                //             <div class="flex-sb">
                //               <span class="fs-14">{`${dataSet.pendingTasks}  Completed out of /  ${dataSet.completedTasks}`}</span>
                //             </div>
                //           </div>
                //         }
                //       </div>
                //     </div>
                //   </div>
                // )
              }
              {
                (!followups || followups.length === 0 ) && (
                  <div class="workspace-cards ">
                    <div class="row justify-between align-center">
                      <div class="display-flex">
                        <span class="workspace-cardsnumber fs-16 f-w-500">
                          <div class="h-24px w-24px">
                            {props.stageIcon}
                          </div>
                        </span>
                        <span class="m-l-5 fs-14">SALES WORKFLOW</span>
                      </div>
                    </div>
                    <div class="row m-t-10">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                        {
                          interactionStageData.map((item) => (
                            <div class="flex-sb borderteambottom p-b-8 p-t-8">
                              <div class="flex-sb">
                                <span class="fs-14">{item.displayName ? item.displayName : '--'}</span>
                                {
                                  dataSet.currentStage === item.displayName && (
                                    <div class="h-20px w-20px m-l-5">
                                      <img src="/assets/images/finish-flag.png" class="h-full w-full" />
                                    </div>
                                  )
                                }
                                {
                                  dataSet.nextStage === item.displayName && (
                                    <div class="h-20px w-20px m-l-5">
                                      <img src="/assets/images/inProgress.png" class="h-full w-full" />
                                    </div>
                                  )
                                }
                                {
                                  (dataSet.nextStage !== item.displayName) && (dataSet.currentStage !== item.displayName) && (
                                    <div class="h-20px w-20px m-l-5">
                                      <img src="/assets/images/notStarted.png" class="h-full w-full" />
                                    </div>
                                  )
                                }
                              </div>
                              {
                                dataSet.currentStage === item.displayName && (
                                  <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.currentStageActiveSince ? dataSet.currentStageActiveSince + ' Days' : '--'}</span></span>
                                )
                              }
                              {
                                dataSet.nextStage === item.displayName && (
                                  <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">Next Stage</span></span>
                                )
                              }
                              {
                                (dataSet.currentStage !== item.displayName) && (dataSet.nextStage !== item.displayName) && (
                                  <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">--</span></span>
                                )
                              }
                            </div>
                          ))
                        }

                        {(dataSet && dataSet.completedTasks > 0 && dataSet.pendingTasks > 0) &&
                          <div class="flex-sb borderteambottom p-b-8 p-t-8">
                            <div class="flex-sb">
                              <span class="fs-14">{`${dataSet.pendingTasks}  Completed out of /  ${dataSet.completedTasks}`}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )
              }
              <div class="workspace-cards " style='margin-top: 10px;min-height: 100px;'>
                <div class="row justify-between align-center">
                  <div class="display-flex">
                    <span class="workspace-cardsnumber fs-16 f-w-500">
                      <div class="h-24px w-24px">
                        {props.remarkIcon}
                      </div>
                    </span>
                    <span class="m-l-5 fs-14">REMARK</span>
                  </div>
                </div>
                <div class="row m-t-10">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                    {props.taskData.description}
                  </div>
                </div>
              </div>

              {
                // (!followups || followups.length === 0 ) && (
                //   <div class="workspace-cards " style='margin-top:10px'>
                //     <div class="row justify-between align-center">
                //       <div class="display-flex">
                //         <span class="workspace-cardsnumber fs-16 f-w-500">
                //           <div class="h-24px w-24px">
                //             {props.stageIcon}
                //           </div>
                //         </span>
                //         <span class="m-l-5 fs-14">INFORMATION</span>
                //       </div>
                //     </div>
                //     <div class="row m-t-10">
                //       <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                //         {
                //           (dataSet && dataSet.status !== 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && (
                //             <span>{`Current state of this task is ${dataSet.status}. Please proceed by clicking ${dataSet.displayName} to complete the task`}</span>

                //           ))
                //         }
                //         {
                //           (dataSet && dataSet.status === 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && dataSet.nextTask && dataSet.nextTask.status && dataSet.nextTask.status.toLowerCase() !== "completed" && (
                //             <span>{`Current state of the task is Complete. Next task to perform is ${dataSet.nextTask.displayName}. Click on the button below to
                //             proceed to next task.`}</span>
                //           ))
                //         }
                //         {
                //           (dataSet && dataSet.status === 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && dataSet.nextTask && dataSet.nextTask.status && dataSet.nextTask.status.toLowerCase() === "completed" && (
                //             <span>{`This task has been completed. You can view the form by clicking the ${dataSet.displayName} button below`}</span>
                //           ))
                //         }
                //         {
                //           (dataSet && dataSet.status === 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && !dataSet.nextTask && (
                //             <span>{`This task has been completed. You can view the form by clicking the ${dataSet.displayName} button below`}</span>
                //           ))
                //         }
                //         {/*
                //           Modification: Gave condition of incompleteTasks before getting the length, so it won't give any errors and semiDetailView will be shown
                //           By: Devang
                //           Date: 26/05/2022
                //         */}
                //         {
                //           (dataSet && !dataSet.areDependentTasksCompleted && dataSet.incompleteTasks && dataSet.incompleteTasks.length > 0 && dataSet.status !== "Completed" ) && (
                //             <span>{`Current state of the task is Pending. You will not be able to perform this task unless below task(s) are completed:`}</span>
                //           )
                //         }
                //         {
                //           (dataSet && !dataSet.areDependentTasksCompleted && dataSet.incompleteTasks && dataSet.incompleteTasks.length > 0 && dataSet.status !== "Completed" ) && (
                //             <div class="bor-t">
                //               {
                //                 dataSet.incompleteTasks.map((task, index) => (
                //                   <span>{index+1}. {task}<br /></span>
                //                 ))
                //               }
                //             </div>
                //           )
                //         }
                //         {(dataSet && dataSet.completedTasks > 0 && dataSet.pendingTasks > 0) &&
                //           <div class="flex-sb borderteambottom p-b-8 p-t-8">
                //             <div class="flex-sb">
                //               <span class="fs-14">{`${dataSet.pendingTasks}  Completed out of /  ${dataSet.completedTasks}`}</span>
                //             </div>
                //           </div>
                //         }
                //       </div>
                //     </div>
                //   </div>
                // )
              }
              {/*
                followups && followups.length > 0 && (
                  <div class="workspace-cards " style='margin-top:10px'>
                    <div class="row justify-between align-center">
                      <div class="display-flex">
                        <span class="workspace-cardsnumber fs-16 f-w-500">
                          <div class="h-24px w-24px">
                            {props.stageIcon}
                          </div>
                        </span>
                        <span class="m-l-5 fs-14">Follow Ups</span>
                      </div>
                    </div>
                        <div class="row m-t-10">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0" style="height:60vh;overflow:auto;">
                            {
                              followups && followups.map((followup) => (
                                <div>
                                  <div class="flex-sb p-b-8 p-t-8">
                                    <div class="flex-sb">
                                      <span class="fs-14">Follow up Date</span>
                                    </div>
                                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600">{formatDateTime(followup.createdAt)}</span>
                                  </div>
                                  <div class="flex-sb p-b-8 p-t-8">
                                    <div class="flex-sb">
                                      <span class="fs-14">Remark Category</span>
                                    </div>
                                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600" title={followup.remark}>{followup.remarkCategory}</span>
                                  </div>
                                  <div class="flex-sb p-b-8 p-t-8">
                                    <div class="flex-sb">
                                      <span class="fs-14">Remark</span>
                                    </div>
                                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600">{followup.remark}</span>
                                  </div>
                                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                                    <div class="flex-sb">
                                      <span class="fs-14">Next Follow-up Date</span>
                                    </div>
                                    <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600">{followup.nextFollowupDate ? formatDateTime(followup.nextFollowupDate): '--'}</span>
                                  </div>
                                  {
                                    followup.callID && followup.callRecordingLink && (
                                      <div class="flex-sb borderteambottom p-b-8 p-t-8">
                                        <div class="flex-sb">
                                          <span class="fs-14">Call Recording</span>
                                        </div>
                                        <span class="fs-14 color-header-blue p-l-12 p-r-24 f-w-600"><audio src = {"http://114.143.17.132:6751" + followup.callRecordingLink.split("192.168.55.2:6751")[1]} controls /></span>
                                      </div>
                                    )
                                  }
                                  {
                                    followup.callID && followup.callStatus && (
                                      <div class="flex-sb borderteambottom p-b-8 p-t-8">
                                        <div class="flex-sb">
                                          <span class="fs-14">Call Status</span>
                                        </div>
                                        <span class="fs-14 color-header-blue first-letter-capital p-l-12 p-r-24 f-w-600">{followup.callStatus}</span>
                                      </div>
                                    )
                                  }
                                </div>
                              ))
                            }
                          </div>
                        </div>

                  </div>
                )
              */}
              {
                followups && followups.length > 0 && (
                  <ListSingleCardWizard followups={followups} />
                )
              }
            </div>

          </div>
          <div class="row">
            <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12 p-l-0 p-r-0'>
              <div class="workspace-cards " style='margin-top:10px'>
                <div class="row justify-between align-center">
                  <div class="display-flex">
                    <span class="workspace-cardsnumber fs-16 f-w-500">
                      <div class="h-24px w-24px">
                        {props.instructionIcon}
                      </div>
                    </span>
                    <span class="m-l-5 fs-14">TASK INSTRUCTIONS</span>
                  </div>
                </div>
                <div class="row m-t-10">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                    {
                      (dataSet && dataSet.status !== 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && (
                        <span>{`Current state of this task is ${dataSet.status}. Please proceed by clicking ${dataSet.displayName} to complete the task`}</span>

                      ))
                    }
                    {
                      (dataSet && dataSet.status === 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && dataSet.nextTask && dataSet.nextTask.status && dataSet.nextTask.status.toLowerCase() !== "completed" && (
                        <span>{`Current state of the task is Complete. Next task to perform is ${dataSet.nextTask.displayName}. Click on the button below to
                    proceed to next task.`}</span>
                      ))
                    }
                    {
                      (dataSet && dataSet.status === 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && dataSet.nextTask && dataSet.nextTask.status && dataSet.nextTask.status.toLowerCase() === "completed" && (
                        <span>{`This task has been completed. You can view the form by clicking the ${dataSet.displayName} button below`}</span>
                      ))
                    }
                    {
                      (dataSet && dataSet.status === 'Completed' && (!dataSet.incompleteTasks || dataSet.incompleteTasks.length === 0) && !dataSet.nextTask && (
                        <span>{`This task has been completed. You can view the form by clicking the ${dataSet.displayName} button below`}</span>
                      ))
                    }
                    {/*
                  Modification: Gave condition of incompleteTasks before getting the length, so it won't give any errors and semiDetailView will be shown
                  By: Devang
                  Date: 26/05/2022
                */}
                    {
                      (dataSet && !dataSet.areDependentTasksCompleted && dataSet.incompleteTasks && dataSet.incompleteTasks.length > 0 && dataSet.status !== "Completed" ) && (
                        <span>{`Current state of the task is Pending. You will not be able to perform this task unless below task(s) are completed:`}</span>
                      )
                    }
                    {
                      (dataSet && !dataSet.areDependentTasksCompleted && dataSet.incompleteTasks && dataSet.incompleteTasks.length > 0 && dataSet.status !== "Completed" ) && (
                        <div class="bor-t">
                          {
                            dataSet.incompleteTasks.map((task, index) => (
                              <span>{index+1}. {task}<br /></span>
                            ))
                          }
                        </div>
                      )
                    }
                    {(dataSet && dataSet.completedTasks > 0 && dataSet.pendingTasks > 0) &&
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">{`${dataSet.pendingTasks}  Completed out of /  ${dataSet.completedTasks}`}</span>
                    </div>
                  </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12 p-l-0 p-r-0'>
              <div class="workspace-cards " style='margin-top:10px'>
                <div class="row justify-between align-center">
                  <div class="display-flex">
                    <span class="workspace-cardsnumber fs-16 f-w-500">
                      <div class="h-24px w-24px">
                        {props.instructionIcon}
                      </div>
                    </span>
                    <span class="m-l-5 fs-14">ACTIONS</span>
                  </div>
                </div>
                <div class="row m-t-10">

                  <button class="primary-button display-flex align-center min-w-fit-content" title='View History' >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M12.95 21Q11.25 21 9.637 20.363Q8.025 19.725 6.7 18.4L8.1 17Q9.125 17.975 10.4 18.488Q11.675 19 13 19Q15.9 19 17.95 16.95Q20 14.9 20 12Q20 9.1 17.95 7.05Q15.9 5 13 5Q10.125 5 8.062 7.012Q6 9.025 6 12.2L7.85 10.35L9.25 11.75L5 16L0.75 11.75L2.2 10.35L4 12.2Q4 10.25 4.7 8.562Q5.4 6.875 6.625 5.637Q7.85 4.4 9.488 3.7Q11.125 3 13 3Q14.85 3 16.488 3.712Q18.125 4.425 19.35 5.65Q20.575 6.875 21.288 8.512Q22 10.15 22 12Q22 13.85 21.288 15.488Q20.575 17.125 19.35 18.35Q18.125 19.575 16.475 20.288Q14.825 21 12.95 21ZM15.8 16.2 12 12.4V7H14V11.6L17.2 14.8Z"/></svg>
                  </button>
                  <button class="primary-button display-flex align-center min-w-fit-content m-l-5" title='View Messages'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" width="24"><path d="M6 14H18V12H6ZM6 11H18V9H6ZM6 8H18V6H6ZM22 22 18 18H4Q3.175 18 2.588 17.413Q2 16.825 2 16V4Q2 3.175 2.588 2.587Q3.175 2 4 2H20Q20.825 2 21.413 2.587Q22 3.175 22 4ZM4 4V16Q4 16 4 16Q4 16 4 16H18.825L20 17.175V4Q20 4 20 4Q20 4 20 4H4Q4 4 4 4Q4 4 4 4ZM4 4V17.175V16Q4 16 4 16Q4 16 4 16V4Q4 4 4 4Q4 4 4 4Q4 4 4 4Q4 4 4 4Z"/></svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      }
      {/*
        Modification: Added courierCaseDetails template and integrated with backend response
        By: Devang
        Date: 27/05/2022
      */}
      {props.typeOfCard === 'courierCaseDetails' && dataSet &&
        <div class="row">
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">{dataSet.caseID}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Created By</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.displayName ? dataSet.displayName : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received Date</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.receivedDate ? formatDateTime(dataSet.receivedDate) : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received From</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.receivedFrom ? dataSet.receivedFrom : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Description</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDescription ? dataSet.courierDescription : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Type Of Courier</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.typeOfCourier ? dataSet.typeOfCourier : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Department</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.departmentDisplayName ? dataSet.departmentDisplayName : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Whom To</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.whomTo ? dataSet.whomTo : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Legal</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.isImportant ? "Yes" : "No"}</span></span>
                  </div>
                  {dataSet.isImportant &&
                    <div class="flex-sb borderteambottom p-b-8 p-t-8">
                      <div class="flex-sb">
                        <span class="fs-14">MD Response</span>
                      </div>
                      <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.acceptOrReject && dataSet.acceptOrReject === "accept" ? "Accepted" : "Rejected"}</span></span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-5 p-r-0 display-flex justify-center align-start'>
            {/*
              Modification: Changed the md size of workspace parent div to 12 to make it responsive
              By: Devang
              Date: 27/05/2022
            */}
            {dataSet.uploadPackagePhotoSrc && dataSet.uploadPackagePhotoSrc.length &&
              <div class='col-xs-12 col-sm-6 col-md-12 col-lg-12 p-l-0 p-r-0 display-flex justify-center align-start'>
                <div class="workspace-cards  w-full">
                  <div class="row justify-between align-center">
                    <div class="display-flex">
                      <span class="workspace-cardsnumber fs-16 f-w-500">
                        <div class="h-24px w-24px">
                          {props.stageIcon}
                        </div>
                      </span>
                      <span class="m-l-5 fs-14">Uploaded Images</span>
                    </div>
                  </div>
                  <div class="row m-t-10">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                      {
                        <div class="flex-sb borderteambottom p-b-8 p-t-8 display-flex justify-center align-center">
                          {dataSet.uploadPackagePhotoSrc.map((imgSrc,id) => (
                            <div class="w-full" style="margin: 0 calc(1rem + 5px)">
                              <img id={`image-0-${id}`} class="cursor-pointer object-fit-contain w-full" src={imgSrc} onClick={(e)=> viewAllImages(e,id, 0)} />
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      {props.typeOfCard === 'courierDetails' && dataSet &&
        <div class="row">
          {/*
            Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
            By: Devang
            Date: 26/05/2022
          */}
          <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12 p-l-0 p-r-0' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  {/*
                    Modification: Handled dataSet property - an error was returned in console (reading property of undefined)
                    By: Devang
                    Date: 26/05/2022
                  */}
                  <span class="m-l-5 fs-14">{dataSet.courierDetails && dataSet.courierDetails.typeOfCourier}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received from</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.receivedFrom ? dataSet.courierDetails.receivedFrom : '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Whom To</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.whomTo ? dataSet.courierDetails.whomTo : '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Legal?</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.isImportant ? 'Yes' : 'No'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">MD Accepted?</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.acceptOrReject === "accept" ? 'Yes' : 'No'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received date</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.receivedDate ? formatDateTime(dataSet.courierDetails.receivedDate) : '--'}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {props.typeOfCard === 'callDetails' && dataSet &&
        <div class="row">
          {/*
            Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
            By: Devang
            Date: 26/05/2022
          */}
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  {/*
                    Modification: Handled dataSet property - an error was returned in console (reading property of undefined)
                    By: Devang
                    Date: 26/05/2022
                  */}
                  <span class="m-l-5 fs-14">{dataSet.courierDetails && dataSet.courierDetails.typeOfCourier}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received from</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.receivedFrom ? dataSet.courierDetails.receivedFrom : '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Whom To</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.whomTo ? dataSet.courierDetails.whomTo : '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Legal?</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.isImportant ? 'Yes' : 'No'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">MD Accepted?</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.acceptOrReject === "accept" ? 'Yes' : 'No'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received date</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.receivedDate ? formatDateTime(dataSet.courierDetails.receivedDate) : '--'}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/*
        Modification: Added inboxCaseDetails template and binded Data
        Date: 26/05/2022
        By: Devang
      */}
      {props.typeOfCard === 'inboxCaseDetails' && dataSet &&
        <div class="row">
          {/*
            Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
            By: Devang
            Date: 26/05/2022
          */}
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">{dataSet.taskDetails && dataSet.taskDetails.caseID}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.interaction && dataSet.taskDetails.interaction["dynamicProperties_customerName"]) ? dataSet.taskDetails.interaction["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.interaction && dataSet.taskDetails.interaction["dynamicProperties_source"]) ? dataSet.taskDetails.interaction["dynamicProperties_source"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Sub Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.interaction && dataSet.taskDetails.interaction["dynamicProperties_subSource"]) ? dataSet.taskDetails.interaction["dynamicProperties_subSource"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Area</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.interaction && dataSet.taskDetails.interaction["dynamicProperties_area"]) ? dataSet.taskDetails.interaction["dynamicProperties_area"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Interested Model</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.model) ? dataSet.taskDetails.model : (dataSet.taskDetails.interaction && dataSet.taskDetails.interaction['dynamicProperties_selectedModelName']) ? dataSet.taskDetails.interaction['dynamicProperties_selectedModelName']: '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Interested Variant</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.variant) ? dataSet.taskDetails.variant : (dataSet.taskDetails.interaction && dataSet.taskDetails.interaction['dynamicProperties_selectedVariantName']) ? dataSet.taskDetails.interaction['dynamicProperties_selectedVariantName']: '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">No. of Visitors</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.taskDetails && dataSet.taskDetails.visitDetails) && dataSet.taskDetails.visitDetails.noOfVisitors ? dataSet.taskDetails.visitDetails.noOfVisitors : '01'}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-5 p-r-0'>
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="black"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" /></svg>
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">Message Details</span>
                </div>
              </div>
              {/*
                Modification: Changed the data for this card and data-key binding
                By: Devang
                Date: 27/05/2022
              */}
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Message</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.message ? dataSet.message : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received At</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.createdAt ? formatDateTime(dataSet.createdAt) : "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received From</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.userName ? dataSet.userName : "--"}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {props.typeOfCard === 'inboxDetails' && dataSet &&
        <div class="row">
          {/*
            Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
            By: Devang
            Date: 26/05/2022
          */}
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  {/*
                    Modification: Handled dataSet property - an error was returned in console (reading property of undefined)
                    By: Devang
                    Date: 26/05/2022
                  */}
                  <span class="m-l-5 fs-14">{dataSet.courierDetails && dataSet.courierDetails.typeOfCourier}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received from</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.receivedFrom ? dataSet.courierDetails.receivedFrom : '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Whom To</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.whomTo ? dataSet.courierDetails.whomTo : '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Legal?</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.isImportant ? 'Yes' : 'No'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">MD Accepted?</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.acceptOrReject === "accept" ? 'Yes' : 'No'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Received date</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.courierDetails && dataSet.courierDetails.receivedDate ? formatDateTime(dataSet.courierDetails.receivedDate) : '--'}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {props.typeOfCard === 'repairRequest' && dataSet &&
        <div class="row">
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">Not ok Checklist Items</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  {
                    (props.notOkChecklistItems && props.notOkChecklistItems.length !== 0) && props.notOkChecklistItems.map((checklistItem, index) => (
                      <div class="flex-sb borderteambottom p-b-8 p-t-8">
                        <div class="flex-sb">
                          <span class="fs-14">{index+1}. {checklistItem.displayName}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0'>
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.stageIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">Uploaded Images</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  {
                    (props.notOkChecklistItems && props.notOkChecklistItems.length !== 0) && props.notOkChecklistItems.map((item, index) => (
                      <div class="flex-sb borderteambottom p-b-8 p-t-8">
                        {item.uploadFileSrc && item.uploadFileSrc.map((imgSrc,id) => (
                          <div style="margin-left: calc(1rem + 5px)">
                            <img id={`image-${index}-${id}`} class="cursor-pointer object-fit-contain" src={imgSrc} width="100" height="100" onClick={(e)=> viewAllImages(e,id, index)} />
                          </div>
                        ))}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {props.typeOfCard === 'discountApproval' && dataSet &&
        <div class="row">
          {/*
            Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
            By: Devang
            Date: 26/05/2022
          */}
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">{dataSet.caseID}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_customerName"]) ? dataSet.interaction["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_source"]) ? dataSet.interaction["dynamicProperties_source"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Sub Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_subSource"]) ? dataSet.interaction["dynamicProperties_subSource"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Area</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{(dataSet.interaction && dataSet.interaction["dynamicProperties_area"]) ? dataSet.interaction["dynamicProperties_area"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Interested Model</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.model ? dataSet.model : (dataSet.interaction && dataSet.interaction['dynamicProperties_selectedModelName']) ? dataSet.interaction['dynamicProperties_selectedModelName']: '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Interested Variant</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.variant ? dataSet.variant : (dataSet.interaction && dataSet.interaction['dynamicProperties_selectedVariantName']) ? dataSet.interaction['dynamicProperties_selectedVariantName']: '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">No. of Visitors</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.visitDetails && dataSet.visitDetails.noOfVisitors ? dataSet.visitDetails.noOfVisitors : '01'}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0'>
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.stageIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">Purchase Details</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Purchase Price</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{getFormattedAmount(700000)}</span></span>
                  </div>
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Age</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">40 Days</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-0'>
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.stageIcon}
                    </div>
                  </span>
                  <span class="m-l-5 fs-14">Discount Details</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottom p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Requested Discount</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600"><span class="fs-14 first-letter-capital">{dataSet.interaction ? getFormattedAmount(dataSet.interaction['dynamicProperties_discount']): getFormattedAmount(0)}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {props.typeOfCard === 'formCard' && props.group && props.groupProps && props.interactionObj &&
        <div class="row">
          {/*
            Modification: Removed unnecessary condition of checking if dataSet exists from below code as it is already checked above
            By: Devang
            Date: 26/05/2022
          */}
          <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="m-l-5 fs-14 listcard-modal-label">{props.group}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  {
                    props.groupProps && props.groupProps.length > 0 && props.groupProps.map((groupProp, propIndex) => (
                      ( (!groupProp.dependentProp) || ((groupProp.dependentProp && groupProp.dependentPropValue) && (props.interactionObj && props.interactionObj["dynamicProperties_"+groupProp.dependentProp] === groupProp.dependentPropValue))) && (
                        <div class="flex-sb borderteambottom p-b-8 p-t-8">
                          <div class="flex-sb">
                            <span class="fs-14 ">{groupProp.displayName}</span>
                          </div>
                          {
                            groupProp.formType === "file" && (
                              <div class="uploaded-image">
                                <img id={`image-${propIndex}`} class="cursor-pointer object-fit-contain w-half" src={groupProp.signedUrl} width="100" height="100" onClick={(e)=> viewAllImages(e, propIndex,0)} />
                              </div>
                            )
                          }
                          {
                            (groupProp.name === "waitingPeriodMin" || groupProp.name === "waitingPeriodMax") && (
                              <span id={groupProp.displayName} class="color-header-blue p-l-12 p-r-24 min-h-85 text-align-last"><span class={"fs-14 first-letter-capital list-card-value "}>{(props.interactionObj['dynamicProperties_'+ groupProp.name] && props.interactionObj['dynamicProperties_'+ groupProp.name] > 7) ? getWeeks(props.interactionObj['dynamicProperties_'+ groupProp.name]) + " Weeks": props.interactionObj['dynamicProperties_'+ groupProp.name] + " Days"}</span></span>
                            )
                          }
                          {
                            (groupProp.name === "selectedModel") && (
                              <span id={groupProp.displayName} class="color-header-blue p-l-12 p-r-24 min-h-85 text-align-last"><span class={"fs-14 first-letter-capital list-card-value "}>{modelMapping[props.interactionObj['dynamicProperties_selectedModelName']]}</span></span>
                            )
                          }
                          {
                            (groupProp.name === "selectedVariant") && (
                              <span id={groupProp.displayName} class="color-header-blue p-l-12 p-r-24 min-h-85 text-align-last"><span class={"fs-14 first-letter-capital list-card-value "}>{modelMapping[props.interactionObj['dynamicProperties_selectedVariantName']]}</span></span>
                            )
                          }
                          {
                            (groupProp.formType !== "file" && groupProp.name !== "selectedModel" && groupProp.name !== "selectedVariant" && groupProp.name !== "waitingPeriodMin" && groupProp.name !== "waitingPeriodMax") && (
                              <span id={groupProp.name} class="color-header-blue p-l-12 p-r-24 min-h-85 text-align-last"><span title={props.interactionObj['dynamicProperties_'+ groupProp.name]} class={"fs-14 first-letter-capital list-card-value " + (props.interactionObj['dynamicProperties_'+ groupProp.name] && props.interactionObj['dynamicProperties_'+ groupProp.name] === "no" ? "text-color-red" : props.interactionObj['dynamicProperties_'+ groupProp.name] === "yes" ? "text-color-green" : "")}>
                                {
                                  props.interactionObj['dynamicProperties_'+ groupProp.name] ?
                                    ((typeof props.interactionObj['dynamicProperties_'+ groupProp.name] === "string" && props.interactionObj['dynamicProperties_'+ groupProp.name].length < 15) ? props.interactionObj['dynamicProperties_'+ groupProp.name] : (props.interactionObj['dynamicProperties_'+ groupProp.name].toString().slice(0,15) + "...")): '--'}</span>
                              <span class="p-l-10 pos-relative" title="Click to Copy"><svg fill="#808080" class={props.interactionObj['dynamicProperties_'+ groupProp.name] ? "cursor-pointer": "cursor-not-allowed"} onClick={(e) => props.interactionObj['dynamicProperties_'+ groupProp.name] ? copyText(props.interactionObj['dynamicProperties_'+ groupProp.name], groupProp.name) : ""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15"
                                height="15"
                              ><g id="_01_align_center" data-name="01 align center"><path d="M22,4.145,17.986,0H10A3,3,0,0,0,7,3V4H5A3,3,0,0,0,2,7V24H18V19h4ZM16,22H4V7A1,1,0,0,1,5,6H7V19h9ZM9,17V3a1,1,0,0,1,1-1h6V6h4V17Z" /></g></svg>
                              <span id={`${groupProp.name}-tooltip`} class="tooltip">Copied!</span>
                              </span>
                              </span>
                            )
                          }
                        </div>
                      )
                    ))
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {props.typeOfCard === 'case' && dataSet &&
        /* <div class="row">
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span class="workspace-cardsnumber fs-16 f-w-500">
                    <div class="h-24px w-24px">
                      {props.caseIcon}
                    </div>
                  </span>
                  <span class="m-l-10 fs-14 align-self-center cursor-pointer" title="Click to view task" onClick={(e)=> redirectToCaseDetail(dataSet.uuid)}>{dataSet.displayName}</span>
                </div>
              </div>
              <div class="row m-t-10">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_customerName"]) ? dataSet["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_source"]) ? dataSet["dynamicProperties_source"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Sub Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_subSource"]) ? dataSet["dynamicProperties_subSource"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Area</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_area"]) ? dataSet["dynamicProperties_area"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Selected Model</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{dataSet.model ? dataSet.model : (dataSet && dataSet['dynamicProperties_selectedModelName']) ? dataSet['dynamicProperties_selectedModelName']: '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Selected Variant</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{dataSet.variant ? dataSet.variant : (dataSet && dataSet['dynamicProperties_selectedVariantName']) ? dataSet['dynamicProperties_selectedVariantName']: '--'}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">No. of Visitors</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{dataSet.visitDetails && dataSet.visitDetails.noOfVisitors ? dataSet.visitDetails.noOfVisitors : '01'}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-5 p-r-0'>


            {
              props.followups && props.followups.length > 0 && (

                <ListSingleCardWizard followups={props.followups} />
              )
            }
          </div>
          <div class='col-xs-12 col-sm-6 col-md-6 col-lg-6 p-l-5 p-r-0'>

            {
              props.tasks && props.tasks.length > 0 && (

                <TaskListSingleCardWizard followups={props.tasks} />
              )
            }
          </div>

        </div> */
        <div style='margin-top: -15px'>
          <div style='margin-top: -15px' class='col-xs-12 col-sm-6 col-md-12 col-lg-12 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex" style='position: relative'>
                  <span>Step 1  </span>
                  <p onClick={(e) => StageDetailModalVisibility(e, 'Enquiry')} class="f-w-600 fs-14 align-self-center cursor-pointer" title="Click to view Details"> : Enquiry </p>
                </div>
                <div title='Completed' style='position: absolute;left: 118px;top: 5px;'><img src='/assets/images/two-motor-flags.png' style='width: 30px'/></div>

              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_customerName"]) ? dataSet["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_source"]) ? dataSet["dynamicProperties_source"]: "--"}</span></span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div style='margin-top: -15px' class='col-xs-12 col-sm-6 col-md-12 col-lg-12 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex">
                  <span>Step 2  </span>
                  <p onClick={(e) => StageDetailModalVisibility(e, 'Booking')} class="f-w-600 fs-14 align-self-center cursor-pointer" title="Click to view details"> : Booking</p>
                </div>
                <div title='Completed' style='position: absolute;left: 118px;top: 5px;'><img src='/assets/images/work-in-progress.png' style='width: 30px'/></div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_customerName"]) ? dataSet["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_source"]) ? dataSet["dynamicProperties_source"]: "--"}</span></span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div style='margin-top: -15px' class='col-xs-12 col-sm-6 col-md-12 col-lg-12 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center">
                <div class="display-flex" style='position: relative'>
                  <span>Step 3  </span>
                  <p onClick={(e) => StageDetailModalVisibility(e, 'Retail')} class="f-w-600 fs-14 align-self-center cursor-pointer" title="Click to view task"> : Retail</p>
                </div>
                <div style="display: flex; "><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M8.45 15.525h1.5v-7.05h-1.5Zm3.6 0L17.35 12l-5.3-3.525ZM12 21.5q-1.975 0-3.712-.75Q6.55 20 5.275 18.725T3.25 15.712Q2.5 13.975 2.5 12t.75-3.713Q4 6.55 5.275 5.275T8.288 3.25Q10.025 2.5 12 2.5t3.713.75q1.737.75 3.012 2.025t2.025 3.012q.75 1.738.75 3.713t-.75 3.712q-.75 1.738-2.025 3.013t-3.012 2.025q-1.738.75-3.713.75Zm0-1.5q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg></div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_customerName"]) ? dataSet["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_source"]) ? dataSet["dynamicProperties_source"]: "--"}</span></span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div style='margin-top: -15px' class='col-xs-12 col-sm-6 col-md-12 col-lg-12 p-l-0 p-r-5' >
            <div class="workspace-cards ">
              <div class="row justify-between align-center" style='position: relative'>
                <div class="display-flex">
                  <span>Step 4  </span>
                  <p onClick={(e) => StageDetailModalVisibility(e, 'Delivery')} class="f-w-600 fs-14 align-self-center cursor-pointer" title="Click to view task"> : Delivery </p>
                </div>
                <div title='Cancel' style="display: flex; "><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m8.4 16.65 3.6-3.6 3.6 3.6 1.05-1.05-3.6-3.6 3.6-3.6-1.05-1.05-3.6 3.6-3.6-3.6L7.35 8.4l3.6 3.6-3.6 3.6ZM12 21.5q-1.975 0-3.712-.75Q6.55 20 5.275 18.725T3.25 15.712Q2.5 13.975 2.5 12t.75-3.713Q4 6.55 5.275 5.275T8.288 3.25Q10.025 2.5 12 2.5t3.713.75q1.737.75 3.012 2.025t2.025 3.012q.75 1.738.75 3.713t-.75 3.712q-.75 1.738-2.025 3.013t-3.012 2.025q-1.738.75-3.713.75Zm0-1.5q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg></div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Customer Name</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_customerName"]) ? dataSet["dynamicProperties_customerName"]: "--"}</span></span>
                  </div>
                  <div class="flex-sb borderteambottomdark  p-b-8 p-t-8">
                    <div class="flex-sb">
                      <span class="fs-14">Source</span>
                    </div>
                    <span class="color-header-blue p-l-12 p-r-24 f-w-600 cardvalue-companyblue"><span class="fs-14 first-letter-capital">{(dataSet && dataSet["dynamicProperties_source"]) ? dataSet["dynamicProperties_source"]: "--"}</span></span>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      }
      {isStageDetailModalOpen &&
        <div class="visitmodalslide">
          <Modal title="View Details" modalSize="is-small-right" isProfileModal
            modalDisplay={(isStageDetailModalOpen ? 'show-modal' : 'hide-modal')} onClose={(e) => closeStageDetailModal(e)} >
            {
              isStageDetailModalOpen && (
                <StageDetailModal modalFor={stageDetailModalFor} open={isStageDetailModalOpen} onClose={(e) => closeStageDetailModal(e)} />
              )
            }
          </Modal>
        </div>
      }
    </div>
  );
};

export default ListSingleCard;
