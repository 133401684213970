const Documents = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action()} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
	id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M1383 5106 c-124 -39 -192 -129 -209 -276 l-7 -60 -41 0 c-59 0 -136 -25 -183 -59 -49 -35 -101 -113 -109 -162 l-6 -36 -65 -6 c-120 -12 -214 -74 -265 -175 l-23 -47 -3 -1306 -2 -1306 21 -26 c23 -31 72 -36 105 -13 19 14 19 42 24 1319 5 1299 5 1306 26 1333 33 45 66 64 122 71 l52 6 2 -1934 3 -1934 22 -48 c43 -92 102 -144 194 -171 49 -15 158 -16 1038 -14 l983 3 19 24 c26 32 24 73 -4 99 l-23 22 -991 2 c-984 3 -992 3 -1019 24 -15 11 -37 33 -48 48 l-21 27 0 2002 c0 1953 0 2003 19 2034 25 41 84 73 134 73 l41 0 3 -219 c3 -199 5 -221 22 -240 22 -25 73 -28 102 -7 18 14 19 33 24 363 6 390 6 390 80 432 38 21 40 21 1123 21 l1086 0 3 -337 3 -338 27 -51 c31 -59 84 -108 147 -138 43 -20 65 -21 379 -24 l332 -3 -2 -1609 c-3 -1607 -3 -1609 -24 -1636 -11 -15 -33 -37 -48 -48 -27 -21 -28 -21 -1516 -21 -1488 0 -1489 0 -1516 21 -15 11 -37 33 -48 48 -21 27 -21 30 -24 1556 l-2 1529 -27 20 c-30 24 -42 26 -78 9 -16 -7 -29 -24 -36 -47 -7 -24 -9 -521 -7 -1546 l3 -1510 23 -50 c30 -65 92 -128 156 -157 l51 -23 1374 -3 1374 -2 -6 -53 c-7 -55 -26 -88 -71 -121 -26 -20 -43 -21 -398 -26 -351 -5 -370 -6 -384 -24 -20 -28 -17 -69 5 -97 19 -24 21 -24 255 -27 130 -2 236 -5 236 -8 0 -21 -41 -69 -72 -85 -36 -18 -81 -19 -1510 -19 -1407 0 -1475 1 -1508 18 -22 12 -44 35 -57 62 l-23 44 0 562 0 563 -26 20 c-15 12 -37 21 -50 21 -28 0 -61 -30 -69 -62 -3 -13 -5 -277 -3 -588 l3 -565 28 -57 c30 -61 74 -105 140 -140 l42 -23 1521 0 1520 0 59 30 c70 36 128 106 146 176 l12 47 62 5 c162 12 274 123 291 284 l7 60 60 7 c116 12 209 75 259 174 l23 47 0 1678 0 1677 -465 465 -465 465 -1145 -1 c-913 0 -1153 -3 -1187 -13z m2755 -874 c-244 -2 -253 -2 -297 20 -78 39 -81 51 -81 353 l0 260 315 -315 315 -315 -252 -3z" />
          <path d="M1866 3479 c-33 -26 -36 -79 -6 -109 20 -20 33 -20 1049 -20 965 0 1030 1 1049 18 12 9 24 29 28 44 5 22 0 33 -24 58 l-30 30 -1020 0 c-1016 0 -1019 0 -1046 -21z" />
          <path d="M1860 2920 c-30 -30 -27 -83 6 -109 27 -21 30 -21 1040 -21 732 0 1020 3 1038 11 51 23 55 103 7 128 -13 8 -342 11 -1045 11 -1013 0 -1026 0 -1046 -20z" />
          <path d="M1890 2383 c-33 -12 -50 -37 -50 -73 0 -76 -85 -70 1072 -70 1024 0 1035 0 1053 20 25 28 23 76 -4 103 l-22 22 -1017 2 c-559 1 -1024 -1 -1032 -4z" />
        </g>
      </svg>
    </span>
  );
};
export default Documents;
