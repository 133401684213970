import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const VB = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M1482 3511 c-9 -16 -129 -251 -265 -523 -179 -356 -251 -508 -258 -547 -13 -75 -11 -429 2 -493 27 -126 101 -223 212 -280 240 -121 755 -125 1004 -8 175 81 227 180 239 452 11 279 -17 413 -108 513 -106 116 -281 169 -545 163 -84 -2 -153 1 -153 5 0 4 81 170 180 368 100 198 178 364 175 369 -4 6 -98 10 -236 10 l-229 0 -18 -29z m395 -1124 c55 -30 67 -59 71 -172 4 -86 1 -109 -16 -148 -33 -73 -58 -82 -242 -82 -179 0 -218 11 -249 74 -39 75 -34 234 7 291 34 46 100 60 257 57 111 -2 147 -6 172 -20z"/>
          <path d="M2846 3493 c-8 -27 -84 -291 -168 -588 l-153 -540 0 -147 c0 -80 4 -150 8 -155 5 -4 202 -9 438 -11 l429 -3 0 -213 c0 -118 3 -221 6 -230 5 -14 35 -16 229 -16 194 0 224 2 229 16 3 9 6 112 6 230 l0 214 131 0 c72 0 139 5 150 10 18 10 19 23 19 193 0 129 -4 187 -12 195 -8 8 -56 12 -150 12 l-138 0 0 269 c0 148 -3 276 -6 285 -3 9 -11 16 -18 16 -6 0 -105 -30 -220 -67 -178 -58 -209 -71 -217 -91 -5 -13 -9 -111 -9 -218 l0 -194 -185 0 c-129 0 -185 3 -185 11 0 6 63 238 140 516 77 278 140 516 140 529 l0 24 -224 0 -224 0 -16 -47z"/>
        </g>
      </svg>
    </span>
  );
};

export default VB;
