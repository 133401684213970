import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const ImageIcon = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || ""} height={props.width || ""} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet" onclick={(e) => props.action && props.action(e)}>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M515 5104 c-184 -39 -360 -176 -442 -344 -78 -159 -73 -6 -73 -2200 0 -2194 -5 -2041 73 -2200 58 -119 168 -229 287 -287 159 -78 6 -73 2200 -73 2194 0 2041 -5 2200 73 119 58 229 168 287 287 78 159 73 6 73 2200 0 2194 5 2041 -73 2200 -58 119 -168 229 -287 287 -159 78 -5 73 -2207 72 -1692 -1 -1978 -3 -2038 -15z m4090 -317 c78 -36 146 -104 182 -181 l28 -61 3 -920 c1 -506 1 -1005 0 -1110 l-3 -190 -345 425 c-190 234 -362 444 -383 467 -118 129 -298 167 -457 98 -61 -27 -105 -68 -783 -744 -560 -559 -720 -714 -731 -706 -8 6 -133 108 -277 228 -151 125 -286 229 -318 245 -50 24 -67 27 -161 27 -172 0 -111 43 -973 -692 l-87 -75 2 1474 3 1473 28 60 c49 104 146 184 251 205 22 4 923 7 2001 6 l1960 -1 60 -28z m-768 -1750 c12 -7 238 -279 503 -605 l481 -592 -3 -633 -3 -632 -28 -60 c-36 -78 -104 -146 -181 -182 l-61 -28 -1985 0 -1985 0 -61 28 c-77 36 -145 104 -181 182 l-28 60 -3 315 -2 315 436 370 c628 533 581 495 615 495 17 0 39 -5 50 -11 11 -6 158 -125 327 -266 169 -140 321 -264 337 -274 37 -23 96 -24 141 -3 19 9 356 341 770 757 406 408 749 750 763 759 30 21 67 22 98 5z"/>
          <path d="M1490 4481 c-153 -29 -277 -96 -390 -210 -142 -143 -211 -306 -211 -501 0 -52 5 -117 10 -145 55 -279 263 -496 543 -567 109 -27 288 -22 388 12 198 67 342 193 430 375 62 128 75 184 75 320 -1 84 -6 129 -23 190 -70 245 -251 429 -497 505 -78 24 -249 35 -325 21z m253 -316 c189 -57 318 -266 286 -465 -31 -198 -193 -347 -390 -358 -180 -10 -333 82 -412 248 -40 83 -49 208 -22 291 39 119 119 213 222 261 104 49 205 56 316 23z"/>
        </g>
      </svg>
    </span>
  );
};

export default ImageIcon;
