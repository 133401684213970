import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const BullsEye = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" onclick={(e) => props.action && props.action()} version="1.0" width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2315 5109 c-884 -91 -1661 -631 -2049 -1423 -93 -188 -137 -308 -186 -500 -61 -240 -74 -355 -74 -626 0 -271 13 -386 74 -626 49 -192 93 -312 185 -500 248 -505 663 -920 1170 -1169 248 -122 496 -199 770 -241 169 -26 541 -26 710 0 562 85 1054 333 1450 731 439 440 689 974 746 1595 14 158 7 413 -16 570 -106 718 -510 1358 -1115 1765 -322 216 -665 349 -1060 410 -126 19 -477 28 -605 14z m420 -509 c316 -30 599 -121 863 -278 528 -314 880 -831 984 -1442 30 -177 30 -463 0 -640 -150 -883 -820 -1552 -1702 -1702 -166 -28 -454 -30 -615 -5 -448 72 -836 269 -1150 582 -657 656 -791 1663 -328 2464 160 276 415 545 670 704 393 247 841 358 1278 317z" />
          <path d="M2433 4090 c-368 -31 -685 -181 -954 -449 -182 -183 -299 -368 -374 -595 -152 -458 -86 -937 186 -1346 84 -127 282 -325 409 -409 409 -272 889 -338 1346 -186 227 75 412 192 595 374 182 183 299 368 374 595 152 458 86 937 -186 1346 -84 127 -282 325 -409 409 -302 201 -641 290 -987 261z m402 -544 c357 -106 617 -372 717 -734 18 -65 22 -104 22 -252 0 -148 -4 -187 -22 -252 -52 -189 -135 -333 -271 -469 -191 -191 -406 -285 -674 -296 -197 -9 -347 23 -511 107 -256 131 -434 349 -523 637 -25 82 -27 100 -27 273 0 173 2 191 27 273 90 293 277 517 538 644 169 82 270 103 479 99 132 -3 168 -8 245 -30z" />
          <path d="M2433 3055 c-271 -73 -437 -352 -368 -618 100 -383 570 -516 851 -242 285 279 155 759 -232 860 -72 18 -182 18 -251 0z" />
        </g>
      </svg>
    </span>
  );
};

export default BullsEye;
