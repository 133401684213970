import { h } from 'preact';

// from flaticon
const Mic = (props) => {
  return (
    <span onclick={(e) => props.action && props.action(e)} class={props.masterClasses || ''} title={props.title || ''}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512.000000 512.000000"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke="none">
          <path d="M2388 5105 c-104 -17 -196 -49 -298 -101 -263 -135 -446 -365 -528 -662 l-27 -97 0 -840 0 -840 27 -97 c98 -355 341 -613 684 -727 257 -86 538 -62 784 65 264 136 446 365 528 662 l27 97 0 840 0 840 -27 97 c-81 294 -263 524 -521 659 -200 104 -429 141 -649 104z"/>
          <path d="M975 2886 c-37 -16 -83 -68 -91 -103 -11 -42 4 -261 25 -363 45 -224 150 -468 279 -650 86 -122 281 -317 402 -403 117 -83 321 -185 455 -227 105 -34 275 -70 325 -70 l30 0 0 -375 0 -374 -364 -3 c-352 -3 -366 -4 -393 -24 -98 -73 -98 -195 0 -268 28 -21 31 -21 917 -21 886 0 889 0 917 21 98 73 98 195 0 268 -27 20 -41 21 -393 24 l-364 3 0 374 0 375 30 0 c50 0 219 36 325 70 132 41 337 144 455 227 121 86 315 280 402 403 180 254 292 576 304 880 6 130 5 136 -17 169 -54 78 -140 102 -213 59 -64 -37 -79 -73 -87 -210 -22 -351 -153 -643 -399 -888 -195 -196 -418 -317 -692 -377 -105 -22 -407 -25 -513 -4 -182 35 -379 118 -535 224 -107 73 -274 244 -348 355 -144 216 -232 487 -232 712 0 59 -5 86 -22 120 -39 76 -127 109 -203 76z"/>
        </g>
      </svg>
    </span>
  );
};

export default Mic;
