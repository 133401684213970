import * as React from "react";

const QueuedCalls = (props) => (
  <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
    <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || '16'}
      height={props.height || "18"} viewBox="0 0 512 512"
    >
      <path d="M220.7 22.4c-7.3 2.7-7.9 3.8-17.1 30.6-14.6 42.5-15.9 53-7.2 57.4 3.9 1.9 67.8 22.6 70 22.6 3 0 12.9-4.3 15.8-6.9 2.5-2.2 3-3.4 3-7.3 0-5.3-2-9.7-5.7-12.6-1.4-1.2-9-4.2-16.8-6.7-7.8-2.6-14.9-4.9-15.7-5.2-4-1.3 19.5-9.3 34.9-11.8 12.5-2.1 40.3-1.6 51.5 1 55.3 12.5 95.8 53 108.1 108 2.6 11.3 3.1 38.2 1.1 50.6-4.6 27.6-16.7 52.1-36.9 74.6-5.9 6.6-7.4 10.6-6.3 16.7 1.4 7.1 7.3 11.6 15.4 11.6 6.4 0 9.7-2.2 19-13 65.6-76.1 51.9-192.3-29.5-250.7-28.9-20.7-62.8-31.5-99.3-31.5-23.2 0-43.4 3.6-62.8 11.3-4.1 1.6-7.7 2.8-7.9 2.5-.2-.2 1.2-5.7 3.2-12.2 4.6-14.7 4.5-19.7-.6-25-2.9-3-8.2-5.5-11.4-5.4-.5.1-2.7.7-4.8 1.4zM101.5 93.4c-7.2 3.7-55.3 52.1-58.9 59.3-9.6 19.2-7.5 58.5 5.5 101.3 12.2 40.5 35.9 85.9 61.5 118 9.1 11.4 34.6 36.8 46.4 46.2 54.8 43.6 126.1 71.8 182 72.1 16.6.1 24.6-1.2 33.9-5.4 5.9-2.7 9.4-5.8 33.8-30.2 26.1-26.2 27.2-27.4 28.8-33 3.8-13.1-2.5-26.4-22-46.6-29.7-30.8-79.2-65-96.8-66.8-3-.3-6.8-.2-8.4.1-1.8.5-11.1 9-27.4 25.2l-24.5 24.5-7.5-2.7c-32-11.4-57.4-34.8-73.2-67.2l-6-12.4 26.1-26.1c28.3-28.5 29.2-29.7 27.8-38.9-3-20.1-52.3-84.8-81.1-106.6-16.4-12.4-29.8-16-40-10.8zm22.6 37.4c11.1 9.3 32.7 31.4 41.7 42.6 10.5 13.1 23.2 33.2 23.2 36.7 0 .7-11 12.4-24.4 25.9-15.9 15.9-25 25.8-26 28.2-.9 2.1-1.6 6.2-1.6 9.3 0 13.7 17.4 46.8 35.3 67.4 17.3 20 42.6 36.6 68.6 45.1 10.5 3.4 15.9 3.7 24.5 1.3 6-1.6 6.6-2.2 28.8-24.1l22.7-22.5 4.8 2.8c12.6 7.4 34.3 22.9 49.6 35.6 10.7 8.9 28.2 26.5 30.3 30.5l1.9 3.6-13.5 13.1c-14.5 14.2-27.8 24.8-36.8 29.3-7.6 3.9-16.5 4.3-33.6 1.5-55.6-9.1-110.7-34.5-152-70.1-50-43-85.9-107.6-97.6-175.5-2.2-12.7-3.1-33.3-1.7-40 .7-3.5 3.8-7 23-26.3C103.5 133 113.7 123 114 123c.4 0 4.9 3.5 10.1 7.8z" />
      <path d="M294 138.5c-4.2 1.9-7.7 5.1-9.6 8.7-2 3.9-2.1 73.3 0 78.1 1.9 4.4 7.1 9.1 13.1 11.9 4.9 2.2 5.9 2.3 41 2.6 19.8.2 37.5 0 39.3-.3 4.3-.8 10.8-6.7 12.3-11.2 2.9-9-.3-18.5-7.7-22.9-4.1-2.4-4.3-2.4-33.2-2.4H320v-26c0-24.2-.1-26.4-2-30-4.1-8.1-15.7-12.2-24-8.5z" />
    </svg>
  </span>
);
export default QueuedCalls;
