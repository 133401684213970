import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Download = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ""} xmlns="http://www.w3.org/2000/svg" width={props.width || "512px"} height={props.height || "512px"} viewBox={props.viewBox || "0 0 512 512"}>
        <path fill={props.fill || ''} d="M420,128.1V16H92V128.1A80.1,80.1,0,0,0,16,208V400H84V368H48V208a48.054,48.054,0,0,1,48-48H416a48.054,48.054,0,0,1,48,48V368H420v32h76V208A80.1,80.1,0,0,0,420,128.1Zm-32-.1H124V48H388Z" class="ci-primary"/>
        <rect width="32" height="32" x="396" y="200" fill={props.fill || ''} class="ci-primary"/>
        <path fill={props.fill || ''} d="M116,264H76v32h40V496H388V296h40V264H116ZM356,464H148V296H356Z" class="ci-primary"/>
      </svg>
    </span>
  );
};

export default Download;
