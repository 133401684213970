import { h } from 'preact';

const Analytics = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} width={props.width ||"20"} height={props.height ||"20"} viewBox={props.viewBox || "0 0 20 20"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1V19H19" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17 7L12 12L8 8L5 11" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Analytics;
