import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Approval = (props) => {
  return (
    <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || '16'}
      height={props.height || "18"} viewBox="0 0 9 10"
    >
      <path d="M1.40039 9H7.00039C7.21256 9 7.41605 8.91571 7.56608 8.76569C7.71611 8.61566 7.80039 8.41217 7.80039 8.2V3.2L5.60039 1H2.20039C1.98822 1 1.78473 1.08429 1.63471 1.23431C1.48468 1.38434 1.40039 1.58783 1.40039 1.8V3.4" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.40039 1V3.4H7.80039" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 6.20002L1.8 7.00002L3.4 5.40002" stroke={props.stroke || "#3C3C3C"} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default Approval;
