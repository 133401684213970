const CONSTANTS = {
  /* localhost url*/
  // API_URL: 'http://localhost:9000',

  /* testing url*/
  API_URL: 'https://test.kotharihyundai.com',
  SOCKETAPI_URL: 'https://socket.kotharihyundai.com',

  /* production url*/
  // SOCKETAPI_URL: 'https://sockettest.kotharihyundai.com',
  // API_URL: 'https://app.kotharihyundai.com',

  // API_URL: process.env.API_URL || 'http://localhost:9000',
  publicVapidKey: 'BHar8mMOW2JOSM37IQcHphtHytCHeIY86qvUaoFJdvypSllGSFuc4QSvXxnWtgyVYQBOOZsQPcW64W7V07mRCLc',
  googleMapsAPIKey: 'AIzaSyC2pHwk5BNWVswDVf6RZVEXFx7dvWsqAOA',
  defaultCenter: {
    lat: 20.7493472,
    lng: 78.5759262
  },
  center: {
    lat: 20.7493472,
    lng: 78.5759262
  },
  defaultZoom: 10
};

// function getCurrentLocation(position) {//eslint-disable-line
//   CONSTANTS.defaultCenter = {
//     // lat: Number(position.coords.latitude.toFixed(7)),
//     // lng: Number(position.coords.longitude.toFixed(7))
//     lat: 20.7493472,
//     lng: 78.5759262
//   };
// }

// (function getGeoLocation() {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(getCurrentLocation);
//   } else {
//     alert('Geolocation is not supported by this browser.');
//   }
// })();

export default CONSTANTS;
