import { h,Fragment } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";
import { Task, Plus, Calendar, Alarm2, Dots, CirclePlus, Department2, RoleBadge, Checklist2, Approval, FieldInput } from '../../components/svgs';
import { getInitials, isString, formatDateTime, formatDateTimeRelative, isValidDate, getDateColor, isEmptyObject, delegateClick } from '../../lib/utils';
import { getItem } from '../../lib/myStore';
import { callAPI, reportException } from '../../lib/transceiver';
import CreateEditForm from '../../components/createEditForm';
import toastr from "toastr";

const AddNuclearTaskModal = (props) => {
  const {isAddNuclearTaskModalVisible, setIsAddNuclearTaskModalVisible} = props;

  const userInfo = getItem('userinfo');
  const fileName = 'addNuclearTaskModal';

  const [isLoading, setIsLoading] = useState(false);
  const [taskName, setTaskName] = useState('');

  const [processList, setProcessList] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState({});

  const [stageList, setStageList] = useState([]);
  const [selectedStage, setSelectedStage] = useState({});

  const [selectedAssigneeType, setSelectedAssigneeType] = useState('');
  const [userList, setUserList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState({});

  const [taskDescription, setTaskDescription] = useState('');

  const typeOfTasks = [
    {name: 'checklist', displayName: 'Checklist'},
    {name: 'form', displayName: 'Form'},
    /*
      Modification: Commented approval option, as the functionality after creating the approval task is pending
      BY: Devang
      Date: 12/06/2023
    */
    // {name: 'approval', displayName: 'Approval'}
  ];
  const [selectedTypeOfTask, setSelectedTypeOfTask] = useState('');

  const [isAddingDueDate, setAddingDueDate] = useState(false);
  const [isAddingEstimatedTime, setIsAddingEstimatedTime] = useState(false);

  const [dueDate, setDueDate] = useState('');
  const [estimatedTime, setEstimatedTime] = useState('');

  const [taskChecklist, setTaskChecklist] = useState([]);

  const [approvalsList, setApprovalsList] = useState([]);
  // {userType: "user", name:"vaishnav"}, {userType:"role", name:"consultant"}

  const [formFieldlist, setFormFieldList] = useState([]);
  // {fieldName: "user", fieldValue:"vaishnav"},{fieldName:"department", fieldValue:"atul"},{fieldName:"role", fieldValue:"consultant"}

  const [inputChecklist, setinputChecklist] = useState("");
  const [editChecklistIndex, setEditChecklistIndex] = useState(0);
  const [editFormPropertyIndex, setEditFormPropertyIndex] = useState(-1);
  const [showEditDropDown, setShowEditDropDown] = useState(0);
  const [editChecklistValue, setEditChecklistValue] = useState("");
  const [addChecklistValue, setAddChecklistValue] = useState("");
  const [editApprovalValue, setEditApprovalValue] = useState({userType:"", name:"", uuid: ""});
  // const [addFormValue, setAddFormValue] = useState({fieldName:"" , fieldValue:""})
  // const [editFormValue, setEditFormValue] = useState({fieldName:"" , fieldValue:""})
  const [addApprovalValue, setAddApprovalValue] = useState({userType:"", name:"", uuid: ""});
  const [isAddingChecklistItem, setIsAddingChecklistItem] = useState(false);

  const [isCreateEditFormPopover, setIsCreateEditFormPopover] = useState(false); // state to check if any process is selected to edit or to create new process and show CreateEditForm
  const [formEntityObject, setFormEntityObject] = useState({}); // state to store data of selected process object
  const [formDynamicProps, setFormDynamicProps] = useState([]);
  const [formOperation, setFormOperation] = useState();


  useEffect(() => {
    /*mobiscroll.datepicker("#timepicker-nuclearTask", {
      controls: ['time'],
      showOnClick: true,
      display: 'bubble',
      touchUi: true,
    });*/
    mobiscroll.datepicker("#datepicker-nuclearTask", {
      controls: ['date'],
      showOnClick: true,
      display: 'bubble',
      touchUi: true,
      onChange: (event, instance) => {
        setDueDate(event.valueText);
      }
    });
  }, [isAddingDueDate]);

  useEffect(async() => {
    initialiseToaster();
    await getProcessList();
    await getUsersList();
    await getDesignationList();
    await getEntityDynamicProps();
  }, []);

  useEffect(async() => {
    if (!isEmptyObject(selectedProcess)) {
      await getStageList();
    }
  }, [selectedProcess]);

  const initialiseToaster = () => {
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-bottom-left",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "slideDown",
      "hideMethod": "slideUp"
    };
  };

  const getProcessList = async () => {
    try {
      let response = await callAPI(`get`, `/v1/interaction`, `dealershipID=${userInfo.userDealershipID}&isDealershipMasterInteraction=true`);
      if (response && response.data) {
        setProcessList(response.data);
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getProcessList'
      });
    }
  };
  const getStageList = async () => {
    try {
      let response = await callAPI(`get`, `/v1/stage`, `dealershipInteractionID=${selectedProcess.uuid}&isDealershipMasterStage=true`);
      if (response && response.data) {
        setStageList(response.data);
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getProcessList'
      });
    }
  };

  const getUsersList = async () => {
    try {
      let response = await callAPI(`get`, `/v1/userOrganization/caseManagement`, `dealershipID=${userInfo.userDealershipID}&uniqueBy=userID`);
      if (response && response.data) {
        setUserList(response.data);
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getUsersList'
      });
    }
  };
  const getDesignationList = async () => {
    try {
      let response = await callAPI(`get`, `/v1/userOrganization/caseManagement`, `dealershipID=${userInfo.userDealershipID}&uniqueBy=designation`);
      console.log(response.data, 'response.dataresponse.dataresponse.data');
      if (response && response.data) {
        setDesignationList(response.data);
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getDesignationList'
      });
    }
  };

  const onOptionChangeHandler = (event) => {
    const { name, value } = event.target;

    if (name === "process") {
      const process = processList.find(process => process.uuid === value);
      console.log(process, 'processprocess-processID');
      setSelectedProcess(process || {});
      setSelectedStage({});
      // setSelectedAssigneeType('');
      // setSelectedAssignee({});
    }
    if (name === "stage") {
      const stage = stageList.find(stage => stage.uuid === value);
      console.log(stage, 'stagestage-stageID');
      setSelectedStage(stage || {});
    }

    if (name === "assigneeType") {
      setSelectedAssigneeType(value);
      setSelectedAssignee({});
    }
    if (name === "userAssignee") {
      const user = userList.find(user => user.userID === value);
      console.log(user, 'useruser-userID');
      setSelectedAssignee(user || {});
    }
    if (name === "roleAssignee") {
      const designation = designationList.find(designation => designation.designation === value);
      console.log(designation, 'designationdesignation-designation');
      setSelectedAssignee(designation || {});
    }

    if (name === "typeOfTask") {
      setIsAddingChecklistItem(false);
      setSelectedTypeOfTask(value);
    }

    /*if (name === "dueDate") {
      setDueDate(value);
    }*/
    if (name === "estimatedTime") {
      setEstimatedTime(value);
    }

    /*
    REPLACED BY FORMENTITYOBJECT
    if (name === "fieldName" || name === 'fieldValue') {
      setAddFormValue({...addFormValue,[name]:value});
    }
    if (name === "fieldNameEdit") {
      setEditFormValue({...editFormValue,'fieldName':value});
    }
    if (name === "fieldValueEdit") {
      setEditFormValue({...editFormValue,'fieldValue':value});
    }
    */
  };

  const handleChecklistAdd = () => {
    if (selectedTypeOfTask === 'checklist') {
      if (addChecklistValue !== '') {
        setTaskChecklist([...taskChecklist, addChecklistValue]);
        setAddChecklistValue("");
        setIsAddingChecklistItem(false);
      } else {
        toastr.error("Checklist item cannot be empty");
      }
    }
    if (selectedTypeOfTask === 'approval') {
      if (addApprovalValue.name !== '' && addApprovalValue.userType !== '') {
        let {name, userType, uuid} = addApprovalValue;
        setApprovalsList([...approvalsList,{userType, name, uuid}]);
        setAddApprovalValue({userType:"", name:""});
        setIsAddingChecklistItem(false);
      } else {
        toastr.error("Please Select both options");
      }
    }
    if (selectedTypeOfTask === 'form') {
      /*
      if (addFormValue.fieldName !== '' ) {
        let {fieldName,fieldValue} = addFormValue;
        setFormFieldList([...formFieldlist,{fieldName,fieldValue}]);
        setAddFormValue({fieldName:"", fieldValue:""});
        setIsAddingChecklistItem(false);
      } else {
        toastr.error("Field Name cannot be empty!");
      }
      */
      setFormFieldList([...formFieldlist, {...formEntityObject}]);
      setFormEntityObject({});
      setIsAddingChecklistItem(false);
    }
    setShowEditDropDown(0);
    setEditChecklistIndex(0);
  };

  const handleChecklistEdit = (id) => {
    if (selectedTypeOfTask === 'checklist') {
      if (editChecklistValue !== '') {
        setTaskChecklist((list) => {
          return list.map((item,index) => {
            if (index === id) {
              return editChecklistValue;
            }
            return item;

          });
        });
        setEditChecklistValue("");
        setEditChecklistIndex(0);
      } else {
        toastr.error("Checklist item cannot be empty");
      }
    }
    if (selectedTypeOfTask === 'approval') {
      if (editApprovalValue.name !== '' && editApprovalValue.userType !== '') {
        let { name, userType, uuid } = editApprovalValue;
        setApprovalsList((list) => {
          return list.map((item,index) => {
            if (index === id) {
              return { userType, name, uuid };
            }
            return item;

          });
        });
        setEditChecklistValue({userType:"",name:"", uuid:""});
        setEditChecklistIndex(0);
      } else {
        toastr.error("Please Select both options");
      }
    }
    if (selectedTypeOfTask === 'form') {
      /*if (editFormValue.fieldName !== '') {
        let {fieldName,fieldValue} = editFormValue;
        setFormFieldList((list) => {
          return list.map((item,index) => {
            if (index === id) {
              return {fieldName,fieldValue}
            } else {
              return item;
            }
          })
        })
        setEditFormValue({fieldName:"",fieldValue:""});
        setEditChecklistIndex(0);
      } else {
        toastr.error("Field name cannot be empty!");
      }*/

      setFormFieldList((list) => {
        return list.map((item,index) => {
          if (index === editFormPropertyIndex) {
            return formEntityObject;
          }
          return item;

        });
      });
      setFormEntityObject({});
      setEditChecklistIndex(0);
    }

    setShowEditDropDown(0);
  };

  const handleChecklistRemove = (index) => {
    if (index === -1) {
      setIsAddingChecklistItem(false);
    }
    if (selectedTypeOfTask === 'approval') {
      setApprovalsList(list =>  {
        return list.filter((item,ind) => index!==ind);
      });
    }
    if (selectedTypeOfTask === 'checklist') {
      setTaskChecklist(list =>  {
        return list.filter((item,ind) => index!==ind);
      });
    }
    if (selectedTypeOfTask === 'form') {
      setFormFieldList(list =>  {
        return list.filter((item,ind) => index!==ind);
      });
    }

    setShowEditDropDown(0);
    setEditChecklistIndex(0);
  };

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {

        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };
  const handleChecklistClick = () => {
    setShowEditDropDown(0);
    setEditChecklistIndex(0);
  };
  const checklistRef = useOutsideClick(handleChecklistClick);

  const handleApprovalEdit = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'role') {
      setEditApprovalValue((prev) => { return {...prev, name:e.target.value};});
    } else if (e.target.name === 'user') {
      const user = userList.find(user => user.userID === value);
      setEditApprovalValue((prev) => { return {...prev, name: user.userName, uuid: value};});
    }
  };
  const handleApprovalAdd = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'role') {
      setAddApprovalValue((prev) => { return {...prev, name:e.target.value};});
    } else if (e.target.name === 'user') {
      const user = userList.find(user => user.userID === value);
      setAddApprovalValue((prev) => { return {...prev, name: user.userName, uuid: value};});
    }
  };


  const saveTask = async () => {
    try {
      setIsLoading(true);

      let isError = false;
      if (!taskName) {
        toastr.error('Task Name is not provided');
        isError = true;
      }
      if (!taskDescription) {
        toastr.error('Task Description is not provided');
        isError = true;
      }
      if (!selectedTypeOfTask) {
        toastr.error('Type of Task is not selected');
        isError = true;
      }
      if (isEmptyObject(selectedAssignee) && selectedTypeOfTask !== 'approval') {
        toastr.error('Please assign the task to user or designation');
        isError = true;
      }
      // if (selectedTypeOfTask === 'approval') {
      //   toastr.error('Please assign the task to user or designation');
      //   isError = true;
      // }
      if (selectedTypeOfTask === 'checklist' && !taskChecklist.length) {
        toastr.error('Checklist Items are empty');
        isError = true;
      }

      if (selectedTypeOfTask === 'approval' && !approvalsList.length) {
        toastr.error('Approver list is empty');
        isError = true;
      }

      if (selectedTypeOfTask === 'form' && !formFieldlist.length) {
        toastr.error('Forms field list is empty');
        isError = true;
      }

      if (isError) {
        setIsLoading(false);
        return;
      }

      let payload = {
        displayName: taskName,
        description: taskDescription,
        typeOfTask: selectedTypeOfTask
      };
      if (selectedProcess.uuid) {
        payload['dealershipInteractionID'] = selectedProcess.uuid;
      }
      if (selectedStage.uuid) {
        payload['dealershipStageID'] = selectedStage.uuid;
      }
      if (dueDate) {
        payload['dueDate'] = dueDate;
      }
      if (estimatedTime) {
        payload['estimatedTime'] = estimatedTime;
      }

      if (selectedTypeOfTask === 'checklist') {
        payload['checklistItems'] = taskChecklist.filter(checklist => checklist);
      }
      if (selectedTypeOfTask === 'form' && formFieldlist.length > 0) {
        payload['dynamicProperties'] = formFieldlist;
      }
      if (selectedTypeOfTask === 'approval' && approvalsList.length > 0) {
        payload['approvers'] = approvalsList.map(approver => approver.uuid || approver.name);
        payload['actionableTo'] = approvalsList[0].uuid || approvalsList[0].name;
      } else {
        payload['actionableTo'] = selectedAssigneeType === 'user' ? selectedAssignee.userID : selectedAssignee.designation;
      }

      // console.log(payload);
      const response = await callAPI(`post`, `/v1/task/nuclear`, payload);
      if (response && response.data) {
        setIsAddNuclearTaskModalVisible(false);
        toastr.success("Task created and sent successfully");
      }

      setIsLoading(false);
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'saveTask'
      });
      toastr.error("Something Went Wrong.");
      setIsLoading(false);
    }
  };

  const toggleFormPopover = (e) => {
    setIsCreateEditFormPopover(!isCreateEditFormPopover);
  };

  const getEntityDynamicProps = async () => {
    try {
      let response = await callAPI(`get`, `/v1/dynamicProperty`, `formName=DynamicProperty&sortBy=serialNumber`);
      if (response.data) {
        setFormDynamicProps(response.data);
        console.log(response.data, 'dynamicPropsForEntity.data');
      }
    } catch (HTTPException) {
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getEntityDynamicProps'
      });
    }
  };

  const inputClicked = async (e) => {
    e.preventDefault();
    let textBox = document.getElementById(e.target.id);
    if (textBox) {
      await textBox.focus();
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    console.log(formEntityObject, 'formEntityObjectformEntityObjectformEntityObject');

    let isError = false;
    if (!formEntityObject.displayName) {
      toastr.error("Display Name cannot be empty!");
      isError = true;
    }
    if (!formEntityObject.formType) {
      toastr.error("Form Type cannot be empty!");
      isError = true;
    }
    if (!formEntityObject.name) {
      toastr.error("Name cannot be empty!");
      isError = true;
    }
    if (isNaN(formEntityObject.serialNumber)) {
      toastr.error("Please enter a valid Serial Number");
      isError = true;
    }
    if (!formEntityObject.typeofField) {
      toastr.error("Type Of Field cannot be empty!");
      isError = true;
    }

    /*
      Modification: Given condition to restrict duplicate values in name and displayName
      By: Devang
      Date: 12/06/2023
    */
    if (formOperation === "Edit") {
      if (formEntityObject.name) {
        let index = formFieldlist.findIndex((formFields, index) => formFields.name === formEntityObject.name && index !== editFormPropertyIndex);
        if (index > -1) {
          isError = true;
          toastr.error("Please don't give duplicate values in the Name field");
        }
      }
      if (formEntityObject.displayName) {
        let index = formFieldlist.findIndex((formFields, index) => formFields.displayName === formEntityObject.displayName && index !== editFormPropertyIndex);
        if (index > -1) {
          isError = true;
          toastr.error("Please don't give duplicate values in the Display Name field");
        }
      }
    } else {
      if (formEntityObject.name) {
        let index = formFieldlist.findIndex((formFields, index) => formFields.name === formEntityObject.name);
        if (index > -1) {
          isError = true;
          toastr.error("Please don't give duplicate values in the Name field");
        }
      }
      if (formEntityObject.displayName) {
        let index = formFieldlist.findIndex((formFields, index) => formFields.displayName === formEntityObject.displayName);
        if (index > -1) {
          isError = true;
          toastr.error("Please don't give duplicate values in the Display Name field");
        }
      }
    }

    if (isError) return;

    if (formOperation === "Edit") {
      handleChecklistEdit();
    } else {
      handleChecklistAdd();
    }
    toggleFormPopover();
  };

  const setFormValueInput = async (e, fieldName) => {
    e.preventDefault();
    console.log("input", fieldName);
    let obj = JSON.parse(JSON.stringify(formEntityObject));

    if (!e.target.value) {
      obj[fieldName] = e.target.value;
      console.log(e.target.value,"e.target.valuee.target.valuee.target.value");
      let successNode = document.getElementById("success-"+fieldName);
      if (successNode) {
        successNode.style.display = "none";
      }
    } else {
      obj[fieldName] = e.target.value;
    }
    console.log(obj, 'input');
    setFormEntityObject(obj);
  };

  const saveFormInput = async (e, fieldName, formType, index) => {
    console.log("input", fieldName);
    e.preventDefault();

    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (formType && formType === "select") {
      obj[fieldName] = e.target.value;
    } else {
      obj[fieldName] = e.target.value;
    }
    setFormEntityObject(obj);
    console.log(obj, 'input');
  };

  const saveFormInputRadioDefault = (e, fieldName, typeOfDBField) => {
    console.log('innnnnnnnnnnnn radio def', e.target.value, fieldName);
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    if (typeOfDBField === "Boolean") {
      obj[fieldName] = e.target.value.toLowerCase() === 'yes' ? true : false;
    } else {
      obj[fieldName] = e.target.value;
    }
    console.log(obj, 'radio');
    setFormEntityObject(obj);
  };

  const saveFormInputRadio = (e, fieldName, value, index) => {
    console.log('innnnnnnnnnnnn radio', e.target.value, fieldName);
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    obj[fieldName] = e.target.value;
    console.log(obj, 'radio');
    setFormEntityObject(obj);
  };

  const saveFormInputCheckbox = (e, fieldName, value) => {
    console.log('innnnnnnnnnnnn check', e.target.value, fieldName);
    let obj = JSON.parse(JSON.stringify(formEntityObject));

    if (e.target.checked && (!obj[fieldName] || !obj[fieldName].split(",").includes(value))) {
      obj[fieldName] = obj[fieldName] + ',' + value;
    } else if (!e.target.checked && obj[fieldName].split(",").includes(value)) {
      let idx = obj[fieldName].split(",").findIndex(d => d === value);
      let tempArr = obj[fieldName].split(",");
      let deleted = tempArr.splice(idx,1);
      obj[fieldName] = tempArr.join(",");
    }
    console.log(obj, 'check');
    setFormEntityObject(obj);
  };

  const saveAreaFromForm = (e, name) => {
    console.log("iiinnnnnnn area", name);
    let obj = JSON.parse(JSON.stringify(formEntityObject));
 	  obj[name] = e.target.value;
    console.log(obj, 'area');
    setFormEntityObject(obj);
  };

  const setFormStageValueInput = (e, key, index) => {
    let obj = JSON.parse(JSON.stringify(formDynamicProps));
 	  obj[index][key] = e.target.value;
    setFormDynamicProps(obj);
  };

  const setFormArrayValueInput = (e, name, value, key, index) => {
    e.preventDefault();
    console.log(name, value, index, key);
    let formObj = JSON.parse(JSON.stringify(formEntityObject));
    if (value.trim()) {
      if (formObj[name]) {
        formObj[name].push(value.trim());
      } else {
        formObj[name] = [];
        formObj[name].push(value.trim());
      }
    }
    setFormEntityObject(formObj);

    let dynamicPropObj = JSON.parse(JSON.stringify(formDynamicProps));
	  dynamicPropObj[index][key] = "";
    setFormDynamicProps(dynamicPropObj);
  };

  const removeFormArrayValueInput = (e, key, arrRemoveIndex) => {
    let obj = JSON.parse(JSON.stringify(formEntityObject));
    console.log(key, arrRemoveIndex, obj[key]);
 	  obj[key].splice(arrRemoveIndex, 1);
    setFormEntityObject(obj);
  };

  return (
    <div class={isAddNuclearTaskModalVisible ? 'faded-background' : ''}>
      <div class={`${isAddNuclearTaskModalVisible ? 'nuclearTask-show' : 'nuclearTask-hide'} m-auto col-xs-6 h-full overflow-hidden pos-relative`}>
        <div class="nuclear-task-container bor-rad-all-5 m-t-16 bg-white h-96-p">
          <div class="nuclear-task-header h-40px flex-c-m bg-grey bor-rad-all-5-5-0-0 p-l-24">
            <Task masterClasses="m-r-8 m-t-4" />
            <p class="text-3C3C3C fs-16">Add New Task</p>
            <div class="m-l-auto m-r-8">
              <span onClick={()=> setIsAddNuclearTaskModalVisible(false)} class="flex-c-m w-20px h-20px bor-all-black bor-rad-circular cursor-pointer">
                <p class="fs-16 text-black h-15px lh-1-0">&times;</p>
              </span>
            </div>
          </div>
          <div class="nuclear-task-main m-l-24 m-r-24 p-t-16 overflow-y" style={`height: calc(100% - 40px);`}>
            <div class="display-flex m-b-16 pos-relative">
              <div class="col-xs-6 p-l-0 fs-14 text-red flex-col gap-8px pos-relative">
                <input type="text" value={taskName} onInput={(e) => setTaskName(e.target.value)} placeholder="Task Name" />
                <select name="process" onChange={onOptionChangeHandler} class={`${!isEmptyObject(selectedProcess) ? 'select-nuclear' : 'select-grey'}`} >
                  <option value="" selected={!selectedProcess.uuid}>Select Process</option>
                  {
                    processList.length > 0 &&
                    processList.map((process) => (
                      <option value={process.uuid} selected={process.uuid === selectedProcess.uuid}>{process.displayName}</option>
                    ))
                  }
                </select>
                { !isEmptyObject(selectedProcess) &&
                  <div class="pos-absolute process-tag-pos">
                    <p class="text-white fs-13 p-l-6 p-r-6 first-letter-capital">{selectedProcess.displayName} Process</p>
                  </div>
                }
                {
                  selectedTypeOfTask !== 'approval' &&
                  <Fragment>
                    <select name="assigneeType" value={selectedAssigneeType} onChange={onOptionChangeHandler} class={`${selectedAssigneeType ? 'select-nuclear' : 'select-grey'} first-letter-capital`}>
                      <option value="" selected={!selectedAssigneeType}>Assign To</option>
                      <option value="user" selected={selectedAssigneeType === 'user'}>User</option>
                      <option value="role" selected={selectedAssigneeType === 'role'}>Role</option>
                      {/*<option value="department">Department</option>*/}
                    </select>
                    { selectedAssigneeType &&
                      <div class="pos-absolute assignee-tag-pos flex-c-m gap-4px">
                        { selectedAssigneeType === 'user' && <CirclePlus /> }
                        { selectedAssigneeType === 'role' && <RoleBadge /> }
                        {/* selectedAssigneeType === 'department' && <Department2/> */}
                        <p class="fs-13 p-l-4 p-r-4 text-up">{selectedAssigneeType}</p>
                      </div>
                    }
                  </Fragment>
                }
              </div>
              <div class={`${selectedAssigneeType ? 'justify-flex-end' : 'justify-center'} col-xs-6 p-r-0 fs-14 flex-col gap-8px pos-relative`}>
                {
                  !isEmptyObject(selectedProcess) &&
                  <select name="stage" onChange={onOptionChangeHandler} class={`${!isEmptyObject(selectedStage) ? 'select-nuclear' : 'select-grey'}`}>
                    <option value="" selected={!selectedStage.uuid}>Select Stage</option>
                    {
                      stageList.length > 0 &&
                      stageList.map((stage) => (
                        <option value={stage.uuid} selected={stage.uuid === selectedStage.uuid}>{stage.displayName}</option>
                      ))
                    }
                  </select>
                }
                { !isEmptyObject(selectedStage) &&
                  <div class="pos-absolute stage-tag-pos">
                    <p class="text-white fs-13 p-l-6 p-r-6 first-letter-capital">{selectedStage.displayName}</p>
                  </div>
                }

                {
                  selectedTypeOfTask !== 'approval' &&
                  <Fragment>
                    { selectedAssigneeType === 'user' &&
                        <select name="userAssignee" onChange={onOptionChangeHandler} class={`${!isEmptyObject(selectedAssignee) ? 'select-nuclear' : 'select-grey'}`}>
                          <option value="" disabled selected={!selectedAssignee.userID}>Select User</option>
                          {
                            userList.length > 0 &&
                            userList.map((user) => (
                              <option value={user.userID} selected={user.userID === selectedAssignee.userID}>{user.userName}</option>
                            ))
                          }
                        </select>
                    }
                    { selectedAssigneeType === 'role' &&
                        <select name="roleAssignee" onChange={onOptionChangeHandler} class={`${!isEmptyObject(selectedAssignee) ? 'select-nuclear' : 'select-grey'}`}>
                          <option value="" disabled selected={!selectedAssignee.designation}>Select Role</option>
                          {
                            designationList.length > 0 &&
                            designationList.map((designation) => (
                              <option value={designation.designation} selected={designation.designation === selectedAssignee.designation}>{designation.designation}</option>
                            ))
                          }
                        </select>
                    }
                    {/* selectedAssigneeType === 'department' &&
                        <select name="assigneeType" onChange={onOptionChangeHandler} class={`${selectedAssignee ? 'select-nuclear' : 'select-grey'}`}>
                          <option value="" disabled selected>Select Department</option>
                          <option value="sales">Sales</option>
                          <option value="backOffice">Back Office</option>
                          <option value="admin">Admin</option>
                        </select>
                    */}
                    { !isEmptyObject(selectedAssignee) &&
                      <div class="pos-absolute assigneeType-tag-pos flex-l-m gap-4px w-80p">
                        <span class="bor-rad-circular min-w-20px w-20px h-20px bg-002c5f flex-c-m">
                          { selectedAssigneeType === 'user' && <p class="text-white fs-10">{getInitials(selectedAssignee.userName)}</p> }
                          {console.log(selectedAssigneeType, selectedAssignee, 'selectedAssignee.designationselectedAssignee.designationselectedAssignee.designation')}
                          { selectedAssigneeType === 'role' && <p class="text-white fs-10">{getInitials(selectedAssignee.designation)}</p> }
                        </span>
                        { selectedAssigneeType === 'user' && <p class="fs-13 p-l-4 p-r-4 first-letter-capital">{selectedAssignee.userName}</p> }
                        { selectedAssigneeType === 'role' && <p class="fs-13 p-l-4 p-r-4 first-letter-capital">{selectedAssignee.designation}</p> }
                      </div>
                    }
                  </Fragment>
                }
              </div>
            </div>
            <div class="m-b-16">
              <textarea placeholder="Task Description" value={taskDescription} onInput={(e) => setTaskDescription(e.target.value)} class="text-theme-color bor-all-bfbfbf bor-rad-all-5 h-76px p-8"  />
            </div>
            <div class="display-flex m-b-16 pos-relative">
              <div class="col-xs-8 p-l-0 fs-14 flex-col gap-8px nuclearTask-task-type">
                <select onChange={onOptionChangeHandler} name="typeOfTask" class={`${selectedTypeOfTask ? 'select-nuclear' : 'select-grey'} max-w-75-p`}>
                  <option value="" selected={!selectedTypeOfTask}>Type of Task</option>
                  {
                    typeOfTasks.length > 0 &&
                    typeOfTasks.map((typeOfTask) => (
                      <option value={typeOfTask.name} selected={typeOfTask.name === selectedTypeOfTask}>{typeOfTask.displayName}</option>
                    ))
                  }
                </select>
                { selectedTypeOfTask &&
                  <div class="pos-absolute task-tag-pos flex-c-m">
                    <span class="bor-rad-circular w-20px h-20px bg-002c5f flex-c-m">
                      { selectedTypeOfTask === 'checklist' && <Checklist2 stroke='#f5f5f5' width="12" height="12" /> }
                      { selectedTypeOfTask === 'form' && <FieldInput stroke='#f5f5f5' width="12" height="12"  /> }
                      { selectedTypeOfTask === 'approval' && <Approval stroke='#f5f5f5' width="12" height="12"  /> }
                    </span>
                    <p class="fs-13 p-l-6 p-r-6 first-letter-capital">{selectedTypeOfTask}</p>
                  </div>
                }
                { selectedTypeOfTask === 'checklist' &&
                  <div ref={checklistRef}>
                    { taskChecklist && taskChecklist.length > 0  &&
                      taskChecklist.map((item,index) => (
                        <div class="flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 m-b-6 bor-all-bfbfbf w-full pos-relative">
                          <Dots action={()=> setShowEditDropDown(index+1)} fill="#3C3C3C" classes="p-l-4 p-r-8 cursor-pointer" width="10px" height="10px" />
                          <div class={`${showEditDropDown === index+1 ? 'checklist-edit-show' : 'checklist-edit-hide' } pos-absolute nuclearTask-checklist-edit bor-all-bfbfbf bor-rad-all-5 z-index-1`}>
                            <p class="cursor-pointer" onClick={() => { setEditChecklistValue(item); setShowEditDropDown(0); setEditChecklistIndex(index+1); }}>Edit</p>
                            <p class="cursor-pointer" onClick={() => { setShowEditDropDown(0); handleChecklistRemove(index); }}>Remove</p>
                          </div>
                          {editChecklistIndex === (index+1) ?
                            <input onChange={(e) => setEditChecklistValue(e.target.value)} type="text" value={editChecklistValue} />
                            :
                            <p class="p-l-2 fs-12 text-3C3C3C">{item}</p>
                          }
                          { editChecklistIndex === (index+1) &&
                            <button onClick={()=> handleChecklistEdit(index)} class="nuclearTask-save-btn"> Update </button>
                          }
                        </div>
                      )
                      )}

                    { isAddingChecklistItem ?
                      <div class={`bor-all-bfbfbf m-b-6 flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 w-full pos-relative`}>
                        <Dots action={() => setShowEditDropDown(-1)} fill="#3C3C3C" classes="p-l-4 p-r-8 cursor-pointer" width="10px" height="10px" />
                        <div class={`${showEditDropDown === -1 ? 'checklist-edit-show' : 'checklist-edit-hide' } pos-absolute nuclearTask-checklist-edit bor-all-bfbfbf bor-rad-all-5 z-index-1`}>
                          <p class="cursor-pointer" onClick={() => handleChecklistRemove(-1)}>Remove</p>
                        </div>
                        <input onChange={(e) => setAddChecklistValue(e.target.value)} value={addChecklistValue} type="text" class="" onKeyDown={(event) => event.keyCode == 13 && handleChecklistAdd()} />
                        <button onClick={handleChecklistAdd} class="nuclearTask-save-btn"> Add </button>
                      </div>
                      :
                      <div onClick={() => { setShowEditDropDown(0); setEditChecklistIndex(0); setIsAddingChecklistItem(true);}} class={`bg-grey cursor-pointer flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 w-full`}>
                        <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                        <p class="fs-12 text-808080 m-l-6 m-r-16">Add new checklist item here</p>
                      </div>
                    }

                    { isAddingChecklistItem &&
                      <div class="flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 w-full bg-grey disabled-div">
                        <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                        <p class="fs-12 text-808080 m-l-6 m-r-16">Add new checklist item here</p>
                      </div>
                    }
                  </div>
                }

                { selectedTypeOfTask === 'form' &&
                  <div ref={checklistRef} class="bor-all-526f95 bor-rad-all-5">
                    { formFieldlist && formFieldlist.length > 0 && formFieldlist.map((item,index) => (
                      <Fragment>
                        <div class="display-flex gap-8px">
                          <div class="flex-l-m h-28px p-l-4 p-r-8 bor-b-526f95 w-full pos-relative nuclearTask-fieldInput">
                            <Dots action={()=> setShowEditDropDown(index+1)} fill="#3C3C3C" classes="p-l-4 p-r-8 cursor-pointer" width="10px" height="10px" />
                            <div class={`${showEditDropDown === index+1 ? 'checklist-edit-show' : 'checklist-edit-hide' } pos-absolute nuclearTask-checklist-edit bor-all-bfbfbf bor-rad-all-5 z-index-1`}>
                              {/*<p onClick={() =>{ setEditFormValue({fieldName:item.fieldName, fieldValue: item.fieldValue}); setShowEditDropDown(0); setEditChecklistIndex(index+1)}}>Edit</p>*/}
                              <p class="cursor-pointer" onClick={() =>{ setFormEntityObject(item); setIsCreateEditFormPopover(true); setEditFormPropertyIndex(index); setFormOperation('Edit'); setShowEditDropDown(0);}}>Edit</p>
                              <p class="cursor-pointer" onClick={() =>{ setShowEditDropDown(0); handleChecklistRemove(index);}}>Remove</p>
                            </div>
                            <p class="p-l-2 p-r-8 fs-12 text-3C3C3C w-full display-flex first-letter-capital h-full">
                              <span class="w-40 bor-none p-t-4 p-b-4 truncate">{item.displayName}</span>
                              <span class="w-64 p-l-12 p-t-4 p-b-4 bor-l-526f95 truncate">{item.typeofField}</span>
                            </p>
                            {/*editChecklistIndex === (index+1) ?
                                <Fragment>
                                  <div class="w-41 h-28px">
                                    <input onChange={onOptionChangeHandler} name="fieldNameEdit" value={editFormValue.fieldName} type="text" class="p-t-0 first-letter-capital" />
                                  </div>
                                  <div class="w-55 h-28px bor-l-526f95 fieldInput-2">
                                    <input onChange={onOptionChangeHandler} name="fieldValueEdit" value={editFormValue.fieldValue} type="text" class="p-t-0 first-letter-capital" />
                                  </div>
                                </Fragment>
                                :
                                <p class="p-l-2 p-r-8 fs-12 text-3C3C3C w-full display-flex first-letter-capital h-full"><span class="w-40 bor-none p-t-4 p-b-4">{item.fieldName}</span> <span class="w-64 p-l-12 p-t-4 p-b-4 bor-l-526f95">{item.fieldValue}</span></p>
                              */}

                            {/* editChecklistIndex === (index+1) &&
                                <button onClick={()=>{handleChecklistEdit(index)}} class="nuclearTask-save-btn"> Update </button>
                              */}
                          </div>
                        </div>
                      </Fragment>
                    )
                    )}
                    <div onClick={() => {setIsCreateEditFormPopover(true); setFormEntityObject({}); setFormOperation('Add');}} class={`${isAddingChecklistItem ? 'bor-b-526f95' : ''} flex-l-m w-full cursor-pointer`}>
                      <div class={`bg-grey flex-l-m bor-rad-b-l-5 h-28px p-l-4 p-r-8 w-40`}>
                        <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                        <p class="fs-12 text-808080 m-l-6 m-r-16">Add new field</p>
                      </div>
                      <div class={`bg-grey flex-l-m bor-rad-b-r-5 h-28px p-l-4 p-r-8 w-60 bor-l-526f95`}>
                        <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                        <p class="fs-12 text-808080 m-l-6 m-r-16">Add new field</p>
                      </div>
                    </div>
                    {/* isAddingChecklistItem ?
                      <div class={`bor-b-526f95 cursor-pointer flex-l-m h-28px p-l-4 p-r-8 w-full pos-relative nuclearTask-fieldInput`}>
                        <Dots action={()=> setShowEditDropDown(-1)} fill="#3C3C3C" classes="p-l-4 p-r-8 cursor-pointer" width="10px" height="10px" />
                        <div class={`${showEditDropDown === -1 ? 'checklist-edit-show' : 'checklist-edit-hide' } pos-absolute nuclearTask-checklist-edit bor-all-bfbfbf bor-rad-all-5 z-index-1`}>
                          <p class="cursor-pointer" onClick={() => handleChecklistRemove(-1)}>Remove</p>
                        </div>
                        <div class="w-41 h-28px">
                          <input onChange={onOptionChangeHandler} name="fieldName" value={addFormValue.fieldName} type="text" class="p-t-0" />
                        </div>
                        <div class="w-55 h-28px bor-l-526f95 fieldInput-2">
                          <input onChange={onOptionChangeHandler} name="fieldValue" value={addFormValue.fieldValue} type="text" class="p-t-0" />
                        </div>
                        <button onClick={handleChecklistAdd} class="nuclearTask-save-btn"> Add </button>
                      </div>
                      :
                      / *<div class={`${isAddingChecklistItem ? 'bor-b-526f95' : ''} flex-l-m w-full`}>
                        <div onClick={() =>{ setShowEditDropDown(0); setEditChecklistIndex(0); setIsAddingChecklistItem(true)}} class={`bg-grey cursor-pointer flex-l-m bor-rad-b-l-5 h-28px p-l-4 p-r-8 w-40`}>
                          <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                          <p class="fs-12 text-808080 m-l-6 m-r-16">Add new field</p>
                        </div>
                        <div onClick={() =>{ setShowEditDropDown(0); setEditChecklistIndex(0); setIsAddingChecklistItem(true)}} class={`bg-grey cursor-pointer flex-l-m bor-rad-b-r-5 h-28px p-l-4 p-r-8 w-60 bor-l-526f95`}>
                          <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                          <p class="fs-12 text-808080 m-l-6 m-r-16">Add new field</p>
                        </div>
                      </div>* /
                    */}
                    {/* isAddingChecklistItem &&
                      <div class="flex-l-m w-full">
                        <div class={`bg-grey cursor-pointer flex-l-m bor-rad-b-l-5 h-28px p-l-4 p-r-8 w-40`}>
                          <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                          <p class="fs-12 text-808080 m-l-6 m-r-16">Add new field</p>
                        </div>
                        <div class={`bg-grey cursor-pointer flex-l-m bor-rad-b-r-5 h-28px p-l-4 p-r-8 w-60 bor-l-526f95`}>
                          <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                          <p class="fs-12 text-808080 m-l-6 m-r-16">Add new field</p>
                        </div>
                      </div>
                    */}
                  </div>
                }

                { selectedTypeOfTask === 'approval' &&
                  <div ref={checklistRef} class="nuclearTask-approval" >
                    { approvalsList && approvalsList.length > 0  && approvalsList.map((item,index) => (
                      <Fragment>
                        <div class="display-flex gap-8px m-l-4">
                          <div class="m-t-8">
                            <p class="h-10px w-10px bor-rad-circular bg-002c5f text-white fs-8 flex-c-m">{index+1}</p>
                          </div>
                          <div class="flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 m-b-6 bor-all-bfbfbf w-full pos-relative">
                            <Dots action={()=> setShowEditDropDown(index+1)} fill="#3C3C3C" classes="p-l-4 p-r-8 cursor-pointer" width="10px" height="10px" />
                            <div class={`${showEditDropDown === index+1 ? 'checklist-edit-show' : 'checklist-edit-hide' } pos-absolute nuclearTask-checklist-edit bor-all-bfbfbf bor-rad-all-5 z-index-1`}>
                              <p class="cursor-pointer" onClick={() => { setEditApprovalValue({userType:item.userType, name: item.name}); setShowEditDropDown(0); setEditChecklistIndex(index+1);}}>Edit</p>
                              <p class="cursor-pointer" onClick={() => { setShowEditDropDown(0); handleChecklistRemove(index);}}>Remove</p>
                            </div>
                            {editChecklistIndex === (index+1) ?
                              <Fragment>
                                <select value={editApprovalValue.userType} name="userType" onChange={(e) => setEditApprovalValue((prev) => { return {...prev, [e.target.name]:e.target.value};})} class={`${editApprovalValue.userType ? 'select-nuclear' : 'select-grey'} w-half bor-none`}>
                                  <option value="" disabled>Assign To</option>
                                  <option value="user">User</option>
                                  <option value="role">Role</option>
                                  {/*<option value="department">Department</option>*/}
                                </select>
                                { editApprovalValue.userType === 'user' &&
                                  <select name="user" onChange={handleApprovalEdit} class={`${editApprovalValue.name ? 'select-nuclear' : 'select-grey'} m-l-auto m-r-8 w-half bor-none`}>
                                    <option value="" disabled selected={!item.uuid}>Select User</option>
                                    {
                                      userList.length > 0 &&
                                      userList.map((user) => (
                                        <option value={user.userID} selected={user.userID === item.uuid}>{user.userName}</option>
                                      ))
                                    }
                                  </select>
                                }
                                { editApprovalValue.userType === 'role' &&
                                  <select name="role" onChange={handleApprovalEdit} class={`${editApprovalValue.name ? 'select-nuclear' : 'select-grey'} m-l-auto m-r-8 w-half bor-none`}>
                                    <option value="" disabled selected={!item.name}>Select Role</option>
                                    {
                                      designationList.length > 0 &&
                                      designationList.map((designation) => (
                                        <option value={designation.designation} selected={designation.designation === item.name}>{designation.designation}</option>
                                      ))
                                    }
                                  </select>
                                }
                                {/* editApprovalValue.userType === 'department' &&
                                  <select value={editApprovalValue.name} name="name" onChange={(e) => setEditApprovalValue((prev) => { return {...prev, [e.target.name]:e.target.value}})} class={`${editApprovalValue.name ? 'select-nuclear' : 'select-grey'} m-l-auto m-r-8 w-half bor-none`}>
                                    <option value="" disabled>Select Department</option>
                                    <option value="sales">Sales</option>
                                    <option value="backOffice">Back Office</option>
                                    <option value="admin">Admin</option>
                                  </select>
                                */}
                              </Fragment>
                              :
                              <p class="p-l-2 p-r-8 fs-12 text-3C3C3C w-full display-flex first-letter-capital"><span class="w-half bor-none">{item.userType}</span> <span class="w-half bor-none">{item.name}</span></p>
                            }
                            { editChecklistIndex === (index+1) &&
                              <button onClick={()=>{handleChecklistEdit(index);}} class="nuclearTask-save-btn"> Update </button>
                            }
                          </div>
                        </div>
                      </Fragment>
                    )
                    )}
                    { isAddingChecklistItem ?
                      <div class="display-flex m-l-4 gap-8px">
                        <div class="m-t-8">
                          <p class="h-10px w-10px bor-rad-circular bg-808080 flex-c-m" />
                        </div>
                        <div class={`bor-all-bfbfbf m-b-6 flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 w-full pos-relative`}>
                          <Dots action={()=> setShowEditDropDown(-1)} fill="#3C3C3C" classes="p-l-4 p-r-8 cursor-pointer" width="10px" height="10px" />
                          <div class={`${showEditDropDown === -1 ? 'checklist-edit-show' : 'checklist-edit-hide' } pos-absolute nuclearTask-checklist-edit bor-all-bfbfbf bor-rad-all-5 z-index-1`}>
                            <p class="cursor-pointer" onClick={() => handleChecklistRemove(-1)}>Remove</p>
                          </div>
                          <select value={addApprovalValue.userType} name="userType" onChange={(e) => setAddApprovalValue((prev) => { return {...prev, [e.target.name]:e.target.value};})} class={`${addApprovalValue.userType ? 'select-nuclear' : 'select-grey'} w-half bor-none`}>
                            <option value="" disabled selected>Assign To</option>
                            <option value="user">User</option>
                            <option value="role">Role</option>
                            {/*<option value="department">Department</option>*/}
                          </select>
                          { addApprovalValue.userType === 'user' &&
                            <select name="user" onChange={handleApprovalAdd} class={`${addApprovalValue.name ? 'select-nuclear' : 'select-grey'} m-l-auto m-r-8 w-half bor-none`}>
                              <option value="" disabled selected={!addApprovalValue.uuid}>Select User</option>
                              {
                                userList.length > 0 &&
                                userList.map((user) => (
                                  <option value={user.userID} selected={user.userID === addApprovalValue.uuid}>{user.userName}</option>
                                ))
                              }
                            </select>
                          }
                          { addApprovalValue.userType === 'role' &&
                            <select name="role" onChange={handleApprovalAdd} class={`${addApprovalValue.name ? 'select-nuclear' : 'select-grey'} m-l-auto m-r-8 w-half bor-none`}>
                              <option value="" disabled selected={!addApprovalValue.name}>Select Role</option>
                              {
                                designationList.length > 0 &&
                                designationList.map((designation) => (
                                  <option value={designation.designation} selected={designation.designation === addApprovalValue.name}>{designation.designation}</option>
                                ))
                              }
                            </select>
                          }
                          {/* addApprovalValue.userType === 'department' &&
                              <select value={addApprovalValue.name} name="name" onChange={(e) => setAddApprovalValue((prev) => { return {...prev, [e.target.name]:e.target.value}})} class={`${addApprovalValue.name ? 'select-nuclear' : 'select-grey'} m-l-auto m-r-8 w-half bor-none`}>
                                <option value="" disabled selected>Select Department</option>
                                <option value="sales">Sales</option>
                                <option value="backOffice">Back Office</option>
                                <option value="admin">Admin</option>
                              </select>
                          */}
                          <button onClick={handleChecklistAdd} class="nuclearTask-save-btn m-l-auto"> Add </button>
                        </div>
                      </div>
                      :
                      <div class="display-flex m-l-4 gap-8px">
                        <div class="m-t-8">
                          <p class="h-10px w-10px bor-rad-circular bg-808080" />
                        </div>
                        <div onClick={() =>{ setShowEditDropDown(0); setEditChecklistIndex(0); setIsAddingChecklistItem(true);}} class={`bg-grey cursor-pointer flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 w-full`}>
                          <p class="fs-12 text-808080 m-l-6 m-r-16">Assign for Approval</p>
                          <span class="m-l-auto h-20px w-20px bor-rad-circular bg-D4D2D2 shadow1 flex-c-m">
                            <Plus fill="#fff" masterClasses="p-t-2" width="10px" height="10px" />
                          </span>
                        </div>
                      </div>
                    }
                    { isAddingChecklistItem &&
                      <div class="display-flex m-l-4 gap-8px disabled-div">
                        <div class="m-t-8">
                          <p class="h-10px w-10px bor-rad-circular bg-808080" />
                        </div>
                        <div class={`bg-grey cursor-pointer flex-l-m bor-rad-all-5 h-28px p-l-4 p-r-8 w-full`}>
                          <p class="fs-12 text-808080 m-l-6 m-r-16">Assign for Approval</p>
                          <span class="m-l-auto h-20px w-20px bor-rad-circular bg-D4D2D2 shadow1 flex-c-m">
                            <Plus fill="#fff" masterClasses="p-t-2" width="10px" height="10px" />
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
              <div class="col-xs-4 p-r-0 fs-14 flex-col">
                <div class="m-l-auto flex-col gap-8px">
                  <div onClick={() => !isAddingDueDate && setAddingDueDate(true)} class={`${isAddingDueDate ? 'bor-all-bfbfbf' : 'bg-grey'} cursor-pointer flex-l-m bor-rad-all-5 h-20px p-l-4 p-r-8 w-fit-content`}>
                    { isAddingDueDate ?
                      <input id="datepicker-nuclearTask" value={dueDate} placeholder="Add Due Date" name="dueDate"  type="text" />
                      :
                      <Fragment>
                        <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                        <p class="fs-12 text-3C3C3C m-l-6 m-r-16">Add Due Date</p>
                      </Fragment>
                    }
                    <Calendar action={() => delegateClick("datepicker-nuclearTask")} stroke="#3C3C3C" width="12px" height="12px" masterClasses="m-l-auto m-t-2" />
                  </div>
                  <div onClick={()=> !isAddingEstimatedTime && setIsAddingEstimatedTime(true)} class={`${isAddingEstimatedTime ? 'bor-all-bfbfbf' : 'bg-grey cursor-pointer'} flex-l-m bor-rad-all-5 p-l-4 p-r-8 w-fit-content`}>
                    { isAddingEstimatedTime ?
                      /*<input id="timepicker-nuclearTask" value={estimatedTime} onChange={onOptionChangeHandler} name="estimatedTime" type="text"/>*/
                      <input value={estimatedTime} onChange={onOptionChangeHandler} placeholder="Estimated Time" name="estimatedTime" type="number" />
                      :
                      <Fragment>
                        <Plus fill="#3C3C3C" masterClasses="p-t-2" width="10px" height="10px" />
                        <p class="fs-12 text-3C3C3C m-l-6 m-r-16">Add Estimated Time</p>
                      </Fragment>
                    }
                    {/*<Alarm2 stroke="#3C3C3C" width="12px" height="12px" classes="m-l-auto"/>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pos-absolute bottom-24px right-24px">
            <button class="primary-button-2 fs-12 flex-c-m w-96px m-l-auto m-r-24" disabled={isLoading} onClick={saveTask}>Create Task</button>
          </div>
        </div>
      </div>
      {
        isCreateEditFormPopover &&
        <CreateEditForm
          operation={formOperation}
          entityName={"Fields"}
          entityObject={formEntityObject}
          setFormEntityObject={setFormEntityObject}
          dynamicProps={formDynamicProps}
          toggleFormPopover={toggleFormPopover}
          backgroundColor={"#f7f7f7"}
          inputClicked={inputClicked}
          submitForm={submitForm}

          setFormValueInput={setFormValueInput}
          saveFormInputRadioDefault={saveFormInputRadioDefault}
          saveFormInputRadio={saveFormInputRadio}
          saveFormInputCheckbox={saveFormInputCheckbox}
          saveAreaFromForm={saveAreaFromForm}
          saveFormInput={saveFormInput}

          setFormStageValueInput={setFormStageValueInput}
          setFormArrayValueInput={setFormArrayValueInput}
          removeFormArrayValueInput={removeFormArrayValueInput}
        />
      }
    </div>
  );
};

export default AddNuclearTaskModal;
