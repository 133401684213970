import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Dashboard = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"}
        viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M275 5106 c-111 -35 -190 -104 -239 -206 l-31 -65 0 -570 0 -570 33 -67 c40 -82 119 -157 200 -190 l57 -23 840 -3 c603 -2 857 0 900 8 160 31 274 145 305 305 8 42 10 216 8 580 -3 517 -3 520 -26 577 -33 81 -108 160 -190 200 l-67 33 -875 2 c-688 2 -884 -1 -915 -11z m1739 -328 c14 -20 16 -85 16 -515 0 -480 -1 -494 -20 -513 -20 -20 -33 -20 -833 -20 -719 0 -815 2 -835 16 l-22 15 0 501 c0 453 2 503 17 520 15 17 54 18 839 18 l823 0 15 -22z"/>
          <path d="M3049 5106 c-106 -28 -209 -121 -251 -224 l-23 -57 -3 -1160 c-2 -838 0 -1177 8 -1220 31 -160 145 -274 305 -305 43 -8 297 -10 900 -8 l840 3 57 23 c81 33 160 108 200 190 l33 67 0 1210 0 1210 -31 65 c-40 84 -100 144 -182 183 l-67 32 -870 2 c-667 1 -881 -1 -916 -11z m1733 -323 c17 -15 18 -64 18 -1159 l0 -1143 -22 -15 c-20 -14 -116 -16 -835 -16 -800 0 -813 0 -833 20 -20 20 -20 33 -20 1153 0 1009 2 1135 16 1155 l15 22 821 0 c750 0 823 -1 840 -17z"/>
          <path d="M300 2979 c-112 -22 -217 -107 -268 -217 l-27 -57 0 -1210 0 -1210 31 -65 c39 -83 101 -145 184 -184 l65 -31 890 0 890 0 67 33 c82 40 157 119 190 200 l23 57 3 1160 c2 838 0 1177 -8 1220 -30 157 -142 271 -298 304 -64 13 -1672 14 -1742 0z m1710 -329 c20 -20 20 -33 20 -1153 0 -1009 -2 -1135 -16 -1155 l-15 -22 -821 0 c-750 0 -823 1 -840 17 -17 15 -18 64 -18 1159 l0 1143 22 15 c20 14 116 16 835 16 800 0 813 0 833 -20z"/>
          <path d="M3065 1696 c-150 -37 -255 -148 -285 -301 -8 -42 -10 -215 -8 -580 3 -517 3 -520 26 -577 33 -81 108 -160 190 -200 l67 -33 890 0 890 0 67 32 c82 39 142 99 182 183 l31 65 0 570 0 570 -33 67 c-40 82 -119 157 -200 190 l-57 23 -855 2 c-649 1 -867 -1 -905 -11z m1713 -322 l22 -15 0 -501 c0 -453 -2 -503 -17 -520 -15 -17 -54 -18 -839 -18 l-823 0 -15 22 c-14 20 -16 85 -16 515 0 480 1 494 20 513 20 20 33 20 833 20 719 0 815 -2 835 -16z"/>
        </g>
      </svg>
    </span>
  );
};

export default Dashboard;
