import { h } from 'preact';
// from flaticon
const Tickeet = (props) => {
  return (
    <span onclick={(e) => props.action && props.action(e)} class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M320 5039 c-32 -13 -78 -56 -96 -91 -21 -41 -21 -4735 0 -4776 19 -36 65 -78 99 -91 20 -8 580 -11 1824 -11 l1797 0 43 21 c30 15 52 35 71 67 l27 47 5 504 5 504 70 14 c216 44 436 189 569 377 60 84 110 186 143 292 25 82 27 102 27 259 0 156 -2 177 -27 258 -32 104 -83 210 -142 292 -49 69 -169 187 -235 232 -102 69 -264 136 -361 149 l-48 6 -3 642 c-3 579 -5 644 -20 671 -29 52 -599 613 -640 630 -33 13 -213 15 -1560 14 -984 0 -1532 -4 -1548 -10z m3000 -394 c0 -274 5 -300 62 -335 31 -19 50 -20 286 -20 l252 0 0 -595 0 -595 -34 0 c-19 0 -66 -7 -103 -14 -366 -77 -647 -345 -738 -701 -37 -143 -37 -318 -1 -460 70 -269 255 -499 501 -620 103 -50 210 -82 308 -92 l67 -6 0 -488 0 -489 -1775 0 -1775 0 0 2330 0 2330 1475 0 1475 0 0 -245z m325 -35 l160 -160 -163 0 -162 0 0 160 c0 88 1 160 3 160 1 0 74 -72 162 -160z m503 -1690 c271 -65 486 -270 572 -545 31 -98 38 -264 16 -371 -62 -309 -303 -556 -604 -619 -100 -22 -263 -19 -358 5 -357 92 -594 397 -594 765 0 244 99 455 288 610 182 151 446 211 680 155z"/>
          <path d="M1953 4445 c-334 -60 -631 -285 -778 -588 -242 -501 -78 -1087 389 -1389 321 -207 752 -226 1086 -46 391 209 620 637 572 1066 -16 145 -43 237 -107 367 -110 225 -279 393 -500 500 -196 94 -449 129 -662 90z m115 -623 l2 -473 236 -400 c130 -220 234 -402 232 -405 -13 -12 -148 -55 -219 -70 -111 -22 -311 -15 -417 16 -153 43 -276 115 -396 229 -362 347 -377 919 -33 1287 148 158 295 236 542 288 53 11 50 37 53 -472z m315 447 c198 -49 388 -180 513 -355 84 -117 164 -322 164 -421 l0 -33 -415 0 -415 0 0 420 0 421 38 -6 c20 -4 72 -15 115 -26z m677 -1002 c0 -47 -35 -177 -67 -250 -58 -133 -184 -297 -283 -367 l-29 -21 -102 173 c-56 95 -144 244 -195 331 -52 88 -94 161 -94 163 0 2 173 4 385 4 l385 0 0 -33z"/>
          <path d="M624 2071 c-28 -12 -54 -49 -54 -77 0 -12 9 -33 21 -48 l20 -26 179 0 c98 0 186 3 195 6 23 9 46 60 39 88 -14 55 -32 61 -213 63 -91 2 -176 -1 -187 -6z"/>
          <path d="M1264 2066 c-48 -21 -61 -89 -24 -126 20 -20 33 -20 564 -20 299 0 552 3 561 6 23 9 46 60 39 88 -3 14 -15 33 -26 43 -19 17 -53 18 -552 20 -431 2 -537 0 -562 -11z"/>
          <path d="M600 1561 c-35 -35 -38 -68 -9 -105 l20 -26 474 0 c436 0 474 1 494 18 34 28 37 73 8 108 l-25 29 -467 3 -466 3 -29 -30z"/>
          <path d="M1853 1580 c-34 -14 -56 -54 -48 -90 13 -61 4 -60 511 -60 l463 0 20 26 c29 37 26 70 -8 105 l-29 29 -444 -1 c-244 0 -453 -4 -465 -9z"/>
          <path d="M615 1088 c-51 -29 -60 -84 -20 -123 l24 -25 814 0 c761 0 816 1 836 18 11 9 24 31 27 49 5 27 1 37 -24 63 l-30 30 -804 0 c-585 -1 -808 -4 -823 -12z"/>
          <path d="M2623 1090 c-47 -19 -58 -92 -20 -128 23 -22 29 -22 338 -22 l316 0 21 23 c27 29 28 77 3 108 l-19 24 -309 2 c-169 1 -318 -2 -330 -7z"/>
          <path d="M615 598 c-51 -27 -60 -84 -20 -123 l24 -25 1419 0 1419 0 23 25 c32 34 30 83 -5 112 l-26 23 -1407 0 c-999 -1 -1413 -4 -1427 -12z"/>
          <path d="M3887 2875 c-22 -7 -50 -24 -64 -37 -45 -41 -53 -70 -53 -181 0 -137 18 -603 26 -645 3 -18 19 -49 37 -69 89 -102 239 -78 290 47 21 50 45 730 28 783 -31 92 -156 141 -264 102z m108 -156 c10 -15 -15 -662 -26 -673 -5 -5 -11 -8 -13 -5 -8 8 -30 655 -23 673 7 18 52 22 62 5z"/>
          <path d="M3865 1797 c-115 -65 -135 -218 -40 -312 72 -72 169 -81 256 -22 113 76 105 262 -15 332 -57 34 -143 35 -201 2z m133 -169 c5 -38 -28 -54 -58 -30 -20 16 -21 21 -11 41 18 33 65 26 69 -11z"/>
        </g>
      </svg>
    </span>
  );
};

export default Tickeet;
