import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Chair2 = (props) => {
  return (
    <span class={props.masterClasses || 'flex-c-m p-l-8'} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "28px"} height={props.height || "28px"}
        viewBox={props.viewBox || "0 0 20 22"}
      >
        <path d="M5.64422 4H6.64422M5.64422 4C5.64422 4.39052 5.64422 4.60948 5.64422 5M5.64422 4C4.64422 4.00006 4.14404 3.5 4.64422 2.5C5.1444 1.5 5.14404 1 6.64404 1M4.64422 12.5L4.64404 8M2.14422 11.5L2.64422 7.5C2.86266 5.71489 3.19072 5.01425 4.64422 5L4.64404 8M2.14422 11.5C2.96438 11.3845 3.32523 11.6116 3.64422 12.5M2.14422 11.5C0.644096 11.5 0.144142 16 3.64422 16M3.64422 12.5V16M3.64422 12.5H6.64404M3.64422 16H6.64404M6.64404 8H4.64404" stroke={props.stroke || "#3C3C3C"} />
        <path d="M7.70709 4H6.64406M7.70709 4C7.70709 4.39052 7.70709 4.60948 7.70709 5M7.70709 4C8.77013 4.00006 9.30183 3.5 8.77013 2.5C8.23842 1.5 8.23879 1 6.64424 1M8.64936 12.5L8.64955 8M11.307 11.5L10.7754 7.5C10.5432 5.71489 10.1945 5.01425 8.64936 5L8.64955 8M11.307 11.5C10.3694 11.4184 10.0636 11.698 9.7124 12.5M11.307 11.5C12.9016 11.5 13.4331 16 9.7124 16M9.7124 12.5V16M9.7124 12.5H6.64424M9.7124 16H6.64424M6.64424 8H8.64955" stroke={props.stroke || "#3C3C3C"} />
      </svg>
    </span>
  );
};

export default Chair2;
