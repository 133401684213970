import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Receive = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M185 3975 l-25 -24 0 -1311 0 -1311 25 -24 24 -25 1568 0 1568 0 0 -133 c1 -94 6 -152 19 -202 20 -77 81 -214 126 -286 l30 -46 0 -202 c0 -201 0 -202 25 -226 32 -33 78 -33 110 0 l25 24 0 227 0 227 -51 80 c-29 44 -63 107 -76 140 -44 108 -63 258 -47 359 l7 38 136 0 136 0 17 -58 c20 -68 76 -163 128 -221 46 -50 87 -55 125 -16 36 35 32 73 -14 131 -135 171 -144 361 -26 537 60 89 66 79 -204 351 -226 228 -239 244 -246 286 -17 117 85 209 200 179 34 -9 88 -59 345 -314 168 -166 315 -306 327 -309 31 -10 75 13 91 47 21 43 4 70 -108 182 l-100 100 0 330 0 330 188 -190 c156 -157 194 -202 225 -262 66 -126 67 -138 67 -810 0 -687 3 -661 -88 -801 -28 -42 -56 -95 -62 -117 -7 -26 -10 -111 -8 -248 3 -206 3 -209 27 -228 32 -26 77 -24 106 6 25 24 25 25 25 226 l0 201 54 87 c30 47 65 115 78 151 l23 65 0 665 0 665 -23 75 c-51 162 -73 192 -354 476 l-258 260 0 448 0 447 -25 24 -24 25 -2031 0 -2031 0 -24 -25z m3965 -164 c0 -47 -46 -180 -87 -251 -46 -79 -178 -213 -256 -259 -31 -18 -96 -47 -144 -65 l-88 -31 -512 -3 -512 -3 -20 46 c-32 72 -75 119 -143 156 -58 31 -70 34 -148 34 -73 0 -92 -4 -137 -28 -69 -36 -120 -88 -150 -154 l-24 -53 -474 0 c-519 0 -565 4 -694 58 -136 58 -268 173 -344 303 -41 69 -87 202 -87 250 l0 29 1910 0 1910 0 0 -29z m-3719 -523 c102 -101 230 -173 394 -222 66 -19 100 -20 587 -24 l517 -3 24 -53 c30 -65 81 -117 150 -153 44 -23 64 -27 137 -27 78 -1 90 2 148 33 68 37 111 84 143 155 l20 46 478 0 c519 0 584 5 718 55 135 51 274 149 363 256 l50 60 0 -537 0 -537 -123 120 c-132 130 -175 158 -261 171 -111 18 -214 -16 -286 -94 -62 -67 -84 -124 -84 -214 0 -128 21 -160 256 -397 l201 -202 -31 -68 c-36 -80 -52 -135 -52 -180 l0 -33 -1730 0 -1730 0 0 988 0 987 27 -35 c14 -19 52 -61 84 -92z m1872 -22 c103 -43 128 -177 48 -257 -65 -65 -157 -65 -222 0 -124 123 13 325 174 257z"/>
          <path d="M505 2215 l-25 -24 0 -271 0 -271 25 -24 24 -25 671 0 671 0 24 25 25 24 0 271 0 271 -25 24 -24 25 -671 0 -671 0 -24 -25z m1255 -295 l0 -160 -560 0 -560 0 0 160 0 160 560 0 560 0 0 -160z"/>
          <path d="M2585 2135 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 351 0 351 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -351 0 -351 0 -24 -25z"/>
          <path d="M2585 1815 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 431 0 431 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -431 0 -431 0 -24 -25z"/>
        </g>
      </svg>
    </span>
  );
};

export default Receive;
