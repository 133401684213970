import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Lost = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg  onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"}
  	viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || '#000000'} stroke="none">
          <path d="M3490 4908 c-70 -36 -90 -80 -90 -202 0 -89 -2 -98 -17 -91 -10 4 -52 20 -95 35 -42 16 -121 50 -177 75 -164 75 -160 75 -811 74 -316 -1 -782 -4 -1035 -8 l-460 -6 -70 -34 c-197 -97 -327 -338 -297 -551 6 -41 14 -83 17 -92 5 -11 -6 -25 -38 -46 -99 -69 -181 -186 -212 -303 -17 -61 -19 -194 -5 -253 9 -39 8 -41 -49 -102 -173 -184 -199 -471 -61 -680 l37 -57 -23 -51 c-37 -81 -54 -163 -54 -259 0 -209 89 -380 268 -512 157 -117 281 -166 469 -185 158 -16 381 7 597 61 43 11 80 18 82 16 2 -2 -1 -69 -8 -150 -18 -223 7 -483 67 -692 64 -221 148 -377 270 -499 141 -143 272 -200 455 -201 141 0 196 19 271 94 90 91 89 84 83 554 -5 325 -3 412 8 452 18 65 661 1083 735 1163 l52 57 1 -75 c1 -95 23 -148 76 -181 l39 -24 745 0 745 0 38 24 c21 12 47 40 57 62 20 38 20 60 18 1271 l-3 1233 -24 34 c-13 18 -42 42 -65 52 -39 18 -81 19 -766 19 l-726 0 -44 -22z m1310 -1328 l0 -1030 -540 0 -540 0 0 1030 0 1030 540 0 540 0 0 -1030z m-1795 844 c61 -28 174 -75 253 -104 l142 -53 0 -689 c0 -384 -4 -688 -9 -688 -5 0 -44 -23 -87 -51 -89 -58 -186 -148 -249 -229 -53 -69 -677 -1055 -716 -1131 -51 -100 -54 -131 -54 -568 l1 -403 -59 6 c-85 9 -168 56 -230 131 -217 260 -291 850 -157 1239 39 115 19 196 -60 237 -50 27 -87 24 -228 -21 -479 -149 -794 -166 -965 -51 -22 14 -51 34 -66 43 -45 31 -102 91 -124 133 -18 32 -22 57 -22 130 0 99 18 149 75 210 40 43 50 68 50 120 0 59 -19 94 -75 141 -72 59 -100 115 -100 199 1 110 44 173 162 237 39 20 56 37 73 73 28 57 22 103 -20 179 -23 40 -30 65 -30 105 0 118 71 198 193 216 93 14 151 74 151 158 1 48 -4 61 -41 114 -54 77 -69 135 -53 202 13 56 57 123 92 143 29 16 479 24 1393 23 l650 0 110 -51z"/>
        </g>
      </svg>
    </span>
  );
};

export default Lost;
