import { useContext, useEffect, useState } from "preact/hooks";
import { useSocketContext } from "./socketContext";
import { createContext } from 'preact';
import io from "socket.io-client";
import axios from "axios";
import CONSTANTS from '../../../lib/constants';
import { getItem } from '../../../lib/myStore';
const UsersContext = createContext();
/*
 Modification: Created, Imported and added ChatPage to the routes
 By: Komal Wable
 Date: 22/05/2023
*/
const useUsersContext = () => useContext(UsersContext);
/*
  modified : Ashutosh G
  modified : 22/02/2024
  modification : socket taken outside for only one time initialization
*/
const socket = io(`${CONSTANTS.SOCKETAPI_URL}`);
const UsersProvider = ({ children }) => {
  let userInfo = getItem('userinfo');
  let token = getItem('token');
  const [users, setUsers] = useState([]);

  const _updateUserProp = (userId, prop, value) => {
  	setUsers((users) => {
  		const usersCopy = [...users];
  		let userIndex = users.findIndex((user) => user.id === userId);
  		const userObject = usersCopy[userIndex];
  		usersCopy[userIndex] = { ...userObject, [prop]: value };
  		return usersCopy;
  	});
  };

  const setUserAsTyping = (data) => {
  	const { userId } = data;
  	_updateUserProp(userId, "typing", true);
  };

  const setUserAsNotTyping = (data) => {
  	const { userId } = data;
  	_updateUserProp(userId, "typing", false);
  };

  const fetchMessageResponse = (data) => {
  	setUsers((users) => {
  		const { userId, response } = data;

  		let userIndex = users.findIndex((user) => user.id === userId);
  		const usersCopy = JSON.parse(JSON.stringify(users));
  		const newMsgObject = {
  			content: response,
  			sender: userId,
  			time: new Date().toLocaleTimeString(),
  			status: null,
  		};

  		usersCopy[userIndex].messages.TODAY.push(newMsgObject);

  		return usersCopy;
  	});
  };

  /*
    modified : Ashutosh G
    modified : 22/02/2024
    modification : socket removed for useEffect dependecies
  */
  useEffect(() => {
  	socket.on("fetch_response", fetchMessageResponse);
  	socket.on("start_typing", setUserAsTyping);
  	socket.on("stop_typing", setUserAsNotTyping);
  }, []);

  const setUserAsUnread = (userId) => {
  	_updateUserProp(userId, "unread", 0);
  };

  useEffect(async() => {
    if (token && token.token) {
      await getUsers();
    }
  },[]);

  const getUsers = async() => {
    const user = await axios.get(`${CONSTANTS.API_URL}/api/v1/userOrganization`);
    if (user && user.data) {
      setUsers(user.data)
    }
  };
  // const addNewMessage = async(collaborator,userId, message,file,type) => {
  // 	const newMsgObject = {
  // 		content: message,
  // 		senderID: userInfo.uuid,
  //     typeOfMessage: type ? type :'',
  //     senderName:userInfo.displayName,
  //     fileID: file ? file.uuid :'',
  //     fileName:file ? file.name :'',
  // 		time: new Date().toLocaleTimeString(),
  // 		status: "delivered",
  // 		collaboratorID : collaborator ? collaborator:''
  // 	};
  // 	let messageObj = await axios.post(`${CONSTANTS.API_URL}/api/v1/collaborator/message`, newMsgObject);
  // };

  return (
    <UsersContext.Provider value={{ }}>
      {children}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersProvider };
