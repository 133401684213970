import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
/*
 Modification: add condition for chatpage
 By: Komal Wable
 Date: 22/05/2023
*/
const Nudge = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''} onclick={(e) => props.action && props.action(e)}>
      { props.isMove ? '' :
        <img src="/assets/images/gifs/finger-pointing-static.png" class={`static-gif ${props.identityClass || ''}`} width={props.width ? props.width: "30"} height={props.height ? props.height : "30"}/>}
      <img src="/assets/images/gifs/finger-pointing.gif" class={`active-gif ${props.classes || ""} ${props.identityClass || ''}`} width={props.width ? props.width: "30"} height={props.height ? props.height : "30"}/>
    </span>
  );
};

export default Nudge;
