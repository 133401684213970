/*
  modified : Ashutosh G
  modified : 01/03/2024
  modification : RoboFace added
*/
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const RoboFace = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class={props.classes || ""}
        width={props.width || "512pt"}
        height={props.height || "512pt"}
        viewBox={props.viewBox || "0 -960 960 960"}
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="M160-360q-50 0-85-35t-35-85q0-50 35-85t85-35v-80q0-33 23.5-56.5T240-760h120q0-50 35-85t85-35q50 0 85 35t35 85h120q33 0 56.5 23.5T800-680v80q50 0 85 35t35 85q0 50-35 85t-85 35v160q0 33-23.5 56.5T720-120H240q-33 0-56.5-23.5T160-200v-160Zm200-80q25 0 42.5-17.5T420-500q0-25-17.5-42.5T360-560q-25 0-42.5 17.5T300-500q0 25 17.5 42.5T360-440Zm240 0q25 0 42.5-17.5T660-500q0-25-17.5-42.5T600-560q-25 0-42.5 17.5T540-500q0 25 17.5 42.5T600-440ZM320-280h320v-80H320v80Zm-80 80h480v-480H240v480Zm240-240Z"  fill={props.fill || "#000000"} stroke={props.stroke || "none"} />
      </svg>
    </span>
  );
};

export default RoboFace;
