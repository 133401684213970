const Brochure = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} onclick={(e) => props.action && props.action()} width={props.width || '512.000000pt'} height={props.height || '512.000000pt'} xmlns="http://www.w3.org/2000/svg" version="1.1"
	id="Icons" x="0px" y="0px" viewBox={props.viewBox || "0 0 512.000000 512.000000"} style={`enable-background:new 0 0 32 32; ${props.style || ''}`} fill={props.fill || ''}
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'}>
          <path d="M240 3040 l0 -2080 775 0 775 0 0 -480 0 -480 1545 0 1545 0 0 2080 0 2080 -775 0 -775 0 0 480 0 480 -1545 0 -1545 0 0 -2080z m2788 3 l-3 -1778 -1242 -3 -1243 -2 0 1780 0 1780 1245 0 1245 0 -2 -1777z m1552 -963 l0 -1780 -1101 0 -1101 0 476 371 476 371 0 134 0 134 475 0 475 0 0 150 0 150 -475 0 -475 0 0 150 0 150 475 0 475 0 0 150 0 150 -475 0 -475 0 0 150 0 150 475 0 475 0 0 500 0 500 -475 0 -475 0 0 175 0 175 625 0 625 0 0 -1780z m-600 930 l0 -200 -325 0 -325 0 0 200 0 200 325 0 325 0 0 -200z m-1256 -2059 c-10 -9 -537 -420 -596 -465 l-38 -28 0 251 0 251 322 0 c183 0 318 -4 312 -9z" />
          <path d="M1637 4453 c-4 -3 -7 -71 -7 -150 l0 -143 150 0 150 0 0 150 0 150 -143 0 c-79 0 -147 -3 -150 -7z" />
          <path d="M1437 3903 c-4 -3 -7 -71 -7 -150 l0 -143 100 0 100 0 0 -225 0 -224 -137 -3 -138 -3 -3 -147 -3 -148 431 0 430 0 0 150 0 150 -140 0 -140 0 0 375 0 375 -243 0 c-134 0 -247 -3 -250 -7z" />
          <path d="M840 2380 l0 -150 945 0 945 0 0 150 0 150 -945 0 -945 0 0 -150z" />
          <path d="M840 1780 l0 -150 945 0 945 0 0 150 0 150 -945 0 -945 0 0 -150z" />
        </g>
      </svg>
    </span>
  );
};
export default Brochure;
