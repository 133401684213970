import { h } from 'preact';

const InteractionSearchSuggestion = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""} ${props.identityClass || ''}`} width={props.width || "18"} height={props.height || "18"} viewBox={props.viewBox || "0 0 18 18"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1849 17C15.7389 17 16.9987 15.7464 16.9987 14.2C16.9987 12.6536 15.7389 11.4 14.1849 11.4C12.6309 11.4 11.3711 12.6536 11.3711 14.2C11.3711 15.7464 12.6309 17 14.1849 17Z" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.49609 3.93323H12.3099C12.8074 3.93323 13.2846 4.10804 13.6364 4.41921C13.9882 4.73038 14.1858 5.15242 14.1858 5.59248V11.3999" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.6289 1L8.50244 3.66666L11.6289 6.33333" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.81508 0.999981C2.26105 0.999981 1.00126 2.25358 1.00126 3.79998C1.00126 5.34637 2.26105 6.59998 3.81508 6.59998C5.36912 6.59998 6.62891 5.34637 6.62891 3.79998C6.62891 2.25358 5.36912 0.999981 3.81508 0.999981Z" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.50391 14.0667L5.69008 14.0667C5.19257 14.0667 4.71543 13.8918 4.36364 13.5807C4.01184 13.2695 3.8142 12.8475 3.8142 12.4074L3.8142 6.59999" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.37109 17L9.49756 14.3333L6.37109 11.6667" stroke={props.stroke || "#404040"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default InteractionSearchSuggestion;
