import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const ReceivePayment = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "24px"} height={props.height || "24px"}
	viewBox="0 0 512.000000 512.000000" fill={props.fill || "#408b8b"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#408b8b"} stroke={props.stroke || "none"}>
          <path d="M2865 5106 c-184 -30 -365 -101 -518 -203 -95 -62 -249 -213 -320 -311 -169 -234 -244 -489 -234 -787 8 -219 60 -402 165 -583 179 -307 460 -515 812 -603 120 -29 361 -37 486 -15 526 91 931 481 1039 1001 25 119 30 310 11 431 -42 276 -162 511 -360 710 -180 180 -377 288 -626 345 -112 26 -346 33 -455 15z m262 -346 c43 -26 66 -64 73 -118 5 -43 7 -45 49 -58 99 -29 202 -129 243 -236 29 -75 30 -177 2 -214 -51 -69 -130 -91 -194 -56 -48 27 -71 62 -81 123 -6 36 -17 61 -34 78 -24 25 -31 26 -131 26 l-106 0 -29 -33 c-34 -38 -38 -78 -9 -114 10 -14 117 -97 237 -186 120 -88 237 -180 260 -204 199 -206 115 -548 -159 -646 -44 -15 -48 -19 -48 -50 0 -80 -63 -143 -143 -144 -82 -2 -140 51 -153 138 -6 39 -9 43 -52 60 -142 53 -243 185 -257 334 -7 76 11 121 65 158 87 59 230 -9 230 -110 0 -80 44 -108 169 -108 81 0 92 2 119 25 35 29 47 70 33 108 -7 17 -94 89 -259 211 -137 102 -261 200 -274 218 -91 119 -112 278 -53 405 47 103 114 168 214 209 54 22 56 24 67 74 6 27 20 60 30 72 52 58 132 75 191 38z" />
          <path d="M2113 2385 c-297 -54 -575 -243 -721 -492 l-30 -50 307 -532 306 -531 625 0 c678 0 738 4 910 56 199 61 294 119 881 544 725 527 681 488 687 599 4 82 -23 141 -86 186 -54 37 -147 46 -203 18 -20 -10 -242 -166 -493 -348 -251 -181 -480 -345 -509 -362 -29 -18 -88 -42 -131 -53 -73 -18 -111 -20 -479 -20 -384 0 -402 1 -443 21 -47 22 -84 82 -84 135 0 42 40 104 80 125 32 18 65 19 455 19 l421 0 50 25 c85 42 128 136 104 226 -15 56 -81 123 -135 138 -25 7 -152 11 -344 11 l-303 0 -59 55 c-206 190 -524 281 -806 230z" />
          <path d="M847 1930 c-15 -5 -195 -104 -398 -222 -402 -231 -409 -237 -409 -320 0 -40 35 -104 363 -674 200 -346 369 -638 376 -649 26 -40 64 -60 116 -60 47 0 72 13 385 194 425 245 425 245 444 285 30 64 23 90 -84 276 -477 834 -656 1135 -685 1151 -41 23 -74 28 -108 19z" />
        </g>
      </svg>
    </span>
  );
};

export default ReceivePayment;
