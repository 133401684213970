import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

/*
  modified : Ashutosh G
  modified : 10/08/2023
  modification : addCallV2 icon added
*/

const AddCallV2 = (props) => {
  return (
    <span class={`${props.masterClasses || ''} ${props.identityClass || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "23"} height={props.width || "22"} fill={props.fill || "none"} onclick={(e) => props.action && props.action(e)}>
        <path d="M9.692,7.436a3.112,3.112,0,0,0-.651-3.442L6.955,1.909A3.064,3.064,0,0,0,4.3,1.035,3.1,3.1,0,0,0,2,2.672a8.58,8.58,0,0,0-.727,6.186C2.606,14.138,9.863,21.4,15.142,22.723a8.938,8.938,0,0,0,2.18.274,8.54,8.54,0,0,0,4.006-1,3.11,3.11,0,0,0,.764-4.951h0L20.006,14.96a3.11,3.11,0,0,0-3.444-.651,4.859,4.859,0,0,0-1.471.987c-.178.177-.5.205-.977.077A9.981,9.981,0,0,1,8.626,9.886c-.126-.471-.1-.8.078-.977A4.864,4.864,0,0,0,9.692,7.436Zm3.9,9.87a2.821,2.821,0,0,0,2.907-.6,2.906,2.906,0,0,1,.874-.576,1.1,1.1,0,0,1,1.214.239l2.085,2.085a1.089,1.089,0,0,1,.31.942,1.114,1.114,0,0,1-.591.826,6.516,6.516,0,0,1-4.766.556C11.089,19.641,4.36,12.912,3.216,8.37A6.53,6.53,0,0,1,3.773,3.6,1.115,1.115,0,0,1,4.6,3.013,1.044,1.044,0,0,1,4.767,3a1.088,1.088,0,0,1,.774.323L7.626,5.408a1.1,1.1,0,0,1,.239,1.213A2.9,2.9,0,0,1,7.29,7.5,2.817,2.817,0,0,0,6.7,10.4C7.417,13.1,10.9,16.583,13.6,17.306ZM17,4V6h2a1,1,0,0,1,0,2H17v2a1,1,0,0,1-2,0V8H13a1,1,0,0,1,0-2h2V4a1,1,0,0,1,2,0Z"/>
      </svg>
    </span>
  );
};

export default AddCallV2;
