import { h } from 'preact';

const Screenshot = (props) => {
  return (
    <span onclick={(e) => props.action && props.action(e)} class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M1720 4920 l0 -200 460 0 460 0 -2 198 -3 197 -457 3 -458 2 0 -200z"/>
          <path d="M3038 5114 c-5 -4 -8 -94 -8 -200 l0 -193 383 -3 382 -3 45 -25 c24 -14 58 -45 75 -68 l30 -44 3 -389 3 -389 199 0 200 0 0 388 c0 434 -5 477 -72 605 -45 87 -173 212 -259 254 -131 63 -146 65 -579 70 -217 3 -398 1 -402 -3z"/>
          <path d="M445 5091 c-211 -60 -385 -246 -430 -457 -11 -53 -15 -155 -15 -451 l0 -383 200 0 199 0 3 383 3 384 30 48 c21 35 45 56 80 74 l49 26 378 3 378 3 0 195 0 194 -407 -1 c-355 0 -416 -3 -468 -18z"/>
          <path d="M0 2940 l0 -460 200 0 200 0 0 460 0 460 -200 0 -200 0 0 -460z"/>
          <path d="M3950 2940 l0 -460 200 0 200 0 0 460 0 460 -200 0 -200 0 0 -460z"/>
          <path d="M0 1698 c0 -430 5 -473 66 -596 41 -85 129 -184 212 -238 31 -21 95 -51 142 -68 l85 -31 408 -3 407 -4 0 201 0 200 -382 3 -383 3 -45 25 c-24 14 -58 45 -75 68 l-30 44 -3 389 -3 389 -199 0 -200 0 0 -382z"/>
          <path d="M3950 1620 l0 -460 -460 0 -460 0 2 -197 3 -198 457 -3 458 -2 2 -378 3 -377 198 -3 197 -2 0 380 0 380 385 0 385 0 0 200 0 200 -385 0 -385 0 0 460 0 460 -200 0 -200 0 0 -460z"/>
          <path d="M1720 960 l0 -200 458 2 457 3 3 198 2 197 -460 0 -460 0 0 -200z"/>
        </g>
      </svg>
    </span>
  );
};

export default Screenshot;
