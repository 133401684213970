import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon

const QuestionMark = (props) => {
  return (
    <span onclick={() => props.action && props.action()} class={props.masterClasses || ''} title={props.title || ''}>
      <svg class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512"} height={props.height || "512"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || '#000000'} stroke="none">
          <path d="M2305 4449 c-374 -55 -676 -261 -830 -569 -99 -198 -119 -308 -73 -398 61 -118 199 -162 322 -102 61 29 91 70 130 177 124 334 309 492 612 523 296 31 568 -101 717 -346 65 -108 81 -172 81 -319 0 -113 -3 -138 -23 -190 -52 -131 -101 -186 -506 -571 -174 -165 -255 -263 -315 -381 -68 -136 -103 -279 -115 -473 -7 -121 5 -158 64 -197 31 -21 46 -23 153 -23 193 0 214 18 234 204 32 294 72 368 326 607 453 427 550 548 622 776 47 151 52 371 10 527 -25 95 -107 256 -172 337 -184 231 -427 367 -742 414 -104 16 -396 18 -495 4z"/>
          <path d="M2470 1171 c-78 -24 -157 -99 -178 -169 -53 -176 66 -342 245 -342 109 0 185 46 233 143 53 105 34 213 -50 298 -66 66 -168 95 -250 70z"/>
        </g>
      </svg>
    </span>
  );
};

export default QuestionMark;
