import { h, Component, Fragment } from 'preact';
import { Nudge } from "../../components/gifs";
import { DownArrow2, Home, Clipboard, Cases, AddCall, PriceList, AddCase, ReceivePayment, Folder, Hyundai, Finance, Car, Accessories, Insurance, Vas, OngoingCall, Search, List, Table, User, Users, Department, Alarm2, Location2, Call, Siren, SirenSm, LeftArrow, Exclamation2,Expand, OpenBox, Briefcase, Plus, NudgeGrey, MissedCall } from '../../components/svgs';

const ShowNotification = (props) => {
  console.log(props.message,'props.showSlideNotificationprops.showSlideNotification');
  return (
    <Fragment>
      {/*<div ref={props.slideNotificationRef} class={`workspace-notification-hide flex-l-m gap-16px `}>*/}
      <div ref={props.slideNotificationRef} class={`${props.showSlideNotification ? 'workspace-notification-show ' : 'workspace-notification-hide '} flex-l-m gap-16px `}>
        <div class="workspace-notification-disk display-flex p-t-4">
          <NudgeGrey classes="m-b-4 m-t-11 m-l-12" fill="black" stroke="black" width="22" height="22" />
          <p class="fs-14 m-l-6 text-black"> {props.message} </p>
        </div>
        {/*<div class="workspace-notification-disk flex-r-m flex-col p-b-6  ">
          <MissedCall width="20" height="20"/>
          <p class="fs-12 text-red m-t-neg-2 lh-1-0 text-center"> 2 </p>
        </div>*/}
      </div>
    </Fragment>
  );
};

export default ShowNotification;
