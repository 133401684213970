import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const MoveRightArrow = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "24"} height={props.height || "24"} fill={props.fill || "#000000"} stroke={props.stroke || "#000000"} strokeLinecap="round" strokeLinejoin="round" strokeWidth={props.strokeWidth || "0.5"} class={props.classes || "lucide lucide-move-right"} viewBox={props.viewBox || "0 0 24 24"} preserveAspectRatio="xMidYMid meet">
        <path d="m18 8 4 4-4 4M2 12h20" />
      </svg>
    </span>
  );
};

export default MoveRightArrow;
