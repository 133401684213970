import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";
import { NoDocument } from '../../components/svgs';
import { months, formatDateTime } from '../../lib/utils';

const MultiSelectBox = (props) => {
  const {
    masterClasses,
    classes,
    inputClasses,
    lableClasses,
    count,
    title,
    circleBgClass,
    data,
    cardHeightClass,
    isLoading,
    loaderColorType,
    multiSelectCheckBoxDropDown,
    optionsArray,
    isMonths,
    isYears,
    isDateOnly,
    selectedFilterColumns,
    selectedColumn,
    action,
    objectValueKey,
    objectDisplayKey,
    defaultSelectedOptionsArray,
    boxID,
    objectDisplayKey2
  } = props;
  const [getCheckBoxValue,setCheckBoxValue] = useState(false);
  let [selectedList, setSelectedList] = useState(optionsArray && optionsArray.length && optionsArray.map(singleOption => singleOption[objectDisplayKey || 'name'] === '(BLANKS)' ? '' : singleOption[objectDisplayKey || 'name']));
  let [optionArray, setOptionsArray] = useState(optionsArray);
  let [selectedFilterColumn, setSelectedFilterColumn] = useState(selectedFilterColumns);

  const [isDefaultSelectedOptionsEvaluated, setIsDefaultSelectedOptionsEvaluated] = useState(false);

  /*
    Modification: Added useEffect to pre-fill the checked values
    By: Devang
    Date: 04/12/2023
  */
  useEffect(() => {
    if (!isDefaultSelectedOptionsEvaluated && defaultSelectedOptionsArray && defaultSelectedOptionsArray.length) {
      setIsDefaultSelectedOptionsEvaluated(true);
      let updatedOptionsArray = optionsArray.map(singleOption => {
        if (defaultSelectedOptionsArray.includes(singleOption[objectValueKey || 'name'])) {
          singleOption.checked = true;
          singleOption.visible = true;
        }
        return singleOption;
      });
      const updatedSelectedList = updatedOptionsArray.filter(singleOption => singleOption.checked).map(singleOption => (singleOption[objectDisplayKey || 'name'] === '(BLANKS)' ? '' : singleOption[objectDisplayKey || 'name']));
      setOptionsArray(updatedOptionsArray);
      setSelectedList(updatedSelectedList);
    }
  }, [defaultSelectedOptionsArray]);

  useEffect(()=> {
    setOptionsArray(optionsArray);
    setSelectedFilterColumn(selectedFilterColumns);
  },[optionsArray, selectedList, selectedFilterColumn]);

  function changeOption(option, e) {
    e.preventDefault();
    let updatedOptionsArray = optionsArray.map(singleOption => {
      if (option[objectDisplayKey || 'name'] === singleOption[objectDisplayKey || 'name']) {
        singleOption.checked = !option.checked;
        singleOption.visible = true;
      }
      return singleOption;
    });
    const updatedSelectedList = updatedOptionsArray.filter(singleOption => singleOption.checked).map(singleOption => (singleOption[objectDisplayKey || 'name'] === '(BLANKS)' ? '' : singleOption[objectDisplayKey || 'name']));
    setOptionsArray(updatedOptionsArray);
    setSelectedList(updatedSelectedList);
    // Call the action function with the updated selected list and selected column
    action(updatedSelectedList, selectedColumn, updatedOptionsArray, selectedFilterColumn, e);
  }

  function transformName(name) {
    if (name) {
      return name.replace(/[^a-zA-Z0-9]/g, ' ').toUpperCase();
    }
    return name;
  }

  return (
    <>
      {/*
        Modification: Removed ref from here for transferring it to parent element for sake of opening it properly
        By: Devang
        Date: 28/11/2023
      */}
      { multiSelectCheckBoxDropDown &&
        <div style={objectDisplayKey === 'requestNumber' ? { maxHeight: '200px'} : { maxHeight: '150px'}} id={boxID} class={`${masterClasses || ''} multi-select-container w-full bor-rad-all-5 bor-all-gray p-7 overflow-auto m-t-5`}>
          { optionsArray.length > 0 ? optionsArray.map((element, index) => (
            <div key={index} class={`${classes || 'flex-l-m p-b-5'}`}>
              <label class={`${lableClasses || ''} text-color-002c5f-imp cursor-pointer`}>
                <input onChange={(e) => {changeOption(element,e);}} checked={element.checked} value={element[objectValueKey || 'name']} class={`${inputClasses || ''} multi-select-input cursor-pointer`} type="checkbox" />
                {
                  !isMonths && !isYears && !objectDisplayKey2 &&
                  <span>{transformName(element[objectDisplayKey || 'name'])}</span>
                }
                {
                  isMonths && (
                    element[objectDisplayKey || 'name'] !== '(BLANKS)' ?
                      transformName(months[element[objectDisplayKey || 'name']]) :
                      '(BLANKS)'
                  )
                }
                {
                  isYears &&
                  <span>{element[objectDisplayKey || 'name']}</span>
                }
                {
                  isDateOnly && (
                    element[objectDisplayKey || 'name'] !== '(BLANKS)' ?
                      formatDateTime(element[objectDisplayKey || 'name']) :
                      '(BLANKS)'
                  )
                }
                {
                  objectDisplayKey2 && (
                    <span>{transformName(element[objectDisplayKey || 'name'])} - {transformName(element[objectDisplayKey2 || 'name'])}</span>
                  )
                }
              </label>
            </div>
          )): (
            <div class="w-full h-full flex-c-m flex-direction-column">
              <NoDocument/>
              <p class="m-t-16 fs-16 lh-16-px">No fields to show</p>
            </div>
          )
          }
        </div>
      }
    </>
  );
};

export default MultiSelectBox;
