import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const ProcessImpact = (props) => {
  return (
    <span title={props.title || ''} class={props.masterClasses || ''}>
      <svg class={props.classes || ''} xmlns="http://www.w3.org/2000/svg" width={props.width || "10"} height={props.width || "10"} fill={props.fill || "none"} viewBox="0 0 16 16" fill="none">
        <path d="M14 1.33337V5.33337H10" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 8.00003C2.00105 6.84224 2.33707 5.70948 2.9675 4.73839C3.59794 3.76729 4.49588 2.99934 5.55301 2.52716C6.61013 2.05497 7.7813 1.89873 8.92524 2.07727C10.0692 2.2558 11.137 2.7615 12 3.53336L14 5.33336" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 14.6666V10.6666H6" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 8C13.9989 9.15779 13.6629 10.2905 13.0325 11.2616C12.4021 12.2327 11.5041 13.0007 10.447 13.4729C9.38987 13.9451 8.2187 14.1013 7.07476 13.9228C5.93082 13.7442 4.86297 13.2385 4 12.4667L2 10.6667" stroke={props.stroke || "black"} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default ProcessImpact;
