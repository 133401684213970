/*
  Modification: create Acknowledge svg Component
  By: Komal
  Date: 24/04/2023
*/
import { h } from 'preact';
// from flaticon
const Acknowledge = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" fill="none" version="1.0" width={props.width || "256pt"} height={props.height || "256pt"}
        viewBox={props.viewBox || "0 0 32 32"} preserveAspectRatio="xMidYMid meet"
      >
        <g filter="url(#filter0_d_1873_1479)">
          <path d="M16 28C23.732 28 30 21.732 30 14C30 6.26801 23.732 0 16 0C8.26801 0 2 6.26801 2 14C2 21.732 8.26801 28 16 28Z" fill="white"/>
          <path d="M16 28C23.732 28 30 21.732 30 14C30 6.26801 23.732 0 16 0C8.26801 0 2 6.26801 2 14C2 21.732 8.26801 28 16 28Z" fill="#02CB2E" fill-opacity="0.5"/>
          <path d="M23 9.33325L13.375 18.6666L9 14.4242" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <filter id="filter0_d_1873_1479" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1873_1479"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1873_1479" result="shape"/>
          </filter>
        </defs>
      </svg>
    </span>
  );
};

export default Acknowledge;
