import { h, Component } from 'preact';
import { getInitials, isString, formatDateTime, isValidDate, getDateColor, typeOfEnquiryMappingClassColor, formatDateTimeRelative, registrationTypeMappingClassColor, registrationTypeMapping, registrationTypeTextMappingClassColor} from '../../lib/utils';
import Ribbon from '../../components/ribbon';
import CallingButton from "../../components/callingButton";
import { DownArrow, Tick, Cross, View, Download, Print, BullsEye, BlockSign, AddContact, Generate, Send, Verify, FollowUp, Receive, Folder, Menu, Upload, Hyundai, Finance, Vas, Car, Accessories, Insurance } from '../../components/svgs';
import SemiInfoDetailCard from '../../components/semiInfoDetailCard';
import SingleMainRow from '../../components/singleMainRow';
import SingleCard from '../../components/singleCard';
import LabelDataElement from '../../components/labelDataElement';
import { useState, useEffect } from 'preact/hooks';

const CallingCard = (props) => {
  const getRowCardIcon = (button) => {
    if (button && button.toLowerCase() === 'hyundai') {
      return <Hyundai width="25px" height="25px" fill="#808080" />;
    } else if (button && button.toLowerCase() === 'car') {
      return <Car width="25px" height="25px" fill="#808080" />;
    } else if (button && button.toLowerCase() === 'accessories') {
      return <Accessories width="25px" height="25px" fill="#808080" />;
    } else if (button && button.toLowerCase() === 'vas') {
      return <Vas width="25px" height="25px" fill="#808080" />;
    } else if (button && button.toLowerCase() === 'finance') {
      return <Finance width="25px" height="25px" fill="#808080" />;
    }
  };
  let [isPageRefreshed, setIsPageRefreshed] = useState(false);
  // useEffect(() => {
  //   var seconds = 0;
  //   var minutes = 0;
  //   var appendMinutes = document.getElementById("minutes")
  //   var appendSeconds = document.getElementById("seconds")
  //   var Interval ;
  //   clearInterval(Interval);
  //   Interval = setInterval(() => {
  //     seconds++;
  //     console.log("in interval");
  //     if(seconds <= 9){
  //       appendSeconds.innerHTML = "0" + seconds;
  //     }
  //
  //     if (seconds > 9){
  //       appendSeconds.innerHTML = seconds;
  //     }
  //
  //     if (seconds > 59) {
  //       console.log("minutes");
  //       minutes++;
  //       appendMinutes.innerHTML = "0" + minutes;
  //       seconds = 0;
  //       appendSeconds.innerHTML = "0" + 0;
  //     }
  //     if (minutes > 9){
  //       appendMinutes.innerHTML = minutes;
  //     }
  //   }, 1000);
  // }, [isPageRefreshed])

  return (
    <div class={`newWorkSpaceCard fadeMoveLeftAnimation semi-info-detail-card-no-hover overflow-hidden`}
      style={`background:${props.cardBgColor};height:${props.cardHeight}; padding: ${!props.isTaskView ? "16px 0px;" : "8px 0px"}; z-index:9999999; position: absolute; width: 258px; right: 0; bottom: 5px;`}
    >
      <div class={props.masterClasses || "p-t-0 p-b-0 display-flex justify-between flex-direction-column h-full first-letter-capital"}>
        <button onclick={(e) => props.action && props.action(e)} class='callingClose'>Close</button>
        {
          props.ribbons && props.ribbons.length > 0 && props.ribbons.map(ribbon => (
            <Ribbon ribbonText={ribbon.text} ribbonColor={ribbon.color} type={ribbon.type} variant={ribbon.variant} isTextNotVisible={ribbon.isTextNotVisible} ribbonTextColor={ribbon.ribbonTextColor} />
          ))
        }
        <div class="row">
          {
            props.cardHeader && (
              <div class={`${props.headerContainerClasses || 'col-xs-12 col-sm-12 '} m-t-0 p-r-0 p-l-0 p-b-12`}>
                <div className={props.mainHeaderClasses || "display-flex"}>
                  {
                    props.iconText && (
                      <div class={`${props.iconContainerClasses || ''}  m-t-0 col-xs-12 col-sm-12 p-r-0 p-l-0 flex-c-m`}>
                        <CallingButton userDisplayName={props.iconText} title={props.iconText} classes={`${props.iconClasses || ''} grey-white fs-10 m-0`} masterClasses='w-40px h-40px' isTaskView = {props.isTaskView} />
                      </div>
                    )
                  }
                  <div class={props.isTaskView ? "m-t-0 col-xs-4 col-sm-4 p-r-0 p-l-0 flex-c-m p-t-0": "m-t-8 col-xs-12 col-sm-12 p-r-0 p-l-0 flex-c-m"}>
                    <p class={props.headerClasses || `fs-11 f-w-400 text-white blinking`} style="margin:0; align-self: baseline;letter-spacing: 0.08px;">{props.header}</p>
                  </div>
                  <div class={props.isTaskView ? "m-t-0 col-xs-8 col-sm-8 p-r-0 p-l-0 flex-c-m display-grid": "m-t-0 col-xs-12 col-sm-12 p-r-0 p-l-0 flex-c-m"}>
                    <p class={props.headerClasses || `fs-15 f-w-400 text-white`} style="margin:0; align-self: baseline;letter-spacing: 0.08px;">{props.cardHeader}</p>
                    {
                      props.isTaskView && (
                        <span class={(props.subHeaderClasses || "") + " fs-9"}>{props.subHeader}</span>
                      )
                    }
                  </div>
                  {
                    !props.isTaskView && props.subHeader &&
                      <div class="m-t-8 col-xs-12 col-sm-12 p-r-0 p-l-0 flex-c-m">
                        <p class={(props.subHeaderClasses || "") + " fs-9 text-white"}>{props.subHeader}</p>
                      </div>
                  }
                  <div class= {props.isTaskView ? "display-none" : "m-t-8 col-xs-8 col-sm-12 p-r-0 p-l-0 flex-c-m blinking text-white"}>
                    <span id="minutes">00</span>:<span id="seconds">00</span>
                  </div>
                  {
                    props.tagName &&
                    <div class={(props.tagNameClasses || "") + "f-c-m"} onclick={(e) => props.getReferenceFilesForFileViewer(e, 'Uploaded Files', true, props.fileIDs, props.interactionObj && props.interactionObj.uuid, false)}>
                      <span class={(props.tagNameClasses || "") + " fs-10 f-w-500 m-l-10"} style="border-radius:20px;color:#fff;padding:5px 10px;background:#154042;align-self: center;">{props.tagName}</span>
                    </div>
                  }
                </div>
              </div>
            )
          }
          <div class={`${'col-xs-12 col-sm-12 '} m-t-0 p-r-0 p-l-0 p-b-12`} style="height: 100px;">
            <div class="row" style="height: 100%">
              <div class={"m-t-0 col-xs-12 col-sm-12 p-r-0 p-l-0 flex-c-m p-t-0 display-block"} style="background: #3391FF;height: 60px;">
                <p class={`fs-14 f-w-400 text-white p-t-16 p-l-4`} style="margin:0; align-self: baseline;letter-spacing: 0.08px;">{props.truecallerName}</p>
                <span class={" fs-10 f-w-300 m-l-4 text-white"} >{props.state + ', '+ props.country}</span>
              </div>
              <div class={"m-t-0 col-xs-12 col-sm-12 p-r-0 p-l-0 flex-c-m p-t-0 display-block"} style="background: #fff;height: 28px;">
                <div class="row">
                  <div class="col-xs-12 display-block p-l-4 p-t-0">
                    <span class={"fs-10 f-w-300 m-l-0"} >Mobile</span>
                    <img style="float: right;right: 6px !important;position: absolute;" src="/assets/images/TrueCaller_Logo.png" height="15" />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <span class={props.iconMasterClasses ? props.iconMasterClasses + " pos-absolute" : " pos-absolute right-0 bottom-0"}>
        {props.cardIcon}
      </span>
    </div>
  );
};

export default CallingCard;
