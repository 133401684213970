import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Target = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width={props.width || "330pt"} height={props.height || "330pt"} viewBox={props.viewBox || "0 0 330 330"} style={`enable-background:new 0 0 330 330; ${props.style || ''}`} fill={props.fill || '#3b3b3b91'} >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill} stroke="none">
        <path d="M2370 5113 c-232 -22 -409 -56 -590 -115 -397 -129 -719 -325 -1019 -621 -412 -407 -661 -908 -742 -1497 -20 -143 -18 -527 4 -670 90 -586 331 -1065 738 -1467 196 -193 400 -343 631 -461 815 -419 1808 -366 2570 136 789 521 1229 1426 1148 2362 -19 213 -80 498 -117 549 -71 95 -204 95 -263 -1 -33 -54 -33 -85 -1 -211 52 -205 65 -319 65 -557 0 -243 -13 -349 -70 -570 -203 -779 -832 -1404 -1610 -1599 -211 -53 -318 -65 -554 -65 -231 0 -342 12 -535 60 -504 126 -943 417 -1252 829 -229 304 -367 632 -430 1020 -24 153 -24 496 0 650 81 497 290 910 636 1256 348 346 760 555 1256 635 81 14 167 18 325 18 238 0 352 -13 557 -65 132 -33 168 -32 221 7 87 67 83 188 -9 257 -50 37 -330 97 -544 117 -102 9 -333 11 -415 3z"/>
        <path d="M4130 5110 c-31 -13 -636 -615 -656 -653 -10 -19 -28 -162 -55 -440 l-41 -412 -483 -485 c-524 -525 -507 -505 -490 -597 9 -49 69 -109 118 -118 92 -17 72 -34 597 490 l485 483 412 40 c277 28 421 46 440 56 15 8 168 156 340 328 271 272 312 318 318 351 9 48 1 91 -26 128 -41 58 -69 64 -389 89 -162 13 -298 26 -301 29 -4 3 -17 137 -29 296 -24 318 -32 354 -90 395 -37 26 -111 36 -150 20z m-56 -727 c23 -281 28 -286 309 -309 100 -8 190 -15 200 -17 12 -1 -24 -43 -125 -145 l-143 -142 -299 -30 c-164 -17 -300 -29 -303 -27 -2 3 10 139 27 303 l30 299 142 143 c102 101 144 137 145 125 2 -10 9 -100 17 -200z"/>
        <path d="M2345 4313 c-552 -71 -1043 -401 -1319 -884 -63 -111 -134 -278 -165 -390 -50 -187 -56 -234 -56 -479 0 -245 6 -293 56 -475 40 -149 127 -341 216 -480 232 -361 600 -630 1023 -749 330 -93 770 -71 1090 56 531 210 920 639 1074 1188 59 207 74 524 36 747 -29 170 -43 213 -85 253 -31 30 -43 35 -98 38 -52 3 -67 0 -96 -20 -40 -27 -71 -81 -71 -123 0 -17 9 -71 21 -121 30 -130 38 -406 15 -546 -87 -529 -462 -968 -971 -1137 -181 -60 -291 -75 -503 -68 -237 7 -374 40 -577 137 -422 203 -722 601 -799 1059 -21 124 -21 358 0 482 101 603 584 1083 1192 1184 140 24 416 16 546 -14 108 -25 137 -26 183 -5 50 24 83 78 83 137 0 124 -72 166 -347 207 -67 9 -381 12 -448 3z"/>
        <path d="M2393 3505 c-201 -36 -365 -124 -513 -274 -129 -130 -212 -278 -257 -457 -24 -95 -24 -333 0 -428 46 -182 128 -328 261 -462 134 -133 280 -215 462 -261 95 -24 333 -24 428 0 185 46 324 125 461 262 183 183 276 395 279 632 1 110 -7 132 -68 177 -39 29 -133 29 -172 0 -54 -40 -66 -67 -75 -166 -5 -51 -18 -122 -29 -158 -58 -191 -228 -360 -425 -423 -57 -18 -93 -22 -190 -22 -104 1 -130 5 -193 27 -105 38 -176 83 -257 163 -247 246 -245 645 5 895 129 130 282 189 483 190 109 0 138 10 180 60 72 85 24 219 -88 249 -53 14 -202 12 -292 -4z"/>
        </g>
      </svg>
    </span>
  );
};

export default Target;
