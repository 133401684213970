import { getItem } from './myStore';
import { throwError, isEmptyObject } from './utils';
import CONSTANTS from './constants';
import axios from 'axios';

/*
  Modification: Updated 2 function and added function to handle cancelToken
  By: Devang
  Date: 18/03/2024
*/

const callAPI = async (method, url, paramData, options = {}) => {
  const { isIgnoreEncrpytion, headers = {}, cancelTokenSource } = options;

  if (!url) {
    throwError('Provide API URL to call API');
  }

  let QUERY = `${CONSTANTS.API_URL}/api${url}`;

  let headerOpts = { headers };
  if (isIgnoreEncrpytion) {
    headerOpts['headers']['is-ignore-encryption'] = true;
  }
  if (cancelTokenSource) {
    headerOpts['cancelToken'] = cancelTokenSource.token;
  }

  let response;
  if (method === 'get') {
    if (typeof paramData === 'string') {
      QUERY += `?${paramData}`;
    } else if (Array.isArray(paramData)) {
      QUERY += `?${paramData.join('&')}`;
    } else if (typeof paramData === 'object' && !Array.isArray(paramData) && paramData !== null && !isEmptyObject(paramData)) {
      Object.keys(paramData).map((key, index) => {
        QUERY += `${!index ? `?` : `&`}${key}=${paramData[key]}`;
      });
    }
    response = await axios[method](QUERY, headerOpts);
  } else if (['post', 'put'].includes(method)) {
    response = await axios[method](QUERY, paramData, headerOpts);
  } else {
    throwError('Handle function parameters properly to call API');
  }

  return response;
};

const isExceptionFromCancel = (HTTPException) => {
  return axios.isCancel(HTTPException);
};

const reportException = async (params = {}) => {
  const userInfo = getItem('userinfo');
  const fallbackValue = 'NA';
  const {
    HTTPException = {},
    functionName = fallbackValue,
    fileName = fallbackValue,
    /*
      Modification: Commented pageName as not needed to be sent when called reportException
      By: Devang
      Date: 03/02/2024
    */
    // pageName = fallbackValue,
    userID = (userInfo && userInfo.uuid) || fallbackValue,
    userName = (userInfo && userInfo.displayName) || fallbackValue
  } = params;

  if (isExceptionFromCancel(HTTPException)) return;

  console.error(HTTPException, 'From Exception Reporter');

  let payload = fallbackValue;
  /*if (HTTPException.config && HTTPException.config.data) {
    if (HTTPException.config.headers && HTTPException.config.headers['is-ignore-encryption']) {
      payload = JSON.parse(HTTPException.config.data);
    } else {
      let decryptedPayload = getDecryptedData(JSON.parse(HTTPException.config.data));
    }
  }*/

  if (HTTPException && HTTPException.config && HTTPException.config.url) {
    let payloadObj = {
      apiURL: HTTPException.config.url,
      errorObject: HTTPException,
      statusCode: HTTPException.response && HTTPException.response.status ? HTTPException.response.status : 0,
      errorMessage: HTTPException.message,
      userID,
      userName,
      functionName,
      fileName,
      /*
        Modification: Set pagename as pathname of the page
        By: Devang
        Date: 03/02/2024
      */
      pageName: window.location.pathname,
      payload
    };
    await callAPI(`post`, `/v1/exception`, payloadObj);
  }
  return HTTPException;
};

const generateCancelTokenSource = (source) => {
  if (source && source.cancel) {
    source.cancel();
  }
  return axios.CancelToken.source();
};

export { callAPI, reportException, generateCancelTokenSource, isExceptionFromCancel };
