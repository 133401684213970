import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const ThreeDots = (props) => {
  return (
    <span id={props.identifier} class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "256pt"} height={props.height || "256pt"}
        fill={props.fill || ''} stroke={props.stroke || 'none'} viewBox={props.viewBox || "0 0 16 16"} preserveAspectRatio="xMidYMid meet"
      >
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
      </svg>
    </span>
  );
};
export default ThreeDots;
