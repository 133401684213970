import { h, Component, Fragment } from 'preact';
import { useState, useEffect, useContext,useRef } from "preact/hooks";
import Tabs from '../../components/tabs';
import { Modal2, ModalBody2 } from '../../components/rightDrawer2';
import { Popup, PopupBody } from '../../components/popup';
import { DownArrow, Search, Trash, LineArrow } from '../../components/svgs';
import ComboBox from '../../components/comboBox';
import { isEmptyObject, modelMapping, fuelTypeMapper } from '../../../src/lib/utils';
import { callAPI, reportException } from '../../lib/transceiver';
import Multiselect from 'multiselect-react-dropdown';
import CloseIcon from "../../assets/images/close.png";
import toastr from "toastr";

const StockRightDrawer = (props) => {
  const {
    isStockDetailModalOpen,
    setIsStockDetailModal,
    activeStockTab,
    setActiveStockTab,
    selectedStockType,
    setSelectedStockType,
    toggleStockDetailsModal,
    catalogoueItemList,
    isMobileView
  } = props;

  let [isShowDetails, setShowDetails] = useState(false);
  let [stockModalTabs, setStockModalTabs] = useState([]);
  let [activePageTabItem, setActivePageTabItem] = useState('Stock');
  let [mainTabOptions, setMainTabOptions] = useState([]);
  let [monthWiseStockCount, setMonthWiseStockCount] = useState({});
  let [stockVariantData, setStockVariantData] = useState({});
  let [selectedStockTypeData, setSelectedStockTypeData] = useState([]);
  let [model, setModel] = useState();
  let [modelDisplayName, setModelDisplayName] = useState('');
  let [fuelTypeName, setFuelTypeName] = useState('');
  let [fuelTypes, setFuelTypes] = useState([]);
  let [variantList, setVariantList] = useState([]);
  let [variant, setVariant] = useState('');
  let [variantDisplayName, setVariantDisplayName] = useState('');
  let [typeOfFuel, setTypeOfFuel] = useState('');
  let [selectedVariant, setSelectedVariant]= useState([]);
  let [exteriorColor, setExteriorColor] = useState('');
  let [clearAll, setClearAll] = useState(false);

  const fileName = 'StockRightDrawer';

  useEffect(async () => {
    let mainTabOptions = [{
      label: 'Stock',
      isAddItem: true,
      isCounter: false,
      isPercentage: false,
      isEditable: true,
      isDraggable: true
    }];
    setMainTabOptions(mainTabOptions);
    let stockModalTabs = [{
      label: 'Vehicle Stock Search',
      isAddItem: true,
      isCounter: false,
      isEditable: true,
      isDraggable: true
    }];
    setStockModalTabs(stockModalTabs);

    function initAccordion(elem, option){
      document.addEventListener('click', (e) => {
        // Daca elementul nu este .acc-title, nu fa nimic
        if (!e.target.matches(elem+' .acc-title')) return;

        // Daca parintele (.acc-container) lui .acc-title are clasa .acc-active
        if (!e.target.parentElement.classList.contains('acc-active')) {
          // Daca option==true, sterge clasa .acc-active pentru celelalte elemente
          if (option === true) {
            let elementList = document.querySelectorAll(elem + ' .acc-container');
            Array.prototype.forEach.call(elementList, (e) => {
              e.classList.remove('acc-active');
            });
          }
          // Daca nu are clasa .acc-active, adauga clasa .acc-active
          e.target.parentElement.classList.add('acc-active');
        } else {
          // Daca are clasa .acc-active, remove clasa .acc-active
          e.target.parentElement.classList.remove('acc-active');
        }

      });
    }

    // Deschide doar unul, inchide celelalte
    initAccordion('.accordion.acc-single-open', true);
  }, [activePageTabItem]);

  useEffect(async() => {

    if (model && model.length) await getFuelTypesForSelectedModel(model);

    if (!model) {
      await setFuelTypes([]);
      await setVariantList([]);
      await setVariant('');
    }

    if (model && fuelTypes && fuelTypes.length) await getVariantForSelectedModel(fuelTypeName);

    if (variant) await getVariantDetails(variant);

  },[model, fuelTypeName, variant]);


  async function getFuelTypesForSelectedModel(id) {
    try {
      let response = await callAPI(`get`, `/v1/catalogue/fuelTypes`, `selectedCatalogueItemID=${id}`);

      if (response && response.data) {
        let fuelTypesResponse = response.data;
        const result = [];
        const map = new Map();
        // for (const item of variantReponse) {
        await Promise.all(fuelTypesResponse.map(item => {
          if (!map.has(item.fuelType)) {
            map.set(item.fuelType, true);    // set any value to Map
            result.push(item);
          }
        }));

        await setFuelTypes(result);
        await setVariantList([]);
        await setVariant('');
      }

    } catch (HTTPException) {
      reportException({
        HTTPException,
        fileName,
        functionName: 'getFuelTypesForSelectedModel'
      });
    }
  }

  async function getVariantForSelectedModel(fuelType){
    try {
      let response = await callAPI(`get`, `/v1/catalogue/variants`, `selectedCatalogueItemID=${model}&fuelType=${fuelType}`);

      if (response && response.data) {
        let variantReponse = response.data;
        const result = [];
        const map = new Map();

        variantReponse.map(item => {
          let veriantColorArray = [];
          veriantColorArray.push();
          if (!map.has(item.variant)) {
            item['colors'] = [];
            let color = (item.oemColor === '$color' || !item.oemColor) ? item.color.split(' ').join('-') : item.oemColor.split(' ').join('-');
            item.colors.push(color);
            map.set(item.variant, true);    // set any value to Map
            result.push(item);
          } else {
            let idx = result.findIndex(d => d.variant === item.variant);
            let color = (item.oemColor === '$color' || !item.oemColor) ? item.color.split(' ').join('-') : item.oemColor.split(' ').join('-');
            result[idx]['colors'].push(color);
          }
        });

        setVariantList(result);
      }

    } catch (HTTPException) {
      reportException({
        HTTPException,
        fileName,
        functionName: 'getVariantForSelectedModel'
      });
    }
  }

  const getStockList = async() => {
    try {
      let todaysDate = new Date();
      let fullYear = todaysDate.getFullYear();
      let month = todaysDate.getMonth() + 1; // Adding 1 to get the actual month number

      // If month is a single digit, pad it with a leading zero
      let formattedMonth = month < 10 ? '0' + month : month;

      let params = {
        // monthYear: `${fullYear}-${formattedMonth}`
      };
      if (modelDisplayName) params['model'] = modelDisplayName;
      if (fuelTypeName) params['fuelType'] = fuelTypeName;
      if (variantDisplayName) params['variant'] = variantDisplayName;
      if (exteriorColor) params['color'] = exteriorColor;

      let response = await callAPI(`get`, `/v2/stockItem`, `${new URLSearchParams(params)}`);

      if (response && response.data) {
        setSelectedStockTypeData(response.data);
      }

      if (response && !response.data.length) toastr.info('No records to show');

    } catch (HTTPException) {
      reportException({
        HTTPException,
        fileName,
        functionName: 'getStockList'
      });
    }
  };

  async function getVariantDetails(id) {
    let index = variantList.findIndex(variant => variant.uuid === id);
    if (index > -1) {
      await setSelectedVariant(variantList[index]);
    }
  }

  const changeActiveStockTab = (tabName) => {
    setShowDetails(false);
    setActiveStockTab(tabName);
    setSelectedStockType(`Total ${tabName}`);
  };

  const setActiveStockType = (type) => {
    setSelectedStockType(type);
    setVariantName('');
    setShowDetails(false);
  };

  const getTotalCountForModelVariants = (modelObj) => {
    let sum = 0;
    modelObj.map(variant => {
      sum += variant.total;
    });
    return sum;
  };

  const handleStockSearch = async() => {
    await getStockList();
  };

  const handleClearStockSearch = () => {
    setClearAll(true);
    setSelectedStockTypeData([]);
  };

  function openAccordion (e, id) {
    e.stopPropagation();
    let target = document.getElementById(id);
    if (!target.classList.contains('acc-active')) {
      target.classList.add('acc-active');
    } else {
      target.classList.remove('acc-active');
    }
  }

  return (
    <Modal2 title="Lead #10" modalSize="is-full-height"
      modalDisplay={(isStockDetailModalOpen ? 'show-modal' : 'hide-modal')} onClose={(e) => toggleStockDetailsModal(e)}>
      <ModalBody2 modalPadding="noPadding" modalFullHeight="" onClose={(e) => toggleStockDetailsModal(e)} closeID={'userProfileMenu'} isMobileView={isMobileView}>
        <div class="row p-t-12 p-b-20 flex-sb-m">
          <div class="col-xs-10 fs-12 f-w-500 display-flex text-3C3C3C">
            <div>
              <p class="fs-14">Stock Availability</p>
            </div>
          </div>
          {
            isMobileView && (
              <div class='col-xs-2' onClick={(e) => toggleStockDetailsModal(e)}>
                <span class="fs-18">x</span>
              </div>
            )
          }
        </div>

        <div class={`${isMobileView ? 'col-xs-12' : 'row'}`}>
          <div class={`${isMobileView ? 'col-xs-12 p-0' : 'col-xs-6'} flex-col`}>
            <div class={`${isMobileView ? '' : 'p-r-24'} analytics-right-half-section`}>
              {
                activeStockTab === "Vehicle Stock Search" && (
                  <div class="flex-col gap-10px m-b-16">
                    <div class={`${isMobileView ? 'col-xs-12 p-r-0' : 'col-xs-8 p-r-0'}`}>
                      <p for="interestedCar" class="form-label text-black">Model <span class="star-mandatory-entry p-l-2">{"*"}</span></p>
                      <ComboBox
                        placeholder={"Select Model"}
                        options={catalogoueItemList}
                        value={model}
                        setValue={setModel}
                        objectDisplayKey={'displayName'}
                        mapping={modelMapping}
                        setValueDisplayName={setModelDisplayName}
                        isSetDisplayName={true}
                        clearAll={clearAll}
                        setClearAll={setClearAll}
                      />
                    </div>

                    <div class={`${isMobileView ? 'col-xs-12 p-r-0' : 'col-xs-8 p-r-0'}`}>
                      <p for="interestedCar" class="form-label text-black">Fule Type</p>
                      <ComboBox
                        placeholder={"Select Fuel Type"}
                        options={fuelTypes}
                        value={typeOfFuel}
                        setValue={setTypeOfFuel}
                        objectDisplayKey={'fuelType'}
                        mapping={fuelTypeMapper}
                        setValueDisplayName={setFuelTypeName}
                        isSetDisplayName={true}
                        clearAll={clearAll}
                        setClearAll={setClearAll}
                      />
                    </div>

                    <div class={`${isMobileView ? 'col-xs-12 p-r-0' : 'col-xs-8 p-r-0'}`}>
                      <p for="interestedCar" class="form-label text-black">Variant</p>
                      <ComboBox
                        placeholder={"Select Variant"}
                        options={variantList}
                        value={variant}
                        setValue={setVariant}
                        objectDisplayKey={'variant'}
                        setValueDisplayName={setVariantDisplayName}
                        isSetDisplayName={true}
                        clearAll={clearAll}
                        setClearAll={setClearAll}
                      />
                    </div>

                    <div class={`${isMobileView ? 'col-xs-12 p-r-0' : 'col-xs-8 p-r-0'}`}>
                      <p for="interestedCar" class="form-label text-black">Ex. Color</p>
                      <ComboBox
                        placeholder={"Select Ex. Color"}
                        options={selectedVariant.colors}
                        value={exteriorColor}
                        setValue={setExteriorColor}
                        displayKey={'color'}
                        clearAll={clearAll}
                        setClearAll={setClearAll}
                      />
                    </div>
                    <div class={`${isMobileView ? 'col-xs-12' : 'col-xs-8'} flex-r p-r-0`}>
                      <button class="primary-button m-r-12" disabled={!model} onClick={handleClearStockSearch}>Clear</button>
                      <button class="primary-button" disabled={!model} onClick={handleStockSearch}>Search</button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          {
            activeStockTab === "Vehicle Stock Search" && (
              <div class={`${isMobileView ? 'col-xs-12 p-0 m-b-22' : 'col-xs-6'} flex-col`}>
                {(selectedStockTypeData && selectedStockTypeData.length > 0) && <p for="interestedCar" class="form-label text-black">Result</p>}
                <div class="row accordian-row overflow-auto p-r-0 p-5">
                  <div class="accordion no-shadow bor-rad-all-0 acc-single-open">
                    {
                      selectedStockTypeData && selectedStockTypeData.map((stock, stockIndex) => (
                        <div class="acc-container" id={`model-${stockIndex}`}>
                          <div class={`bg-EF7F68-25 acc-title-stock bor-color-transparent p-0 acc-title row overflow-hidden`} onclick={(e) => openAccordion(e, `model-${stockIndex}`)}>
                            <div class="col-xs-3">
                              <p class="p-t-8 p-b-8 fs-12 f-w-400 text-3C3C3C">{modelMapping[stock.displayName]}</p>
                            </div>
                            <div class="col-xs-5">
                              <p class="p-t-8 p-b-8 fs-12 f-w-400 text-3C3C3C">{stock.variant}</p>
                            </div>
                            <div class="col-xs-2 p-t-8 p-b-8">
                              <span class="order-number-card flex-c-m #04364a p-12 bg-04364a">
                                <p class={`text-white fs-18 f-400`}>{stock  && stock.variants ? stock.variants.length : '0'}</p>
                              </span>
                            </div>
                            <div class="acc-title-arrow m-l-auto p-t-10 p-r-12"><DownArrow classes="rotate-270" fill="#000" width="12" height="12"/>
                            </div>
                            <div onclick={(e) => e.stopPropagation()} class="acc-content bg-white bor-none p-0">
                              <table class="bor-none">
                                <thead>
                                  <tr>
                                    <th class='bg-F2F7FA p-t-8 p-b-8 fs-12 text-6F727C f-w-400'>Color</th>
                                    <th class='bg-F2F7FA p-t-8 p-b-8 fs-12 text-6F727C f-w-400'>VIN</th>
                                    <th class='bg-F2F7FA p-t-8 p-b-8 fs-12 text-6F727C f-w-400'>Fuel Type</th>
                                    <th class='bg-F2F7FA p-t-8 p-b-8 fs-12 text-6F727C f-w-400'>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    stock && stock.variants.map((variantObj, index) => (
                                      <tr id={`${stockIndex}-${index}`} style={`${variant === variantObj.variant ? `background: #f0f8ff`: ''}`}>
                                        <td class="fs-12 text-404040 f-w-400">{variantObj.color ? variantObj.color : "-"}</td>
                                        <td class={`${isMobileView && 'w-20px'} fs-12 text-404040 f-w-400`}>{variantObj.vin ? variantObj.vin : "-"}</td>
                                        <td class="fs-12 text-404040 f-w-400">{variantObj.fuelType ? variantObj.fuelType : "-"}</td>
                                        <td class="fs-12 text-404040 f-w-400">{variantObj.status ? variantObj.status : "-"}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </ModalBody2>
    </Modal2>
  );
};

export default StockRightDrawer;
