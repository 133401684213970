import { h, Component } from 'preact';
import { useState, useEffect } from "preact/hooks";
import axios from "axios";
import moment from 'moment';
import { formatDateTime, getFormattedAmount, modelMapping} from '../../lib/utils';
import CONSTANTS from '../../lib/constants';

const LabelDataElement = (props) => {
  const {
    masterClasses,
    classes,
    headerClasses,
    valueClasses,
    label,
    data,
    variant,
    isHeader,
    isDataBadge,
    isVerified,
    action,
    image,
    isEditable,
    propName,
    formType,
    interactionObj,
    saveOnChange,
    isAmount,
    isHighlighted,
    areaList,
    showVerification,
    isModel,
    isMasked,
    subValue
  } = props;

  let [dynamicPropertyEnum, setDynamicPropertyEnum] = useState([]);

  useEffect(async () => {
    if (isEditable && (formType === 'select' || formType === 'radio')) {
      try {
        if (propName !== "area" && propName !== "registrationArea" && propName !== "companyArea") {
          let dynamicProp = await axios.get(`${CONSTANTS.API_URL}/api/v1/properties?propertyType=dynamicProperty&name=${propName}`);
          if (dynamicProp.data && dynamicProp.data.Properties && dynamicProp.data.Properties.length && dynamicProp.data.Properties[0].enum && dynamicProp.data.Properties[0].enum.length > 0) {
            setDynamicPropertyEnum(dynamicProp.data.Properties[0].enum);
          } else if (formType === 'radio') {
            setDynamicPropertyEnum(['yes', 'no']);
          }
        }
      } catch (e) {
        console.error(e.message);
      }
    }
  }, [isEditable, areaList]);

  const showData = (data) => {
    if (formType === "date") return formatDateTime(data);
    if (isAmount) return getFormattedAmount(data);
    if (isModel) return modelMapping[data];
    if (isMasked) return data.replace(data.substring(0,8), 'XXXXXXXX');
    return data;
  };

  const verifyData = (data) => {
    return (data && data !== 'NA' && data !== '-');
  };

  let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const checkFirstLetterCapital = (data) => {
    return (
      (formType === 'radio' || formType === 'text') &&
      !emailRegex.test(data)
    )
      ?
      'first-letter-capital'
      :
      '';
  };

  return (
    <div>
      {
        variant && variant.toLowerCase() === 'single' &&
        <SingleLabelDataElement
          labelClasses={props.labelClasses}
          headerClasses={props.headerClasses}
          masterClasses={masterClasses}
          classes={classes}
          label={label}
          data={data}
          isHeader={isHeader}
          isEditable={isEditable}
          propName={propName}
          formType={formType}
          dynamicPropertyEnum={dynamicPropertyEnum}
          interactionObj={interactionObj}
          saveOnChange={saveOnChange}
          isHighlighted={isHighlighted}
          areaList={areaList}
          showData={showData}
          verifyData={verifyData}
          checkFirstLetterCapital={checkFirstLetterCapital}
        />
      }
      {
        variant && variant.toLowerCase() === 'double' &&
        <DoubleLabelDataElement
          masterClasses={masterClasses}
          valueClasses={valueClasses}
          label={label}
          data={data}
          subValue={subValue}
          isDataBadge={isDataBadge}
          isEditable={isEditable}
          propName={propName}
          formType={formType}
          dynamicPropertyEnum={dynamicPropertyEnum}
          interactionObj={interactionObj}
          saveOnChange={saveOnChange}
          isHighlighted={isHighlighted}
          areaList={areaList}
          showData={showData}
          verifyData={verifyData}
          checkFirstLetterCapital={checkFirstLetterCapital}
          classes={classes}
          headerClasses={headerClasses}
        />
      }
      {
        variant && variant.toLowerCase() === 'triple' &&
        <TripleLabelDataElement
          masterClasses={masterClasses}
          action={action}
          label={label}
          action={action}
          image={image}
          data={data}
          classes={classes}
          isVerified={isVerified}
          isEditable={isEditable}
          propName={propName}
          formType={formType}
          dynamicPropertyEnum={dynamicPropertyEnum}
          interactionObj={interactionObj}
          saveOnChange={saveOnChange}
          isHighlighted={isHighlighted}
          areaList={areaList}
          showData={showData}
          verifyData={verifyData}
          checkFirstLetterCapital={checkFirstLetterCapital}
          showVerification={showVerification}
          isMasked={isMasked}
        />
      }
    </div>
  );
};

const EditableElement = (props) => {
  const {label, data, formType, propName, interactionObj, dynamicPropertyEnum, areaList, saveOnChange} = props;

  return (
    <div>
      {
        label &&
        <span class={`fs-10`}>{label}</span>
      }
      {
        (formType === 'text' || formType === 'number') && propName !== 'customerAddress' && propName !== 'registrationAddress' && propName !== 'companyCompleteAddress' &&
          <input type={formType} name={propName} id={`${propName}`} value={data ? data : ''} onInput={(e) => saveOnChange(e, propName, formType)} />
      }
      {
        (formType === 'text' || formType === 'number') && (propName === 'customerAddress') &&
          <div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="addressLine1">Address Line 1</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="addressLine1" name="addressLine1" placeholder="Address Line 1" value={(interactionObj && interactionObj.dynamicProperties_addressLine1) ? interactionObj.dynamicProperties_addressLine1 : ''} onInput={(e) => saveOnChange(e, propName, formType, 'addressLine1')} />
              </div>
              <span id={`error-addressLine1`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="addressLine2">Address Line 2</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="addressLine2" name="addressLine2" placeholder="Address Line 2" value={(interactionObj && interactionObj.dynamicProperties_addressLine2) ? interactionObj.dynamicProperties_addressLine2 : ''} onInput={(e) => saveOnChange(e, propName, formType, 'addressLine2')} />
              </div>
              <span id={`error-addressLine2`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="city">City</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="city" name="city" placeholder="City" value={(interactionObj && interactionObj.dynamicProperties_city) ? interactionObj.dynamicProperties_city : ''} onInput={(e) => saveOnChange(e, propName, formType, 'city')} />
              </div>
              <span id={`error-city`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="state">State</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="state" name="state" placeholder="State" value={(interactionObj && interactionObj.dynamicProperties_state) ? interactionObj.dynamicProperties_state : ''} onInput={(e) => saveOnChange(e, propName, formType, 'state')} />
              </div>
              <span id={`error-state`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="pincode">Pincode</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="pincode" name="pincode" placeholder="Pincode" value={(interactionObj && interactionObj.dynamicProperties_pincode) ? interactionObj.dynamicProperties_pincode : ''} onInput={(e) => saveOnChange(e, propName, formType, 'pincode')} />
              </div>
              <span id={`error-pincode`} class="error-message" style="display:none;" />
            </div>
          </div>
      }
      {
        (formType === 'text' || formType === 'number') && (propName === 'registrationAddress') &&
          <div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="registrationAddressLine1">Address Line 1</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="registrationAddressLine1" name="registrationAddressLine1" placeholder="Address Line 1" value={(interactionObj && interactionObj.dynamicProperties_registrationAddressLine1) ? interactionObj.dynamicProperties_registrationAddressLine1 : ''} onInput={(e) => saveOnChange(e, propName, formType, 'registrationAddressLine1')} />
              </div>
              <span id={`error-registrationAddressLine1`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="registrationAddressLine2">Address Line 2</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="registrationAddressLine2" name="registrationAddressLine2" placeholder="Address Line 2" value={(interactionObj && interactionObj.dynamicProperties_registrationAddressLine2) ? interactionObj.dynamicProperties_registrationAddressLine2 : ''} onInput={(e) => saveOnChange(e, propName, formType, 'registrationAddressLine2')} />
              </div>
              <span id={`error-registrationAddressLine2`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="registrationAddressCity">City</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="registrationAddressCity" name="registrationAddressCity" placeholder="City" value={(interactionObj && interactionObj.dynamicProperties_registrationAddressCity) ? interactionObj.dynamicProperties_registrationAddressCity : ''} onInput={(e) => saveOnChange(e, propName, formType, 'registrationAddressCity')} />
              </div>
              <span id={`error-registrationAddressCity`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="registrationAddressState">State</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="registrationAddressState" name="registrationAddressState" placeholder="State" value={(interactionObj && interactionObj.dynamicProperties_registrationAddressState) ? interactionObj.dynamicProperties_registrationAddressState : ''} onInput={(e) => saveOnChange(e, propName, formType, 'registrationAddressState')} />
              </div>
              <span id={`error-registrationAddressState`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="registrationAddressPincode">Pincode</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="registrationAddressPincode" name="registrationAddressPincode" placeholder="Pincode" value={(interactionObj && interactionObj.dynamicProperties_registrationAddressPincode) ? interactionObj.dynamicProperties_registrationAddressPincode : ''} onInput={(e) => saveOnChange(e, propName, formType, 'registrationAddressPincode')} />
              </div>
              <span id={`error-registrationAddressPincode`} class="error-message" style="display:none;" />
            </div>
          </div>
      }
      {
        (formType === 'text' || formType === 'number') && (propName === 'companyCompleteAddress') &&
          <div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="companyAddress">Address Line 1</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="companyAddress" name="companyAddress" placeholder="Address Line 1" value={(interactionObj && interactionObj.dynamicProperties_companyAddress) ? interactionObj.dynamicProperties_companyAddress : ''} onInput={(e) => saveOnChange(e, propName, formType, 'companyAddress')} />
              </div>
              <span id={`error-companyAddress`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="companyAddressLine2">Address Line 2</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="companyAddressLine2" name="companyAddressLine2" placeholder="Address Line 2" value={(interactionObj && interactionObj.dynamicProperties_companyAddressLine2) ? interactionObj.dynamicProperties_companyAddressLine2 : ''} onInput={(e) => saveOnChange(e, propName, formType, 'companyAddressLine2')} />
              </div>
              <span id={`error-companyAddressLine2`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="companyCity">City</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="companyCity" name="companyCity" placeholder="City" value={(interactionObj && interactionObj.dynamicProperties_companyCity) ? interactionObj.dynamicProperties_companyCity : ''} onInput={(e) => saveOnChange(e, propName, formType, 'companyCity')} />
              </div>
              <span id={`error-companyCity`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="companyState">State</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="companyState" name="companyState" placeholder="State" value={(interactionObj && interactionObj.dynamicProperties_companyState) ? interactionObj.dynamicProperties_companyState : ''} onInput={(e) => saveOnChange(e, propName, formType, 'companyState')} />
              </div>
              <span id={`error-companyState`} class="error-message" style="display:none;" />
            </div>
            <div class="row">
              <div class="col-lg-4 p-l-0 display-flex align-center">
                <label for="companyPincode">Pincode</label>
              </div>
              <div class="col-lg-8 p-l-0">
                <input type={formType} id="companyPincode" name="companyPincode" placeholder="Pincode" value={(interactionObj && interactionObj.dynamicProperties_companyPincode) ? interactionObj.dynamicProperties_companyPincode : ''} onInput={(e) => saveOnChange(e, propName, formType, 'companyPincode')} />
              </div>
              <span id={`error-companyPincode`} class="error-message" style="display:none;" />
            </div>
          </div>
      }
      {
        formType === 'select' && (propName !== "area" && propName !== "registrationArea" && propName !== "companyArea") &&
          <select id={`${propName}`} onChange={(e) => saveOnChange(e, propName, formType)}>
            <option value=''>Select {label}</option>
            {dynamicPropertyEnum.length > 0 && dynamicPropertyEnum.map(option =>
              <option selected={data === option} value={option}>{option}</option>
            )}
          </select>
      }
      {
        formType === 'select' && (propName === "area" || propName === "registrationArea" || propName === "companyArea") && (
          <select id={`${propName}`} onChange={(e) => saveOnChange(e, propName, formType)}>
            <option value=''>Select {label}</option>
            {
              areaList.length > 0 && areaList.map((enumVal) => (
                <option selected={data === enumVal.officeName} value={enumVal.officeName}>{enumVal.officeName}</option>
              ))
            }
          </select>
        )
      }
      {
        formType === 'radio' && dynamicPropertyEnum.length > 0 && dynamicPropertyEnum.map((option, index) =>
          (<div class="display-flex">
            <input type={formType} checked={data === option ? true : false} value={option} id={`${option}-${index}`} name={`${propName}`} onChange={(e) => saveOnChange(e, propName, formType)} />
            <label class={`first-letter-capital`} for={`${option}-${index}`}>
              {option}
            </label>
          </div>)
        )
      }
      {
        (formType === 'date' || formType === 'month') &&
        <input name={propName} id={`${propName}`} type={formType} value={data || moment(new Date()).format('YYYY-MM-DD')} onChange={(e) => saveOnChange(e, propName, formType)} />
      }
      <span id={`error-${propName}`} class="error-message" style="display:none;" />
    </div>
  );
};

const SingleLabelDataElement = (props) => {
  const { masterClasses, classes, label, data, isHeader, isEditable, propName, formType, dynamicPropertyEnum, interactionObj, saveOnChange, isHighlighted, areaList, showData, verifyData, checkFirstLetterCapital } = props;

  return (
    <div class={`${masterClasses || ''} h-full w-full single-label-data-element edit-element-focus`}>
      {isEditable ?
        <div class={`${classes || ''} p-l-8 p-r-8 display-flex flex-direction-column`}>
          <EditableElement
            label={label}
            data={data}
            formType={formType}
            propName={propName}
            interactionObj={interactionObj}
            dynamicPropertyEnum={dynamicPropertyEnum}
            areaList={areaList}
            saveOnChange={saveOnChange}
          />
        </div>
        :
        <p class={`${classes || ''} p-0 display-flex flex-direction-column`}>
          {
            label &&
            <span class={`${props.labelClasses ? props.labelClasses : "fs-10"} `}>{label}</span>
          }
          {
            verifyData(data) ?
              <span class={`${isHeader ? 'fs-21 f-w-600' : ' '} ${checkFirstLetterCapital(data)} ${isHighlighted ? 'highlight-yellow' : ''} ${props.headerClasses ? props.headerClasses : ' fs-14 ' }`} title={showData(data)}>{showData(data)}</span>
              :
              <span class={`${isHeader ? 'fs-21 f-w-600' : ' '} ${checkFirstLetterCapital(data)} not-available ${props.headerClasses ? props.headerClasses : ' fs-14 ' }`} title="Not Available">Not Available</span>
          }
        </p>
      }
    </div>
  );
};

const DoubleLabelDataElement = (props) => {
  const { masterClasses, label, data, isDataBadge, isEditable, propName, formType, dynamicPropertyEnum, interactionObj, saveOnChange, isHighlighted, areaList, showData, verifyData, checkFirstLetterCapital, classes, valueClasses, subValue, headerClasses } = props;
  console.log(subValue,"subValuesubValuesubValue");
  return (
    <div>
      {isEditable ?
        <div class={`${masterClasses || ''} h-full w-full double-label-data-element p-0 row edit-element-focus`}>
          <div class="col-lg-12 p-l-0 display-flex align-items-center">
            <EditableElement
              label={label}
              data={data}
              formType={formType}
              propName={propName}
              interactionObj={interactionObj}
              dynamicPropertyEnum={dynamicPropertyEnum}
              areaList={areaList}
              saveOnChange={saveOnChange}
            />
          </div>
        </div>
        :
        <div class={`${masterClasses || ''} h-full w-full double-label-data-element p-0 row`}>
          {/*
          Modification: added headerClasses and classes
          By: Masum Raja
          Date: 10/03/2024
        */}
          <div class={`${headerClasses || 'col-lg-8 col-md-8'} col-sm-6 col-xs-6 p-l-0 display-flex align-items-center`}>
            {
              label &&
            <p class={`fs-10`}>{label}</p>
            }
          </div>
          <div class={`${classes || 'col-lg-4 col-md-4'} col-sm-6 col-xs-6 p-0 display-flex align-items-center`}>
            {
              verifyData(data) ?
                <div class="flex-c-m">
                  <p class={`${valueClasses || 'fs-14'} ${isHighlighted ? 'highlight-yellow' : ''} ${checkFirstLetterCapital(data)} ${isDataBadge ? 'card-data-badge' : ''}`} title={showData(data)}>{showData(data)}</p>
                  {subValue ? <p class="p-l-8">{`(${subValue})`}</p>: ""}
                </div>
                :
                <p class={`fs-14 not-available ${checkFirstLetterCapital(data)} ${isDataBadge ? 'card-data-badge' : ''}`} title="Not Available">Not Available</p>
            }
          </div>
        </div>
      }
    </div>

  );
};

const TripleLabelDataElement = (props) => {
  const { masterClasses, classes, action, label, image, data, isVerified, isEditable, propName, formType, dynamicPropertyEnum, interactionObj, saveOnChange, isHighlighted, areaList, showVerification, showData, verifyData, checkFirstLetterCapital, isMasked } = props;

  return (
    <div>
      {isEditable ?
        <div class={`${masterClasses || ''} h-full w-full triple-label-data-element p-0 row edit-element-focus`}>
          <div class={`col-lg-2 p-l-0 p-r-5 display-flex align-items-center ${action && 'cursor-pointer'}`} title={label} onClick={(e) => {action && action();}}>
            { image && image }
          </div>
          <div class="col-lg-10 p-l-0 display-flex align-items-center">
            <EditableElement
              label={label}
              data={data}
              formType={formType}
              propName={propName}
              interactionObj={interactionObj}
              dynamicPropertyEnum={dynamicPropertyEnum}
              areaList={areaList}
              saveOnChange={saveOnChange}
            />
          </div>
        </div>
        :
        <div class={`${masterClasses || ''} h-full w-full triple-label-data-element p-0 row`}>
          <div class={`col-lg-2 p-l-0 p-r-5 display-flex align-items-center`} title={label}>
            { image && image }
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-l-0 display-flex align-items-center">
            {
              verifyData(data) ?
                <p class={`${classes || ''} ${isHighlighted ? 'highlight-yellow' : ''} ${checkFirstLetterCapital(data)} fs-14`} title={showData(data)}>{showData(data)}</p>
                :
                <p class={`${classes || ''} ${checkFirstLetterCapital(data)} fs-14 not-available`} title='Not Available'>Not Available</p>
            }
          </div>
          {
            verifyData(data) &&
            <div class={`col-lg-4 col-md-5 col-sm-4 col-xs-4 p-0 display-flex align-items-center`}>
              {
                showVerification && (
                  isVerified ?
                    <p class={`fs-10 w-full text-align-center text-green`}>Verified</p>
                    :
                    <button class={`fs-10 f-w-600 ${action && 'cursor-pointer'}`} onClick={(e) => {action && action();}}>Verify</button>
                )
              }
            </div>
          }
        </div>
      }
    </div>
  );
};

export default LabelDataElement;
