import { h } from 'preact';

const Loop = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={`${props.classes || ''}`} onclick={(e) => props.action && props.action(e)} width={props.width || "20"} height={props.height || "22"} viewBox={props.viewBox || "0 0 20 22"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 1V7H13" stroke={props.stroke || "#002C5F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.45312 6.10752C3.39878 4.65088 4.74569 3.49895 6.33138 2.79067C7.91707 2.0824 9.67382 1.84803 11.3897 2.11584C13.1056 2.38365 14.7074 3.14219 16.0019 4.29998L19.0019 6.99998" stroke={props.stroke || "#002C5F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 21V15H7" stroke={props.stroke || "#002C5F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5487 15.8925C16.6031 17.3491 15.2562 18.501 13.6705 19.2093C12.0848 19.9176 10.3281 20.152 8.61214 19.8841C6.89623 19.6163 5.29445 18.8578 4 17.7L1 15" stroke={props.stroke || "#002C5F"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  );
};

export default Loop;
