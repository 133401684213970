import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const CirclePlus = (props) => {
  return (
    <svg class={props.classes || ''} onClick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" class={`${props.classes || ""}`} fill={props.fill || "none"} width={props.width || '18'}
      height={props.height || "18"} viewBox="0 0 18 18"
    >
      <circle cx="9" cy="9" r="8.5" stroke={props.stroke || "#002C5F"} stroke-dasharray="2 2" />
      <path d="M12.3838 7.54492V9.02832H5.56152V7.54492H12.3838ZM9.77246 4.75586V12.002H8.17969V4.75586H9.77246Z" fill={props.fill || "#002C5F"} />
    </svg>
  );
};

export default CirclePlus;
