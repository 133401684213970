import { h, Component, Fragment } from 'preact';
import { useState, useEffect, useRef } from "preact/hooks";
import { route } from "preact-router";
import CONSTANTS from '../../lib/constants';
import axios from "axios";
import { setItem, getItem, removeAll, removeItem } from '../../lib/myStore';
import { Search, ContactSearchSuggestion, TaskSearchSuggestion, InteractionSearchSuggestion, CaseSearchSuggestion, Cross } from '../../components/svgs';
import { isEmptyObject, getTextWithHighlightMatching } from '../../lib/utils';
import { reportException } from '../../lib/transceiver';
import toastr from "toastr";
let mainSource;

const SearchInputSuggestion = (props) => {
  const { setIsGlobalSearchVisible, masterClasses, isMobileView } = props;
  const fileName = 'searchInputSuggestion';

  // let searchInputRegex = /[^a-zA-Z0-9]/;
  const searchInputRegex = /[^[\w\-\s]+$]/;
  let localStorageSearchText = getItem('searchText');
  console.log(localStorageSearchText,'localStorageSearchTextlocalStorageSearchTextlocalStorageSearchTextlocalStorageSearchText');
  const userInfo = getItem('userinfo');
  let [searchText, setSearchText] = useState(!isEmptyObject(localStorageSearchText) ? localStorageSearchText : '');
  let [searchSuggestionList, setSearchSuggestionList] = useState([]);
  let [isSuggestionListShown, setIsSuggestionListShown] = useState(false);
  let searchInputElement = useRef();
  let [isLoading, setIsLoading] = useState(true);

  let [recentlyViewedList, setRecentlyViewedList] = useState([]);
  let [masterInteractionID, setMasterInteractionID] = useState('');

  useEffect(() => {
    focusSearchInput();
  }, []);

  useEffect(async () => {
    if (mainSource) {
      console.log(mainSource, 'mainSourcemainSource');
      mainSource.cancel();
    }
    const CancelToken = axios.CancelToken;
    mainSource = CancelToken.source();

    setIsLoading(true);
    /*
      By: Yashvi
      On: 26th March 2024
      Point 19: Remove recently viewed and search suggestions
    */
    if (searchText) {
      // await getSearchSuggestions();
      // await searchRecentlyViewedCases();
    } else {
      // await getRecentlyViewedCases();
      setIsSuggestionListShown(false);
    }
    setIsLoading(false);
  }, [searchText]);
  {/*
    Modification: Display a toastr error message when the user tries to search without entering any value into the search input box.
    By: Arun Singh
    Date: 15/04/2024
  */}
  useEffect(()=>{
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-bottom-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "600",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "slideDown",
      "hideMethod": "slideUp"
    };
  },[]);

  const focusSearchInput = () => {
    searchInputElement.current.focus();
  };

  const getSearchSuggestions = async () => {
    try {
      /*
        Modification: Encoded URI param
        By: Devang
        Date: 27/07/2023
      */
      let response = await axios.get(`${CONSTANTS.API_URL}/api/v1/globalSearchSuggestions/${encodeURIComponent(searchText)}?dealershipID=${userInfo.userDealershipID}`, {
        cancelToken: mainSource.token
      });
      if (response && response.data && response.data.length) {
        setSearchSuggestionList(response.data);
        setIsSuggestionListShown(true);
      } else {
        setSearchSuggestionList([]);
        setIsSuggestionListShown(false);
      }
    } catch (HTTPException) {
      setSearchSuggestionList([]);
      setIsSuggestionListShown(false);
      await reportException({
        HTTPException,
        fileName,
        functionName: 'getSearchSuggestions'
      });
    }
  };

  const getRecentlyViewedCases = async () => {
    let mainInteraction = await axios.get(`${CONSTANTS.API_URL}/api/v3/interaction`, {
      cancelToken: mainSource.token
    }).then(async (res) => {
      console.log(res);
      let masterInteractionID = res.data[0].uuid;
      setMasterInteractionID(masterInteractionID);
      // let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?masterInteractionID=${masterInteractionID}&dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
      let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
      let response = await axios.get(QUERY);
      console.log(response,'responseresponseresponse');
      setRecentlyViewedList(response.data.list);
    });
  };

  const searchRecentlyViewedCases = async () => {
    // let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?masterInteractionID=${masterInteractionID}&dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
    let QUERY = `${CONSTANTS.API_URL}/api/v1/caseListCount?dealershipID=${userInfo.userDealershipID}&recentlyViewedID=${userInfo.uuid}&isRecentlyViewed=true&noLimit=noLimit&sortBy=recentlyViewed`;
    if (searchText) {
      QUERY += `&q=${searchText}`;
    }
    let response = await axios.get(QUERY, {
      cancelToken: mainSource.token
    });
    console.log(response,'responseresponseresponse');
    setRecentlyViewedList(response.data.list);
  };

  const getRowButtonIcon = (typeOfResult) => {
    if (['Contact', 'User'].includes(typeOfResult)) {
      return <ContactSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Task') {
      return <TaskSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Interaction') {
      return <InteractionSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Case') {
      return <CaseSearchSuggestion masterClasses="flex-c-m" title={typeOfResult} fill="#404040" width="20" height="20" />;
    } else if (typeOfResult === 'Courier') {
      return <div class="search-suggestion-letter-icon flex-c-m" title={typeOfResult}>C</div>;
    } else if (typeOfResult === 'Visit') {
      return <div class="search-suggestion-letter-icon flex-c-m" title={typeOfResult}>V</div>;
    }
  };

  const setSearchTextValue = (e) => {
    if (!searchInputRegex.test(e.target.value)) {
      setSearchText(e.target.value);
    }
  };
  {/*
    Modification: Display a toastr error message when the user tries to search without entering any value into the search input box.
    By: Arun Singh
    Date: 15/04/2024
  */}
  const routeToSearchPage = (text) => {
    if (text) {
      setItem('searchText', text);
      setIsGlobalSearchVisible(e => !e);
      route('/search');
    } else {
      toastr.error('Please enter text!');
    }
  };
  {/*
    Modification: To removed search key from localStorage using resetSearchInput function
    By: Arun Singh
    Date: 15/04/2024
  */}

  const resetSearchInput = () => {
    try {
      removeItem('searchText');
      localStorageSearchText = getItem('searchText');
      setSearchText(!isEmptyObject(localStorageSearchText) ? localStorageSearchText : '');
    } catch (error) {
      console.error('Error resetting search input:', error);
    }
  };

  const routeToSearchPageOnEnter = (e) => {
    if (e.keyCode == 13) {
      routeToSearchPage(searchText);
    }
  };

  const selectSearchSuggestion = (text) => {
    setSearchText(text);
    routeToSearchPage(text);
  };

  return (
    <Fragment>
      <div class={`${masterClasses || ''} search-input-suggestion-container`}>
        <div class="search-input-container flex-c-m gap-10px">
          <input type="text" class="search-input" ref={searchInputElement} aria-label="search" placeholder="Search Text" value={searchText} onInput={setSearchTextValue} onkeydown={routeToSearchPageOnEnter} />
          {/*
            Suggestion(22): T1692 - Scenario - Desktop - Global Search - Cross icon
            Description: Cross icon is missing to remove the search input
            By: Arun Singh
            Date: 15/04/2024
          */}
          {searchText && <Cross masterClasses={'flex-c-m cursor-pointer'} height="14px" width="14px" fill="#002C5F" title='Clear Search' action={() => resetSearchInput(searchText)} />}
          <div class='h-33px p-7 bor-l-v2'>
            <Search masterClasses={'flex-c-m cursor-pointer'} height="16px" width="16px" fill="#002C5F" title='Global Search' action={() => routeToSearchPage(searchText)} />
          </div>
        </div>
        {/*
            By: Yashvi
            On: 26th March 2024
            Point 19: Remove recently viewed and search suggestions

            <div class="search-suggestion-list-container">
            !isLoading ?
              <Fragment>
                {
                  (recentlyViewedList.length > 0) &&
                  <div class={isMobileView ? 'w-full' : ''}>
                    <span class={isMobileView ? 'm-l-10' : ''}>Recently Viewed Cases</span>
                    <div style="border-bottom: 1px dotted;max-height: 200px;overflow-y: auto;">
                      {recentlyViewedList.map((item) => (
                        <div class="search-suggestion cursor-pointer flex-l-m" onclick={() => selectSearchSuggestion(item.dynamicProperties_customerName)}>
                          {getRowButtonIcon('Case')}
                          {console.log(item, item.dynamicProperties_customerName, 'item item item')}
                          <p class="search-suggestion-text" dangerouslySetInnerHTML={getTextWithHighlightMatching(item.dynamicProperties_customerName, searchText)} />
                        </div>
                      ))}
                    </div>
                  </div>
                }
                {
                  (isSuggestionListShown && searchText && searchSuggestionList.length > 0) &&
                <div class={isMobileView ? 'w-full' : ''}>
                  {searchSuggestionList.map((item) => (
                    <div class="search-suggestion cursor-pointer flex-l-m" onclick={() => selectSearchSuggestion(item.text)}>
                      {getRowButtonIcon(item.typeOfResult)}
                      <p class="search-suggestion-text" dangerouslySetInnerHTML={getTextWithHighlightMatching(item.text, searchText)} />
                    </div>
                  ))}
                </div>
                }
                {
                  (recentlyViewedList.length === 0 && searchSuggestionList.length === 0) &&
                <span> No Records </span>
                }
              </Fragment>
              :
              <span> Loading... </span>
            </div>
          */}
      </div>
    </Fragment>
  );
};

export default SearchInputSuggestion;
