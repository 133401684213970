import { h } from 'preact';
import { useState, useEffect,useRef } from 'preact/hooks';


const SkeletonCard = (props) => {
  const {containerClassName, containerHeight, className, height, children, colorType} = props;

  /*
    Modification: Added props and configuration for loader color type
    By: Devang
    Date: 13/06/2023
  */
  const getColorTypeClass = (colorType) => {
    if (colorType === 'yellow') return 'yellow-card-loader';
    if (colorType === 'orange') return 'orange-card-loader';
    if (colorType === 'blue') return 'blue-card-loader';
    return 'default-card-loader';
  };
  return (
    <div class={`skeleton-card ${containerClassName || ''}`}>
      <div class={`is-loading`} style={`height:${containerHeight || ''}; width: 100%; box-shadow: none;`}>
        <div class={`${className || ''} ${getColorTypeClass(colorType)} w-full`} style={`height:${height || ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
