import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Accessories = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "256pt"} height={props.height || "256pt"}
        viewBox={props.viewBox || "0 0 256.000000 256.000000"} preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill={props.fill || ''} stroke={props.stroke || 'none'} >
          <path d="M1020 2549 c-32 -13 -59 -38 -75 -70 -27 -51 -52 -420 -34 -488 3 -10 -9 -17 -37 -22 -97 -18 -177 -77 -220 -162 -35 -69 -43 -154 -51 -523 -6 -335 7 -609 31 -669 12 -28 11 -32 -25 -66 -47 -47 -59 -100 -59 -257 1 -181 18 -227 100 -270 l44 -22 586 0 586 0 44 23 c82 42 99 88 100 269 0 157 -12 210 -59 257 -36 34 -37 38 -25 66 24 60 37 334 31 669 -4 176 -11 356 -16 398 -20 158 -111 261 -255 287 -28 5 -40 12 -37 22 12 46 11 126 -4 285 -17 191 -26 219 -78 258 -27 20 -42 21 -275 23 -149 1 -257 -2 -272 -8z m514 -101 c8 -14 20 -94 30 -212 16 -177 16 -190 0 -207 -16 -18 -36 -19 -284 -19 -248 0 -268 1 -284 19 -16 17 -16 30 0 207 10 118 22 198 30 212 14 22 16 22 254 22 238 0 240 0 254 -22z m75 -553 c138 -23 191 -55 225 -135 l14 -36 -121 -136 -122 -137 -325 0 -325 0 -120 135 c-73 82 -119 142 -117 152 6 31 37 75 72 104 39 31 117 51 262 67 117 14 444 5 557 -14z m-805 -402 l96 -107 0 -393 0 -393 -73 0 c-102 0 -114 14 -129 146 -19 166 -13 854 7 854 2 0 46 -48 99 -107z m1068 -318 c1 -387 -9 -515 -43 -551 -20 -21 -32 -24 -96 -24 l-73 0 0 393 0 393 97 109 98 108 8 -44 c5 -24 9 -197 9 -384z m-294 128 l3 -63 -301 0 -300 0 0 58 c0 32 3 62 7 65 4 4 137 6 297 5 l291 -3 3 -62z m2 -218 l0 -65 -300 0 -300 0 0 65 0 65 300 0 300 0 0 -65z m0 -210 l0 -65 -300 0 -300 0 0 65 0 65 300 0 300 0 0 -65z m-2 -212 l3 -63 -301 0 -300 0 0 58 c0 32 3 62 7 65 4 4 137 6 297 5 l291 -3 3 -62z m297 -159 c28 -11 45 -60 45 -134 l0 -70 -640 0 -640 0 0 70 c0 71 17 123 42 133 19 8 1173 8 1193 1z m45 -329 c0 -25 -8 -44 -25 -60 l-24 -25 -591 0 -591 0 -24 25 c-17 16 -25 35 -25 60 l0 35 640 0 640 0 0 -35z" />
        </g>
      </svg>
    </span>
  );
};

export default Accessories;
