import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Clipboard = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" class={`${props.classes || ""}`} width={props.width || "512pt"} height={props.height || "512pt"} viewBox={props.viewBox || "0 0 512 512"} preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={props.fill || "#000000"} stroke={props.stroke || "none"}>
          <path d="M2381 4955 c-150 -42 -281 -145 -345 -272 l-16 -31 -432 -4 c-411 -4 -436 -6 -499 -26 -172 -57 -309 -201 -354 -373 -22 -83 -22 -3608 0 -3692 52 -200 219 -351 425 -387 46 -8 456 -10 1400 -8 l1335 3 72 26 c130 48 242 146 302 266 53 107 51 15 51 1948 0 1704 -1 1794 -18 1856 -46 165 -183 305 -351 361 -62 20 -89 22 -496 26 l-430 5 -50 76 c-61 92 -156 168 -259 207 -89 34 -250 43 -335 19z m278 -271 c70 -37 114 -91 147 -175 39 -105 48 -109 222 -109 l145 0 -5 -83 c-9 -137 -60 -222 -166 -274 l-57 -28 -425 0 -425 0 -57 28 c-105 52 -155 134 -165 272 l-6 85 145 0 c172 0 184 5 219 101 54 148 160 223 305 216 54 -3 82 -10 123 -33z m-1035 -386 c7 -118 22 -174 68 -261 74 -140 231 -246 394 -268 50 -7 236 -9 490 -7 l409 3 71 26 c136 49 242 141 300 258 41 85 54 136 61 249 l6 104 211 -4 c229 -5 251 -10 329 -64 31 -22 54 -50 76 -94 l31 -63 0 -1767 c0 -1958 5 -1816 -66 -1898 -19 -23 -60 -53 -92 -69 l-57 -28 -1335 0 -1335 0 -57 28 c-32 16 -73 46 -92 69 -71 82 -66 -60 -66 1897 l0 1766 24 51 c40 87 114 145 209 163 23 5 126 9 228 10 l186 1 7 -102z"/>
          <path d="M3145 2775 c-16 -7 -207 -187 -424 -399 l-394 -385 -204 197 c-113 108 -214 202 -226 209 -50 28 -130 5 -160 -46 -20 -34 -22 -97 -4 -127 16 -28 506 -508 534 -522 28 -15 92 -15 119 1 35 20 911 878 923 904 18 39 13 98 -10 127 -41 53 -97 68 -154 41z"/>
        </g>
      </svg>
    </span>
  );
};

export default Clipboard;
